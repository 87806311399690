import { Typography } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { myTheme } from 'theme/theme';

const CustomPageHeader = ({
  header,
  sx,
  tooltipText,
  margin = true
}: {
  header: string;
  sx?: any;
  tooltipText: string;
  margin?: boolean;
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: margin ? '24px' : '0'
    }}>
    <Typography variant='h4' sx={{ color: myTheme.palette.fontColor, fontWeight: '600', ...sx }}>
      {header}
    </Typography>
    <CustomTooltip title={tooltipText} />
  </div>
);

export default CustomPageHeader;
