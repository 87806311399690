import styled from 'styled-components';
import { Header } from '../Typography/Header';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${Header};
  margin-bottom: ${({ theme }) => theme.dimensions.spacingHeader * 2}px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const TableButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ActionButtonsWrapper = styled.td`
  display: flex;

  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const ActionColumn = styled.span`
  color: ${({ theme }) => theme.palette.link2};
  cursor: pointer;
`;
