import SearchBar from 'adp-panel/components/Navbar/SearchBar';
import { InnerWrapper, Wrapper } from './styled';
import { useUsers } from 'adp-panel/hooks/api/useUsers';
import { UserExtendOptions, UsersQueryParams } from 'adp-panel/api/users/users.types';
import ConnectButton from '../ConnectButton/ConnectButton';
import { useLocation } from 'react-router-dom';
import { DASHBOARD } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';

const Navbar = () => {
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.devices,
      UserExtendOptions.clinicians,
      UserExtendOptions.devicesAsClinician
    ],
    roles: 'Amputee'
  };
  const { rolesByName } = useUserData();
  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);
  const { result: patientsData } = useUsers(queryParams);
  const location = useLocation();
  const patientsExist = patientsData?.length > 0;

  const dontShowDashboard = location.pathname === DASHBOARD && !patientsExist;

  return (
    <Wrapper>
      <InnerWrapper>
        {!dontShowDashboard && !isAdmin && (
          <>
            <SearchBar />
            <ConnectButton />
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default Navbar;
