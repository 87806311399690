import { toast } from 'react-hot-toast';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { closeVideoSession } from 'configurator/api/liveSession/liveSession';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useRemoteSession from './useRemoteSession';

const useMeeting = () => {
  const { checkVideoMeeting, initiateVideoMeeting, videoSessionId, resetVideoMeeting } =
    useMeetingStore((state) => ({
      checkVideoMeeting: state.checkVideoMeeting,
      initiateVideoMeeting: state.initVideoMeeting,
      setItemMeeting: state.setItemMeeting,
      videoSessionId: state.videoSessionId,
      resetVideoMeeting: state.resetVideoMeeting
    }));
  const { sendMeeting, sendCloseMeeting } = useRemoteSession();

  const handleOpenMeeting = async (amputeeId: number) => {
    let videoMeeting = await checkVideoMeeting({ amputeeId });
    if (!videoMeeting) videoMeeting = await initiateVideoMeeting({ amputeeId });

    if (!videoMeeting) return;

    sendMeeting({
      roomId: videoMeeting.room_name,
      appId: process.env.REACT_APP_JITSI_APP_ID,
      token: videoMeeting.jwt_guest,
      meetingId: videoMeeting.id
    });
  };

  const handleCloseMeeting = async (notifyMobile = true) => {
    try {
      if (videoSessionId === null) {
        return;
      }
      useUiStore.setState({ videoSessionState: FETCHING_STATES.loading });
      await closeVideoSession({ sessionId: videoSessionId });
      resetVideoMeeting();
      if (notifyMobile) sendCloseMeeting();
      useUiStore.setState({ videoSessionState: FETCHING_STATES.successful });
    } catch (err) {
      console.log(err);
      useUiStore.setState({ videoSessionState: FETCHING_STATES.failed });
      toast.error("Couldn't stop a video meeting");
    }
  };
  return { handleOpenMeeting, handleCloseMeeting };
};

export default useMeeting;
