import { Grips } from 'bluetooth/Bluetooth/Grips';
import powerGripImg from 'assets/grips/power.png';
import precisionOpenGripImg from 'assets/grips/precision-open.png';
import precisionClosedGripImg from 'assets/grips/precision-closed.png';
import triggerGripImg from 'assets/grips/trigger.png';
import tripodClosedGripImg from 'assets/grips/tripod-closed.png';
import tripodOpenGripImg from 'assets/grips/tripod-open.png';
import hookGripImg from 'assets/grips/hook.png';
import fingerPointGripImg from 'assets/grips/fingerPoint.png';
import keyGripImg from 'assets/grips/key.png';
import mouseGripImg from 'assets/grips/mouse.png';
import restGripImg from 'assets/grips/rest.png';
import openPalmGripImg from 'assets/grips/open-palm.png';
import activeIndexGripImg from 'assets/grips/active-index.png';
import countingGripImg from 'assets/grips/counting.png';
import customGripImg from 'assets/grips/custom_grip.svg';

const gripsImages: any = [
  [Grips.kGripPower, powerGripImg],
  [Grips.kGripTripodClosed, tripodClosedGripImg],
  [Grips.kGripPrecisionOpen, precisionOpenGripImg],
  [Grips.kGripPrecisionClosed, precisionClosedGripImg],
  [Grips.kGripTripodOpen, tripodOpenGripImg],
  [Grips.kGripTrigger, triggerGripImg],
  [Grips.kGripHook, hookGripImg],
  [Grips.kGripKey, keyGripImg],
  [Grips.kGripFingerPoint, fingerPointGripImg],
  [Grips.kGripMouse, mouseGripImg],
  [
    Grips.kGripTypeUnknown,
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
  ],
  [Grips.kGripRestOpp, restGripImg],
  [Grips.kGripRestNopp, openPalmGripImg],
  [Grips.kGripFingerPointOpen, activeIndexGripImg],
  [Grips.kGripCounting, countingGripImg],
  [Grips.kGripCustom1, customGripImg],
  [Grips.kGripCustom2, customGripImg],
  [Grips.kGripCustom3, customGripImg]
];
export const gripsImagesMap = new Map(gripsImages);
