import styled, { css } from 'styled-components';

const TextStyle = css`
  color: ${({ theme }) => theme.palette.neutral};
  font-size: ${({ theme }) => theme.fontSize.label};
`;

export const Wrapper = styled.div<{ lightVariant: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme, lightVariant }) =>
    lightVariant ? theme.palette.primaryLight : 'inherit'};
`;

export const LogoLoginPage = styled.img`
  height: 44px;
  margin: 20px 0;
`;

export const LogoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 4}px;
  width: 160px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const FooterWrapper = styled.footer`
  margin-top: ${({ theme }) => theme.dimensions.spacing * 2}px;
  text-align: center;
  ${TextStyle};
`;

export const Udi = styled.div`
  text-align: center;
  ${TextStyle};
`;

export const Spacer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.separator};
  width: 100%;
`;

export const MainWrapper = styled.main``;
