import i18n from 'i18n';
import { DUMMY_ROLE_ID } from './utils';
import * as yup from 'yup';
import { emailSchema } from 'configurator/views/Register/utils';

export const medicalProfessionalsSchema = (cliniciansData, patientRequired = true) =>
  yup.object().shape({
    ...(patientRequired && {
      patient: yup
        .mixed()
        .required(i18n.t('medical_professionals:patient_required.text', 'Patient is required'))
    }),
    clinicians: yup.array().of(
      yup.object().shape({
        role: yup
          .mixed()
          .required(i18n.t('medical_professionals:role_required.text', 'Role is required'))
          .test(
            'is-valid-role',
            i18n.t('medical_professionals:role_required.text', 'Role is required'),
            function (value) {
              const role = value as { id: number };
              if (role.id === DUMMY_ROLE_ID) {
                return false;
              } else {
                return true;
              }
            }
          ),

        clinician: yup
          .mixed()
          .required(
            i18n.t('medical_professionals:clinician_required.text', 'Clinician is required')
          )
          .test(function (value) {
            const clinician = value as { email: string };
            if (typeof clinician === 'string') {
              if (!emailSchema.isValidSync(clinician)) {
                return this.createError({
                  message: i18n.t(
                    'medical_professionals:clinician_valid.text',
                    'Clinician must be a valid email string'
                  )
                });
              } else if (cliniciansData.some((item) => item.email === clinician)) {
                return this.createError({
                  message: i18n.t(
                    'medical_professionals:email_in_database.text',
                    'email already in database, select person from list'
                  )
                });
              }
            } else if (typeof clinician === 'object' && clinician !== null) {
              const isEmailValid = emailSchema.isValidSync(clinician.email);
              if (!isEmailValid) {
                return this.createError({
                  message: i18n.t(
                    'medical_professionals:clinician_validation.text',
                    'Clinician must have a valid email'
                  )
                });
              }
            } else {
              return this.createError({
                message: i18n.t(
                  'medical_professionals:clinician_required.text',
                  'Clinician is required'
                )
              });
            }

            return true;
          })
          .nullable()
      })
    ),
    trainingConfirmation: yup
      .boolean()
      .oneOf(
        [true],
        i18n.t('medical_professionals:training_required.text', 'Training confirmation is required')
      )
  });
