import { FormControl, Select } from '@mui/material';
import { CustomInputLabel } from './FormComboBox';

const CustomDropdown = ({
  id,
  label,
  value = undefined,
  onChange,
  options,
  disabled = false,
  renderValue,
  displayEmpty = false
}: {
  id: string;
  label?: string;
  value?: any;
  onChange: any;
  options: any;
  disabled?: boolean;
  renderValue?: any;
  displayEmpty?: boolean;
}) => {
  return (
    <FormControl sx={{ marginTop: label ? '30px' : 0 }}>
      {label && (
        <CustomInputLabel
          id={id}
          label={label}
          style={{ position: 'relative', top: '-42px', left: '-14px' }}
        />
      )}
      <Select
        disabled={disabled}
        notched={false}
        value={value}
        labelId={`${id}-label`}
        id={id}
        size='small'
        renderValue={renderValue}
        displayEmpty={displayEmpty}
        onChange={(e) => onChange(e.target.value)}>
        {options}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
