/* eslint-disable no-await-in-loop */
import { isEmpty } from 'lodash';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import {
  postCommunicateMode,
  postSaveSettings
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { compareConfigs } from 'configurator/reducers/helpers/bluetoothHelpers';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';

const useSynchronizeBluetooth = () => {
  const {
    slotSelected,
    setItemConfigStore,
    sendWholeConfigDevice,
    getInitialConfigAPI,
    setConfigCopy,
    addSendingQueue,
    removeSendingQueue
  } = useConfigStore((state) => ({
    slotSelected: state.slotSelected,
    setItemConfigStore: state.setItemConfigStore,
    importConfig: state.importConfig,
    sendWholeConfigDevice: state.sendWholeConfigDevice,
    getInitialConfigAPI: state.getInitialConfigAPI,
    setConfigCopy: state.setConfigCopy,
    addSendingQueue: state.addSendingQueue,
    removeSendingQueue: state.removeSendingQueue
  }));
  const { setItemUiStore, blockScreen, unblockScreen } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen
  }));

  const sendAllConfig = async (
    config: { commonDifference: any; modes: { difference: any; slot: number }[] },
    permanently = true
  ) => {
    let sendingQueue;
    try {
      console.log(config, 'SENDING');
      sendingQueue = addSendingQueue(config);

      if (sendingQueue.length > 1) {
        blockScreen(BLOCK_MODALS.SEND_ALL_CONFIG);
        await delay(2000);
      }

      await sendWholeConfigDevice({
        configToSend: config.commonDifference
      });

      for (let index = 0; index < config.modes.length; index += 1) {
        const element = config.modes[index];
        await postCommunicateMode(element.slot);
        await delay(100);
        await sendWholeConfigDevice({
          configToSend: element.difference
        });
      }
    } finally {
      sendingQueue = removeSendingQueue();
      if (sendingQueue.length === 0) unblockScreen(BLOCK_MODALS.SEND_ALL_CONFIG);
      if (permanently) {
        await postSaveSettings();
      }
    }
  };

  // Sends apiConfig settings based on differences between apiConfig and deviceConfig, by default sends API config to hand

  const synchronizeConfig = async (config, reverse = false) => {
    setItemUiStore('synchronizingState', FETCHING_STATES.loading);
    try {
      await delay(100);
      const configDifferences = reverse
        ? compareConfigs(config.common.configAPI, config.common.config)
        : compareConfigs(config.common.config, config.common.configAPI);

      const modesDifferences: any = [];

      config.modes.forEach((mode) => {
        const difference = reverse
          ? compareConfigs(mode.configAPI, mode.config)
          : compareConfigs(mode.config, mode.configAPI);
        if (!isEmpty(difference)) modesDifferences.push({ ...mode, difference });
      });

      await sendAllConfig({ commonDifference: configDifferences, modes: modesDifferences });

      // Switch to previously selected mode
      await postCommunicateMode(slotSelected);

      // Apply new config to store
      if (!reverse) {
        await getInitialConfigAPI();
      }
      setConfigCopy();
      setItemConfigStore('configConflict', false);
      setItemUiStore('synchronizingState', FETCHING_STATES.successful);
    } catch (e) {
      console.log(e);
      setItemUiStore('synchronizingState', FETCHING_STATES.failed);
    }
  };

  return { synchronizeConfig, sendAllConfig };
};

export default useSynchronizeBluetooth;
