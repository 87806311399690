import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advanced from 'dayjs/plugin/advancedFormat';
import { BasicTooltip } from '@nivo/tooltip';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

export const GripBreakdownGraphHour = ({
  data,
  keys,
  userTimezone,
  yLabel = 'Number of grips performed'
}: any) => (
  <ResponsiveBar
    data={data}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    keys={keys}
    indexBy='group_by'
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Hour ${dayjs().tz(userTimezone).format('z')}`,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Grip breakdown by hour chart'
  />
);

export const EmgPeakGraphNew = ({
  data,
  keys,
  userTimezone,
  yLabel = 'Count of EMG peaks'
}: any) => (
  <ResponsiveLine
    data={[
      {
        id: 'emg',
        color: 'hsl(248, 70%, 50%)',
        data
      }
    ]}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    colors={['#0e63f4']}
    xFormat='time:%H:%M'
    xScale={{
      format: '%Y-%m-%d %H:%M:%S.%L',
      precision: 'minute',
      type: 'time',
      useUTC: false
    }}
    yScale={{
      type: 'linear'
    }}
    axisBottom={{
      format: '%H:%M',
      tickValues: 'every 1 hour',
      legend: `Hour ${dayjs().tz(userTimezone).format('z')}`,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    tooltip={({ point }: any) => {
      return (
        <BasicTooltip
          id={
            <span>
              time: <strong>{point.data.xFormatted}</strong>, count:{' '}
              <strong>{point.data.yFormatted}</strong>
            </span>
          }
          enableChip={true}
          color={point.serieColor}
        />
      );
    }}
    pointSize={8}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
);
export const EmgPeakGraph = ({ data, keys, userTimezone, yLabel = 'Count of EMG peaks' }: any) => (
  <ResponsiveBar
    data={data}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    keys={keys}
    indexBy='datetime_interval'
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Hour ${dayjs().tz(userTimezone).format('z')}`,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Count of EMG peaks'
  />
);

const findMaxValue = (data: any[], keys: string[]) => {
  return Math.max(...data.map((item) => Math.max(...keys.map((key) => Number(item[key]) || 0))));
};

export const GripCountGraph = ({ data, keys, yLabel = 'Number of grips performed' }: any) => {
  const maxValue = findMaxValue(data, keys);
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy='label_name'
      margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      maxValue={maxValue * 1.18}
      colors={['#0e63f4']}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Grip',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendPosition: 'middle',
        legendOffset: -90,
        tickValues: 8
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 80,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role='application'
      ariaLabel='Total grip breakdown chart'
    />
  );
};

export const GripCountHourlyGraph = ({
  data,
  keys,
  userTimezone,
  yLabel = 'Number of grips performed'
}: any) => (
  <ResponsiveBar
    data={data}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    keys={keys}
    indexBy='group_by'
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Hour ${dayjs().tz(userTimezone).format('z')}`,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Total grip breakdown chart'
  />
);

export const GripBreakdownGraphDay = ({
  data,
  keys,
  yLabel = 'Number of grips performed'
}: any) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy='group_by'
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Day',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Grip breakdown by day chart'
  />
);

export const GripTransitionBreakdownGraph = ({ data }: any) => (
  <ResponsiveBar
    data={data}
    colors={['#0e63f4']}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Switches to selected grip',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Number of switches',
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Grip transition breakdown chart'
  />
);

const GOALS_GRAPH_LEGEND_OFFSET = 50;
const GOALS_GRAPH_BOTTOM_LEGEND_OFFSET = GOALS_GRAPH_LEGEND_OFFSET + 40;
const GOALS_GRAPH_MARGINS = { top: 32, right: 0, bottom: 140, left: 100 };

const adjustment = (label: string, fontHeight = 4) =>
  label?.length ? label.length * fontHeight : 0;

const rotateTick = (label: string, dataSize: number, maxTotalLabelLength = 760, fontWidth = 10) => {
  if (!label || !dataSize) return 0;

  return label.length * dataSize * fontWidth > maxTotalLabelLength ? -45 : 0;
};

const findBiggestGripValue = (data: any, keys: string[]) => {
  let maxVal = Number.MIN_SAFE_INTEGER;
  const transformedData = data.map((item: any) => {
    const transformedItem: any = {};
    keys.forEach((key: string) => {
      if (item[key]) {
        transformedItem[key] = item[key];
      }
    });
    return transformedItem;
  });
  transformedData.forEach((item: any) => {
    Object.keys(item).forEach((key: any) => {
      if (item[key] > maxVal) {
        maxVal = item[key];
      }
    });
  });

  return maxVal < 0 ? 0 : maxVal;
};

export const GripGoalsCountGraph = ({
  data,
  keys,
  yLabel = 'Number of grips performed',
  legend,
  size,
  ...props
}: any) => {
  const tickRotation = rotateTick(data?.[0]?.label_name, data?.length, size);
  const dataProvided = data?.[0]?.label_name;
  const markerValues = props?.markers.map((marker: any) => marker.value);
  const maxMarkerValue = Math.max(...markerValues);
  const placeholderDataset = [{ ...keys, label_name: 'No data' }];

  const maxValue = findBiggestGripValue(data, keys);

  return (
    <ResponsiveBar
      {...props}
      data={dataProvided ? data : placeholderDataset}
      keys={keys}
      indexBy='label_name'
      groupMode='grouped'
      margin={GOALS_GRAPH_MARGINS}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      axisTop={null}
      axisRight={null}
      maxValue={Math.max(maxValue, maxMarkerValue) * 1.2}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation,
        legend,
        legendPosition: 'middle',
        legendOffset:
          GOALS_GRAPH_LEGEND_OFFSET +
          adjustment(data?.[0]?.label_name) * Math.sin(Math.abs(tickRotation))
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendPosition: 'middle',
        legendOffset: -90
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY:
            GOALS_GRAPH_BOTTOM_LEGEND_OFFSET +
            adjustment(data?.[0]?.label_name) * Math.sin(Math.abs(tickRotation)),
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role='application'
      ariaLabel='Grips progress graph'
    />
  );
};

export const SwitchesGoalsCountGraph = ({
  data,
  keys,
  legend,
  yLabel = 'Number of grips switches performed',
  size,
  ...props
}: any) => {
  const tickRotation = rotateTick(data?.[0]?.label_name, data?.length, size);
  const dataProvided = data?.[0]?.label_name;
  const markerValues = props?.markers.map((marker: any) => marker.value);
  const maxMarkerValue = Math.max(...markerValues);
  const placeholderDataset = [{ ...keys, label_name: 'No data' }];

  const maxValue = findBiggestGripValue(data, keys);

  return (
    <ResponsiveBar
      {...props}
      data={dataProvided ? data : placeholderDataset}
      keys={keys}
      indexBy='label_name'
      groupMode='grouped'
      margin={GOALS_GRAPH_MARGINS}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'set1' }}
      axisTop={null}
      axisRight={null}
      maxValue={Math.max(maxValue, maxMarkerValue) * 1.2}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: tickRotation,
        legend,
        legendPosition: 'middle',
        legendOffset:
          GOALS_GRAPH_LEGEND_OFFSET +
          adjustment(data?.[0]?.label_name) * Math.sin(Math.abs(tickRotation))
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendPosition: 'middle',
        legendOffset: -90
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY:
            GOALS_GRAPH_BOTTOM_LEGEND_OFFSET +
            adjustment(data?.[0]?.label_name) * Math.sin(Math.abs(tickRotation)),
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role='application'
      ariaLabel='Switches progress graph'
    />
  );
};

export const ExerciseGoalsGraph = ({
  data,
  yLabel = 'Performed exercises',
  legend = 'Time',
  size,
  ...props
}: any) => {
  const dataInner = data?.[0]?.data;

  const label = dataInner?.[0]?.x;
  const labelLength = label?.length;
  const tickRotation = rotateTick(label, dataInner?.length, size);
  const dataValues = dataInner?.map((pointA: any) => pointA.y);
  const maxDataValue = Math.max(...dataValues);

  const markerValues = props?.markers.map((marker: any) => marker.value);
  const maxMarkerValue = Math.max(...markerValues);
  const applyMarginAdjustment = tickRotation === 0;

  return (
    <ResponsiveLine
      {...props}
      data={data}
      indexBy='label_name'
      groupMode='grouped'
      yScale={{ type: 'linear', min: 0, max: Math.max(maxDataValue, maxMarkerValue) * 1.2 }}
      margin={{
        ...GOALS_GRAPH_MARGINS,
        right: 10 + (applyMarginAdjustment ? labelLength * 2.2 : 0),
        bottom: 180
      }}
      padding={0.5}
      valueScale={{ type: 'linear', max: 10 }}
      indexScale={{ type: 'band', round: true }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation,
        legend,
        legendPosition: 'middle',
        legendOffset:
          GOALS_GRAPH_LEGEND_OFFSET + adjustment(label) * Math.sin(Math.abs(tickRotation))
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendPosition: 'middle',
        legendOffset: -90
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY:
            GOALS_GRAPH_BOTTOM_LEGEND_OFFSET + adjustment(label) * Math.sin(Math.abs(tickRotation)),
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role='application'
      ariaLabel='Exercise progress graph'
    />
  );
};
