import dayjs from 'dayjs';

export const groupConfigEntries = (configEntries) => {
  const grouped: any = [];
  let array: any = [];

  const configItems = configEntries.items;

  for (let index = 0; index < configItems.length; index += 1) {
    const currentDate = dayjs(configItems[index].created_at);
    let nextDate;

    array.push(configItems[index]);

    if (configItems[index + 1]) {
      nextDate = dayjs(configItems[index + 1].created_at);
      if (!currentDate.isSame(nextDate, 'day')) {
        grouped.push(array);
        array = [];
      }
    } else {
      grouped.push(array);
      array = [];
    }
  }
  return grouped;
};

export const getYears = (dayjs) => {
  const currentYear = dayjs().year();
  const minYear = 2022;
  const difference = currentYear - minYear - 1;

  const yearsArray: { id: number; name: string }[] = [{ id: minYear, name: String(minYear) }];

  for (let index = 1; index <= difference; index++) {
    yearsArray.push({ id: minYear + index, name: String(minYear + index) });
  }
  yearsArray.push({ id: currentYear, name: String(currentYear) });
  yearsArray.push({ id: currentYear + 1, name: String(currentYear + 1) });

  return yearsArray;
};
