/* eslint-disable no-unused-vars */
import { SingleElectrodeMode } from 'bluetooth/Bluetooth/Control';
import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import {
  singleElectrodeModeMap,
  singleElectrodeModeReverseMap
} from 'configurator/utils/definesLocal';
import { singleElectrodeModeEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';
import { useTranslation } from 'react-i18next';

const SingleElectrodeStrategy = ({
  addHistoryProsthesis,
  singleElectrodeMode,
  disable,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  singleElectrodeMode: singleElectrodeModeEntry;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const { t } = useTranslation();
  const handleSingleElectrodeStrategy = (type: any, value: any) => {
    addHistoryProsthesis(
      // @ts-ignore
      setConfigProperty('singleElectrodeMode', [singleElectrodeModeReverseMap.get(value)])
    );
  };

  const SingleElectrodeStrategy = useMemo(
    () => (
      <>
        <InputOptions
          header={t('configurator:component.single_electrode_strategy.header', {
            defaultValue: 'Single electrode direction control'
          })}
          options={[
            'configurator:constants.single_electrode_mode.slope',
            'configurator:constants.single_electrode_mode.alternating'
          ]}
          id='single-electrode-strategy'
          onChange={handleSingleElectrodeStrategy}
          disable={disable}
          // @ts-ignore
          value={singleElectrodeModeMap.get(singleElectrodeMode[0])}
          description={t('configurator:component.single_electrode_strategy.description', {
            defaultValue: 'Single electrode control method'
          })}
          tooltip={t('configurator:component.single_electrode_strategy.tooltip', {
            defaultValue: 'Hello'
          })}
        />
      </>
    ),
    [singleElectrodeMode, disable]
  );

  return SingleElectrodeStrategy;
};

export default SingleElectrodeStrategy;
