import { getUser } from 'adp-panel/api/users/users';
import { fetchUserData } from 'adp-panel/api/authentication/authentication';
import { UserExtendOptions, UserEntry } from 'adp-panel/api/users/users.types';

let permissionExpiresAt = 0;
let insideCall = 0;
const fetchPermissions = async () => {
  const result: any = await fetchUserData();
  const user: UserEntry = await getUser(result.id, {
    params: { extend: [UserExtendOptions.permissions, UserExtendOptions.roles] }
  });
  localStorage.setItem('roles', JSON.stringify(user.roles));
  localStorage.setItem('permissions', JSON.stringify(user.permissions));
  permissionExpiresAt = Date.now() + 1000 * 60 * 5; // 5 minutes
  insideCall = 0;
  return { roles: JSON.stringify(user.roles), permissions: JSON.stringify(user.permissions) };
};

const authProvider = {
  getPermissions: async () => {
    if (Date.now() > permissionExpiresAt && insideCall === 0) {
      insideCall += 1;
      await fetchPermissions();
    }
    const permissions = localStorage.getItem('permissions');
    const roles = localStorage.getItem('roles');

    return {
      permissions: permissions ? JSON.parse(permissions) : [],
      roles: roles ? JSON.parse(roles) : []
    };
  },
  getAcl: async () => fetchPermissions()
};

export default authProvider;
