/* eslint-disable lines-between-class-members */
import { checkLength } from './funcs';

class TelemetryParser {
  private emgExtensionData: number[];
  private emgFlexionData: number[];

  constructor() {
    this.emgExtensionData = [];
    this.emgFlexionData = [];
  }

  clear() {
    this.emgExtensionData = [];
    this.emgFlexionData = [];
  }

  parseTelemetry(telemetryData, length, oldTelemetry) {
    if (telemetryData && telemetryData.length > 0) {
      telemetryData.forEach(({ payload }) => {
        this.emgExtensionData = [oldTelemetry ? payload[20] : payload[1], ...this.emgExtensionData];
        this.emgFlexionData = [oldTelemetry ? payload[21] : payload[2], ...this.emgFlexionData];
      });
      this.emgExtensionData = checkLength(this.emgExtensionData, length);
      this.emgFlexionData = checkLength(this.emgFlexionData, length);
      return [this.emgExtensionData, this.emgFlexionData];
    }
    return false;
  }
}

export default TelemetryParser;
