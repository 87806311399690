import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/api/useModal';
import { useQueryClient } from '@tanstack/react-query';
import { USERS_QUERY_KEY } from '../../hooks/api/useUsers';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import useUserData from '../../../hooks/useUserData';
import { useUsers } from '../../hooks/api/useUsers';
import { PATIENTS_PERMISSIONS } from '../../constants/rolesPermissions';
import Card from 'adp-panel/components/Card/Card';
import { Chip, CircularProgress, Divider, Typography, Box } from '@mui/material';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';
import { ReactComponent as NoUsersSvg } from 'assets/no_users.svg';
import { ReactComponent as ListSvg } from 'assets/list.svg';
import { ReactComponent as GridSvg } from 'assets/grid.svg';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import DotsBackground from 'assets/dots.svg';
import CustomButton from 'components/Button/CustomButton';
import PatientCard from '../../components/PatientCard/PatientCard';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import CustomTable, { TableHeader } from 'adp-panel/components/Table/CustomTable';
import UserListAvatar from 'components/UserListAvatar/UserListAvatar';
import LoaderWrapper from 'components/Loader/Loader';
import { TableLink } from 'adp-panel/components/Links/Links';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useSessionStart } from 'hooks/useSessionStart';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import ConnectButton from 'adp-panel/components/ConnectButton/ConnectButton';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { ReactComponent as NextSvg } from 'assets/next-pagination.svg';
import { ReactComponent as PreviousSvg } from 'assets/previous-pagination.svg';
import ConfirmDeleteModal from 'adp-panel/components/Modals/ConfirmDeleteModal';
import ChooseDeviceModal from 'adp-panel/components/Modals/ChooseDeviceModal';
import { useDetachUser } from 'hooks/useDetachUser';
import { useHandleMultipleDevice } from 'hooks/useHandleMultipleDevice';

export const hasPermissionToDeletePatient = (
  clinicianId: any,
  rolesByName: any,
  userData: any
): boolean => {
  if (
    userHasPermissions([RoleEnum.clinician], rolesByName) &&
    userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName)
  ) {
    return clinicianId === userData.id;
  }

  return userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName);
};

const mapPatientsRows = (apiData: UserEntry[]) =>
  apiData.map((patient) => ({
    id: patient.id,
    name: patient.name,
    devices: patient.devices
  }));

const PatientsDashboard = () => {
  const { t } = useTranslation('patients');
  const { rolesByName } = useUserData();
  const { connected, serial } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    serial: state.serial
  }));
  const [selectedPatient, setSelectedPatient] = useState<UserEntry>();
  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 9
  });
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.devices,
      UserExtendOptions.clinicians,
      UserExtendOptions.devicesAsClinician
    ],
    roles: 'Amputee',
    perpage: 9,
    page: paginationModel.page + 1
  };

  const {
    result: patientsData,
    total: totalPatients,
    isLoading: isLoadingPatients
  } = useUsers(queryParams);
  const pageCount = Math.ceil(totalPatients / 9);

  const [tableView, setTableView] = useState(false);
  const navigate = useNavigate();

  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const {
    handleConfigure,
    isChooseModalOpen,
    handleChooseDeviceModalClose,
    patientMultipleDevices
  } = useHandleMultipleDevice();

  const { handleOpenRemoteSession, sessionStartingState } = useSessionStart();

  const { handleSubmit: detachUser, isLoading: isLoadingDelete } = useDetachUser();

  const handleDeleteModal = (patient: UserEntry) => {
    setSelectedPatient(patient);
    handleModalOpen();
  };

  const handleDelete = async () => {
    if (selectedPatient) {
      await detachUser(selectedPatient.id);
      handleModalClose();
      await queryClient.invalidateQueries([USERS_QUERY_KEY]);
    }
  };

  const redirectCreatePatient = () => {
    navigate(routes.PATIENT_ADD);
  };

  const isClinician = userHasPermissions([RoleEnum.clinicAdmin, RoleEnum.clinician], rolesByName);

  const PatientsDashboardActions = (
    <div style={{ display: 'flex', gap: '16px' }}>
      {isClinician && (
        <>
          <CustomButton Icon={AddUserSvg} color='lightBlue' onClick={redirectCreatePatient}>
            {t('patients:dashboard.create_patient', 'Create new patient')}
          </CustomButton>
          <Divider orientation='vertical' flexItem />
        </>
      )}
      <CustomButton
        Icon={tableView ? GridSvg : ListSvg}
        color='light'
        onClick={() => setTableView((prev) => !prev)}
      />
    </div>
  );

  const NoPatientsDashboard = (
    <Card style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          padding: '40px',
          backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
        }}>
        <Card style={{ display: 'flex', alignItems: 'center' }}>
          <NoUsersSvg />
        </Card>
        <Typography sx={{ fontWeight: 600 }}>
          {t('patients:dashboard.no_patients', 'No patients found')}
        </Typography>
        <Typography variant='caption' sx={{ width: '250px' }}>
          {isClinician
            ? t(
                'patients:dashboard.no_patients_description',
                'Click the button below to add your first patient or connect to Zeus.'
              )
            : t(
                'patients:dashboard.no_patients_description.allied',
                'Click the button below to connect to Zeus.'
              )}
        </Typography>
        <div style={{ display: 'flex', gap: '16px' }}>
          {isClinician && (
            <CustomButton Icon={AddUserSvg} color='lightBlue' onClick={redirectCreatePatient}>
              {t('patients:dashboard.create_patient', 'Create new patient')}
            </CustomButton>
          )}
          <ConnectButton />
        </div>
      </div>
    </Card>
  );

  const PatientsWithCards = (
    <Card>
      <TableHeader
        tableHeader={t('patients:dashboard.table.header', 'My patients')}
        actions={PatientsDashboardActions}
      />
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {patientsData?.map((patient) => (
          <PatientCard
            name={patient.user_name}
            key={patient.id}
            serial={patient?.devices}
            loadingSession={sessionStartingState === FETCHING_STATES.loading}
            handleConfigure={() => handleConfigure(patient?.devices)}
            disableConnect={connected && serial !== patient?.devices[0]?.serial}
            handleProfile={() => navigate(`${routes.PATIENTS}/${patient.id}`)}
            handleSession={() => handleOpenRemoteSession(patient.id)}
          />
        ))}
      </div>

      <MuiPagination
        color='lightPagination'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px'
        }}
        count={pageCount}
        page={paginationModel.page + 1}
        onChange={(event, newPage) => {
          setPaginationModel((prev) => ({
            ...prev,
            page: newPage - 1
          }));
        }}
        shape='rounded'
        renderItem={(item) => (
          <PaginationItem
            slots={{
              previous: (props) => <CustomButton {...props} Icon={PreviousSvg} color='light' />,
              next: (props) => (
                <CustomButton
                  {...props}
                  onClick={(event, newPage) => {
                    setPaginationModel((prev) => ({
                      ...prev,
                      page: newPage - 1
                    }));
                  }}
                  Icon={NextSvg}
                  color='light'
                />
              )
            }}
            {...item}
          />
        )}
      />
    </Card>
  );

  const PatientsTable = (
    <Card>
      <TableHeader
        tableHeader={t('patients:dashboard.table.header', 'My patients')}
        actions={PatientsDashboardActions}
        count={totalPatients}
      />
      {isModalOpen && selectedPatient && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={() => handleDelete()}
          isLoading={isLoadingDelete}
          customTitle={t('patients:dashboard.modal.title', 'Remove patient from my view')}
          message={t('patients:dashboard.modal.text', {
            defaultValue: `${t(
              'patients:dashboard.modal.text',
              'We don`t delete the patient, you just won`t be attached to him/her anymore. The patient`s account can olny be deleated by the patient himself.'
            )}`
          })}
        />
      )}
      <CustomTable
        actions={PatientsDashboardActions}
        tableData={patientsData}
        totalItems={totalPatients}
        isLoading={isLoadingPatients}
        columns={[
          {
            field: 'name',
            headerName: t('patients:dashboard.table.name_columns', 'Name'),
            flex: 2,
            renderCell: (params) => {
              const { name, id } = params.row;
              return (
                <UserListAvatar name={name}>
                  <TableLink to={`${routes.PATIENTS}/${id}`}>{name}</TableLink>
                </UserListAvatar>
              );
            }
          },
          {
            field: 'devices',
            headerName: t('patients:dashboard.table.device_column', 'Devices SN'),
            flex: 1,
            renderCell: (params) => {
              const { devices } = params.row;
              return (
                <div>
                  {devices.length
                    ? devices.map((device) => (
                        <Chip
                          size='small'
                          key={device.serial}
                          label={device.serial}
                          sx={{ marginRight: '4px' }}
                        />
                      ))
                    : '-'}
                </div>
              );
            }
          },
          {
            field: 'actions',
            type: 'actions',
            flex: 2,
            headerName: '',
            renderCell: (params) => {
              const { id, devices } = params.row;
              return (
                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}>
                  <CustomButton
                    Icon={CogSvg}
                    disabled={!devices?.[0]?.serial}
                    onClick={() => handleConfigure(devices)}>
                    {t('patients:dashboard.table.button.configure_zeus', 'Configure Zeus')}
                  </CustomButton>

                  <CustomButton
                    Icon={ComputerSvg}
                    color='light'
                    disabled={!devices?.[0]?.serial}
                    onClick={() => handleOpenRemoteSession(id)}>
                    {t(
                      'patients:dashboard.table.button.start_remote_session',
                      'Start Remote Session'
                    )}
                  </CustomButton>
                  <Divider orientation='vertical' flexItem style={{ margin: '0 5px' }} />
                  <CustomButton
                    Icon={TrashSvg}
                    color='light'
                    onClick={() => handleDeleteModal(params.row)}
                  />
                </Box>
              );
            }
          }
        ]}
        mapTableData={mapPatientsRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Card>
  );

  const showPatientsDashboard = () => {
    if (isLoadingPatients)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    if (totalPatients) {
      return (
        <>
          {isChooseModalOpen && (
            <ChooseDeviceModal
              handleModalClose={handleChooseDeviceModalClose}
              devices={patientMultipleDevices}
            />
          )}
          {tableView ? PatientsTable : PatientsWithCards}
        </>
      );
    }

    return NoPatientsDashboard;
  };

  return <>{showPatientsDashboard()}</>;
};

export default PatientsDashboard;
