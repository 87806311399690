import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useSidebar from 'configurator/hooks/useSidebar';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import {
  APPLICATION_SETTINGS,
  CHOOSE_GRIPS,
  CONFIG_TEMPLATES,
  DEVICE_HISTORY,
  GRAPH,
  GRIPS_CONFIGURATION,
  PROSTHESIS_SETTINGS,
  SERVICE_MENU
} from 'constants/routes';
import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import { ReactComponent as GripSvg } from 'assets/grips-icon.svg';
import { ReactComponent as EmgSvg } from 'assets/emg-icon.svg';
import { ReactComponent as SettingsSvg } from 'assets/settings-icon.svg';
import { ReactComponent as ToolsSvg } from 'assets/tools-svg.svg';
import { ReactComponent as DeviceNavbarSvg } from 'assets/device_navbar.svg';

const SidebarListItemButton = styled(ListItemButton)<{ component?: React.ElementType }>({
  '&.Mui-selected': {
    backgroundColor: '#253786',
    '&:hover': {
      backgroundColor: `#253786`
    }
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: '#283985'
  }
});

interface MenuItem {
  name: string;
  url?: string;
  disabled?: boolean;
  items?: MenuItem[];
  icon?: any;
  action?: any;
}

const MenuItem = ({
  name,
  url,
  pathname,
  action
}: {
  name: string;
  url?: string;
  pathname?: string;
  action?: any;
}) => {
  const Item = (
    <ListItem disablePadding>
      <SidebarListItemButton
        selected={url === pathname}
        onClick={action}
        sx={{ borderRadius: '8px', height: '40px' }}>
        <ListItemText primary={name} sx={{ color: '#FFF', fontWeight: '500' }} />
      </SidebarListItemButton>
    </ListItem>
  );

  if (url) return <NavLink to={url}>{Item}</NavLink>;

  return <>{Item}</>;
};

const Menu = ({ item }: { item: MenuItem }) => {
  const location = useLocation();

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '20px auto 1fr',
          color: '#FFF',
          alignItems: 'center',
          gap: '12px'
        }}>
        {item.icon}
        <div style={{ fontWeight: '300' }}>{item.name}</div>
        <div style={{ borderTop: '2px solid #869DE1' }} />
      </div>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {item.items?.map((_item) => (
          <MenuItem
            name={_item.name}
            url={_item.url}
            pathname={location.pathname}
            action={_item.action}
          />
        ))}
      </List>
    </div>
  );
};

const Navbar = () => {
  const { t } = useTranslation('configurator');
  const { deviceConnected } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    serial: state.serial
  }));
  const liveSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const { handleFirmwareNavbar, handleServiceMenuNavbar } = useSidebar();

  const MENU_CONFIG: MenuItem[] = [
    {
      name: t('configurator:navbar.grips', 'Grip'),
      icon: <GripSvg />,
      items: [
        {
          name: t('configurator:navbar.choose_grips'),
          url: CHOOSE_GRIPS
        },
        {
          name: t('configurator:navbar.grips_configuration'),
          url: GRIPS_CONFIGURATION
        }
      ]
    },
    {
      name: t('configurator:navbar.emg_settings', 'EMG'),
      icon: <EmgSvg stroke='#D8E3FF' />,
      items: [
        {
          name: replayIsEnabled
            ? t('configurator:navbar.emg_playback')
            : t('configurator:navbar.emg_settings.name'),
          url: GRAPH
        }
      ]
    },
    {
      name: t('configurator:navbar.prosthesis', 'Prosthesis'),
      icon: <SettingsSvg />,
      items: [
        {
          name: t('configurator:navbar.prosthesis_settings'),
          url: PROSTHESIS_SETTINGS
        }
      ]
    },
    {
      name: t('configurator:navbar.devices', 'Devices'),
      icon: <DeviceNavbarSvg />,
      items: [
        {
          name: t('configurator:navbar.device_history', 'History log'),
          url: DEVICE_HISTORY
        },
        {
          name: t('configurator:navbar.config_templates', 'Config templates'),
          url: CONFIG_TEMPLATES
        },
        {
          name: t('configurator:navbar.update_firmware'),
          action: () => handleFirmwareNavbar(deviceConnected, liveSessionEnabled)
        }
      ]
    },
    {
      name: t('configurator:navbar.application', 'Application'),
      icon: <ToolsSvg />,
      items: liveSessionEnabled
        ? [
            {
              name: t('configurator:navbar.app_settings'),
              url: APPLICATION_SETTINGS
            }
          ]
        : [
            {
              name: t('configurator:navbar.tools', 'Tools'),
              url: SERVICE_MENU,
              action: (e) => handleServiceMenuNavbar(deviceConnected, e)
            },
            {
              name: t('configurator:navbar.app_settings'),
              url: APPLICATION_SETTINGS
            }
          ]
    }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {MENU_CONFIG.map((menu) => (
        <Menu item={menu} />
      ))}
    </div>
  );
};

export default Navbar;
