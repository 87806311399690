/* eslint-disable react/jsx-key */
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useProcedureReply from 'configurator/hooks/useProcedure';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { useUiStore } from 'configurator/reducers/uiStore';
import { genericSwitchingMap, genericSwitchingReversedMap } from 'configurator/utils/definesLocal';
import {
  CalibrationWrapper,
  DoubleLabel,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  Header2
} from 'configurator/components/molecules/CalibrationProcedure/styled';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';
import { useTranslation } from 'react-i18next';
import { ConfigurationSection } from '../styled';

const SoftGrip = ({
  addHistoryProsthesis,
  softGrip,
  fingerStrength,
  softGripPermission,
  replayIsEnabled,
  sessionEnabled,
  sessionApi,
  channel,
  setConfigProperty,
  handleProcedure,
  disableCalibration
}: any) => {
  const { t } = useTranslation();
  const [softGripStatus] = softGrip;
  const procedure = useProcedureReply(ProcedureTypes.calibrateSoftGrip);
  const [fingerStrengthSliderLocal, setFingerStrengthSliderLocal] = useState<any>(
    // @ts-ignore
    initialState.config.fingerStrength?.[1]
  );
  const procedureState = useUiStore((state: any) => state.procedureState);
  const fingerStrengthMap: any = new Map([
    [0, 50],
    [1, 100],
    [2, 150],
    [3, 300],
    [4, 500],
    [5, 700]
  ]);

  const fingerStrengthReversedMap = new Map(Array.from(fingerStrengthMap, (a: any) => a.reverse()));

  const handleSoftGripStatus = (value: any) => {
    addHistoryProsthesis(setConfigProperty('softGrip', [value]));
  };

  const handleOnAfterChangeFinger = async (value: any) => {
    addHistoryProsthesis(setConfigProperty('fingerStrength', [1, fingerStrengthMap.get(value)]));
  };

  useEffect(() => {
    if (fingerStrength) {
      setFingerStrengthSliderLocal(fingerStrengthReversedMap.get(fingerStrength?.[1]));
    }
  }, [fingerStrength]);

  const Softgrip = useMemo(
    () => (
      <OptionsWithSlider
        header={t('configurator:component.soft_grip.slider_header', 'Soft-grip')}
        toggleOptions={[
          'configurator:constants.generic_switching.on',
          'configurator:constants.generic_switching.off'
        ]}
        disabled={replayIsEnabled || !softGripPermission}
        disableSlider={Boolean(!softGripStatus)}
        toggleValue={genericSwitchingMap.get(softGripStatus)}
        sliderValue={fingerStrengthSliderLocal}
        handleSliderChange={(e: any, value: any) => setFingerStrengthSliderLocal(value)}
        sliderLimits={{ min: 0, max: 5 }}
        sliderLabel={
          <DoubleLabel>
            <span>{t('configurator:component.soft_grip.gentle', 'Gentle')}</span>
            <span>{t('configurator:component.soft_grip.strong', 'Strong')}</span>
          </DoubleLabel>
        }
        step={1}
        handleToggleChange={(value: any) =>
          handleSoftGripStatus(genericSwitchingReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args: any) => handleOnAfterChangeFinger(args[1])}
        description={t(
          'configurator:component.soft_grip.description',
          'Used to limit the strength of the fingers'
        )}
        tooltip={t('configurator:component.soft_grip.tooltip', 'Hello')}
      />
    ),
    [softGrip, fingerStrengthSliderLocal, softGripPermission, fingerStrength]
  );

  return (
    <>
      <ConfigurationSection
        disabled={!softGripPermission}
        tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
        {Softgrip}
      </ConfigurationSection>
      <CalibrationWrapper>
        {procedure && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>
              {t('configurator:component.soft_grip.procedure_results', 'Procedure results')}
            </Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td
                    aria-label={t(
                      'configurator:component.fingers_speed.procedure_information',
                      'Procedure information'
                    )}>
                    &nbsp;
                  </td>
                  <td>{t('configurator:component.fingers_speed.thumb', 'Thumb')}</td>
                  <td>{t('configurator:component.fingers_speed.index', 'Index')}</td>
                  <td>{t('configurator:component.fingers_speed.middle', 'Middle')}</td>
                  <td>{t('configurator:component.fingers_speed.ring', 'Ring')}</td>
                  <td>{t('configurator:component.fingers_speed.pinky', 'Pinky')}</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Status</td>
                  {procedure.status!.map((status, index) => (
                    // @ts-ignore
                    <ProcedureStatusBox colSpan={1} status={procedure.status![index]}>
                      {status === 1
                        ? t('configurator:component.fingers_speed.ok', 'OK')
                        : t('configurator:component.fingers_speed.not_ok', 'NOT OK')}
                    </ProcedureStatusBox>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t('configurator:component.soft_grip.max_current', 'Max current')}
                  </td>
                  {procedure.maxCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip
          title={
            disableCalibration
              ? t('configurator:component.soft_grip.device_not_connected', 'Device not connected')
              : null
          }>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={() =>
                handleProcedure({
                  procedureNumber: ProcedureTypes.calibrateSoftGrip,
                  ...(sessionEnabled && {
                    liveSession: {
                      clinicianUUID: sessionApi?.clinician_uuid,
                      channelName: channel?.name
                    }
                  })
                })
              }>
              <span>
                {t(
                  'configurator:component.soft_grip.calibration_procedure',
                  'Soft grip calibration procedure'
                )}
              </span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};

export default SoftGrip;
