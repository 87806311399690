import { useEffect, useState } from 'react';
import { useQuery } from 'adp-panel/hooks/useQuery';
import { TabsEnum } from '../types/patient.types';

export const usePatientTabs = () => {
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.DEVICE_USAGE);
  const query = useQuery();
  const goalId = query.get('goalId');

  useEffect(() => {
    if (goalId) {
      setSelectedTab(TabsEnum.GOALS);
    }
  }, [goalId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabsEnum) => {
    setSelectedTab(newValue);
  };

  return {
    selectedTab,
    handleTabChange
  };
};
