/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as HandDeviceSvg } from 'assets/hand-device.svg';
import { ReactComponent as DisconnectBluetoothSvg } from 'assets/bt.svg';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useSidebar from 'configurator/hooks/useSidebar';
import CustomChip from 'components/CustomChip/CustomChip';
import DoneIcon from '@mui/icons-material/Done';
import HandStatus from '../HandStatus/HandStatus';
import { Divider } from '@mui/material';

const Wrapper = styled.div`
  height: 85px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
  background-color: #fff;
`;

const TopBar = ({ ...props }: any) => {
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore();
  const { t } = useTranslation();
  const { connected, serial, bluetoothId } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    serial: state.serial,
    bluetoothId: state.bluetoothId
  }));
  const { handleRedirectAdp, handleDisconnect } = useLeaveConfigurator();
  const { bluetoothConnect } = useBluetooth();
  const connectionState = useUiStore((state: any) => state.connectionState);
  const { allowConnectButton } = useSidebar();

  return (
    <Wrapper {...props}>
      <div style={{ display: 'flex', gap: '16px' }}>
        {(remoteSessionEnabled || connected) && <HandStatus />}
      </div>
      <div style={{ display: 'flex', gap: '16px' }}>
        {connected ? (
          <CustomChip
            color='success'
            label={t('configurator:component.top_bar.connected', {
              defaultValue: 'Device connected SN: {{serial}}',
              serial
            })}
            sx={{ borderRadius: '6px', height: 'auto' }}
            icon={<DoneIcon />}
          />
        ) : (
          <CustomChip
            color='secondary'
            label={t('configurator:component.top_bar.async', {
              defaultValue: 'Device SN: {{serial}}',
              serial
            })}
            sx={{ borderRadius: '6px', height: 'auto' }}
          />
        )}
        {allowConnectButton && (
          <CustomButton
            color={connected ? 'light' : 'primary'}
            Icon={connected ? DisconnectBluetoothSvg : HandDeviceSvg}
            loading={connectionState === FETCHING_STATES.loading}
            onClick={connected ? () => handleDisconnect() : () => bluetoothConnect(bluetoothId)}>
            {connected
              ? t('configurator:component.top_bar.disconnect', 'Disconnect')
              : t('configurator:component.top_bar.connect_device', 'Connect device')}
          </CustomButton>
        )}
        <Divider orientation='vertical' flexItem />
        <CustomButton onClick={handleRedirectAdp} color='light'>
          {t('configurator:component.top_bar.go_to_adp', 'Go to ADP')}
        </CustomButton>
      </div>
    </Wrapper>
  );
};

export default TopBar;
