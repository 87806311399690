import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { useTranslation } from 'react-i18next';

type InputOptionsProps = {
  header: string;
  options: string[];
  id: string;
  onChange?: Function;
  value: string;
  description?: string | React.ReactElement;
  disable?: boolean;
  hideInput?: boolean;
  warning?: string | React.ReactElement | null;
  tooltip?: React.ReactNode;
};

const InputOptions = ({
  header,
  options,
  id,
  onChange = () => true,
  value,
  description = '',
  disable = false,
  hideInput = false,
  warning,
  tooltip,
  ...props
}: InputOptionsProps) => {
  const { t } = useTranslation();
  const updateSelectedOption = (option) => {
    onChange(id, option);
  };

  return (
    <div {...props}>
      <div>
        <div style={{ display: 'flex', gap: '6px' }}>
          <Typography sx={{ color: '#101A5A', fontWeight: '600' }}>{header}</Typography>
          {tooltip && <CustomTooltip title={tooltip} />}
        </div>
        {description && (
          <Typography variant='body2' sx={{ color: '#344054', marginTop: '4px' }}>
            {description}
          </Typography>
        )}
      </div>
      {!hideInput && (
        <FormControl>
          <RadioGroup row name='position'>
            {options.map((option) => (
              <FormControlLabel
                disabled={disable}
                value={option}
                control={<Radio onChange={() => updateSelectedOption(option)} />}
                label={t(option)}
                checked={value == option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {warning}
    </div>
  );
};

export default InputOptions;
