/* eslint-disable react/no-array-index-key */
import React from 'react';
import Slider from 'configurator/components/atoms/Slider/Slider';
import {
  AdjustBox,
  GridContainer,
  GridWrapper,
  PositionBox,
  SliderWrapper,
  TextVertical,
  UpperBox,
  Wrapper
} from './styled';
import { Checkbox, Typography } from '@mui/material';
import { ConfigurationSection } from 'configurator/views/ProsthesisSettings/styled';

type PositionsAdjusterProps = {
  values: {
    columnPrimary: number[];
    columnSecondary: number[];
  };
  limits: { min: number; max: number }[];
  rows: string[];
  columns: string[];
  handleSliderChange: Function;
  handleOnAfterChange?: Function;
  handleOnBeforeChange?: Function;
  handleInputChange?: Function;
  handleOnAfterChangeInput?: Function;
  graphVersion?: boolean;
  disableInput?: boolean[] | boolean;
  trackClasses?: string[][];
  checked: boolean[];
  handleCheckbox: Function;
  disableAccess?: boolean;
  disableAccessTooltip?: string;
};

const PositionAdjusterLabel = ({ label, handleCheckbox, checked, checkboxIndex }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
      <Checkbox
        checked={checked}
        onClick={() => handleCheckbox(checkboxIndex, true)}
        sx={{ padding: 0 }}
      />
      <Typography variant='body2' sx={{ fontWeight: 600 }}>
        {label}
      </Typography>
    </div>
  );
};

const PositionsAdjuster = ({
  values,
  limits,
  rows,
  columns,
  handleSliderChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange = () => true,
  handleInputChange = () => true,
  handleOnAfterChangeInput = () => true,
  graphVersion = false,
  disableInput = false,
  trackClasses = undefined,
  checked,
  handleCheckbox,
  disableAccess,
  disableAccessTooltip,
  ...props
}: PositionsAdjusterProps) => (
  <Wrapper {...props}>
    <GridContainer>
      {values.columnPrimary.map((valuePrimary, index) => (
        <GridWrapper key={rows[index] + index}>
          <TextVertical>
            <h4>{rows[index]}</h4>
          </TextVertical>
          <UpperBox>
            <AdjustBox first={index === 0}>
              {graphVersion && index === 0 ? (
                <div>&nbsp;</div>
              ) : (
                <>
                  <PositionAdjusterLabel
                    label={columns[0]}
                    checked={checked[index * 2 - 1]}
                    handleCheckbox={handleCheckbox}
                    checkboxIndex={index * 2 - 1}
                  />
                  <ConfigurationSection disabled={disableAccess} tooltipText={disableAccessTooltip}>
                    <SliderWrapper>
                      <Slider
                        data={{
                          index,
                          sliderType: 'primary',
                          min: limits[index].min,
                          max: limits[index].max
                        }}
                        handleChange={handleSliderChange}
                        handleOnAfterChange={handleOnAfterChange}
                        handleOnBeforeChange={handleOnBeforeChange}
                        value={values.columnPrimary[index]}
                        min={limits[index].min}
                        max={limits[index].max}
                        disable={Boolean(disableInput?.[index])}
                        trackClass={trackClasses ? trackClasses[index][0] : undefined}
                      />
                      <PositionBox
                        type='text'
                        value={values.columnPrimary[index]}
                        min={limits[index].min}
                        max={limits[index].max}
                        onChange={(e) =>
                          handleInputChange(e.target.value, 0, {
                            index,
                            sliderType: 'primary',
                            min: limits[index].min,
                            max: limits[index].max
                          })
                        }
                        onBlur={(e) =>
                          handleOnAfterChangeInput(e.target.value, 0, {
                            index,
                            sliderType: 'primary',
                            min: limits[index].min,
                            max: limits[index].max
                          })
                        }
                        onFocus={handleOnBeforeChange}
                        disabled={Boolean(disableInput?.[index])}
                      />
                    </SliderWrapper>
                  </ConfigurationSection>
                </>
              )}
            </AdjustBox>
            <AdjustBox>
              <PositionAdjusterLabel
                label={columns[1]}
                checked={checked[index * 2]}
                handleCheckbox={handleCheckbox}
                checkboxIndex={index * 2}
              />
              <ConfigurationSection disabled={disableAccess} tooltipText={disableAccessTooltip}>
                <SliderWrapper>
                  <Slider
                    data={{
                      index,
                      sliderType: 'secondary',
                      min: limits[index].min,
                      max: limits[index].max
                    }}
                    handleChange={handleSliderChange}
                    handleOnAfterChange={handleOnAfterChange}
                    handleOnBeforeChange={handleOnBeforeChange}
                    value={values.columnSecondary[index]}
                    min={limits[index].min}
                    max={limits[index].max}
                    disable={Boolean(disableInput?.[index])}
                    trackClass={trackClasses ? trackClasses[index][1] : undefined}
                  />
                  <PositionBox
                    type='text'
                    value={values.columnSecondary[index]}
                    onChange={(e) =>
                      handleInputChange(e.target.value, 0, {
                        index,
                        sliderType: 'secondary',
                        min: limits[index].min,
                        max: limits[index].max
                      })
                    }
                    onBlur={(e) =>
                      handleOnAfterChangeInput(e.target.value, 0, {
                        index,
                        sliderType: 'secondary',
                        min: limits[index].min,
                        max: limits[index].max
                      })
                    }
                    onFocus={handleOnBeforeChange}
                    disabled={Boolean(disableInput?.[index])}
                    id='box'
                  />
                </SliderWrapper>
              </ConfigurationSection>
            </AdjustBox>
          </UpperBox>
        </GridWrapper>
      ))}
    </GridContainer>
  </Wrapper>
);

export default PositionsAdjuster;
