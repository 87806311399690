import { CircularProgress, Popper, PopperProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import { SearchEntry } from 'adp-panel/api/search/search.types';
import { UserEntry } from 'adp-panel/api/users/users.types';
import { useSearch } from 'adp-panel/hooks/api/useSearch';
import CustomIcon from 'components/CustomIcon/CustomIcon';
import { useState, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import debounce from 'lodash/debounce';

interface SearchOption {
  label: string;
  type: 'User' | 'Device';
  id: number;
  subtitle?: string;
  uri?: string;
}

const StyledPopper = styled(Popper)({
  [`& .MuiAutocomplete-noOptions`]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#667085'
  }
});

function ComboBox({
  isLoading,
  value,
  options,
  setValue,
  setInputValue,
  inputValue,
  onOptionSelect
}: {
  isLoading: boolean;
  value: SearchOption | null;
  options: SearchOption[];
  setValue: (value: SearchOption | null) => void;
  setInputValue: (value: string) => void;
  inputValue: string;
  onOptionSelect: (option: SearchOption) => void;
}) {
  return (
    <Autocomplete
      disablePortal
      autoHighlight
      autoComplete
      filterSelectedOptions
      size={'small'}
      forcePopupIcon={false}
      options={options || []}
      value={value}
      filterOptions={(x) => x}
      PopperComponent={(props: PopperProps) => (
        <StyledPopper {...props} disablePortal placement='bottom' />
      )}
      noOptionsText={inputValue.length < 3 ? 'Type at least 3 characters to search' : 'No results'}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue: SearchOption | null) => {
        setValue(newValue);
        if (newValue) {
          onOptionSelect(newValue);
        }
      }}
      sx={{
        width: 630,
        fontSize: '14px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px'
        },
        '& .MuiAutocomplete-popper': {
          '& .MuiPaper-root': {
            borderRadius: '8px'
          }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          padding: '5px 14px'
        },
        '& .MuiInputBase-input': {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={props.key + option.id}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400
              }}>
              {option.label}
            </Typography>
            {option.subtitle && (
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 400
                }}>
                {option.subtitle}
              </Typography>
            )}
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Search patient or serial number'
          slotProps={{
            input: {
              ...params.InputProps,
              sx: {
                '& input::placeholder': {
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 400,
                  color: '#667085'
                }
              },
              startAdornment: (
                <React.Fragment>
                  <CustomIcon style={{ margin: '5px 0' }} name={'search-icon'} />
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }
          }}
        />
      )}
    />
  );
}

const assertNever = (value: never): never => {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};

const createSearchOption = (searchEntry: SearchEntry): SearchOption => {
  switch (searchEntry.type) {
    case 'User': {
      const user = searchEntry.item as UserEntry;
      const subtitleParts: string[] = [];

      if (user.mrn) {
        subtitleParts.push(`MRN: ${user.mrn}`);
      }

      if (user.email) {
        subtitleParts.push(user.email);
      }

      return {
        label: user.name,
        type: 'User',
        id: user.id,
        uri: `/patients/${user.id}`,
        subtitle: subtitleParts.join(' | ')
      };
    }
    case 'Device': {
      const device = searchEntry.item as DeviceEntry;
      return {
        label: device.serial || '',
        type: 'Device',
        id: device.id,
        uri: device.amputee_id ? `/patients/${device.amputee_id}` : '/device',
        subtitle: device.bluetooth_id ? `Bluetooth ID: ${device.bluetooth_id}` : ''
      };
    }
    default:
      return assertNever(searchEntry.type as never);
  }
};

const mapper = (items: SearchEntry[] | null): SearchOption[] => {
  if (!items) {
    return [];
  }
  return items.map(createSearchOption);
};

const SearchBar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>('');
  const [previousOptions, setPreviousOptions] = useState<SearchEntry[]>([]);

  const debouncedSetQuery = useCallback(
    debounce((query: string) => {
      setDebouncedQuery(query);
    }, 300),
    []
  );
  const { result, isLoading } = useSearch({ query: debouncedQuery }, debouncedQuery.length > 2);

  useEffect(() => {
    debouncedSetQuery(inputValue);
    return () => {
      debouncedSetQuery.cancel();
    };
  }, [inputValue, debouncedSetQuery]);

  useEffect(() => {
    if (inputValue.length < 3) {
      setPreviousOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    if (result && result.length > 0 && inputValue.length >= 3) {
      setPreviousOptions(result);
    }
  }, [result, inputValue]);

  const currentOptions = useMemo(() => {
    if (inputValue.length < 3) {
      return [];
    }

    if (isLoading) {
      return mapper(previousOptions);
    }

    return mapper(result);
  }, [result, previousOptions, isLoading, inputValue]);

  const handleOptionSelect = (option: SearchOption) => {
    if (!option.uri) {
      return;
    }
    switch (option.type) {
      case 'User':
        navigate(option.uri);
        break;
      case 'Device':
        navigate(option.uri);
        break;
      default:
        assertNever(option.type as never);
    }
  };

  return (
    <div>
      <ComboBox
        setValue={setValue}
        setInputValue={setInputValue}
        value={value}
        inputValue={inputValue}
        options={currentOptions}
        isLoading={isLoading && inputValue.length >= 3}
        onOptionSelect={handleOptionSelect}
      />
    </div>
  );
};

export default SearchBar;
