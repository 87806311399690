import { singleElectrodeSettingsAlternatingPositions } from './configPropertiesPositions';

const singleElectrodeSettingsAlternatingNamings: [number, string][] = [
  [
    singleElectrodeSettingsAlternatingPositions.stateSwitchDelay,
    'configurator:component.value_mapping.single_electrode_settings_alternating.direction_change_time'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.minimumPulseTime,
    'configurator:component.value_mapping.single_electrode_settings_alternating.minimum_pulse_time'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.maximumPulseTime,
    'configurator:component.value_mapping.single_electrode_settings_alternating.maximum_pulse_time'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses,
    'configurator:component.value_mapping.single_electrode_settings_alternating.minimum_time_between_pulses'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses,
    'configurator:component.value_mapping.single_electrode_settings_alternating.maximum_time_between_pulses'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.delayBetweenSignals,
    'configurator:component.value_mapping.single_electrode_settings_alternating.delay_between_signals'
  ]
];

export const singleElectrodeSettingsAlternatingNamingsMap: any = new Map(
  singleElectrodeSettingsAlternatingNamings
);
// Delay between signals is currently not used
