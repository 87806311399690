import { getter } from '@progress/kendo-react-common';

const passwordRegexp: RegExp = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
);

const passwordGetter: any = getter('password');
const passwordConfirmGetter: any = getter('confirmPassword');

export const passwordValidator = (values: any) => {
  const password = passwordGetter(values);
  const passwordConfirm = passwordConfirmGetter(values);

  if (
    password &&
    passwordConfirm &&
    passwordRegexp.test(password) &&
    password === passwordConfirm
  ) {
    return;
  }

  return {
    VALIDATION_SUMMARY: 'validation:common.fill_in_fields',
    ['password']:
      password && !passwordRegexp.test(password) ? 'validation:common.password.strong' : '',
    ['confirmPassword']:
      password && passwordConfirm && password !== passwordConfirm
        ? 'validation:common.password.match'
        : ''
  };
};
