export const VideoMime = [
  'video/mp4',
  'video/x-mp4',
  'video/mpeg2',
  'video/mpeg',
  'video/m4v',
  'video/x-flv',
  'tvideo/MP2T',
  'video/3gpp',
  'video/quicktime',
  'tvideo/x-msvideo',
  'video/x-ms-wmv'
];

export const ImageMime = [
  'image/jpeg',
  'image/jpg',
  'image/bmp',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp'
];

export const AudioMime = [
  'audio/x-m4a',
  'audio/mpeg',
  'audio/mp4',
  'audio/x-mp4',
  'audio/aac',
  'audio/basic',
  'audio/x-m4a',
  'audio/m4a',
  'audio/mp3'
];

export const PDFMime = ['application/pdf'];
