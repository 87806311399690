import { HandSide, ThumbLength } from 'bluetooth/Bluetooth/Defines';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import {
  ControlModes,
  GripSwitchingModes,
  InputOptions,
  InputSites,
  SpeedControlStrategies,
  StartOnStartup,
  SingleElectrodeMode,
  InputDevices
} from '../../bluetooth/Bluetooth/Control';
import { Grips } from 'bluetooth/Bluetooth/Grips';

export enum HandMovementStates {
  idle,
  opening,
  closing
}

const handMovementStatesTuples: [HandMovementStates, string][] = [
  [HandMovementStates.idle, 'configurator:constants.hand_movement_states.idle'],
  [HandMovementStates.opening, 'configurator:constants.hand_movement_states.opening'],
  [HandMovementStates.closing, 'configurator:constants.hand_movement_states.closing']
];

const gripsOptionsTuples: any = [
  [Grips.kGripPower, 'configurator:constants.grips.power'],
  [Grips.kGripTripodOpen, 'configurator:constants.grips.tripod_open'],
  [Grips.kGripTripodClosed, 'configurator:constants.grips.tripod_closed'],
  [Grips.kGripTrigger, 'configurator:constants.grips.trigger'],
  [Grips.kGripPrecisionOpen, 'configurator:constants.grips.precision_open'],
  [Grips.kGripPrecisionClosed, 'configurator:constants.grips.precision_closed'],
  [Grips.kGripCamera, 'configurator:constants.grips.camera'],
  [Grips.kGripHook, 'configurator:constants.grips.hook'],
  [Grips.kGripKey, 'configurator:constants.grips.key'],
  [Grips.kGripFingerPoint, 'configurator:constants.grips.finger_point'],
  [Grips.kGripMouse, 'configurator:constants.grips.mouse'],
  [Grips.kGripRestOpp, 'configurator:constants.grips.rest_opp'],
  [Grips.kGripRestNopp, 'configurator:constants.grips.open_palm'],
  [Grips.kGripFingerPointOpen, 'configurator:constants.grips.active_index'],
  [Grips.kGripCounting, 'configurator:constants.grips.counting'],
  [Grips.kGripCustom1, 'configurator:constants.grips.custom1'],
  [Grips.kGripCustom2, 'configurator:constants.grips.custom2'],
  [Grips.kGripCustom3, 'configurator:constants.grips.custom3'],
  [Grips.kGripTypeUnknown, 'configurator:constants.grips.disabled']
];

const inputOptionTuples: any = [
  [InputOptions.kEMG, 'configurator:constants.input_options.emg'],
  [InputOptions.kLinearTransducer, 'configurator:constants.input_options.linear_transducer']
];

const inputDeviceTuples: any = [
  [InputDevices.kInputOptionElectrodes, 'configurator:constants.input_devices.emg'],
  [InputDevices.kInputOptionPatRec, 'configurator:constants.input_devices.coapt']
];

const inputSiteTuples: any = [
  [InputSites.kSingleElectrode, 'configurator:constants.input_sites.single'],
  [InputSites.kDualDirectElectrodes, 'configurator:constants.input_sites.dual_direct'],
  [InputSites.kDualInvertedElectrodes, 'configurator:constants.input_sites.dual_inverted']
];

const speedControlTuples: any = [
  [SpeedControlStrategies.kThreshold, 'configurator:constants.speed_control.one_speed'],
  [SpeedControlStrategies.kProportional, 'configurator:constants.speed_control.proportional']
];

const singleElectrodeModeTuples: any = [
  [SingleElectrodeMode.kFastRising, 'configurator:constants.single_electrode_mode.slope'],
  [SingleElectrodeMode.kAlternating, 'configurator:constants.single_electrode_mode.alternating']
];

const gripSwitchingTuples: any = [
  [GripSwitchingModes.kCoContraction, 'configurator:constants.grip_switching_modes.co_contraction'],
  [GripSwitchingModes.kOpenOpen, 'configurator:constants.grip_switching_modes.open_open'],
  [
    GripSwitchingModes.kSingleGripSwitching,
    'configurator:constants.grip_switching_modes.single_electrode'
  ],
  [GripSwitchingModes.kHoldOpen, 'configurator:constants.grip_switching_modes.hold_open'],
  [GripSwitchingModes.kCoapt, 'configurator:constants.grip_switching_modes.coapt']
];

const controlModeTuples: any = [
  [ControlModes.kGripPairs, 'configurator:constants.control_modes.pairing_mode'],
  [ControlModes.kGripSequence, 'configurator:constants.control_modes.sequential_mode'],
  [ControlModes.kCoapt, 'configurator:constants.control_modes.coapt']
];

const thumbLengthTuples: any = [
  [ThumbLength.kThumbLong, 'configurator:constants.thumb_length.thumb_long'],
  [ThumbLength.kThumbNotSet, 'configurator:constants.thumb_length.thumb_unknown'],
  [ThumbLength.kThumbShort, 'configurator:constants.thumb_length.thumb_short']
];

const handSideTuples: any = [
  [HandSide.kHandLeft, 'configurator:constants.hand_side.hand_left'],
  [HandSide.kHandRight, 'configurator:constants.hand_side.hand_right']
];

const genericSwitchingTuples: any = [
  [0, 'configurator:constants.generic_switching.off'],
  [1, 'configurator:constants.generic_switching.on']
];

const freezeModeTuples: [0 | 1 | 3, string][] = [
  [0, 'configurator:constants.freeze_mode.disabled'],
  [1, 'configurator:constants.freeze_mode.enabled'],
  [3, 'configurator:constants.freeze_mode.enabled']
];

const startOnStartupTuples: any = [
  [StartOnStartup.kNotWaitForFirstCoContraction, 'configurator:constants.start_on_startup.off'],
  [StartOnStartup.kWaitForFirstCoContraction, 'configurator:constants.start_on_startup.on']
];

const useFeedbackTypeTuples: [0, string][] = [
  [0, 'configurator:constants.use_feedback_type.finger_buzzer']
];

const userFeedbackTypePcbV4Tuples: [1 | 2, string][] = [
  [1, 'configurator:constants.user_feedback_type_pcb_v4.external_buzzer'],
  [2, 'configurator:constants.user_feedback_type_pcb_v4.vibration']
];

const allUserFeedbackTypeTuples = [...useFeedbackTypeTuples, ...userFeedbackTypePcbV4Tuples];

export const Fingers = {
  Thumb: 0,
  Index: 1,
  Middle: 2,
  Ring: 3,
  Pinky: 4
};

const SettingsNameTuples: any = [
  ['chooseGripsHistory', 'configurator:constants.settings.choose_grips'],
  ['emgSettingsHistory', 'configurator:constants.settings.emg_settings'],
  ['emgThresholdsHistory', 'configurator:constants.settings.emg_settings'],
  ['gripPairsConfigHistory', 'configurator:constants.settings.grips_configuration'],
  ['gripsConfigurationHistory', 'configurator:constants.settings.grips_configuration'],
  ['gripSequentialConfigHistory', 'configurator:constants.settings.grips_configuration'],
  ['prosthesisSettingsHistory', 'configurator:constants.settings.prosthesis_settings']
];

export const ConfigSettingsNamesTuples: [keyof DeviceConfigTemplate, string][] = [
  ['gripPairsConfig', 'configurator:constants.settings.grip_pairs'],
  ['inputSite', 'configurator:constants.settings.electrode_input_configuration'],
  ['inputDevice', 'configurator:constants.settings.input_device'],
  ['controlMode', 'configurator:constants.settings.control_mode'],
  ['speedControlStrategy', 'configurator:constants.settings.speed_control_strategy'],
  ['gripSwitchingMode', 'configurator:constants.settings.grip_switching_mode'],
  ['gripSequentialConfig', 'configurator:constants.settings.grips_sequential'],
  ['emgThresholds', 'configurator:constants.settings.emg_thresholds'],
  ['fingerStrength', 'configurator:constants.settings.finger_strength'],
  ['autoGrasp', 'configurator:constants.settings.autograsp'],
  ['emgSpike', 'configurator:constants.settings.ignore_emg_spikes'],
  ['holdOpen', 'configurator:constants.settings.hold_open'],
  ['softGrip', 'configurator:constants.settings.soft_grip'],
  ['emgGains', 'configurator:constants.settings.emg_gains'],
  ['pulseTimings', 'configurator:constants.settings.pulse_timings'],
  ['coContractionTimings', 'configurator:constants.settings.co_contraction_timings'],
  ['gripsPositions', 'configurator:constants.settings.grip_positions'],
  ['batteryBeep', 'configurator:constants.settings.low_battery_beep'],
  ['singleElectrodeMode', 'configurator:constants.settings.single_electrode_direction_control'],
  ['singleElectrodeModeSettings', 'configurator:constants.settings.electrode_settings'],
  ['freezeModeEmg', 'configurator:constants.settings.freeze_mode_emg'],
  ['generalHandSettings', 'configurator:constants.settings.general_hand_settings'],
  ['followingGrip', 'configurator:constants.settings.following_grip'],
  ['freezeModeEmgSettings', 'configurator:constants.settings.freeze_mode_emg_settings'],
  ['buzzingVolumeSettings', 'configurator:constants.settings.buzzing_volume'],
  ['userFeedbackType', 'configurator:constants.settings.feedback_type'],
  ['emergencyBatterySettings', 'configurator:constants.settings.emergency_mode'],
  ['oneSpeed', 'configurator:constants.settings.one_speed_settings'],
  [
    'singleElectrodeSettingsAlternating',
    'configurator:constants.settings.single_electrode_alternating_settings'
  ]
];

export const aetherBatteryVoltageFunctions = {
  voltageToPercent: (voltage: any) => {
    if (voltage >= 6500 && voltage < 6900) return Math.round(0.0225 * voltage - 145.25);
    if (voltage >= 6900 && voltage < 8400) return Math.round(0.06 * voltage - 404);
    if (voltage < 6500) return 1;
    return 100;
  },
  percentToVoltage: (percent: any) => {
    if (percent >= 1 && percent < 10) return Math.round(44.444 * percent + 6455.56);
    if (percent >= 10 && percent < 100) return Math.round(16.667 * percent + 6733.3);
    if (percent < 1) return 6500;
    return 8400;
  }
};

// eslint-disable-next-line no-unused-vars
export const inputOptionsMap: any = new Map(inputOptionTuples);
export const inputSiteMap: any = new Map(inputSiteTuples);
export const inputDeviceMap: any = new Map(inputDeviceTuples);
export const speedControlMap: any = new Map(speedControlTuples);
export const singleElectrodeModeMap: any = new Map(singleElectrodeModeTuples);
export const gripSwitchingMap: any = new Map<GripSwitchingModes, string>(gripSwitchingTuples);
export const controlModeMap: any = new Map(controlModeTuples);
export const genericSwitchingMap: any = new Map(genericSwitchingTuples);
export const configSettingsNamesMap: Map<keyof DeviceConfigTemplate, string> = new Map(
  ConfigSettingsNamesTuples
);
export const startOnStartupMap: any = new Map(startOnStartupTuples);
export const thumbLengthMap: any = new Map(thumbLengthTuples);
export const handSideMap: any = new Map(handSideTuples);
export const userFeedbackTypeMap: Map<number, string> = new Map(useFeedbackTypeTuples);
export const userFeedbackTypePcbV4Map: Map<number, string> = new Map(userFeedbackTypePcbV4Tuples);
export const allUserFeedbackTypeMap: Map<number, string> = new Map(allUserFeedbackTypeTuples);
export const freezeModeMap: Map<number, string> = new Map(freezeModeTuples);
export const handMovementStatesMap: Map<HandMovementStates, string> = new Map(
  handMovementStatesTuples
);

export const inputOptionsReversedMap = new Map(
  Array.from(inputOptionsMap, (a: any) => a.reverse())
);
export const inputSiteReversedMap = new Map(Array.from(inputSiteMap, (a: any) => a.reverse()));
export const inputDeviceReversedMap = new Map(Array.from(inputDeviceMap, (a: any) => a.reverse()));
export const speedControlReversedMap = new Map(
  Array.from(speedControlMap, (a: any) => a.reverse())
);
export const singleElectrodeModeReverseMap = new Map(
  Array.from(singleElectrodeModeMap, (a: any) => a.reverse())
);
export const gripSwitchingReversedMap = new Map(
  Array.from(gripSwitchingMap, (a: any) => a.reverse())
);
export const controlModeReversedMap = new Map(Array.from(controlModeMap, (a: any) => a.reverse()));
export const genericSwitchingReversedMap = new Map(
  Array.from(genericSwitchingMap, (a: any) => a.reverse())
);
export const userFeedbackTypeReversedMap = new Map(
  Array.from(userFeedbackTypeMap, (a: any) => a.reverse())
);
export const userFeedbackTypePcbV4ReversedMap = new Map(
  Array.from(userFeedbackTypePcbV4Map, (a: any) => a.reverse())
);
export const allUserFeedbackTypeReversedMap = new Map(
  Array.from(allUserFeedbackTypeMap, (a: any) => a.reverse())
);
export const gripsGroupsOptionsMap: any = new Map(gripsOptionsTuples);
export const gripsGroupsOptionsReversedMap = new Map(
  Array.from(gripsGroupsOptionsMap, (a: any) => a.reverse())
);
export const historySettingsNameMap = new Map(SettingsNameTuples);
export const historySettingsNameReversedMap = new Map(
  Array.from(historySettingsNameMap, (a: any) => a.reverse())
);
export const startOnStartupReversedMap = new Map(
  Array.from(startOnStartupMap, (a: any) => a.reverse())
);
export const thumbLengthReversedMap = new Map(Array.from(thumbLengthMap, (a: any) => a.reverse()));
export const handSideReversedMap = new Map(Array.from(handSideTuples, (a: any) => a.reverse()));
export const freezeModeReversesMap = new Map(Array.from(freezeModeTuples, (a: any) => a.reverse()));
export const handMovementStatesReversedMap = new Map(
  Array.from(handMovementStatesTuples, (a: any) => a.reverse())
);
