import { useState } from 'react';
import { useSessionStart } from './useSessionStart';
import { useModal } from 'adp-panel/hooks/api/useModal';

export const useHandleMultipleDevice = () => {
  const [patientMultipleDevices, setPatientMultipleDevices] = useState([]);
  const { handleOpenLocalSession } = useSessionStart();
  const {
    isOpen: isChooseModalOpen,
    handleOpen: handleChooseDeviceModalOpen,
    handleClose: handleChooseDeviceModalClose
  } = useModal();

  const handleConfigure = (devices) => {
    const isSingleDevice = devices.length === 1;
    if (isSingleDevice) {
      handleOpenLocalSession(devices[0]?.serial, devices[0]?.bluetooth_id, devices[0]?.id);
    } else {
      setPatientMultipleDevices(devices);
      handleChooseDeviceModalOpen();
    }
  };

  return {
    handleConfigure,
    isChooseModalOpen,
    handleChooseDeviceModalClose,
    patientMultipleDevices
  };
};
