import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { GraphComponentInsideContainer } from 'adp-panel/components/DeviceUsageMonitoring/styled';
import React from 'react';

export const TotalGripBreakdownGraph = ({
  data,
  keys,
  yLabel = 'Number of grips performed'
}: any) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy='label_name'
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Grip',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 80,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role='application'
    ariaLabel='Total grip breakdown chart'
  />
);

export const TotalGripBreakdownGraphPie = ({
  data,
  keys,
  yLabel = 'Number of grips performed'
}: any) => {
  return (
    <GraphComponentInsideContainer>
      <div style={{ height: '90%' }}>
        <ResponsivePie
          data={data}
          value={keys}
          margin={{ top: 60, right: 0, bottom: 60, left: 0 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={2}
          activeOuterRadiusOffset={8}
          borderWidth={0}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]}
          colors={[
            'hsl(211, 90%, 53%)',
            'hsl(233, 86%, 51%)',
            'hsl(197, 100%, 48%)',
            'hsl(247, 76%, 69%)',
            'hsl(183, 71%, 41%)',
            'hsl(262, 100%, 38%)',
            'hsl(168, 60%, 36%)',
            'hsl(276, 55%, 81%)',
            'hsl(154, 100%, 29%)',
            'hsl(291, 46%, 26%)',
            'hsl(202, 5%, 88%)',
            'hsl(224, 0%, 85%)',
            'hsl(188, 25%, 82%)',
            'hsl(238, 0%, 100%)',
            'hsl(174, 0%, 76%)',
            'hsl(253, 40%, 73%)',
            'hsl(159, 0%, 70%)',
            'hsl(267, 0%, 100%)',
            'hsl(145, 55%, 64%)',
            'hsl(282, 0%, 61%)'
          ]}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
        />
      </div>
    </GraphComponentInsideContainer>
  );
};
