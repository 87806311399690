import React, { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';

export enum IconSidebarModes {
  standard,
  minimal
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &&:hover {
    transform: scale(1.04, 1.04);
    transition: transform 0.2s;
  }
`;

const StyledIcon = styled.div`
  height: 20px;
  width: 20px;
  object-fit: fill;
  color: ${({ theme }) => theme.colorPrimary};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 30px;
    width: 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 40px;
    width: 40px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface IconDescribedProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
  description: string;
  mode?: IconSidebarModes;
}

const IconDescribed = ({
  children,
  description,
  mode = IconSidebarModes.standard,
  ...props
}: IconDescribedProps) => (
  <Wrapper {...props}>
    <StyledIcon mode={mode}>{children}</StyledIcon>
  </Wrapper>
);

export default IconDescribed;
