export const permissions = [
  {
    text: 'Grips',
    id: 'grips',
    items: [
      { text: 'Change grips', id: 'grips.changeGrips' },
      {
        text: 'Transition mode',
        id: 'grips.transitionMode',
        affectTo: { field: 'grips.changeGrips', when: 'selected' }
      },
      { text: 'Grips configuration', id: 'grips.gripsConfiguration' }
    ]
  },
  {
    text: 'EMG settings',
    id: 'emg'
  },
  {
    text: 'Main settings',
    id: 'settings'
  },
  {
    text: 'Goals',
    id: 'goals'
  },
  {
    text: 'Soft grip',
    id: 'soft-grip'
  },
  {
    text: 'Speed control strategy',
    id: 'speed-control'
  },
  {
    text: 'Other settings',
    id: 'other'
  }
  // {
  //   text: 'Tickets',
  //   id: 'tickets',
  //   items: [
  //     { text: 'View', id: 'tickets.view' },
  //     { text: 'Update', id: 'tickets.update' },
  //     { text: 'Send', id: 'tickets.send' },
  //     { text: 'Close', id: 'tickets.close' }
  //   ]
  // }
];
