import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useTypedParams } from 'hooks/useTypedParams';
import { ErrorComponent } from 'adp-panel/components/Error/ErrorComponent';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import LoaderWrapper from 'components/Loader/Loader';
import Card from 'adp-panel/components/Card/Card';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useSessionStart } from 'hooks/useSessionStart';
import { useHandleMultipleDevice } from 'hooks/useHandleMultipleDevice';
import { usePatientDetails } from './hooks/usePatientDetails';
import { usePatientDevices } from './hooks/usePatientDevices';
import { usePatientModals } from './hooks/usePatientModals';
import { usePatientTabs } from './hooks/usePatientTabs';
import { PatientHeader } from './components/PatientHeader';
import { PatientDevices } from './components/PatientDevices';
import { PatientTabs } from './components/PatientTabs';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import AssignDevicePatientDetailsModal from '../../components/Modals/AssignDevicePatientDetailsModal';
import ChooseDeviceModal from '../../components/Modals/ChooseDeviceModal';
import { Container } from './styled/PatientDetails.styled';
import useUserData from '../../../hooks/useUserData';

const PatientDetails: React.FC = () => {
  const { t } = useTranslation('patients');
  const { patientId } = useTypedParams(['patientId']);
  const { connected, serial } = useDeviceInfoStore();
  const { openModal } = useUiStore();
  const { handleOpenRemoteSession, sessionStartingState } = useSessionStart();
  const {
    handleConfigure,
    isChooseModalOpen,
    handleChooseDeviceModalClose,
    patientMultipleDevices
  } = useHandleMultipleDevice();
  const { rolesByName } = useUserData();

  const { patient, isLoadingPatient, isErrorPatientInfo, isLoadingDelete, handleDelete } =
    usePatientDetails(patientId);

  const {
    devices,
    totalDevices,
    isLoadingDevices,
    paginationModel,
    setPaginationModel,
    handleUnassignDevice
  } = usePatientDevices(patientId);

  const { deleteModal, unassignDeviceModal, assignDeviceModal } = usePatientModals();
  const { selectedTab, handleTabChange } = usePatientTabs();

  if (isErrorPatientInfo) {
    return (
      <ErrorComponent
        message={t(
          'patients:details.error_patient_not_found',
          "The patient no longer exists or you don't have access to it."
        )}
        allowRedirect
      />
    );
  }

  return (
    <DefaultLayout>
      {isLoadingPatient ? (
        <LoaderWrapper full>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <Container>
          {deleteModal.isOpen && (
            <ConfirmDeleteModal
              handleClose={deleteModal.handleClose}
              handleAccept={handleDelete}
              isLoading={isLoadingDelete}
              message={
                <ModalMessageDelete
                  name={patient?.name}
                  id={parseInt(patientId, 10)}
                  text={t(
                    'patients:details.delete_patient_confirmation',
                    'Do you want to delete patient'
                  )}
                />
              }
            />
          )}

          {unassignDeviceModal.isOpen && (
            <ConfirmDeleteModal
              handleClose={unassignDeviceModal.handleClose}
              handleAccept={handleUnassignDevice}
              isLoading={isLoadingDevices}
              message={t(
                'patients:details.unassign_device_confirmation',
                'Do you want to unassign device from the patient?'
              )}
              acceptMessage={t('patients:details.accept_unassign', 'Yes, unassign')}
              title={t('patients:details.unassign_device_title', 'Unassign device')}
            />
          )}

          {isChooseModalOpen && (
            <ChooseDeviceModal
              handleModalClose={handleChooseDeviceModalClose}
              devices={patientMultipleDevices}
            />
          )}

          <AssignDevicePatientDetailsModal
            isModalOpen={assignDeviceModal.isOpen}
            handleModalClose={assignDeviceModal.handleClose}
            patientId={patientId}
          />

          <Card>
            <PatientHeader
              patient={patient}
              devices={devices}
              rolesByName={rolesByName}
              connected={connected}
              serial={serial}
              sessionStartingState={sessionStartingState}
              handleConfigure={handleConfigure}
              handleOpenRemoteSession={handleOpenRemoteSession}
              openModal={openModal}
            />

            <PatientDevices
              devices={devices}
              totalDevices={totalDevices}
              isLoadingDevices={isLoadingDevices}
              rolesByName={rolesByName}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              handleUnassignDevice={handleUnassignDevice}
              handleAssignDeviceModalOpen={assignDeviceModal.handleOpen}
            />
          </Card>

          <PatientTabs
            devices={devices}
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
            rolesByName={rolesByName}
          />
        </Container>
      )}
    </DefaultLayout>
  );
};

export default PatientDetails;
