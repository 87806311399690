import { ApiDUMClient, withCredentials } from '../utils/apiClient';
import {
  EmgPeakChartQueryParams,
  TimeSpentChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartRecord
} from './deviceUsageMonitoring.types';
import qs from 'qs';

export const GET_CHARTS_DATA = 'api/charts/grip/{deviceId}';
export const GET_CHARTS_COUNT_DATA = 'api/charts/grip-count/{deviceId}';
export const GET_TIME_SPENT_DATA = 'api/charts/time-spent/{deviceId}';
export const GET_CHARTS_EMG_PEAK_DATA = 'api/charts/emg-peak/{deviceId}';
export const GET_VELOCITY_LEVEL_DATA = 'api/charts/velocity-level/{deviceId}';

export const getChartsData = (params: UsageMonitoryQueryParams | null): Promise<any> => {
  return ApiDUMClient.get(
    `${GET_CHARTS_DATA.replace('{deviceId}', `${params?.deviceId}`)}?${qs.stringify(params)}`,
    withCredentials
  ).then((res: any) => res.data);
};

export const getChartsCountData = (params: UsageMonitoryQueryParams | null): Promise<any> => {
  return ApiDUMClient.get(
    `${GET_CHARTS_COUNT_DATA.replace('{deviceId}', `${params?.deviceId}`)}?${qs.stringify(params)}`,
    withCredentials
  ).then((res: any) => res.data);
};

export const getTimeSpentData = (params: TimeSpentChartQueryParams | null): Promise<any> => {
  return ApiDUMClient.get(
    `${GET_TIME_SPENT_DATA.replace('{deviceId}', `${params?.deviceId}`)}?${qs.stringify(params)}`,
    withCredentials
  ).then((res: any) => res.data);
};

export const getEmgPeakChartData = (params: EmgPeakChartQueryParams | null): Promise<any> => {
  return ApiDUMClient.get(
    `${GET_CHARTS_EMG_PEAK_DATA.replace('{deviceId}', `${params?.deviceId}`)}?${qs.stringify(
      params
    )}`,
    withCredentials
  ).then((res: any) => res.data);
};

export const getVelocityLevelChartData = (
  params: EmgPeakChartQueryParams | null
): Promise<VelocityLevelChartRecord[]> => {
  return ApiDUMClient.get(
    `${GET_VELOCITY_LEVEL_DATA.replace('{deviceId}', `${params?.deviceId}`)}?${qs.stringify(
      params
    )}`,
    withCredentials
  ).then((res: any) => res.data);
};
