import styled, { css } from 'styled-components';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import useUserData from '../../../hooks/useUserData';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { ActivitiesGrid } from './ActivitiesGrid/ActivitiesGrid';
import { InboxGrid } from './Inbox/Inbox';
import PatientsDashboard from '../Patients/PatientsDashboard';

interface GridWrapperProps {
  isAdmin?: boolean;
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const initialFilterActivities = {
  filter: {
    logic: 'and',
    filters: [{ field: 'element', operator: 'contains', value: '' }]
  }
};

const Dashboard = () => {
  const { rolesByName } = useUserData();

  return (
    <DefaultLayout>
      <GridWrapper isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}>
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <ActivitiesGrid initialFilter={initialFilterActivities} />
        )}
        {userHasPermissions(
          [RoleEnum.clinicianSupport, RoleEnum.clinicAdmin, RoleEnum.clinician],
          rolesByName
        ) && <PatientsDashboard />}
        {userHasPermissions([RoleEnum.clinicAdmin, RoleEnum.clinician], rolesByName) && (
          <InboxGrid />
        )}
      </GridWrapper>
    </DefaultLayout>
  );
};

export default Dashboard;
