import { ModalStyle } from 'adp-panel/components/Modals/styles';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import { Box, Divider, Link, Modal, Stack, Typography } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

const StyledIcons = styled(SvgIcon)`
  color: ${({ theme }) => theme.palette.subtleText};
  margin-right: 8px;
`;

const StyledTopicLabel = styledMui(Typography)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
`;

const StyledTopicText = styledMui(Typography)`
  color: #101828;
  font-size: 14px;
  font-weight: 500;
`;

const DialogWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.p`
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  //margin: 16px 16px 16px 24px;
`;

const InnerDetails = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
  }
`;

const TimeWrapper = styled.ul`
  width: 200px;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;
  margin-bottom: 10px;
  justify-content: center;
`;

type MessageModalProps = {
  handleClose: any;
  content: string;
  title: string;
  date: string;
  hour: string;
  sender: string;
  onModalOpen: any;
};

const MessageModal = ({
  title,
  handleClose,
  content,
  date,
  hour,
  sender,
  onModalOpen
}: MessageModalProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (onModalOpen) onModalOpen();
  }, []);

  return (
    <Modal open={true} sx={{ width: '724px', ...ModalStyle }}>
      <FormContentWrapper title={title}>
        <InnerWrapper>
          <ModalContent dangerouslySetInnerHTML={{ __html: content }} />
          <Divider style={{ margin: '16px 0' }} />
          <Stack direction='row' spacing={6}>
            <Box>
              <StyledTopicLabel>
                {t('common:component.message_modal.from', 'From:')}
              </StyledTopicLabel>
              <StyledTopicText>{sender}</StyledTopicText>
            </Box>
            <Box>
              <StyledTopicLabel>
                {t('common:component.message_modal.created_at', 'Created at:')}
              </StyledTopicLabel>
              <StyledTopicText>
                {date} {hour}
              </StyledTopicText>
            </Box>
          </Stack>
          <ButtonsWrapper>
            <CustomButton
              data-testid='cancel-verify-phone'
              type='button'
              color='light'
              onClick={handleClose}>
              {t('common:verify_phone_modal.buttons.close', 'Close')}
            </CustomButton>
          </ButtonsWrapper>
        </InnerWrapper>
      </FormContentWrapper>
    </Modal>
  );
};

export default MessageModal;
