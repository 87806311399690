import * as Sentry from '@sentry/react';
import { useForceChangePasswordForAnotherUser, useUserCreate } from 'adp-panel/hooks/api/useUsers';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import ClinicianForm from 'adp-panel/pages/Clinicians/ClinicianForm';
import { getValidFiles } from 'adp-panel/utils/getFileName';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { isNil, omitBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserData from '../../../hooks/useUserData';
import { RoleEnum, UserCreatePayload } from '../../api/users/users.types';
import { permanentPermissions } from '../../constants/permanentPermissions';
import { convertToPermissionString } from '../../hoc/useCanAccess';
import { rolesNamesMap } from '../../utils/definesLocal';
import { MEDICAL_PROFESSIONALS } from '../../../constants/routes';

const AddClinician = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role: roleName } = location.state as { role: RoleEnum };
  const { rolesByName } = useUserData();
  const { mutateAsync: createUser, isLoading: isLoadingCreate } = useUserCreate();
  const { mutateAsync: changePasswordRequest } = useForceChangePasswordForAnotherUser();

  const handleSubmit = async ({
    name = undefined,
    email,
    password = undefined,
    image = undefined,
    location,
    permissions = [],
    patients = [],
    role
  }: any) => {
    try {
      const permissionPerUser = convertToPermissionString([
        ...permissions,
        ...permanentPermissions
      ]);
      const user = await createUser(
        omitBy(
          {
            name,
            email,
            location: location?.id || undefined,
            role: role.id,
            image: getValidFiles(image),
            ...(permissions.length > 0 && { permissions: permissionPerUser })
          },
          isNil
        ) as UserCreatePayload
      );
      if (password && password.length > 0) {
        await changePasswordRequest({ userId: user?.id, password });
      }
      navigate(MEDICAL_PROFESSIONALS);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={`Add ${rolesNamesMap.get(roleName)}`} small>
        <ClinicianForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          isLoading={isLoadingCreate}
          roleInitial={roleName}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default AddClinician;
