import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
});

export const ProfileSection = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export const ProfileInfo = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  width: '100%',
  alignItems: 'center'
});

export const InfoBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});
export const ActionButtons = styled('div')({
  display: 'flex',
  gap: '16px',
  marginTop: '16px'
});

export const StyledName = styled(Typography)({
  fontWeight: 600
});

export const StyledValue = styled(Typography)({
  fontWeight: 600,
  variant: 'h6'
});
