import styled from 'styled-components';

export const SectionHeader = styled.h2`
  font-weight: 500;
  margin-bottom: 20px;
  color: '#101A5A';
`;

export const SectionParagraph = styled.p`
marginBottom: '12px',
color: '#344054',
textAlign: 'left',
width: '100%',
`;
