import React from 'react';
import { LogoLoginPage, MainWrapper, Wrapper } from './styled';
import './style.css';
import logo from 'assets/aether_biomedical_logo.svg';

const AuthLayout = ({
  children,
  lightVariant = false
}: {
  children: React.ReactNode;
  showUdi?: boolean;
  lightVariant?: boolean;
  noFooter?: boolean;
}) => (
  <Wrapper lightVariant={lightVariant} style={{ justifyContent: 'flex-start' }}>
    <LogoLoginPage src={logo} />
    <MainWrapper>{children}</MainWrapper>
  </Wrapper>
);

export default AuthLayout;
