/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum Grips {
  kGripRestOpp = 0,
  kGripPower = 1,
  kGripTripodClosed = 2,
  kGripTrigger = 3,
  kGripPrecisionOpen = 4,
  kGripRestNopp = 5,
  kGripHook = 6,
  kGripKey = 7,
  kGripMouse = 8,
  kGripFingerPoint = 9,
  kGripCamera = 10,
  kGripTripodOpen = 11,
  kGripPrecisionClosed = 12,
  kGripFingerPointOpen = 13,
  kGripCounting = 14,
  kGripCustom1 = 16,
  kGripCustom2 = 17,
  kGripCustom3 = 18,
  kGripTypeUnknown = 255
}

export enum CustomGripsConfig {
  kInitPosition = 0,
  kLowerPosition = 1,
  kLimitPosition = 2,
  kActiveFingers = 3,
  kFollowingActive = 4,
  kAdjustableFingers = 5,
  kThumbDelayThreshold = 6,
  kIgnoreUpperLimit = 7,
  kIgnoreLowerLimit = 8,
  kTransitionNotificationConfig = 9
}
