/* eslint-disable prefer-destructuring */
import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { freezeModeMap, freezeModeReversesMap } from 'configurator/utils/definesLocal';
import { ConfigStoreState } from 'configurator/reducers/configStore';
import { freezeModeEmgEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useTranslation } from 'react-i18next';

type FreezeModeEmgProps = {
  addHistoryProsthesis: any;
  freezeModeEmg: freezeModeEmgEntry;
  freezeModeSwitchPermission: boolean;
  replayIsEnabled: boolean;
  setConfigProperty: ConfigStoreState['setConfigProperty'];
};

const FreezeModeEMG = ({
  addHistoryProsthesis,
  freezeModeEmg,
  freezeModeSwitchPermission,
  replayIsEnabled,
  setConfigProperty
}: FreezeModeEmgProps) => {
  const { t } = useTranslation();
  const handleFreezeModeEmgStatus = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('freezeModeEmg', [
        // @ts-ignore
        freezeModeReversesMap.get(value),
        freezeModeEmg[0],
        freezeModeEmg[1]
      ])
    );
  };

  const FreezeModeEMG = useMemo(
    () => (
      <InputOptions
        header={t('configurator:component.freeze_mode_emg.header', 'EMG freeze mode switch')}
        options={[
          'configurator:constants.freeze_mode.enabled',
          'configurator:constants.freeze_mode.disabled'
        ]}
        id='freezeModeEmg'
        onChange={handleFreezeModeEmgStatus}
        disable={replayIsEnabled || !freezeModeSwitchPermission}
        // @ts-ignore
        value={freezeModeMap.get(freezeModeEmg?.[2])}
        description={t(
          'configurator:component.freeze_mode_emg.description',
          'Enable or disable EMG freeze mode for the patient using EMG signals.'
        )}
        tooltip={t('configurator:component.freeze_mode_emg.tooltip', 'Hello')}
      />
    ),
    [freezeModeEmg, freezeModeSwitchPermission]
  );

  return FreezeModeEMG;
};

export default FreezeModeEMG;
