import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoCard = ({ buttonText, buttonAction, headerText, paragraphText }) => (
  <div
    style={{
      border: '1px solid #EAECF0',
      padding: '16px',
      borderRadius: '12px',
      backgroundColor: '#F9FAFB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <div
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: '#EAECF0',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <InfoOutlinedIcon sx={{ color: '#667085' }} />
      </div>
      <div style={{ width: '270px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#101828' }}>
          {headerText}
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#475467' }}>{paragraphText}</Typography>
      </div>
    </div>
    <CustomButton color='light' onClick={buttonAction}>
      {buttonText}
    </CustomButton>
  </div>
);

export default InfoCard;
