import { useAddPatient } from 'adp-panel/pages/Patients/useAddPatient';
import { useTranslation } from 'react-i18next';
import PatientForm from './PatientForm';
import { RoleEnum } from '../../../api/users/users.types';
import useUserData from '../../../../hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useState } from 'react';

const AddPatient = () => {
  const { t } = useTranslation('patients');
  const { rolesByName } = useUserData();

  const { handleSubmit, isLoading } = useAddPatient();

  const FORM_STEPS = [
    {
      id: 0,
      label: t('patients:patient_form.step1_label', 'Patient Details'),
      content: t(
        'patients:patient_form.step1_content',
        "Please provide patient's basic information"
      )
    },
    {
      id: 1,
      label: t('patients:patient_form.step2_label', 'Medical Professionals'),
      content: t(
        'patients:patient_form.step2_content',
        "Invite other medical professionals (CPO's OT/PT, etc. to access patient)"
      )
    }
  ];
  const [formStep, setFormStep] = useState(FORM_STEPS[0].id);

  return (
    <DefaultLayout>
      <FormContentWrapper
        title={
          formStep === 0
            ? t('patients:add_patient.title', 'Create new patient')
            : t('patients:add_patient.title2', 'Invite new Medical Professional')
        }
        small>
        <PatientForm
          handleSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.clinicAdmin], rolesByName)}
          isLoading={isLoading}
          formStep={formStep}
          setFormStep={setFormStep}
          steps={FORM_STEPS}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default AddPatient;
