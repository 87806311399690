import { AddDeviceCodePayload, DeviceCode } from './deviceCodes.types';
import api, { withCredentials } from '../utils/apiClient';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from 'types';
import { encodeQueryData } from '../utils/encodeQuery';
export const GET_ACTIVE_DEVICE_CODE = 'api/activation-codes';
export const CREATE_ACTIVATION_CODE = 'api/activation-codes';
export const ADD_DEVICE_WITH_CODE = 'api/device/add/{serial}';
export const DELETE_ACTIVATION_CODE = 'api/activation-codes/{codeId}';

export const getActiveDeviceCode = (params = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<DeviceCode>>(`${GET_ACTIVE_DEVICE_CODE}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const createNewActivationCode = (): Promise<DeviceCode> => {
  return api
    .post<DeviceCode>(CREATE_ACTIVATION_CODE, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const deleteActivationCode = (codeId: number): Promise<any> =>
  api
    .delete(DELETE_ACTIVATION_CODE.replace('{codeId}', `${codeId}`), withCredentials)
    .then((res) => res.data);
