import { ChartInfo } from './types';

export const switchesPerformedDescription: ChartInfo = {
  description: 'common:component.graphs.switches_performed_description',
  sections: [
    {
      title: 'common:component.graphs.switches_performed.section_1.title',
      content: [
        {
          title: 'common:component.graphs.switches_performed.section_1.issue.title',
          content: 'common:component.graphs.switches_performed.section_1.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.switches_performed.section_1.significance.title',
          content: 'common:component.graphs.switches_performed.section_1.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.switches_performed.section_1.action.title',
          content: 'common:component.graphs.switches_performed.section_1.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ]
      // examples: [
      //   {
      //     label: 'common:component.graphs.switches_performed.section_1.examples.good',
      //     status: 'good',
      //     image: SwitchesPerformedGood
      //   },
      //   {
      //     label: 'common:component.graphs.switches_performed.section_1.examples.bad',
      //     status: 'bad',
      //     image: SwitchesPerformedBad
      //   }
      // ]
    },
    {
      title: 'common:component.graphs.switches_performed.section_2.title',
      content: [
        {
          title: 'common:component.graphs.switches_performed.section_2.issue.title',
          content: 'common:component.graphs.switches_performed.section_2.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.switches_performed.section_2.significance.title',
          content: 'common:component.graphs.switches_performed.section_2.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.switches_performed.section_2.action.title',
          content: 'common:component.graphs.switches_performed.section_2.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ]
      // examples: [
      //   {
      //     label: 'common:component.graphs.switches_performed.section_2.examples.good',
      //     status: 'good',
      //     image: SwitchesPerformedGood
      //   },
      //   {
      //     label: 'common:component.graphs.switches_performed.section_2.examples.bad',
      //     status: 'bad',
      //     image: SwitchesPerformedBad
      //   }
      // ]
    }
  ]
};
