import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import ModalBase from './ModalBase';
import { StyledCheck } from 'configurator/components/atoms/Icons/Icons';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

const CheckIcon = styled(StyledCheck)`
  margin: 10px 0;
  width: 48px;
  height: 48px;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;
type ModalProps = {
  handleClose: any;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalBase
      handleClick={handleClose}
      header={t(
        'configurator:component.reset_all_changes_modal.save_as_restore_point',
        'Save as restore point'
      )}>
      <DialogContent>
        <ContentWrapper>
          <CheckIcon />
          <ParagraphBold>
            {t(
              'configurator:component.reset_all_changes_modal.restore_point_created',
              'The restore point was successfully created.'
            )}
          </ParagraphBold>
        </ContentWrapper>
        <ButtonsWrapper>
          <Button sx={{ width: 200 }} variant='outlined' onClick={handleClose}>
            {t('configurator:component.reset_all_changes_modal.close', 'Close')}
          </Button>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default ResetAllChangesModal;
