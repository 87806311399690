/* eslint-disable react/display-name */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Slider from 'configurator/components/atoms/Slider/Slider';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { myTheme } from 'theme/theme';
import { Checkbox } from '@mui/material';
import { ConfigurationSection } from 'configurator/views/ProsthesisSettings/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Label = styled.h4`
  ${TextS};
  margin-bottom: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 15px;
  }
`;

const InnerWrapper = styled.div`
  display: grid;
  justify-content: center;
  height: 100%;
  grid-gap: 20px;
  justify-items: center;
  padding: 10px 15px 20px 15px;
  grid-auto-flow: column;
  background-color: ${myTheme.palette.background};
  border-radius: 8px;
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SliderInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 10px;
`;

const TooltipWrap = styled.div`
  height: 100%;
`;

const InnerLabel = styled.h5`
  ${TextS};
  margin-bottom: 7px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 15px;
  }
`;

const VerticalGraphSlider = memo(
  ({
    sliders,
    label,
    data,
    handleChange,
    handleOnAfterChange,
    handleOnBeforeChange,
    checked,
    handleCheckbox,
    checkboxIndexes,
    tooltipDirection,
    disableSliders,
    trackClasses,
    limits,
    disabledAccess,
    disableAccessTooltip,
    ...props
  }) => (
    <Wrapper {...props}>
      <Label>{label}</Label>
      <ConfigurationSection tooltipText={disableAccessTooltip} disabled={disabledAccess}>
        <InnerWrapper>
          {sliders.map((slider, index) => (
            <SliderWrapper key={label + slider.label + index}>
              <InnerLabel>{slider.label}</InnerLabel>
              <ReactTooltip
                id={label + slider.label + index + tooltipDirection}
                place={tooltipDirection}
                type='info'
                effect='solid'>
                <span>{slider.value}</span>
              </ReactTooltip>
              <SliderInnerWrapper>
                <TooltipWrap data-tip data-for={label + slider.label + index + tooltipDirection}>
                  <Slider
                    orientationTrack='vertical'
                    data={{ ...data, sliderIndex: slider.index }}
                    handleChange={handleChange}
                    handleOnAfterChange={handleOnAfterChange}
                    handleOnBeforeChange={handleOnBeforeChange}
                    invert
                    value={slider.value}
                    min={limits.min}
                    max={limits.max}
                    disable={disableSliders}
                    trackClass={trackClasses[index]}
                  />
                </TooltipWrap>
                <Checkbox
                  id={`checkbox${label + slider.label + index}`}
                  name={`checkbox${label + slider.label + index}`}
                  checked={checked[index]}
                  onChange={() => handleCheckbox(checkboxIndexes[index])}
                />
              </SliderInnerWrapper>
            </SliderWrapper>
          ))}
        </InnerWrapper>
      </ConfigurationSection>
    </Wrapper>
  )
);

VerticalGraphSlider.defaultProps = {
  data: { default: 'default' },
  handleOnAfterChange: () => true,
  handleOnBeforeChange: () => true,
  checked: false,
  handleCheckbox: () => true,
  checkboxIndexes: [],
  trackClasses: [],
  limits: { min: 1, max: 100 }
};

VerticalGraphSlider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sliders: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  handleOnAfterChange: PropTypes.func,
  handleOnBeforeChange: PropTypes.func,
  checked: PropTypes.bool,
  handleCheckbox: PropTypes.func,
  checkboxIndexes: PropTypes.arrayOf(PropTypes.number),
  trackClasses: PropTypes.arrayOf(PropTypes.string),
  limits: PropTypes.objectOf(PropTypes.number)
};

export default VerticalGraphSlider;
