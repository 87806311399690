import { Typography } from '@mui/material';
import { myTheme } from 'theme/theme';

const CustomHeaderSmall = ({ header, sx }: { header: string; sx?: any }) => (
  <Typography
    variant='h6'
    sx={{ marginBottom: '22px', color: myTheme.palette.fontColor, fontWeight: '600', ...sx }}>
    {header}
  </Typography>
);

export default CustomHeaderSmall;
