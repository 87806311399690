/* eslint-disable no-useless-escape */
import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface PasswordStrengthProps {
  password: string;
  minLength: number;
  specialChars?: boolean;
  upperCase?: boolean;
  numbers?: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
`;

const GenericBox = styled.div<{ inputColor?: string }>`
  background-color: ${(props) => props.inputColor};
  border-radius: 8px;
  height: 4px;
`;

const BoxesColors = ['green', 'green', 'green', 'green', 'green'];

const PasswordStrength = ({
  password,
  minLength,
  specialChars = false,
  upperCase = false,
  numbers = false
}: PasswordStrengthProps) => {
  const { t } = useTranslation();
  let boxesNumber = 2;
  let step = 0;
  const requirements = [];

  if (specialChars) {
    boxesNumber += 1;
  }
  if (upperCase) {
    boxesNumber += 1;
  }
  if (numbers) {
    boxesNumber += 1;
  }

  if (password.length >= minLength) {
    step += 1;
  } else {
    requirements.push(
      t('common:password_strength.at_least_characters', {
        defaultValue: 'at least {{minLength}} characters',
        minLength
      })
    );
  }
  if (/[a-z]/.test(password)) {
    step += 1;
  } else {
    requirements.push(t('common:password_strength.one_lowercase_letter', 'one lowercase letter'));
  }
  if (numbers && /\d/.test(password)) {
    step += 1;
  } else {
    requirements.push(t('common:password_strength.one_number', 'one number'));
  }
  if (upperCase && /[A-Z]/.test(password)) {
    step += 1;
  } else {
    requirements.push(t('common:password_strength.one_uppercase_letter', 'one uppercase letter'));
  }
  if (specialChars && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
    step += 1;
  } else {
    requirements.push(t('common:password_strength.one_special_character', 'one special character'));
  }

  let message: NonNullable<unknown> | null | undefined;
  if (step < boxesNumber) {
    message =
      t('common:password_strength.initial_message', 'The password should contain') +
      ' ' +
      requirements.join(', ');
  } else {
    message = t('common:password_strength.strong_password', 'Password is strong!');
  }
  const boxes = Array.from(Array(boxesNumber).keys());

  return (
    <div>
      <Wrapper>
        {boxes.map((box) => (
          <GenericBox key={box} inputColor={step > box ? BoxesColors[box] : 'gray'} />
        ))}
      </Wrapper>
      <FormHelperText>{message}</FormHelperText>
    </div>
  );
};

export default PasswordStrength;
