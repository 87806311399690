import { LoadingButton } from '@mui/lab';
import { Button, DialogContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 16px;
`;

type DeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  text: string;
  id: string;
  header: string;
  isLoading?: boolean;
};

const DeleteModal = ({
  handleClose,
  handleAccept,
  text,
  id,
  header,
  isLoading = false
}: DeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalPortal>
      <ModalBase handleClick={handleClose} header={header}>
        <DialogContent>
          <div>
            <p>
              {text}
              <strong>{id}</strong>?{' '}
              {t(
                'configurator:component.delete_modal.action_cannot_be_undone',
                'This action cannot be undone.'
              )}
            </p>
          </div>
          <ButtonsWrapper>
            <Button onClick={handleClose} variant='outlined'>
              {t('configurator:component.delete_modal.cancel', 'Cancel')}
            </Button>
            <LoadingButton color='error' onClick={handleAccept} loading={isLoading}>
              {t('configurator:component.delete_modal.delete', 'Delete')}
            </LoadingButton>
          </ButtonsWrapper>
        </DialogContent>
      </ModalBase>
    </ModalPortal>
  );
};

export default DeleteModal;
