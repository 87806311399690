import { useQuery } from '@tanstack/react-query';
import { UserDetails } from 'adp-panel/api/authentication/authentication.types';
import { getUser } from 'adp-panel/api/users/users';
import { UserExtendOptions, UsersQueryParams } from 'adp-panel/api/users/users.types';
import { USER_QUERY_KEY, useUserMe } from 'adp-panel/hooks/api/useUsers';
import { rolesByName } from 'adp-panel/utils/permissionUtils';
import { useAuthStore } from 'reducers/authStore';
import { UseQueryResult } from '@tanstack/react-query';
import { UserEntry } from 'adp-panel/api/users/users.types';

const useUserData = () => {
  const { token, mfa } = useAuthStore((state) => ({
    token: state.token,
    mfa: state.mfa
  }));
  const enabled: boolean = !mfa.required && Boolean(token);
  const {
    result: user,
    isInitialLoading: isLoadingUserMe,
    isError,
    isFetched
  } = useUserMe(enabled);
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.permissions,
      UserExtendOptions.devices,
      UserExtendOptions.devicesAsClinician
    ]
  };

  const userData: UseQueryResult<UserEntry> = useQuery(
    [USER_QUERY_KEY, user?.id],
    () => getUser(user.id, { params: queryParams }),
    {
      cacheTime: Infinity,
      enabled: Boolean(user),
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  return {
    ...userData,
    data: userData.data as UserEntry,
    me: user as UserDetails,
    rolesByName: rolesByName(userData?.data?.roles),
    isLoading: isLoadingUserMe || userData.isInitialLoading,
    isFetched,
    isError
  };
};

export default useUserData;
export type UserDataType = ReturnType<typeof useUserData>;
