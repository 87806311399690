import { ReactComponent as USFlag } from 'assets/flags/US.svg';
import { ReactComponent as PLFlag } from 'assets/flags/PL.svg';
import { ReactComponent as DEFlag } from 'assets/flags/DE.svg';
import { ReactComponent as PTFlag } from 'assets/flags/PT.svg';
import { ReactComponent as FRFlag } from 'assets/flags/FR.svg';
import { ReactComponent as RUFlag } from 'assets/flags/RU.svg';
import { ReactComponent as SEFlag } from 'assets/flags/SE.svg';
import { ReactComponent as ESFlag } from 'assets/flags/ES.svg';
import { ReactComponent as ATFlag } from 'assets/flags/AT.svg';
import { ReactComponent as TRFlag } from 'assets/flags/TR.svg';
import { ReactComponent as ITFlag } from 'assets/flags/IT.svg';
import { ReactComponent as JPFlag } from 'assets/flags/JP.svg';
import { ReactComponent as GBFlag } from 'assets/flags/GB.svg';
import { ReactComponent as CNFlag } from 'assets/flags/CN.svg';
import { ReactComponent as INFlag } from 'assets/flags/IN.svg';
import { ReactComponent as MXFlag } from 'assets/flags/MX.svg';
import { ReactComponent as BRFlag } from 'assets/flags/BR.svg';
import { ReactComponent as AUFlag } from 'assets/flags/AU.svg';
import { ReactComponent as NZFlag } from 'assets/flags/NZ.svg';
import { ReactComponent as KRFlag } from 'assets/flags/KR.svg';
import { ReactComponent as AEFlag } from 'assets/flags/AE.svg';
import { ReactComponent as CZFlag } from 'assets/flags/CZ.svg';
import { ReactComponent as SKFlag } from 'assets/flags/SK.svg';
import { ReactComponent as HRFlag } from 'assets/flags/HR.svg';
import { ReactComponent as UAFlag } from 'assets/flags/UA.svg';
import { ReactComponent as BGFlag } from 'assets/flags/BG.svg';
import { ReactComponent as LTFlag } from 'assets/flags/LT.svg';
import { ReactComponent as LVFlag } from 'assets/flags/LV.svg';
import { ReactComponent as EEFlag } from 'assets/flags/EE.svg';
import { ReactComponent as NOFlag } from 'assets/flags/NO.svg';
import { ReactComponent as DKFlag } from 'assets/flags/DK.svg';
import { ReactComponent as CHFlag } from 'assets/flags/CH.svg';
import { ReactComponent as ROFlag } from 'assets/flags/RO.svg';
import { ReactComponent as HUFlag } from 'assets/flags/HU.svg';
import { ReactComponent as BEFlag } from 'assets/flags/BE.svg';
import { ReactComponent as NLFlag } from 'assets/flags/NL.svg';
import { ReactComponent as GRFlag } from 'assets/flags/GR.svg';
import { ReactComponent as ZAFlag } from 'assets/flags/ZA.svg';
import { ReactComponent as FIFlag } from 'assets/flags/FI.svg';
import { ReactComponent as RSFlag } from 'assets/flags/RS.svg';
import { ReactComponent as ILFlag } from 'assets/flags/IL.svg';
import { ReactComponent as SAFlag } from 'assets/flags/SA.svg';
import { ReactComponent as CAFlag } from 'assets/flags/CA.svg';
import { ReactComponent as LUFlag } from 'assets/flags/LU.svg';
import React, { ReactElement } from 'react';

export type CountryCode =
  | 'EN'
  | 'US'
  | 'RU'
  | 'ZA'
  | 'GR'
  | 'NL'
  | 'BE'
  | 'FR'
  | 'ES'
  | 'HU'
  | 'IT'
  | 'RO'
  | 'CH'
  | 'AT'
  | 'GB'
  | 'DK'
  | 'SE'
  | 'NO'
  | 'PL'
  | 'DE'
  | 'MX'
  | 'BR'
  | 'AU'
  | 'NZ'
  | 'JP'
  | 'KR'
  | 'CN'
  | 'TR'
  | 'IN'
  | 'PT'
  | 'FI'
  | 'BG'
  | 'LT'
  | 'LV'
  | 'EE'
  | 'UA'
  | 'RS'
  | 'HR'
  | 'CZ'
  | 'SK'
  | 'SA'
  | 'AE'
  | 'CA'
  | 'LU'
  | 'IL';

export const codeIconMap = new Map<CountryCode, ReactElement>([
  ['EN', <GBFlag />],
  ['PL', <PLFlag />],
  ['UA', <UAFlag />],
  ['US', <USFlag />],
  ['DE', <DEFlag />],
  ['PT', <PTFlag />],
  ['FR', <FRFlag />],
  ['RU', <RUFlag />],
  ['SE', <SEFlag />],
  ['ES', <ESFlag />],
  ['AT', <ATFlag />],
  ['TR', <TRFlag />],
  ['IT', <ITFlag />],
  ['JP', <JPFlag />],
  ['GB', <GBFlag />],
  ['CN', <CNFlag />],
  ['IN', <INFlag />],
  ['MX', <MXFlag />],
  ['BR', <BRFlag />],
  ['AU', <AUFlag />],
  ['NZ', <NZFlag />],
  ['KR', <KRFlag />],
  ['AE', <AEFlag />],
  ['CZ', <CZFlag />],
  ['SK', <SKFlag />],
  ['HR', <HRFlag />],
  ['BG', <BGFlag />],
  ['LT', <LTFlag />],
  ['LV', <LVFlag />],
  ['EE', <EEFlag />],
  ['NO', <NOFlag />],
  ['DK', <DKFlag />],
  ['CH', <CHFlag />],
  ['RO', <ROFlag />],
  ['HU', <HUFlag />],
  ['BE', <BEFlag />],
  ['NL', <NLFlag />],
  ['GR', <GRFlag />],
  ['ZA', <ZAFlag />],
  ['FI', <FIFlag />],
  ['RS', <RSFlag />],
  ['IL', <ILFlag />],
  ['SA', <SAFlag />],
  ['CA', <CAFlag />],
  ['LU', <LUFlag />]
]);

export const codes = [
  {
    code: '+1',
    country: 'US'
  },
  {
    code: '+1',
    country: 'CA'
  },
  {
    code: '+7',
    country: 'RU'
  },
  {
    code: '+27',
    country: 'ZA'
  },
  {
    code: '+30',
    country: 'GR'
  },
  {
    code: '+31',
    country: 'NL'
  },
  {
    code: '+32',
    country: 'BE'
  },
  {
    code: '+33',
    country: 'FR'
  },
  {
    code: '+34',
    country: 'ES'
  },
  {
    code: '+36',
    country: 'HU'
  },
  {
    code: '+39',
    country: 'IT'
  },
  {
    code: '+40',
    country: 'RO'
  },
  {
    code: '+41',
    country: 'CH'
  },
  {
    code: '+43',
    country: 'AT'
  },
  {
    code: '+44',
    country: 'GB'
  },
  {
    code: '+45',
    country: 'DK'
  },
  {
    code: '+46',
    country: 'SE'
  },
  {
    code: '+47',
    country: 'NO'
  },
  {
    code: '+48',
    country: 'PL'
  },
  {
    code: '+49',
    country: 'DE'
  },
  {
    code: '+52',
    country: 'MX'
  },
  {
    code: '+55',
    country: 'BR'
  },
  {
    code: '+61',
    country: 'AU'
  },
  {
    code: '+64',
    country: 'NZ'
  },
  {
    code: '+81',
    country: 'JP'
  },
  {
    code: '+82',
    country: 'KR'
  },
  {
    code: '+86',
    country: 'CN'
  },
  {
    code: '+90',
    country: 'TR'
  },
  {
    code: '+91',
    country: 'IN'
  },
  {
    code: '+351',
    country: 'PT'
  },
  {
    code: '+352',
    country: 'LU'
  },
  {
    code: '+358',
    country: 'FI'
  },
  {
    code: '+359',
    country: 'BG'
  },
  {
    code: '+370',
    country: 'LT'
  },
  {
    code: '+371',
    country: 'LV'
  },
  {
    code: '+372',
    country: 'EE'
  },
  {
    code: '+380',
    country: 'UA'
  },
  {
    code: '+381',
    country: 'RS'
  },
  {
    code: '+385',
    country: 'HR'
  },
  {
    code: '+420',
    country: 'CZ'
  },
  {
    code: '+421',
    country: 'SK'
  },
  {
    code: '+966',
    country: 'SA'
  },
  {
    code: '+971',
    country: 'AE'
  },
  {
    code: '+972',
    country: 'IL'
  }
];
