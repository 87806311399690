import { SearchEntry, SearchQueryParams } from 'adp-panel/api/search/search.types';
import api, { withCredentials } from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import { PaginatedResponse } from 'types';

export const GET_SEARCH = 'api/search';

export const getSerachRecords = (
  params: SearchQueryParams
): Promise<PaginatedResponse<SearchEntry>> => {
  return api
    .get<PaginatedResponse<SearchEntry>>(
      `${GET_SEARCH}?${encodeQueryData(params)}`,
      withCredentials
    )
    .then((res: any) => res.data);
};
