import styled from 'styled-components';

export const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 10%;
`;

export const GrapLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const LegendCategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const LegendLabel = styled.div`
  font-size: 14px;
  color: #1d2b4f;
`;

type LegendShapeProps = {
  color: string;
};

export const LegendSubLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #1d2b4f;
`;

export const LegendShape = styled.div<LegendShapeProps>`
  width: 15px;
  height: 5px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
`;

export const LegendTooltipIcon = styled.span`
  align-self: baseline;
`;

export const TooltipWrapper = styled.div`
  white-space: pre-line;
  max-width: 300px;
`;
