import CustomAvatar from 'adp-panel/components/CustomAvatar/CustomAvatar';
import React from 'react';

const UserListAvatar = ({ name, children }: { name: string; children?: React.ReactNode }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <CustomAvatar name={name} />
    <div style={{ marginLeft: '8px' }}>{children ? children : name}</div>
  </div>
);

export default UserListAvatar;
