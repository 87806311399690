import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import {
  closeTicket,
  createTicketMessage,
  getSupportTickets,
  getSupportTicketsAndMessages,
  getTicket,
  readTicket,
  readTicketMessage,
  reopenTicket
} from '../../api/tickets/tickets';
import {
  SupportTicketQueryParams,
  SupportTicketsAndMessagesQueryParams,
  SupportTicketsQueryParams
} from '../../api/tickets/tickets.types';
import { mapErrorMessage } from '../../utils/notifications';
import apiErrorNotification from '../../components/Notifications/ApiErrorNotification';
import { useEffect } from 'react';
import { NotificationFactory } from 'lib/NotificationFactory';

export const TICKET_QUERY_KEY = 'ticket';
export const SUPPORT_TICKETS_QUERY_KEY = 'tickets';
export const SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY = 'tickets_and_messages';

export const useSupportTickets = (
  params: SupportTicketsQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SUPPORT_TICKETS_QUERY_KEY, params],
    () => getSupportTickets(params),
    {
      enabled,
      onError() {
        NotificationFactory.errorNotification('Failed to fetch tickets list');
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useSupportTicketsAndMessages = (
  params: SupportTicketsAndMessagesQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY, params],
    () => getSupportTicketsAndMessages(params),
    {
      enabled,
      onError() {
        NotificationFactory.errorNotification(
          i18next.t('common:support_ticket.failed_to_fetch', 'Failed to fetch tickets and messages')
        );
      }
    }
  );

  return {
    result: data ? data.items : [],
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useSupportTicketsInfinite = (queryParams?: any, dependency: any = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [SUPPORT_TICKETS_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getSupportTickets({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useTicket = ({
  ticketId,
  params = {}
}: {
  ticketId: number;
  params?: SupportTicketQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [TICKET_QUERY_KEY, ticketId, params],
    () => getTicket({ ticketId, params }),
    {
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useTicketMessageCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createTicketMessage, {
    onSuccess() {
      NotificationFactory.successNotification('Message has been added to ticket');
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useTicketClose = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(closeTicket, {
    onSuccess() {
      NotificationFactory.successNotification('Ticket has been closed');
      return queryClient.invalidateQueries([
        SUPPORT_TICKETS_QUERY_KEY,
        SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY
      ]);
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useTicketRead = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(readTicket, {
    onSuccess() {
      return queryClient.invalidateQueries([
        SUPPORT_TICKETS_QUERY_KEY,
        SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY
      ]);
    },
    onError(error: any) {
      apiErrorNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useTicketReadMessage = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(readTicketMessage, {
    onSuccess() {
      return queryClient.invalidateQueries([TICKET_QUERY_KEY]);
    },
    onError(error: any) {
      apiErrorNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useTicketReOpen = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(reopenTicket, {
    onSuccess() {
      NotificationFactory.successNotification('Ticket has been re-opened');
    },
    onError(error: any) {
      apiErrorNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
