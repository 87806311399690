/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-svg.svg';
import {
  DEVICE_CONFIG_TEMPLATE_QUERY_KEY,
  TEMPLATE_NOTES_KEY,
  useCreateTemplateNote,
  useDeleteConfigTemplate,
  useDeleteTemplateNote,
  useDeviceConfigTemplate,
  useTemplateNotes
} from 'configurator/hooks/api/useDevice';
import Loader from 'configurator/components/atoms/Loader/Loader';
import ConfigComponent, {
  ToggleTypesChangesHistory
} from 'configurator/components/organisms/ConfigComponent/ConfigComponent';
import { useModal } from 'configurator/hooks/useModal';
import { EditTemplateModal } from 'configurator/modals/AddTemplate';
import DeleteModal from 'configurator/modals/DeleteModal';
import { useConfigStore } from 'configurator/reducers/configStore';
import {
  ConfigNotesSortOptions,
  ConfigTemplatesExtendOptions
} from 'configurator/api/device/device.types';
import Accordion from 'configurator/components/atoms/Accordion/Accordion';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import useImportButton from 'configurator/hooks/useImportButton';
import { SortDirs } from 'configurator/utils/types';
import ImportTemplateModal from 'configurator/modals/ImportTemplateModal';
import { HeaderWrapper } from '../../DeviceConfig/styled';
import { findDifferencesImport, transformConfig } from 'configurator/utils/Config/transformConfig';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import Card from 'adp-panel/components/Card/Card';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';

const ButtonsWrapper = styled.div`
  display: grid;
  align-items: flex-end;
  grid-auto-flow: column;
  gap: 16px;
`;

const ConfigTemplateDetailsComponent = () => {
  // @ts-ignore
  const { templateId } = useParams();
  const { t } = useTranslation();
  const [currentToggle, setCurrentToggle] = useState(ToggleTypesChangesHistory.all);
  // @ts-ignore
  const { result: templateEntry } = useDeviceConfigTemplate({
    templateId: Number(templateId),
    params: { extend: ConfigTemplatesExtendOptions.author }
  });
  const [transformedConfig, setTransformedConfig] = useState<any>(null);
  const {
    mutateAsync: deleteTemplate,
    isError: isErrorDelete,
    isLoading: isLoadingDeleteTemplate
  } = useDeleteConfigTemplate();
  const {
    handleClose: handleCloseDeleteModal,
    handleOpen: handleOpenDeleteModal,
    isOpen: isOpenDeleteModal
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    common: state.config.common,
    modes: state.config.modes
  }));
  const {
    handleClose: handleCloseImportModal,
    handleOpen: handleOpenImportModal,
    isOpen: isOpenImportModal
  } = useModal();
  const {
    handleClose: handleCloseEditModal,
    handleOpen: handleOpenEditModal,
    isOpen: isOpenEditModal
  } = useModal();
  const navigate = useNavigate();
  const { mutateAsync: createTemplateNote, isLoading: isLoadingCreateNote } =
    useCreateTemplateNote();
  const { mutateAsync: deleteTemplateNote } = useDeleteTemplateNote();
  const { result: notes } = useTemplateNotes({
    templateId: Number(templateId),
    // @ts-ignore
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const [importDifferences, setImportDifferences] = useState(false);
  const { importTemplateConfig, isLoadingDeviceManager } = useDeviceManager();
  const { disableImportButton, importTooltip } = useImportButton(importDifferences);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (templateEntry) {
      const parsedConfig = JSON.parse(templateEntry.config);
      const { gripsTransformed, gripPairsConfig, gripSequentialConfig } =
        transformConfig(parsedConfig);
      setTransformedConfig({ grips: gripsTransformed, gripPairsConfig, gripSequentialConfig });
      setImportDifferences(findDifferencesImport(common, modes, JSON.parse(templateEntry.config)));
    }
  }, [templateEntry]);

  const handleAfterEditTemplate = () => {
    queryClient.invalidateQueries([DEVICE_CONFIG_TEMPLATE_QUERY_KEY]);
    handleCloseEditModal();
  };

  const handleDeleteTemplate = async () => {
    await deleteTemplate({ templateId: Number(templateId) });
    if (!isErrorDelete) {
      navigate(`/config-templates`);
    }
  };

  const handleAddNote = async ({ note }: any) => {
    await createTemplateNote({ templateId: Number(templateId), data: { note } });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteTemplateNote({ templateId: Number(templateId), noteId });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  const handleImport = async (modeId: number) => {
    await importTemplateConfig(modeId, Number(templateId));
    handleCloseImportModal();
  };

  return (
    <>
      {isOpenImportModal && (
        <ImportTemplateModal
          header='Import template'
          handleAccept={handleImport}
          handleClose={handleCloseImportModal}
          entry={JSON.parse(templateEntry.config)}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          header='Delete template'
          handleClose={handleCloseDeleteModal}
          handleAccept={handleDeleteTemplate}
          text='Are you sure you want to delete template: '
          isLoading={isLoadingDeleteTemplate}
          id={templateEntry.name}
        />
      )}
      {isOpenEditModal && (
        <EditTemplateModal
          handleClose={handleCloseEditModal}
          handleAfter={handleAfterEditTemplate}
          templateId={Number(templateId)}
        />
      )}
      <HeaderWrapper>
        <CustomPageHeader
          header={`Template: ${templateEntry?.name}`}
          tooltipText={t('configurator:header.config_templates_details.tooltip', 'Tooltip')}
        />
        <ButtonsWrapper>
          <div style={{ display: 'flex', gap: '32px', marginRight: '32px' }}>
            <div>
              <Typography variant='body2' sx={{ color: '#475467' }}>
                Description:
              </Typography>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                {templateEntry?.description}
              </Typography>
            </div>
            <div>
              <Typography variant='body2' sx={{ color: '#475467' }}>
                Author:
              </Typography>
              <Typography variant='body1' sx={{ fontWeight: 600 }}>
                {templateEntry?.author?.name}
              </Typography>
            </div>
          </div>
          <Tooltip title={importTooltip()}>
            <CustomButton
              onClick={handleOpenImportModal}
              color='light'
              disabled={disableImportButton()}>
              Import
            </CustomButton>
          </Tooltip>
          <CustomButton onClick={handleOpenEditModal} color='light' Icon={EditIcon} />
          <CustomButton onClick={handleOpenDeleteModal} color='light' Icon={TrashSvg} />
        </ButtonsWrapper>
      </HeaderWrapper>
      <div style={{ display: 'flex', gap: '48px', flexDirection: 'column' }}>
        <Accordion header='Notes'>
          <Card>
            <NotesList
              notes={notes ?? []}
              handleAdd={handleAddNote}
              handleDelete={handleDeleteNote}
              showTypes={false}
              isLoadingAddNote={isLoadingCreateNote}
            />
          </Card>
        </Accordion>
        {templateEntry ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '24px' }}>
              <ToggleButtonGroup value={currentToggle}>
                <ToggleButton
                  value={ToggleTypesChangesHistory.all}
                  key='all'
                  onClick={() => setCurrentToggle(ToggleTypesChangesHistory.all)}>
                  All
                </ToggleButton>
                <ToggleButton
                  value={ToggleTypesChangesHistory.emg}
                  key='emg'
                  onClick={() => setCurrentToggle(ToggleTypesChangesHistory.emg)}>
                  EMG
                </ToggleButton>
                <ToggleButton
                  value={ToggleTypesChangesHistory.grips}
                  key='grips'
                  onClick={() => setCurrentToggle(ToggleTypesChangesHistory.grips)}>
                  Grips
                </ToggleButton>
                <ToggleButton
                  value={ToggleTypesChangesHistory.prosthesis}
                  key='sequential'
                  onClick={() => setCurrentToggle(ToggleTypesChangesHistory.prosthesis)}>
                  Prosthesis
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <ConfigComponent
              config={JSON.parse(templateEntry.config) || null}
              transformedConfig={transformedConfig}
              currentToggle={currentToggle}
            />
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default ConfigTemplateDetailsComponent;
