import CustomPhoneField from 'components/FormFields/CustomPhoneFIeld';
import { GroupedFormAction } from 'adp-panel/pages/Account/styled';
import { useUserMe, useUserUpdate } from 'adp-panel/hooks/api/useUsers';
import { myTheme } from 'theme/theme';
import FormSwitch from 'components/FormFields/FormSwitch';
import FormRadioGroup from 'components/FormFields/FormRadioGroup';
import { MfaOptions, SetMfaStatusPayload } from 'adp-panel/api/authentication/authentication.types';
import { Box, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { updateUserPhone } from 'adp-panel/api/users/users';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { ME_QUERY_KEY, USER_QUERY_KEY } from 'adp-panel/hooks/api/useUsers';
import { useModal } from 'configurator/hooks/useModal';
import VerifyPhoneModal from 'adp-panel/components/Modals/VerifyPhoneModal';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { phoneValidatorRequired } from 'adp-panel/components/FormInput/validators';
import CustomEmailField from 'components/FormFields/CustomEmailFIeld';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { DASHBOARD } from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useMfaSend, useMfaUpdate } from 'hooks/useAuthentication';
import { UserUpdatePayload } from 'adp-panel/api/users/users.types';
import { NotificationFactory } from 'lib/NotificationFactory';
import VerifyEmailModal from 'adp-panel/components/Modals/VerifyEmailModal';
import { useState } from 'react';
import { emailSchema } from 'configurator/views/Register/utils';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import BackgroundImage from '../BackgroundImage';

const Section2FA = ({ ready = false }) => {
  const { result: accountDetails } = useUserMe(ready);

  const {
    control,
    watch,
    handleSubmit: handleSubmitRegister
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      phone: accountDetails?.phone,
      email: accountDetails?.email,
      mfaMethod: 'email'
    }
  });
  const phone = watch('phone');
  const isPhoneValid =
    phone !== undefined &&
    phone.length > 0 &&
    isValidPhoneNumber(phone) &&
    phoneValidatorRequired(phone).length === 0;
  const phoneVerified = accountDetails?.phone_verified_at;

  const email = watch('email');

  const isValidEmail = !emailSchema.isValidSync(email);

  const isMFAEnabled = watch('mfaEnabled');
  const { t } = useTranslation('auth');
  const queryClient = useQueryClient();

  const { mutateAsync: updateUser, isLoading: isLoadingUserUpdate } = useUserUpdate();
  const { mutateAsync: updateMfa, isLoading: isLoadingMfaUpdate } = useMfaUpdate();
  const { mutateAsync: sendMfaCode, isLoading: isLoadingMfaSend } = useMfaSend();
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const {
    isOpen: isEmailModalOpen,
    handleOpen: handleEmailModalOpen,
    handleClose: handleEmailModalClose
  } = useModal();

  const handlePhoneVerify = async () => {
    try {
      await updateUserPhone({ userId: accountDetails.id, phone });
      handleModalOpen();
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    } finally {
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    }
  };

  const handleEmailVerify = async () => {
    try {
      await sendMfaCode({ channel: MfaOptions.email });
      handleEmailModalOpen();
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    } finally {
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    }
  };

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { language = undefined, mfaEnabled = undefined, mfaMethod, phone = undefined } = data;

    const userData = {
      id: accountDetails.id,
      phone: data.phone,
      email: data.email,
      ...(language && { language: language.code })
    } as UserUpdatePayload;

    const mfaData = {
      enabled: data.mfaEnabled ? 1 : 0,
      ...(mfaMethod && mfaEnabled && { method: mfaMethod })
    } as SetMfaStatusPayload;

    try {
      if (emailVerified && data.email !== accountDetails.email) {
        await updateUser(userData);
      }

      await updateMfa(mfaData);
      navigate(DASHBOARD);
      localStorage.removeItem('redirectUrl');

      NotificationFactory.successNotification(
        t('notifications:component.account_details.user_settings_updated', 'User settings updated')
      );
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  if (!accountDetails) return null;

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
      <div>
        <AuthLayout lightVariant noFooter>
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component.register.title', 'Create account')}</AuthTitle>
            <AuthParagraph variant='body2'>
              {t(
                'auth:component.register.paragraph',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
              )}
            </AuthParagraph>
            {isModalOpen && <VerifyPhoneModal handleClose={handleModalClose} />}
            {isEmailModalOpen && !isModalOpen && (
              <VerifyEmailModal
                setEmailVerified={setEmailVerified}
                handleClose={handleEmailModalClose}
              />
            )}
            <form onSubmit={handleSubmitRegister(onSubmit)}>
              <Box sx={{ m: '14px 0' }}>
                <Typography
                  sx={{
                    color: myTheme.palette.headerTitle2,
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '24px'
                  }}>
                  {t('auth:component.register.two_factor_subtitle', 'Two factor authentication')}
                </Typography>
              </Box>
              <FormSwitch
                label={t('auth:component.register.two_factor_switch', 'Enable 2fa')}
                id='mfaEnabled'
                control={control}
                style={{ marginBottom: '14px' }}
              />
              {isMFAEnabled && (
                <FormRadioGroup
                  label={t(
                    'auth:component.register.two_factor_form.choose_method',
                    'Choose method'
                  )}
                  id='mfaMethod'
                  data={[
                    {
                      label: t('auth:component.register.two_factor_form.2fa_method.sms', 'SMS'),
                      value: MfaOptions.sms,
                      component: (
                        <div style={{ position: 'relative', marginTop: '30px' }}>
                          <GroupedFormAction>
                            <CustomPhoneField
                              label={t(
                                'account:component.account_details.form.phone_number',
                                'Phone number'
                              )}
                              id='phone'
                              isValidPhoneNumber={Boolean(phoneVerified)}
                              control={control}
                              styleForLabel={{ top: '-50px' }}
                            />
                            {!phoneVerified && (
                              <CustomButton
                                onClick={() => handlePhoneVerify()}
                                type='button'
                                disabled={!isPhoneValid}
                                color='lightBlue'>
                                {t(
                                  'account:component.account_details.button.verify_phone',
                                  'Verify'
                                )}
                              </CustomButton>
                            )}
                          </GroupedFormAction>
                        </div>
                      )
                    },
                    {
                      label: t(
                        'auth:component.register.two_factor_form.2fa_method.email',
                        'E-mail'
                      ),
                      value: MfaOptions.email,
                      component: (
                        <div style={{ position: 'relative', marginTop: '30px' }}>
                          <GroupedFormAction>
                            <CustomEmailField
                              label={t('account:component.account_details.form.email', 'Email')}
                              id='email'
                              isValidPhoneNumber={emailVerified}
                              control={control}
                              styleForLabel={{ top: '-50px' }}
                            />
                            {!emailVerified && (
                              <CustomButton
                                onClick={() => handleEmailVerify()}
                                type='button'
                                disabled={isValidEmail}
                                color='lightBlue'>
                                {t(
                                  'account:component.account_details.button.verify_phone',
                                  'Verify'
                                )}
                              </CustomButton>
                            )}
                          </GroupedFormAction>
                        </div>
                      )
                    }
                  ]}
                  control={control}
                />
              )}
              <FormButtonsWrapper>
                <CustomButton type='button' color='light' onClick={() => navigate(DASHBOARD)}>
                  {t('auth:component.register_details.button.cancel', 'Cancel')}
                </CustomButton>
                <LoadingButton type='submit' loading={isLoadingMfaSend}>
                  {t('auth:register.button.next_step', 'Finish')}
                </LoadingButton>
              </FormButtonsWrapper>
            </form>
          </AuthWrapperBox>
        </AuthLayout>
      </div>
      <BackgroundImage />
    </div>
  );
};

export default Section2FA;
