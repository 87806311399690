/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import styled, { css } from 'styled-components';
import { Slider } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import Card from 'adp-panel/components/Card/Card';
import { myTheme } from 'theme/theme';

const Wrapper = styled.div<{ labelHorizontal?: any }>`
  text-align: center;
  background-color: ${myTheme.palette.background};
  padding: 16px;
  border-radius: 8px;
  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
    `};
`;

const Label = styled.div<{ labelHorizontal?: any }>`
  ${TextS};
  margin-bottom: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 15px;
  }
  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      margin: 0;
    `};
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

interface HorizontalGraphSliderType {
  label: string;
  handleChange?: any;
  handleOnAfterChange?: any;
  value?: any;
  valueToDisplay?: any;
  showSliderValue?: boolean;
  limits?: any;
  labelHorizontal?: boolean;
  disableSliders?: boolean;
  scale?: (x: number) => number;
  step?: number;
}

const HorizontalGraphSlider = ({
  label,
  handleChange,
  handleOnAfterChange,
  value,
  valueToDisplay,
  showSliderValue,
  limits,
  labelHorizontal,
  disableSliders,
  scale,
  step,
  ...props
}: HorizontalGraphSliderType) => (
  <Wrapper labelHorizontal={labelHorizontal}>
    {showSliderValue ? (
      <Label labelHorizontal={labelHorizontal}>{label}</Label>
    ) : (
      <Label labelHorizontal={labelHorizontal}>{label}</Label>
    )}
    <InnerWrapper>
      <Slider
        onChange={handleChange}
        onChangeCommitted={handleOnAfterChange}
        value={value}
        min={limits.min}
        max={limits.max}
        disabled={disableSliders}
        scale={scale}
        step={step}
        {...props}
      />
      {valueToDisplay && <Card style={{ minWidth: '80px' }}>{valueToDisplay}</Card>}
    </InnerWrapper>
  </Wrapper>
);

export default HorizontalGraphSlider;
