import { useQuery } from '@tanstack/react-query';
import {
  getChartsCountData,
  getChartsData,
  getEmgPeakChartData,
  getTimeSpentData,
  getVelocityLevelChartData
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring';
import {
  EmgPeakChartQueryParams,
  TimeSpentChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';

export const DEVICE_CHART_DATA = 'device_chart_data';
export const DEVICE_CHART_COUNT_DATA = 'device_chart_count_data';
export const DEVICE_TIME_SPENT_DATA = 'device_time_spent_data';
export const DEVICE_CHART_EMG_PEAKS_DATA = 'device_chart_emg_peaks_data';
export const DEVICE_VELOCITY_LEVEL_DATA = 'device_velocity_level_data';

export const useDeviceUsageChartData = (
  queryParams: UsageMonitoryQueryParams | null = null,
  enabled: boolean = false
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICE_CHART_DATA, queryParams?.deviceId, JSON.stringify(queryParams)],
    () => getChartsData(queryParams),
    {
      staleTime: 5 * 1000 * 60, // 5 minutes,
      enabled
    }
  );

  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDeviceUsageGripsCountData = (
  queryParams: UsageMonitoryQueryParams | null = null,
  enabled: boolean = false
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICE_CHART_COUNT_DATA, queryParams?.deviceId, JSON.stringify(queryParams)],
    () => getChartsCountData(queryParams),
    {
      staleTime: 5 * 1000 * 60, // 5 minutes,
      enabled
    }
  );

  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDeviceUsageTimeSpentData = (
  queryParams: TimeSpentChartQueryParams | null = null,
  enabled: boolean = false
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICE_TIME_SPENT_DATA, queryParams?.deviceId, JSON.stringify(queryParams)],
    () => getTimeSpentData(queryParams),
    {
      staleTime: 5 * 1000 * 60, // 5 minutes,
      enabled
    }
  );

  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useEmgPeakChartData = (
  queryParams: EmgPeakChartQueryParams | null = null,
  enabled: boolean = false
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICE_CHART_EMG_PEAKS_DATA, queryParams?.deviceId, JSON.stringify(queryParams)],
    () => getEmgPeakChartData(queryParams),
    {
      staleTime: 5 * 1000 * 60, // 5 minutes,
      enabled
    }
  );

  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useVelocityLevelChatData = (
  queryParams: VelocityLevelChartQueryParams | null = null,
  enabled: boolean = false
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICE_VELOCITY_LEVEL_DATA, queryParams?.deviceId, JSON.stringify(queryParams)],
    () => getVelocityLevelChartData(queryParams),
    {
      staleTime: 5 * 1000 * 60, // 5 minutes,
      enabled
    }
  );

  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
