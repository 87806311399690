import { convertToPermissionString } from 'adp-panel/hoc/useCanAccess';
import i18n from 'i18n';
import { InvitationType } from 'adp-panel/api/users/users.types';
import { defaultPermissions, permanentPermissions } from 'adp-panel/constants/permanentPermissions';

export const DUMMY_ROLE_ID = 0;
export const ROLES = [
  { id: 2, name: i18n.t('medical_professionals:roles.clinicia_title', 'Clinician') },
  { id: 4, name: i18n.t('medical_professionals:roles.alliedHealth_title', 'Allied Health') }
];

export const updateFullName = (item) => {
  return { ...item, fullName: item.name ? `${item.name} (${item.email})` : item.email };
};
export const updateDataWithFullName = (data) => {
  return data && data.map((item) => updateFullName(item));
};

const defaultEmptyRole = { id: DUMMY_ROLE_ID, name: '' };
export const getDefaultMedicalProfessionalsValues = ({
  selectedPatient,
  defaultClinician,
  noEmptyClinician = false
}) => {
  const defaultClinicianParsed = defaultClinician && updateFullName(defaultClinician);
  const defaultPatientParsed = selectedPatient && updateFullName(selectedPatient);
  const defaultClinicianRole =
    defaultClinician && ROLES.find((role) => role.id === defaultClinician.roles?.[0].id);

  const clinicians = noEmptyClinician
    ? null
    : [
        {
          role: defaultClinicianRole || defaultEmptyRole,
          clinician: defaultClinicianParsed || null
        }
      ];

  return {
    patient: defaultPatientParsed || null,
    clinicians,
    trainingConfirmation: false
  };
};

export const InvitationsData = (clinicians) =>
  clinicians.map((clinicianData) => {
    const invitation: InvitationType = {
      training_confirmed: 1
    };
    if (typeof clinicianData.clinician === 'object' && clinicianData.clinician.id) {
      invitation.user_id = clinicianData.clinician.id;
    } else if (typeof clinicianData.clinician === 'string') {
      invitation.user_email = clinicianData.clinician;
      invitation.role =
        clinicianData.role.name === 'Allied Health' ? 'ClinicianSupport' : clinicianData.role.name;

      if (invitation.role === 'ClinicianSupport') {
        const permissionPerUser = convertToPermissionString([
          ...permanentPermissions,
          ...defaultPermissions
        ]);
        invitation.permissions = permissionPerUser;
      }
    }
    return invitation;
  });
