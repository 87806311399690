import { permissions } from './permissions';

export const permanentPermissions = [
  {
    text: 'Devices',
    id: 'devices'
  },
  {
    text: 'User',
    id: 'view'
  }
];

export const defaultPermissions = [
  permissions[0]?.items?.[0],
  permissions[0]?.items?.[1],
  permissions[0]?.items?.[2],
  permissions[1],
  permissions[3]
];
