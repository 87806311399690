/* eslint-disable react/jsx-key */
import * as React from 'react';
import styled from 'styled-components';
import { Hint, Label } from '@progress/kendo-react-labels';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { ReactComponent as USFlag } from 'assets/flags/US.svg';
import { ReactComponent as PLFlag } from 'assets/flags/PL.svg';
import { ReactComponent as DEFlag } from 'assets/flags/DE.svg';
import { ReactComponent as PTFlag } from 'assets/flags/PT.svg';
import { ReactComponent as FRFlag } from 'assets/flags/FR.svg';
import { ReactComponent as RUFlag } from 'assets/flags/RU.svg';
import { ReactComponent as SEFlag } from 'assets/flags/SE.svg';
import { ReactComponent as ESFlag } from 'assets/flags/ES.svg';
import { ReactComponent as ATFlag } from 'assets/flags/AT.svg';
import { ReactComponent as TRFlag } from 'assets/flags/TR.svg';
import { ReactComponent as ITFlag } from 'assets/flags/IT.svg';
import { ReactComponent as JPFlag } from 'assets/flags/JP.svg';
import { ReactComponent as GBFlag } from 'assets/flags/GB.svg';
import { ReactComponent as CNFlag } from 'assets/flags/CN.svg';
import { ReactComponent as INFlag } from 'assets/flags/IN.svg';
import { ReactComponent as MXFlag } from 'assets/flags/MX.svg';
import { ReactComponent as BRFlag } from 'assets/flags/BR.svg';
import { ReactComponent as AUFlag } from 'assets/flags/AU.svg';
import { ReactComponent as NZFlag } from 'assets/flags/NZ.svg';
import { ReactComponent as KRFlag } from 'assets/flags/KR.svg';
import { ReactComponent as AEFlag } from 'assets/flags/AE.svg';
import { ReactComponent as CZFlag } from 'assets/flags/CZ.svg';
import { ReactComponent as SKFlag } from 'assets/flags/SK.svg';
import { ReactComponent as HRFlag } from 'assets/flags/HR.svg';
import { ReactComponent as UAFlag } from 'assets/flags/UA.svg';
import { ReactComponent as BGFlag } from 'assets/flags/BG.svg';
import { ReactComponent as LTFlag } from 'assets/flags/LT.svg';
import { ReactComponent as LVFlag } from 'assets/flags/LV.svg';
import { ReactComponent as EEFlag } from 'assets/flags/EE.svg';
import { ReactComponent as NOFlag } from 'assets/flags/NO.svg';
import { ReactComponent as DKFlag } from 'assets/flags/DK.svg';
import { ReactComponent as CHFlag } from 'assets/flags/CH.svg';
import { ReactComponent as ROFlag } from 'assets/flags/RO.svg';
import { ReactComponent as HUFlag } from 'assets/flags/HU.svg';
import { ReactComponent as BEFlag } from 'assets/flags/BE.svg';
import { ReactComponent as NLFlag } from 'assets/flags/NL.svg';
import { ReactComponent as GRFlag } from 'assets/flags/GR.svg';
import { ReactComponent as ZAFlag } from 'assets/flags/ZA.svg';
import { ReactComponent as FIFlag } from 'assets/flags/FI.svg';
import { ReactComponent as RSFlag } from 'assets/flags/RS.svg';
import { ReactComponent as ILFlag } from 'assets/flags/IL.svg';
import { ReactComponent as SAFlag } from 'assets/flags/SA.svg';
import { ReactComponent as CAFlag } from 'assets/flags/CA.svg';
import { ReactComponent as LUFlag } from 'assets/flags/LU.svg';
import { codes } from 'adp-panel/constants/countries';
import { countryCodeReversedMap } from 'adp-panel/utils/definesLocal';

const StyledFlag = styled.div`
  min-width: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const codeMap = new Map();
codeMap.set('US', <USFlag />);
codeMap.set('CA', <CAFlag />);
codeMap.set('RU', <RUFlag />);
codeMap.set('ZA', <ZAFlag />);
codeMap.set('GR', <GRFlag />);
codeMap.set('NL', <NLFlag />);
codeMap.set('BE', <BEFlag />);
codeMap.set('FR', <FRFlag />);
codeMap.set('ES', <ESFlag />);
codeMap.set('HU', <HUFlag />);
codeMap.set('IT', <ITFlag />);
codeMap.set('RO', <ROFlag />);
codeMap.set('CH', <CHFlag />);
codeMap.set('AT', <ATFlag />);
codeMap.set('GB', <GBFlag />);
codeMap.set('DK', <DKFlag />);
codeMap.set('SE', <SEFlag />);
codeMap.set('NO', <NOFlag />);
codeMap.set('PL', <PLFlag />);
codeMap.set('DE', <DEFlag />);
codeMap.set('MX', <MXFlag />);
codeMap.set('BR', <BRFlag />);
codeMap.set('AU', <AUFlag />);
codeMap.set('NZ', <NZFlag />);
codeMap.set('JP', <JPFlag />);
codeMap.set('KR', <KRFlag />);
codeMap.set('CN', <CNFlag />);
codeMap.set('TR', <TRFlag />);
codeMap.set('IN', <INFlag />);
codeMap.set('PT', <PTFlag />);
codeMap.set('LU', <LUFlag />);
codeMap.set('FI', <FIFlag />);
codeMap.set('BG', <BGFlag />);
codeMap.set('LT', <LTFlag />);
codeMap.set('LV', <LVFlag />);
codeMap.set('EE', <EEFlag />);
codeMap.set('UA', <UAFlag />);
codeMap.set('RS', <RSFlag />);
codeMap.set('HR', <HRFlag />);
codeMap.set('CZ', <CZFlag />);
codeMap.set('SK', <SKFlag />);
codeMap.set('SA', <SAFlag />);
codeMap.set('AE', <AEFlag />);
codeMap.set('IL', <ILFlag />);

const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  const itemChildren = (
    <ItemWrapper>
      <StyledFlag>{codeMap.get(itemProps.dataItem)}</StyledFlag>
      <span>{countryCodeReversedMap.get(itemProps.dataItem)}</span>
    </ItemWrapper>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
  if (!value) {
    return element;
  }

  const children = [
    <StyledFlag>{codeMap.get(value)}</StyledFlag>,
    <span key={2}>&nbsp; {countryCodeReversedMap.get(value)}</span>
  ];

  return React.cloneElement(element, { ...element.props }, children);
};

const FormCountryCode = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, hint, optional, mask, isValid, ...others } =
    fieldRenderProps;

  const showValidationMessage: string | boolean | null = (touched && validationMessage) || !isValid;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <DropDownList
        id={id}
        {...others}
        style={{ width: '110px' }}
        data={codes.map((item) => item.country)}
        itemRender={itemRender}
        valueRender={valueRender}
        valid={isValid}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
    </FieldWrapper>
  );
};

export default FormCountryCode;
