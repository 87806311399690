export enum SettingsSource {
  ram = 0,
  flash = 1
}

export enum SettingsFragmentationLevel {
  global = 0,
  gripConfiguration_modes = 1,
  grip_mode = 2
}

export enum SettingsCategory {
  gripConfiguration = 0,
  usageMode = 1
}
