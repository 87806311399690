import React from 'react';
import dayjs from 'dayjs';
import Divider from 'configurator/components/atoms/Divider/Divider';
import Card from 'adp-panel/components/Card/Card';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ApplicationSettings = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <div style={{ maxWidth: '800px' }}>
        <Typography variant='h5' sx={{ fontWeight: '600' }}>
          {t('configurator:component.application_settings.version', 'ADP v.')}
          {process.env.REACT_APP_SOFTWARE_VERSION}
        </Typography>
        <Divider margin='20px' />
        <p>
          {t('configurator:component.application_settings.copyright', 'Copyright (C) ')}
          {dayjs().format('YYYY')} Aether Biomedical Sp. z o.o.
        </p>
        <Divider margin='20px' />
        <p>
          {t(
            'configurator:component.application_settings.disclaimer',
            'The program is provided AS IS with NO WARRANTY OF ANY KIND, INCLUDING THE WARRANTY OF DESIGN, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.'
          )}
        </p>
      </div>
    </Card>
  );
};

export default ApplicationSettings;
