import { Tooltip } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { FormColumnWrapper, FormModalCheckboxesList } from 'components/FormFields/commonStyles';
import FormCheckbox from 'components/FormFields/FormCheckbox';
import FormComboBox, { clinicianOption } from 'components/FormFields/FormComboBox';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DUMMY_ROLE_ID, ROLES } from './utils';
import { myTheme } from 'theme/theme';
import { ReactComponent as PlusSvg } from 'assets/plus.svg';
import { ReactComponent as CrossSvg } from 'assets/cross.svg';

const defaultEmptyRole = { id: DUMMY_ROLE_ID, name: '' };

export const InviteMedicalProfessionalComponent = ({
  control,
  updatedPatientsData,
  fields,
  watch,
  convertRoleToProperRenderValue,
  errors,
  updatedCliniciansData,
  isHelperMessagesAvailable,
  remove,
  append,
  isLastFilled,
  patientRequired = true,
  me = null,
  register,
  setValue,
  trigger
}: {
  control: any;
  updatedPatientsData: any;
  fields: any;
  watch: any;
  convertRoleToProperRenderValue: any;
  errors: any;
  updatedCliniciansData: any;
  isHelperMessagesAvailable: any;
  remove: any;
  append: any;
  isLastFilled: any;
  patientRequired?: boolean;
  me?: any;
  register: any;
  setValue: any;
  trigger: any;
}) => {
  const { t } = useTranslation('medical_professionals');
  return (
    <div>
      <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
        {patientRequired && (
          <FormComboBox
            label={t('medical_professionals:invite_modal.fields.patient', 'Patient')}
            id={'patient'}
            control={control}
            options={updatedPatientsData}
            renderOption={(props, option) => clinicianOption(props, option, 'fullName', 'name')}
            optionLabel={'fullName'}
            nameProperty='name'
            placeholder={t(
              'medical_professionals:invite_modal.fields.patient_placeholder',
              'Search by name or e-mail'
            )}
          />
        )}
        {me && (
          <FormColumnWrapper>
            <FormComboBox
              label={t('patients:patient_form.role', 'Role')}
              id='roleMe'
              control={control}
              options={ROLES}
              value={ROLES[0]}
              disabled
              optionLabel='name'
            />
            <FormComboBox
              label={t('patients:patient_form.me', 'Me')}
              id='me'
              control={control}
              options={me}
              value={me[0]}
              renderOption={(props, option) => clinicianOption(props, option, 'nameFull', 'name')}
              optionLabel={'nameFull'}
              nameProperty='name'
              disabled
            />
          </FormColumnWrapper>
        )}
        <FormModalCheckboxesList>
          {fields.map((field, index) => {
            const isFirst = index === 0;
            const isMultipleInvitation = fields.length >= 1;

            const clinicianValue = watch(`clinicians.${index}.clinician`);
            const roleValue = watch(`clinicians.${index}.role`);
            interface RoleText {
              id?: number;
              name: string;
            }

            let adjustedRoleText: RoleText | undefined = undefined;

            const clinicianFromOptionValue =
              clinicianValue !== null && clinicianValue?.id && clinicianValue;

            if (clinicianFromOptionValue) {
              const roleText = watch(`clinicians.${index}.clinician.roles[0]`);
              adjustedRoleText = {
                ...roleText,
                name: convertRoleToProperRenderValue(roleText.name)
              };
              if (adjustedRoleText?.id !== roleValue.id) {
                setValue(`clinicians.${index}.role`, adjustedRoleText);
                !!errors?.clinicians?.[index]?.role && trigger();
              }
            }

            const roleMessage = errors?.clinicians?.[index]?.role?.message;
            const clinicianMessage =
              errors?.clinicians?.[index]?.clinician?.message || isHelperMessagesAvailable(index);

            const roleError = !!errors?.clinicians?.[index]?.role;
            const clinicianError = !!errors?.clinicians?.[index]?.clinician;

            const markPosition = () => {
              if (isFirst) {
                if (roleError || clinicianError || roleMessage || clinicianMessage) return 'center';

                return 'end';
              }
              return 'start';
            };

            return (
              <div
                style={{
                  display: 'grid',
                  gap: '8px',
                  gridTemplateColumns: '170px 30fr 1fr',
                  alignItems: 'start'
                }}
                key={field.id}>
                <span style={{ caretColor: 'transparent' }}>
                  <FormComboBox
                    label={t('medical_professionals:invite_modal.fields.role', 'Role')}
                    id={`clinicians.${index}.role`}
                    {...register(`clinicians.${index}.role`)}
                    control={control}
                    options={ROLES}
                    optionLabel='name'
                    showLabel={isFirst}
                    placeholder={t(
                      'medical_professionals:invite_modal.fields.role_placeholder',
                      'Choose role'
                    )}
                    error={roleError}
                    helperText={roleMessage || ''}
                    disabled={clinicianFromOptionValue}
                    icon={
                      <Tooltip
                        title={t(
                          'medical_professionals:new_user_info.tooltip',
                          'If you invite a new user to the system then they will be given a role according to the one you indicated. If they already exist in the system then the role will be according to the one given to them earlier.'
                        )}>
                        <InfoOutlinedIcon
                          fontSize='small'
                          sx={{ color: '#667085', fontSize: 21, margin: '2px 0 0 2px' }}
                        />
                      </Tooltip>
                    }
                  />
                </span>
                <FormComboBox
                  label={t('medical_professionals:invite_modal.fields.person', 'Assign Person')}
                  id={`clinicians.${index}.clinician`}
                  control={control}
                  options={updatedCliniciansData}
                  renderOption={(props, option) =>
                    clinicianOption(props, option, 'fullName', 'name')
                  }
                  optionLabel={'fullName'}
                  nameProperty='name'
                  showLabel={isFirst}
                  placeholder={t(
                    'medical_professionals:invite_modal.fields.person_placeholder',
                    'Search by name or e-mail'
                  )}
                  error={clinicianError}
                  helperText={clinicianMessage || ''}
                  disabled={clinicianFromOptionValue}
                  freeSolo
                />
                {isMultipleInvitation && (
                  <CustomButton
                    onClick={() => remove(index)}
                    type='button'
                    variant='text'
                    sx={{
                      minWidth: '32px',
                      height: '40px',
                      alignSelf: markPosition()
                    }}
                    Icon={CrossSvg}
                  />
                )}
              </div>
            );
          })}
        </FormModalCheckboxesList>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          alignItems: 'center'
        }}>
        <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
        <Tooltip
          placement='bottom'
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -15]
                  }
                }
              ]
            }
          }}
          title={t(
            'medical_professionals:max_invited_number.tooltip',
            'You can only invite up to 10 Medical Professionals at once.'
          )}>
          <span>
            <CustomButton
              onClick={() => append({ role: defaultEmptyRole, clinician: null })}
              type='button'
              variant='text'
              iconProps={{ stroke: myTheme.palette.primary }}
              Icon={PlusSvg}
              disabled={!isLastFilled()}
              sx={{ margin: '0 4px' }}>
              {t('medical_professionals:invite_modal.buttons.add_next_person', 'Add next person')}
            </CustomButton>
          </span>
        </Tooltip>
        <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
      </div>
      <FormCheckbox
        label={t(
          'medical_professionals:invite_modal.fields.training_confirmation',
          'I confirm that the user I invited has been trained in the use of the application and is competent to do so.'
        )}
        id='trainingConfirmation'
        control={control}
      />
    </div>
  );
};
