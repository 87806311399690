import { RoleEnum } from 'adp-panel/api/users/users.types';
import i18n from 'i18n';

export const parseRole = (role: string | undefined) => {
  if (!role) return 'Unknown';
  if (role === RoleEnum.clinicAdmin || role === RoleEnum.clinician)
    return i18n.t('medical_professionals:roles.clinicia_title', 'Clinician');
  if (role === RoleEnum.clinicianSupport)
    return i18n.t('medical_professionals:roles.alliedHealth_title', 'Allied Health');
  if (role === RoleEnum.superAdmin)
    return i18n.t('medical_professionals:roles.admin_title', 'Admin');
  if (role === RoleEnum.amputee)
    return i18n.t('medical_professionals:roles.patient_title', 'Patient');
};
