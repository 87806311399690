/* eslint-disable react/display-name */
import Navbar from 'configurator/components/molecules/Navbar/Navbar';
import React, { memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'assets/aether_logo_white.png';
import { ReactComponent as LogoutSvg } from 'assets/logout-1.svg';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';
import { ACCOUNT, CHOOSE_GRIPS } from 'constants/routes';
import CustomButton from 'components/Button/CustomButton';
import { CircularProgress, List, Tooltip, Typography } from '@mui/material';
import useUserData from 'hooks/useUserData';
import { useTranslation } from 'react-i18next';
import CustomAvatar from 'adp-panel/components/CustomAvatar/CustomAvatar';
import { MENU_BOTTOM_CONFIG, MENU_BOTTOM_CONFIG_CONFIGURATOR } from 'adp-panel/constants/mainMenu';
import { MenuCustomItem } from 'adp-panel/components/Navbar/Sidebar';
import { userCanVisit } from 'adp-panel/utils/permissionUtils';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #33499c;
  width: 275px;
  padding: 24px 32px;
  overflow-y: auto;

  a {
    z-index: 1;
    text-decoration: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Sidebar = memo(() => {
  const { rolesByName, isLoading } = useUserData();
  const { t } = useTranslation('sidebar-configurator');

  return (
    <Wrapper>
      <InnerWrapper>
        <NavLink to={CHOOSE_GRIPS} style={{ marginBottom: '36px' }}>
          <img src={Logo} />
        </NavLink>
        <Navbar />
      </InnerWrapper>
      <List
        sx={{
          display: 'flex',
          gap: '4px',
          flexDirection: 'column'
        }}>
        {!isLoading &&
          MENU_BOTTOM_CONFIG_CONFIGURATOR.map((element) => {
            if (!userCanVisit(element?.roles, rolesByName)) {
              return null;
            }
            return (
              <MenuCustomItem
                configuratorStyle={true}
                data-testid={element.name}
                key={element.name}
                url={element.url}
                name={t(element.name)}
                items={element.items ?? []}
                Icon={element.icon}
                action={element.action}
              />
            );
          })}
        {isLoading && <CircularProgress />}
      </List>
    </Wrapper>
  );
});

export default Sidebar;
