import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as Save } from 'assets/save_floppy.svg';

const pulseAnimation = keyframes`
  to {
      fill: #869DE1;
    }
    `;

type StyledSaveProps = {
  unsaved: boolean;
};

export const StyledSaved = styled(Save)<StyledSaveProps>`
  ${({ unsaved }) =>
    unsaved === true &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    `}
`;

export const IconsContainer = styled.div<{ mode?: any }>`
  position: fixed;
  right: 0;
  background-color: white;
  box-shadow: 0px 0px 4px 2px #10182814;
  padding: 16px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  z-index: 99;
  display: grid;
  grid-template-rows: repeat(auto-fill, 40px);
  grid-gap: 12px;
`;

export const SavedContainer = styled.div`
  position: relative;
`;
