/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js/auto';
import { ChartsOptions } from 'configurator/consts/consts';

const ChartWrapper = styled.canvas`
  height: 100%;
`;

let chartProportional;

const ChartJS = ({ id, emgData, thresholdsData }, ref) => {
  useEffect(() => {
    const ctx = document.getElementById(id).getContext('2d');
    chartProportional = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [...thresholdsData, ...emgData]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        scales: {
          x: {
            type: 'linear',
            display: ChartsOptions.displayScaleX,
            reverse: true,
            min: 0,
            max: 100,
            ticks: {
              stepSize: ChartsOptions.stepX,
              callback: ChartsOptions.callbackX
            }
          },
          y: {
            position: 'right',
            display: true,
            min: 0,
            max: 100
          }
        }
      }
    });
  }, []);

  useImperativeHandle(ref, () => ({
    updateData: (data) => {
      const newData = data.map((item) => {
        const datasetObject = {
          label: item.label,
          data: item.values
        };
        return datasetObject;
      });
      chartProportional.data.datasets.forEach((dataset) => {
        newData.forEach((element) => {
          if (dataset.label === element.label) {
            dataset.data = element.data;
          }
        });
      });
      chartProportional.update();
    },
    updateTime: (time) => {
      chartProportional.options.scales.x.max = time;
      chartProportional.update('none');
    },
    updateThresholds: (chartParameters, thresholds, isShown) => {
      const newThresholds = thresholds.map((item, index) => {
        const datasetObject = {
          label: item.label,
          data: [
            { x: 0, y: item.values },
            { x: chartParameters.time, y: item.values }
          ],
          borderColor: item.values > 0 ? item.color : 'rgba(0,0,0,0)',
          hidden: isShown ? !isShown[index] : false
        };
        return datasetObject;
      });
      chartProportional.data.datasets.forEach((dataset) => {
        newThresholds.forEach((element) => {
          if (dataset.label === element.label) {
            dataset.data = element.data;
            dataset.hidden = element.hidden;
            dataset.borderColor = element.borderColor;
          }
        });
      });
      chartProportional.update('none');
    }
  }));

  return <ChartWrapper id={id} />;
};

export default forwardRef(ChartJS);
