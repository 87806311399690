import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress, DialogContent, TextField, Typography } from '@mui/material';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import ModalBase from './ModalBase';
import { CHOOSE_GRIPS } from 'constants/routes';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import useUnsaved from 'configurator/hooks/useUnsaved';
import CustomButton from 'components/Button/CustomButton';
import LoaderWrapper from 'components/Loader/Loader';

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
};

type Inputs = {
  note: string;
};

const CloseSessionModal = ({ handleClose }: ModalProps) => {
  const { sendConfigOnlyToApi } = useDeviceManager();
  const { t } = useTranslation();
  const { disconnectRemoteSession, sendQuickSave } = useRemoteSession();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));
  const { isUnsaved } = useUnsaved();
  const additionalArgs = additionalModalArgs(MODALS.closeSession)?.args;

  const accepted = async (data) => {
    try {
      setLoading(true);
      if (isUnsaved) {
        await sendConfigOnlyToApi(data.note);
        await sendQuickSave();
      }
      await disconnectRemoteSession(true);
      navigate(CHOOSE_GRIPS);

      if (additionalArgs?.action) {
        additionalArgs.action();
      }
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const onSubmit = (data) => {
    accepted(data);
  };

  return (
    <ModalBase
      handleClick={handleClose}
      header={t('configurator:component.close_session_modal.stop_session', 'Stop session')}
      allowBackDropClose={false}>
      <DialogContent>
        {loading ? (
          <LoaderWrapper style={{ width: '450px' }}>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography>
              {isUnsaved
                ? t(
                    'configurator:component.close_session_modal.confirm_stop_session_unsaved',
                    'Are you sure you want to stop the session and send unsaved changes to the patient?'
                  )
                : t(
                    'configurator:component.close_session_modal.confirm_stop_session',
                    'Are you sure you want to stop the session?'
                  )}
            </Typography>
            {isUnsaved && (
              <TextField
                fullWidth
                id='note'
                label={t('configurator:component.close_session_modal.description', 'Description')}
                multiline
                required
                placeholder={t(
                  'configurator:component.close_session_modal.describe_the_change',
                  'Describe the change'
                )}
                {...register('note', {
                  required: t(
                    'configurator:component.close_session_modal.field_required',
                    'This field is required'
                  )
                })}
                error={Boolean(errors?.note)}
                helperText={errors?.note?.message}
                rows={6}
                sx={{ marginTop: '16px' }}
              />
            )}
            <ButtonsWrapper>
              <CustomButton onClick={handleClose} color='light'>
                {t('configurator:component.close_session_modal.cancel', 'Cancel')}
              </CustomButton>
              <CustomButton type='submit'>
                {t('configurator:component.close_session_modal.close_session', 'Close session')}
              </CustomButton>
            </ButtonsWrapper>
          </form>
        )}
      </DialogContent>
    </ModalBase>
  );
};

export default CloseSessionModal;
