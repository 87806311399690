import styled, { css } from 'styled-components';
import { DisableFunctionality } from 'configurator/utils/disableFunctionalityModifiier';
import { Typography } from '@mui/material';

export const Header2 = ({ children }) => (
  <Typography sx={{ color: '#101A5A', fontWeight: '600' }}>{children}</Typography>
);

export const OptionsCSS = css`
  margin-top: 15px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const OptionsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const OptionsList = styled.ul<{ disable?: any }>`
  display: grid;
  grid-gap: 10px;
  ${({ disable }) => (disable ? DisableFunctionality : true)}
`;

export const OptionsSliderDescription = ({ children }) => (
  <Typography variant='body2' sx={{ color: '#344054', marginTop: '4px' }}>
    {children}
  </Typography>
);

export const OptionsWrapperMultiSlider = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 16px;
`;

export const SliderLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;
