/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export const Commands = {
  kNone: 0,
  kStartOrStopTransmittingTelemetryData: 1,
  kTelemetryData: 2,
  kSetJointPWM: 3,
  kSetJointSpeed: 4,
  kSetJointTargetPosition: 5,
  kJointPIDSettings: 6,
  kQueryPIDSettings: 7,
  kSetEMGSettings: 8,
  kSetGripMode: 9,
  kEnterBootloaderMode: 10,
  kPartOfFWImage: 11,
  kStopAllMotors: 12,
  kSetGripPairs: 13,
  kSetControlMode: 14,
  kQuerySerialNumber: 15,
  kSerialNumberReply: 16,
  kFirmwareChecksum: 17,
  kBootloaderStatus: 18,
  kInitialGripPositions: 19,
  kSetIntervalBetweenCocontractionPulses: 20,
  kQueryInitialGripPositions: 21,
  kSetGripPosition: 22,
  kQueryFirmwareAndAppVersions: 23,
  kFirmwareAndAppVersions: 24,
  kQueryBootloaderStatus: 25,
  kSetGripsSequence: 26,
  kSetGripSwitchingMode: 27,
  kSetSpeedControlStrategy: 28,
  kQueryGripsSequence: 29,
  kSetLoggingLevel: 30,
  kInputSite: 31,
  kResetToDefaults: 32,
  kQueryGripPairs: 33,
  kQueryControlConfig: 34,
  kControlConfig: 35,
  kQueryEmgSettings: 36,
  kQueryLongCoContractionTime: 37,
  kSetFingerLimits: 38,
  kQueryFingerLimits: 39,
  kQueryBootloaderVersion: 40,
  kBootloaderVersion: 41,
  kChangeThumbOppositon: 42,
  kSetEmgThresholds: 43,
  kQueryEmgThresholds: 44,
  kSetCurrentControlStrategy: 45,
  kQueryCurrentControlStrategy: 46,
  kFrameTypeRunProcedure: 47,
  kFrameTypeProcedureReply: 48,
  kFrameTypeFingerCurrentThreshold: 49,
  kFrameTypeQueryFingerCurrentThreshold: 50,
  kFrameTypeFreezeMode: 51,
  kFrameTypeQueryFreezeMode: 52,
  kSetEMGSpikesCancellingSettings: 53,
  kQueryEMGSpikesCancelling: 54,
  kSetHoldOpenGripSwitchingSettings: 55,
  kQueryHoldOpenGripSwitchingSettings: 56,
  kSetJointCurrent: 57,
  kJointCurrentPidSettings: 58,
  kQueryJointCurrentPidSettings: 59,
  kAutoGraspSettings: 60,
  kQueryAutograspSettings: 61,
  kSetSignalGains: 62,
  kQuerySignalGains: 63,
  kSaveSettings: 64,
  kSelectActiveMode: 65,
  kQueryActiveMode: 66,
  kResetModeSettings: 67,
  kResetCommonSettings: 68,
  kSelectUsageMode: 69,
  kSetIgnoreFingerLowerLimits: 70,
  kSetGripFollowing: 71,
  kQueryGripFollowing: 72,
  kAbortTestProcedure: 73,
  kAppReceivedFrameCallback: 74,
  kSetUserFeedbackType: 75,
  kQueryUserFeedbackType: 76,
  kRequestAction: 77,
  kContinuosThumbPositionDetection: 78,
  kPulseTimings: 79,
  kCoContractionTimings: 80,
  kQueryPulseTimings: 81,
  kQueryCoContractionTimings: 82,
  kSetFingersFollowingDelay: 83,
  kQueryFingersFollowingDelay: 84,
  kQueryForwarder: 85,
  kSetSingleElectrodeSettings: 86,
  kReplyFullMcuSerial: 87,
  kResetSingleGripToDefaults: 88,
  kSetGripSpeed: 89,
  kSetLowBatterySettings: 90,
  kUsageLogs: 91,
  kResetUsageLogs: 92,
  kSetRtcTime: 93,
  kReplyDevicesInfo: 94,
  kFwPartStatus: 95,
  kInitializeFwTransfer: 96,
  kOneSpeedValue: 97,
  kGeneralHandSettings: 98,
  kSingleElectrodeDirectionChangeIndicator: 99,
  kSingleElectrodeSelectedMode: 100,
  kSingleElectrodeModeFastOpenSlowCloseSettings: 101,
  kThumbDelayTime: 102,
  kFastRisingSlopeSettings: 103,
  kLongDoubleStageHoldSettings: 104,
  kFreezeSignalDetectorSettings: 105,
  kBuzzingVolumeSettings: 106,
  kTriggerFeedback: 107,
  kSetCoaptFallbackGrips: 108,
  kSetGripTransitionNotification: 109,
  kSetGripTransitionNotificationConfig: 110,
  kTriggerFeedbackNotification: 111,
  kInputOption: 112,
  kRequestActionWithData: 113,
  kSetDeviceUsageMonitoringState: 114,
  kLowBatteryEmergencySettings: 115,
  kSetVolatileEmgGains: 116,
  kFingerPositionControlSettings: 117,
  kLowBatteryEmergencyState: 118,
  kEmcSetupCarrierTest: 119,
  kReturnAck: 120,
  kEvent: 121,
  kGripConfigPart: 130,
  kGetGripConfigPart: 131,
  kNewTelemetry: 134,
  kStartOrStopNewTelemetry: 135,
  kGetSettingsHash: 137,
  kSettingsHash: 138
};

// Used together with frame 85 (query forwarder)

export const QueryCommands = {
  kQueryJointPidSettings: 0,
  kQuerySerialNumber: 1,
  kQueryInitialGripPositions: 2,
  kQueryFirmwareAndAppVersions: 3,
  kQueryBootloaderStatus: 4,
  kQueryGripsSequence: 5,
  kQueryGripPairs: 6,
  kQueryControlConfig: 7,
  kQueryEmgSettings: 8,
  kQueryControlIntervals: 9,
  kQueryFingerLimits: 10,
  kQueryBootloaderVersion: 11,
  kQueryEMGThresholds: 12,
  kQueryCurentControlStrategy: 13,
  kQueryFingerCurrentThreshold: 14,
  kQueryFreezeMode: 15,
  kQueryEMGSpikesCancelling: 16,
  kQueryHoldOpenGripSwitchingSettings: 17,
  kQueryJointCurrentPidSettings: 18,
  kQueryAutograspSettings: 19,
  kQuerySignalGains: 20,
  kQueryActiveUsageMode: 21,
  kQueryGripFollowing: 22,
  kQueryHoldOpenGripChangeSignalStartedIndicator: 23,
  kQueryPulseTimings: 24,
  kQueryCoContractionTimings: 25,
  kQueryFingersFollowingDelayTime: 26,
  kQuerySingleElectrodeSettings: 27,
  kQueryFullMCUSerialNumber: 28,
  kQueryLowBatteryCheckerSettings: 29,
  kQueryUsageLogs: 30,
  kQueryDevicesInfo: 31,
  kQueryOneSpeedValue: 32,
  kQueryGeneralHandSettings: 33,
  kQuerySingleElectrodeDirectionChangeIndicator: 34,
  kQuerySingleElectrodeSelectedMode: 35,
  kQuerySingleElectrodeModeFastOpenSlowCloseSettings: 36,
  kQueryThumbDelayTime: 37,
  kQueryRtcTime: 38,
  kQueryFastRisingSlopeSettings: 39,
  kQueryLongDoubleStageHoldSettings: 40,
  kQueryFreezeSignalDetectorSettings: 41,
  kQueryBuzzingVolumeSettings: 42,
  kQueryGripTransitionNotification: 43,
  kQueryCoaptFallbackGripsConfig: 44,
  kQueryGripTransitionNotificationConfig: 45,
  kQueryInputOption: 46,
  kQueryDeviceUsageMonitoringState: 47,
  kQueryUsageModeLowBatteryEmergencySettings: 48,
  kQueryVolatileEmgGains: 49,
  kQueryFingerPositionControlSettings: 50,
  kQueryLowBatteryEmergencyState: 51
};

const kDataSizesFromCommands = new Map();
kDataSizesFromCommands.set(Commands.kNone, 0);
kDataSizesFromCommands.set(Commands.kStartOrStopTransmittingTelemetryData, 1);
kDataSizesFromCommands.set(Commands.kTelemetryData, 58);
kDataSizesFromCommands.set(Commands.kSetJointPWM, 3);
kDataSizesFromCommands.set(Commands.kSetJointSpeed, 3);
kDataSizesFromCommands.set(Commands.kSetJointTargetPosition, 5);
kDataSizesFromCommands.set(Commands.kJointPIDSettings, 25);
kDataSizesFromCommands.set(Commands.kQueryPIDSettings, 1);
kDataSizesFromCommands.set(Commands.kSetEMGSettings, 4);
kDataSizesFromCommands.set(Commands.kSetGripMode, 1);
kDataSizesFromCommands.set(Commands.kEnterBootloaderMode, 1);
kDataSizesFromCommands.set(Commands.kPartOfFWImage, 121);
kDataSizesFromCommands.set(Commands.kStopAllMotors, 0);
kDataSizesFromCommands.set(Commands.kSetGripPairs, 8);
kDataSizesFromCommands.set(Commands.kSetControlMode, 1);
kDataSizesFromCommands.set(Commands.kQuerySerialNumber, 0);
kDataSizesFromCommands.set(Commands.kSerialNumberReply, 8);
kDataSizesFromCommands.set(Commands.kFirmwareChecksum, 64);
kDataSizesFromCommands.set(Commands.kBootloaderStatus, 1);
kDataSizesFromCommands.set(Commands.kInitialGripPositions, 11);
kDataSizesFromCommands.set(Commands.kSetIntervalBetweenCocontractionPulses, 2);
kDataSizesFromCommands.set(Commands.kQueryInitialGripPositions, 1);
kDataSizesFromCommands.set(Commands.kSetGripPosition, 2);
kDataSizesFromCommands.set(Commands.kQueryFirmwareAndAppVersions, 0);
kDataSizesFromCommands.set(Commands.kFirmwareAndAppVersions, 16);
kDataSizesFromCommands.set(Commands.kQueryBootloaderStatus, 0);
kDataSizesFromCommands.set(Commands.kSetGripsSequence, 12);
kDataSizesFromCommands.set(Commands.kSetGripSwitchingMode, 1);
kDataSizesFromCommands.set(Commands.kSetSpeedControlStrategy, 1);
kDataSizesFromCommands.set(Commands.kQueryGripsSequence, 0);
kDataSizesFromCommands.set(Commands.kSetLoggingLevel, 1);
kDataSizesFromCommands.set(Commands.kInputSite, 1);
kDataSizesFromCommands.set(Commands.kResetToDefaults, 0);
kDataSizesFromCommands.set(Commands.kQueryGripPairs, 0);
kDataSizesFromCommands.set(Commands.kQueryControlConfig, 0);
kDataSizesFromCommands.set(Commands.kControlConfig, 5);
kDataSizesFromCommands.set(Commands.kQueryEmgSettings, 0);
kDataSizesFromCommands.set(Commands.kQueryLongCoContractionTime, 0);
kDataSizesFromCommands.set(Commands.kSetFingerLimits, 11);
kDataSizesFromCommands.set(Commands.kQueryFingerLimits, 1);
kDataSizesFromCommands.set(Commands.kQueryBootloaderVersion, 0);
kDataSizesFromCommands.set(Commands.kBootloaderVersion, 8);
kDataSizesFromCommands.set(Commands.kChangeThumbOppositon, 0);
kDataSizesFromCommands.set(Commands.kSetEmgThresholds, 10);
kDataSizesFromCommands.set(Commands.kQueryEmgThresholds, 0);
kDataSizesFromCommands.set(Commands.kSetCurrentControlStrategy, 1);
kDataSizesFromCommands.set(Commands.kQueryCurrentControlStrategy, 0);
kDataSizesFromCommands.set(Commands.kFrameTypeRunProcedure, 121);
kDataSizesFromCommands.set(Commands.kFrameTypeProcedureReply, 121);
kDataSizesFromCommands.set(Commands.kFrameTypeFingerCurrentThreshold, 3);
kDataSizesFromCommands.set(Commands.kFrameTypeQueryFingerCurrentThreshold, 1);
kDataSizesFromCommands.set(Commands.kFrameTypeFreezeMode, 1);
kDataSizesFromCommands.set(Commands.kFrameTypeQueryFreezeMode, 0);
kDataSizesFromCommands.set(Commands.kSetEMGSpikesCancellingSettings, 5);
kDataSizesFromCommands.set(Commands.kQueryEMGSpikesCancelling, 0);
kDataSizesFromCommands.set(Commands.kSetHoldOpenGripSwitchingSettings, 8);
kDataSizesFromCommands.set(Commands.kQueryHoldOpenGripSwitchingSettings, 0);
kDataSizesFromCommands.set(Commands.kSetJointCurrent, 3);
kDataSizesFromCommands.set(Commands.kJointCurrentPidSettings, 13);
kDataSizesFromCommands.set(Commands.kQueryJointCurrentPidSettings, 1);
kDataSizesFromCommands.set(Commands.kAutoGraspSettings, 3);
kDataSizesFromCommands.set(Commands.kQueryAutograspSettings, 0);
kDataSizesFromCommands.set(Commands.kSetSignalGains, 2);
kDataSizesFromCommands.set(Commands.kQuerySignalGains, 0);
kDataSizesFromCommands.set(Commands.kSaveSettings, 0);
kDataSizesFromCommands.set(Commands.kSelectActiveMode, 1);
kDataSizesFromCommands.set(Commands.kQueryActiveMode, 0);
kDataSizesFromCommands.set(Commands.kResetModeSettings, 1);
kDataSizesFromCommands.set(Commands.kResetCommonSettings, 0);
kDataSizesFromCommands.set(Commands.kSelectUsageMode, 1);
kDataSizesFromCommands.set(Commands.kSetIgnoreFingerLowerLimits, 1);
kDataSizesFromCommands.set(Commands.kSetGripFollowing, 1);
kDataSizesFromCommands.set(Commands.kQueryGripFollowing, 0);
kDataSizesFromCommands.set(Commands.kAbortTestProcedure, 0);
kDataSizesFromCommands.set(Commands.kAppReceivedFrameCallback, 1);
kDataSizesFromCommands.set(Commands.kSetUserFeedbackType, 1);
kDataSizesFromCommands.set(Commands.kQueryUserFeedbackType, 0);
kDataSizesFromCommands.set(Commands.kRequestAction, 1);
kDataSizesFromCommands.set(Commands.kContinuosThumbPositionDetection, 1);
kDataSizesFromCommands.set(Commands.kPulseTimings, 8);
kDataSizesFromCommands.set(Commands.kCoContractionTimings, 4);
kDataSizesFromCommands.set(Commands.kQueryPulseTimings, 0);
kDataSizesFromCommands.set(Commands.kQueryCoContractionTimings, 0);
kDataSizesFromCommands.set(Commands.kSetFingersFollowingDelay, 4);
kDataSizesFromCommands.set(Commands.kQueryFingersFollowingDelay, 0);
kDataSizesFromCommands.set(Commands.kQueryForwarder, 2);
kDataSizesFromCommands.set(Commands.kSetSingleElectrodeSettings, 20);
kDataSizesFromCommands.set(Commands.kReplyFullMcuSerial, 24);
kDataSizesFromCommands.set(Commands.kResetSingleGripToDefaults, 1);
kDataSizesFromCommands.set(Commands.kSetGripSpeed, 2);
kDataSizesFromCommands.set(Commands.kSetLowBatterySettings, 3);
kDataSizesFromCommands.set(Commands.kUsageLogs, 121);
kDataSizesFromCommands.set(Commands.kResetUsageLogs, 0);
kDataSizesFromCommands.set(Commands.kSetRtcTime, 6);
kDataSizesFromCommands.set(Commands.kReplyDevicesInfo, 10);
kDataSizesFromCommands.set(Commands.kFwPartStatus, 3);
kDataSizesFromCommands.set(Commands.kInitializeFwTransfer, 1);
kDataSizesFromCommands.set(Commands.kOneSpeedValue, 1);
kDataSizesFromCommands.set(Commands.kGeneralHandSettings, 26);
kDataSizesFromCommands.set(Commands.kSingleElectrodeDirectionChangeIndicator, 1);
kDataSizesFromCommands.set(Commands.kSingleElectrodeSelectedMode, 1);
kDataSizesFromCommands.set(Commands.kSingleElectrodeModeFastOpenSlowCloseSettings, 12);
kDataSizesFromCommands.set(Commands.kThumbDelayTime, 3);
kDataSizesFromCommands.set(Commands.kFastRisingSlopeSettings, 6);
kDataSizesFromCommands.set(Commands.kLongDoubleStageHoldSettings, 32);
kDataSizesFromCommands.set(Commands.kFreezeSignalDetectorSettings, 32);
kDataSizesFromCommands.set(Commands.kBuzzingVolumeSettings, 32);
kDataSizesFromCommands.set(Commands.kTriggerFeedback, 32);
kDataSizesFromCommands.set(Commands.kSetCoaptFallbackGrips, 32);
kDataSizesFromCommands.set(Commands.kSetGripTransitionNotification, 32);
kDataSizesFromCommands.set(Commands.kSetGripTransitionNotificationConfig, 32);
kDataSizesFromCommands.set(Commands.kTriggerFeedbackNotification, 32);
kDataSizesFromCommands.set(Commands.kInputOption, 1);
kDataSizesFromCommands.set(Commands.kRequestActionWithData, 121);
kDataSizesFromCommands.set(Commands.kSetDeviceUsageMonitoringState, 1);
kDataSizesFromCommands.set(Commands.kLowBatteryEmergencySettings, 32);
kDataSizesFromCommands.set(Commands.kSetVolatileEmgGains, 2);
kDataSizesFromCommands.set(Commands.kFingerPositionControlSettings, 2);
kDataSizesFromCommands.set(Commands.kLowBatteryEmergencyState, 3);
kDataSizesFromCommands.set(Commands.kEmcSetupCarrierTest, 2);
kDataSizesFromCommands.set(Commands.kReturnAck, 6);
kDataSizesFromCommands.set(Commands.kEvent, 16);
kDataSizesFromCommands.set(Commands.kGripConfigPart, 32);
kDataSizesFromCommands.set(Commands.kGetGripConfigPart, 2);
kDataSizesFromCommands.set(Commands.kNewTelemetry, 10);
kDataSizesFromCommands.set(Commands.kStartOrStopNewTelemetry, 1);
kDataSizesFromCommands.set(Commands.kGetSettingsHash, 4);
kDataSizesFromCommands.set(Commands.kSettingsHash, 8);

export const kFWPartSize = 119;

export enum BootloaderStates {
  incative = 0,
  waiting = 1,
  complete = 2,
  error = 3,
  transfer = 4
}

export enum Fingers {
  thumb = 0,
  index = 1,
  middle = 2,
  ring = 3,
  pinky = 4
}

export enum ThumbLength {
  kThumbLong = 0,
  kThumbShort = 1,
  kThumbNotSet = 255
}

export enum HandSide {
  kHandLeft = 0,
  kHandRight = 1
}

export default kDataSizesFromCommands;
