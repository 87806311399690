/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { FETCHING_STATES, LANGUAGES } from 'configurator/consts/consts';

type StoreState = {
  blockModals: { id: string; showMessage: boolean }[];
  isAuthorized: boolean;
  isCalendarHistory: boolean;
  isRecordReplay: boolean;
  language: LANGUAGES;
  switchOnRemoteSession: boolean;
  mainViewLoaded: boolean;
  initialConfigApiState: FETCHING_STATES;
  initialConfigState: FETCHING_STATES;
  bluetoothState: FETCHING_STATES;
  firmwareState: FETCHING_STATES;
  getPcbState: FETCHING_STATES;
  procedureState: FETCHING_STATES;
  connectionState: FETCHING_STATES;
  synchronizingState: FETCHING_STATES;
  disconnectingState: FETCHING_STATES;
  firmwareUpdateState: FETCHING_STATES;
  videoSessionState: FETCHING_STATES;
  sessionDisconnecting: boolean;
  sessionStartingState: FETCHING_STATES;
  modals: { id: string }[];
  setItemUiStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) => void;
  openModal: (modalId: string, args?: any) => void;
  closeModal: (modalId: string) => void;
  isModalOpen: (modalId: string) => boolean;
  additionalModalArgs: (modalId: string) => { open: boolean; args };
  blockScreen: (blockId: string, showMessage?: boolean) => { id: string; showMessage: boolean }[];
  unblockScreen: (blockId: string) => { id: string; showMessage: boolean }[];
};

export const initialStateUiStore = {
  blockModals: [],
  isAuthorized: false,
  isCalendarHistory: false,
  isRecordReplay: true,
  language: LANGUAGES.english,
  switchOnRemoteSession: false,
  mainViewLoaded: false,
  initialConfigApiState: FETCHING_STATES.idle,
  initialConfigState: FETCHING_STATES.idle,
  bluetoothState: FETCHING_STATES.idle,
  firmwareState: FETCHING_STATES.idle,
  getPcbState: FETCHING_STATES.idle,
  procedureState: FETCHING_STATES.idle,
  connectionState: FETCHING_STATES.idle,
  synchronizingState: FETCHING_STATES.idle,
  disconnectingState: FETCHING_STATES.idle,
  firmwareUpdateState: FETCHING_STATES.idle,
  videoSessionState: FETCHING_STATES.idle,
  sessionDisconnecting: false,
  sessionStartingState: FETCHING_STATES.idle,
  modals: []
};

export const useUiStore = create<StoreState>()(
  devtools(
    (set, get) => ({
      ...initialStateUiStore,
      setItemUiStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) =>
        set({ [`${property}`]: value }),
      blockScreen: (blockId: string, showMessage = true) => {
        let newShownGlobalModal;
        set((state: StoreState) => {
          newShownGlobalModal = [...state.blockModals, { id: blockId, showMessage }];
          return { blockModals: newShownGlobalModal };
        });
        return newShownGlobalModal;
      },
      unblockScreen: (blockId: string) => {
        let newBlockModals;
        set((state: StoreState) => {
          newBlockModals = state.blockModals.filter((element) => element.id !== blockId);
          return { blockModals: newBlockModals };
        });
        return newBlockModals;
      },
      openModal: (modalId: string, args = null) =>
        set((state) => ({ modals: { ...state.modals, [modalId]: { open: true, args } } })),
      closeModal: (modalId: string) =>
        set((state) => ({ modals: { ...state.modals, [modalId]: false } })),
      isModalOpen: (modalId: string) => {
        const { modals } = get();
        return Boolean(modals?.[modalId]?.open);
      },
      additionalModalArgs: (modalId: string) => {
        const { modals } = get();
        return modals?.[modalId];
      }
    }),
    { name: 'UI' }
  )
);
