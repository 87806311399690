import CustomButton from 'components/Button/CustomButton';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useTypedParams } from 'hooks/useTypedParams';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import { useTranslation } from 'react-i18next';

const AddGoal = () => {
  const { patientId } = useTypedParams(['patientId']);
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const { t } = useTranslation();

  const openAddGoalModal = () => {
    openModal(MODALS.addGoalModal, {
      patientId: patientId
    });
  };
  const { canAccess: canAccessGoals } = useCanAccess({
    resource: 'goals'
  });

  return (
    <CustomButton
      color='light'
      data-testid='button-delete'
      disabled={!canAccessGoals}
      onClick={() => openAddGoalModal()}>
      {t('golas:component.add_goal_button', 'Add new goal')}
    </CustomButton>
  );
};

export default AddGoal;
