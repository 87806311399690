import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox from 'components/FormFields/FormComboBox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalStyle } from './styles';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { useSessionStart } from 'hooks/useSessionStart';

const deviceAssignSchema = yup.object().shape({
  device: yup.mixed().test('is-object', VALIDATOR_TEXT.REQUIRED, (value) => {
    return value !== null && typeof value === 'object';
  })
});

const ChooseDeviceModal = ({ handleModalClose, devices }) => {
  const { t } = useTranslation();
  const defaultValues = {
    device: null
  };

  const { handleOpenLocalSession } = useSessionStart();

  const {
    control,
    handleSubmit: handleSubmitDevice,
    reset
  } = useForm<{
    device: any;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(deviceAssignSchema),
    mode: 'onChange',
    shouldUnregister: false
  });

  const handleSubmit = async (formData: any) => {
    const device = formData.device;
    handleOpenLocalSession(device.serial, device.bluetooth_id, device.id);
  };

  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper title={t('patients:dashboard.choose_device.header', 'Choose device')}>
        <form onSubmit={handleSubmitDevice(handleSubmit)}>
          <FormWrapper>
            <FormComboBox
              label={t('patients:dashboard.choose_device.title', 'Device')}
              id={'device'}
              control={control}
              options={devices}
              optionLabel={'serial'}
              placeholder={t('patients:dashboard.choose_device.placeholder', 'Choose from list')}
              helperText={t(
                'patients:dashboard.choose_device.helper_text',
                'This patient have multiple devices, which one you want to connect.'
              )}
            />
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-medical-form'
                type='button'
                color='light'
                onClick={handleModalClose}>
                {t('patients:dashboard.choose_device.cancel', 'Cancel')}
              </CustomButton>
              <LoadingButton data-testid='submit-patient-form' type='submit'>
                <span>{t('patients:dashboard.choose_device.configure', 'Configure')}</span>
              </LoadingButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      </FormContentWrapper>
    </Modal>
  );
};

export default ChooseDeviceModal;
