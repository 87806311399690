export const CONFIGURATOR_URL = process.env.REACT_APP_CONFIGURATOR_URL || '';
export const API_URL = process.env.REACT_APP_API_BASE_URL || '';
export const REMOTE_SESSION_DEBUG: boolean = process.env.REACT_APP_REMOTE_SESSION_DEBUG === 'true';
export const TICKET_DEBUG: boolean = process.env.REACT_APP_TICKET_DEBUG === 'true';
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SENTRY_TRACES_SAMPLE_DATE: number =
  Number(process.env.REACT_APP_SENTRY_TRACES_RATE) ?? 1;
export const AUTOLOGOUT_TIME_IN_SECONDS: number = process.env.REACT_APP_AUTOLOGOUT_TIME
  ? Number(process.env.REACT_APP_AUTOLOGOUT_TIME)
  : 600;
export const AUTOLOGOUT_WARNING_TIME_IN_SECONDS: number = process.env
  .REACT_APP_AUTOLOGOUT_WARNING_TIME
  ? Number(process.env.REACT_APP_AUTOLOGOUT_WARNING_TIME)
  : 540;

export const SIMPLY_LOCALIZE_API_TOKEN = process.env.REACT_APP_SIMPLY_LOCALIZE_PROJECT_TOKEN || '';
export const SIMPLY_LOCALIZE_API_KEY = process.env.REACT_APP_SIMPLY_LOCALIZE_PROJECT_API_KEY || '';
export const SIMPLY_LOCALIZE_API_URL = process.env.REACT_APP_SIMPLY_LOCALIZE_PROJECT_API_URL || '';
export const SIMPLY_LOCALIZE_CDN_URL = process.env.REACT_APP_SIMPLY_LOCALIZE_PROJECT_CDN_URL || '';
export const SIMPLY_LOCALIZE_CDN_ENVIRONMENT =
  process.env.REACT_APP_SIMPLY_LOCALIZE_PROJECT_CDN_ENVIRONMENT || '';
export const LIVE_CHAT_LICENCE = process.env.REACT_APP_LIVE_CHAT_LICENCE || '';
