import { useNavigate } from 'react-router-dom';
import { useUser, useUserDelete } from '../../../hooks/api/useUsers';
import { PATIENTS, PATIENT_EDIT } from 'constants/routes';
import { UserExtendOptions, UsersQueryParams } from '../../../api/users/users.types';

export const usePatientDetails = (patientId: string) => {
  const navigate = useNavigate();

  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.clinicians]
  };

  const {
    result: patient,
    isLoading: isLoadingPatient,
    isError: isErrorPatientInfo
  } = useUser(parseInt(patientId, 10), queryParams);

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const handleDelete = async () => {
    await deleteUser(patientId);
    navigate(PATIENTS);
  };

  const handleEdit = () => {
    const parsedPatient = {
      ...patient,
      company: patient?.location?.company?.name,
      companyId: patient?.location?.company?.id,
      location: patient?.location?.name,
      locationId: patient?.location_id
    };
    navigate(PATIENT_EDIT, { state: { detail: parsedPatient } });
  };

  return {
    patient,
    isLoadingPatient,
    isErrorPatientInfo,
    isLoadingDelete,
    handleDelete,
    handleEdit
  };
};
