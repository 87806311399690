import { styled } from '@mui/material/styles';
import DotsBackground from 'assets/dots.svg';

export const NoDevicesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: 0
});

export const NoDevicesContent = styled('div')({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  padding: '40px',
  backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
});
