import InviteMedicalProfessionalsSuccessModal from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/InviteMedicalProfessionalsSuccessModal';
import LocalSessionConfigBackupRestoreModal from 'configurator/modals/LocalSessionConfigBackupRestoreModal';
import React from 'react';
import useModes from 'configurator/hooks/useModes';
import ConfirmSave from 'configurator/modals/ConfirmSave';
import RestoreRecentConfigModal from 'configurator/modals/RestoreRecentConfigModal';
import PreventInputModal from 'configurator/modals/PreventInputModal';
import AuthorizeServiceModal from 'configurator/modals/AuthorizeServiceModal';
import DisruptiveDisconnectModal from 'configurator/modals/DisruptiveDisconnectModal';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import BootloaderModeModal from 'configurator/modals/BootloaderModeModal';
import useUnsaved from 'configurator/hooks/useUnsaved';
import PcbReplacementModal from 'configurator/modals/PcbReplacementModal.tsx/PcbReplacementModal';
import ImmediateSendingModeChangeModal from 'configurator/modals/ImmediateSendingModeChangeModal';
import SessionReconnectionModal from 'configurator/modals/SessionReconnectionModal';
import { useSettingsStore } from 'configurator/reducers/settingsStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import useHandleSessionReconnection from 'configurator/hooks/useHandleSessionReconnection';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import CloseSessionModal from 'configurator/modals/CloseSessionModal';
import FirmwareModal from 'configurator/modals/FirmwareModal/FirmwareModal';
import HandDetails from 'configurator/modals/HandDetails';
import ResetAllChangesModal from 'configurator/modals/ResetAllChangesModal';
import SaveAsRestorePointModal from 'configurator/modals/SaveAsRestorePointModal';
import SessionWasEndedServicing from 'configurator/modals/SessionWasEndedServicing';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import InviteMedicalProfessionalModal from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/InviteMedicalProfessionalModal';
import StartLiveSessionModal from 'adp-panel/components/Modals/StartLiveSessionModal';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import Meeting from 'configurator/components/organisms/MeetingComponents/Meeting';
import ChangeModeModal from 'configurator/modals/ChangeModeModal';
import PairingModal from 'configurator/modals/PairingModal';
import AfterUpdateModal from 'configurator/modals/AfterUpdateModal';
import EditGoalModal from 'adp-panel/pages/Goals/EditGoalModal';
import AddGoalModal from 'adp-panel/pages/Goals/AddGoalModal';

export const MODALS = {
  servicingModal: 'servicingModal',
  authorize: 'authorize',
  firmware: 'firmware',
  closeSession: 'closeSession',
  resetAllChanges: 'resetAllChanges',
  restorePoint: 'restorePoint',
  info: 'info',
  bootloaderMode: 'bootloaderMode',
  restoreRecent: 'restoreRecent',
  disruptiveDisconnect: 'disruptiveDisconnect',
  repair: 'repair',
  immediateSending: 'immediateSending',
  sessionReconnection: 'sessionReconnection',
  confirmSave: 'confirmSave',
  localSessionBackupConfig: 'localSessionBackupConfig',
  inviteMedicalProfessional: 'inviteMedicalProfessional',
  inviteMedicalProfessionalsSucessModal: 'inviteMedicalProfessionalsSucessModal',
  editGoalModal: 'editGoalModal',
  addGoalModal: 'addGoalModal',
  session: 'session',
  changeMode: 'changeMode',
  pairing: 'pairing',
  calibration: 'calibration'
};

export const MODALS_ARGS = {
  unsavedChanges: 'unsavedChanges',
  unsavedChangesDisconnect: 'unsavedChangesDisconnect'
};

const Modals = () => {
  const {
    blockScreen,
    unblockScreen,
    synchronizingState,
    initialConfigApiState,
    videoSessionState,
    isModalOpen,
    closeModal,
    procedureState,
    blockModals,
    sessionDisconnecting
  } = useUiStore((state) => ({
    synchronizingState: state.synchronizingState,
    initialConfigApiState: state.initialConfigApiState,
    isModalOpen: state.isModalOpen,
    closeModal: state.closeModal,
    procedureState: state.procedureState,
    videoSessionState: state.videoSessionState,
    setItemUiStore: state.setItemUiStore,
    sessionDisconnecting: state.sessionDisconnecting,
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen,
    blockModals: state.blockModals
  }));
  const { amputeeId } = useDeviceInfoStore((state: any) => ({ amputeeId: state.amputeeId }));
  const { handleSaveMode, isLoadingConfigSave, handleOpenSave } = useModes();
  const { isUnsaved } = useUnsaved();
  const { getInitialConfig } = useConfigStore((state: any) => ({
    getInitialConfig: state.getInitialConfig
  }));
  const { meetingStatus, viewMode } = useMeetingStore((state) => ({
    meetingStatus: state.meetingStatus,
    viewMode: state.viewMode
  }));
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));
  const { disconnectRemoteSession } = useRemoteSession();
  const { setItemSettingsStore } = useSettingsStore((state: any) => ({
    setItemSettingsStore: state.setItemSettingsStore
  }));
  const { sessionPatientId } = useLiveConfiguratorStore((state) => ({
    sessionPatientId: state.sessionPatientId
  }));
  useHandleSessionReconnection();

  return (
    <>
      {isModalOpen(MODALS.authorize) && (
        <AuthorizeServiceModal handleClose={() => closeModal(MODALS.authorize)} />
      )}
      {isModalOpen(MODALS.firmware) && (
        <FirmwareModal handleClose={() => closeModal(MODALS.firmware)} />
      )}
      {isModalOpen(MODALS.closeSession) && (
        <CloseSessionModal handleClose={() => closeModal(MODALS.closeSession)} />
      )}
      {isModalOpen(MODALS.servicingModal) && (
        <SessionWasEndedServicing handleClose={() => closeModal(MODALS.servicingModal)} />
      )}
      {isModalOpen(MODALS.resetAllChanges) && (
        <ResetAllChangesModal handleClose={() => closeModal(MODALS.resetAllChanges)} />
      )}
      {isModalOpen(MODALS.restorePoint) && (
        <SaveAsRestorePointModal handleClose={() => closeModal(MODALS.restorePoint)} />
      )}
      {isModalOpen(MODALS.info) && <HandDetails handleClose={() => closeModal(MODALS.info)} />}
      {isModalOpen(MODALS.bootloaderMode) && (
        <BootloaderModeModal handleClose={() => closeModal(MODALS.bootloaderMode)} />
      )}
      {isModalOpen(MODALS.restoreRecent) && (
        <RestoreRecentConfigModal handleClose={() => closeModal(MODALS.restoreRecent)} />
      )}
      {isModalOpen(MODALS.disruptiveDisconnect) && (
        <DisruptiveDisconnectModal
          handleClose={() => closeModal(MODALS.disruptiveDisconnect)}
          handleAccept={() =>
            handleOpenSave(isUnsaved, { action: () => false, args: MODALS_ARGS.unsavedChanges })
          }
        />
      )}
      {isModalOpen(MODALS.confirmSave) && (
        <ConfirmSave
          handleClose={() => closeModal(MODALS.confirmSave)}
          handleAccept={handleSaveMode}
          isLoading={isLoadingConfigSave || synchronizingState === FETCHING_STATES.loading}
          localMode={!amputeeId}
        />
      )}
      {isModalOpen(MODALS.localSessionBackupConfig) && (
        <LocalSessionConfigBackupRestoreModal
          handleClose={() => closeModal(MODALS.localSessionBackupConfig)}
        />
      )}
      {isModalOpen(MODALS.repair) && (
        <PcbReplacementModal handleClose={() => closeModal(MODALS.repair)} />
      )}
      {isModalOpen(MODALS.immediateSending) && (
        <ImmediateSendingModeChangeModal
          handleClose={() => {
            setItemSettingsStore('demoMode', false);
            closeModal(MODALS.immediateSending);
          }}
          handleAccept={(e: any) => {
            handleSaveMode(e);
            closeModal(MODALS.immediateSending);
          }}
          handleSaveWithoutChanges={async () => {
            try {
              blockScreen(BLOCK_MODALS.UNSAVED_CHANGES);
              setItemSettingsStore('demoMode', true);
              await getInitialConfig();
            } catch (e) {
              console.log(e);
            } finally {
              unblockScreen(BLOCK_MODALS.UNSAVED_CHANGES);
              closeModal(MODALS.immediateSending);
            }
          }}
          isLoading={isLoadingConfigSave || synchronizingState === FETCHING_STATES.loading}
        />
      )}
      {isModalOpen(MODALS.sessionReconnection) && (
        <SessionReconnectionModal
          isUnsaved={isUnsaved}
          stopSessionAction={async () => {
            await disconnectRemoteSession(true, false);
            closeModal(MODALS.sessionReconnection);
            openModal(MODALS.confirmSave);
          }}
          isLoading={sessionDisconnecting}
          remainingTimeInSeconds={600}
        />
      )}
      {isModalOpen(MODALS.inviteMedicalProfessional) && (
        <InviteMedicalProfessionalModal
          handleModalClose={() => closeModal(MODALS.inviteMedicalProfessional)}
        />
      )}
      {isModalOpen(MODALS.inviteMedicalProfessionalsSucessModal) && (
        <InviteMedicalProfessionalsSuccessModal
          handleModalClose={() => closeModal(MODALS.inviteMedicalProfessionalsSucessModal)}
        />
      )}
      {isModalOpen(MODALS.session) && sessionPatientId && (
        <StartLiveSessionModal isModalOpen={true} patientId={sessionPatientId} />
      )}
      {isModalOpen(MODALS.changeMode) && (
        <ChangeModeModal handleModalClose={() => closeModal(MODALS.changeMode)} />
      )}
      {isModalOpen(MODALS.editGoalModal) && (
        <EditGoalModal handleClose={() => closeModal(MODALS.editGoalModal)} />
      )}
      {isModalOpen(MODALS.addGoalModal) && (
        <AddGoalModal handleClose={() => closeModal(MODALS.addGoalModal)} />
      )}
      {isModalOpen(MODALS.pairing) && (
        <PairingModal handleModalClose={() => closeModal(MODALS.pairing)} />
      )}
      {isModalOpen(MODALS.calibration) && synchronizingState !== FETCHING_STATES.loading && (
        <AfterUpdateModal handleModalClose={() => closeModal(MODALS.calibration)} />
      )}
      {(meetingStatus ||
        viewMode === ViewModes.minimalEMG ||
        // @ts-ignore
        viewMode === ViewModes.standardEMG) && <Meeting />}
      {(blockModals.length > 0 ||
        sessionDisconnecting ||
        synchronizingState === FETCHING_STATES.loading ||
        initialConfigApiState === FETCHING_STATES.loading ||
        videoSessionState === FETCHING_STATES.loading) && <PreventInputModal />}
    </>
  );
};

export default Modals;
