import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  Button,
  Chip,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from '@mui/material';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDevice,
  useUpdateDeviceConfigEntry,
  DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY
} from 'configurator/hooks/api/useDevice';
import { SortDirs } from 'configurator/utils/types';
import CompanyImg from 'assets/company_img.jpg';
import Accordion from 'configurator/components/atoms/Accordion/Accordion';
import Loader from 'configurator/components/atoms/Loader/Loader';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import { AddTemplateModal } from 'configurator/modals/AddTemplate';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useImportButton from 'configurator/hooks/useImportButton';
import ConfigComponent from 'configurator/components/organisms/ConfigComponent/ConfigComponent';
import {
  findDifferencesImport,
  transformAndApply,
  transformConfigAPI
} from 'configurator/utils/Config/transformConfig';
import RestoreConfigHistoryModal from 'configurator/modals/RestoreConfigHistoryModal';
import { HeaderWrapper } from '../styled';
import { HeaderMode } from '../SessionHistory/SessionHistory';
import { useModal } from 'configurator/hooks/useModal';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import {
  ConfigNotesSortOptions,
  ConfigHistoryItemEntry
} from 'configurator/api/device/device.types';
import {
  ButtonWrapper,
  ConfigDetailsWrapper,
  ConfigurationHeaderWrapper,
  DetailsWrapper,
  Header3,
  Header4,
  InnerWrapper,
  PatientImage,
  TableBody,
  TableHeader,
  TextMedium,
  TextSub,
  Wrapper
} from './styled';
import { ConfigHistoryItem } from 'configurator/components/organisms/Config/ConfigHistoryItem';
import Card from 'adp-panel/components/Card/Card';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import CustomButton from 'components/Button/CustomButton';

enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

enum CONFIG_PROPERTY_SUPPORT {
  active,
  inactive,
  movedToCommon,
  movedToMode
}

export const actionMapping = {
  [CONFIG_PROPERTY_SUPPORT.inactive]: (
    <Tooltip
      title={i18next.t(
        'configurator:component.device_config.action_inactive',
        'Setting is no longer supported by your firmware.'
      )}>
      <Chip label='Unsupported' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.movedToMode]: (
    <Tooltip
      title={i18next.t(
        'configurator:component.device_config.moved_to_mode',
        'Setting was set as common at the time of change, but was moved to mode in current firmware.'
      )}>
      <Chip label='Changed' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.movedToCommon]: (
    <Tooltip
      title={i18next.t(
        'configurator:component.device_config.moved_to_common',
        'Setting was set as mode at the time of change, but was moved to common in current firmware.'
      )}>
      <Chip label='Changed' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.active]: null
};

const PatientDetails = ({ deviceInfo }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      <Header3>{t('configurator:component.patient_details.header', 'Patient details')}</Header3>
      <DetailsWrapper>
        <PatientImage src={deviceInfo?.amputee?.image || CompanyImg} alt='Patient' />
        <div>
          <Header4>{deviceInfo?.amputee?.name}</Header4>
          <p>{deviceInfo?.amputee?.email}</p>
        </div>
      </DetailsWrapper>
      <InnerWrapper>
        <div>
          <TextMedium>
            {t('configurator:component.patient_details.clinician', 'Clinician')}
          </TextMedium>
          <TextSub>{deviceInfo?.clinician?.name}</TextSub>
        </div>
      </InnerWrapper>
    </div>
  );
};

const DeviceConfigComponent = () => {
  const { t } = useTranslation();
  const deviceId = useDeviceInfoStore((state: any) => state.deviceId);
  // @ts-ignore
  const { configId } = useParams();
  const { result: device } = useDevice(Number(deviceId), { extend: 'amputee,clinician,company' });
  const { result: notes } = useConfigEntryNotes({
    deviceId: Number(deviceId),
    // @ts-ignore
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: updateConfig, isLoading: isLoadingUpdateConfigEntry } =
    useUpdateDeviceConfigEntry();
  const [currentToggle, setCurrentToggle] = useState(ToggleTypesChangesHistory.all);
  const {
    state: { detail: changeEntry }
  }: { state: { detail: ConfigHistoryItemEntry } } = useLocation();
  const {
    handleClose: handleCloseTemplateModal,
    handleOpen: handleOpenTemplateModal,
    isOpen
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const { restoreConfigHistory, isLoadingDeviceManager } = useDeviceManager();
  const { config, commonKeys, modeKeys, slotSelected } = useConfigStore((state: any) => ({
    config: state.config,
    commonKeys: state.commonPropertiesAPI,
    modeKeys: state.modePropertiesAPI,
    slotSelected: state.slotSelected
  }));
  const { common, modes } = config;
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const queryClient = useQueryClient();
  const { parsedConfig } = transformConfigAPI(changeEntry, config.modes);
  const importDifferences = findDifferencesImport(common, modes, parsedConfig);
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const toggleRestorePoint = async () => {
    const data = {
      restore_point: 0
    };
    if (changeEntry.restore_point === 0) {
      data.restore_point = 1;
    }
    try {
      await updateConfig({ deviceId, configId: changeEntry.id, data });
      queryClient.invalidateQueries([DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY]);
      toast.success(
        t('configurator:component.device_config.restore_point_updated', 'Restore point updated')
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNote = async ({ note, type }: any) => {
    await createConfigNote({
      deviceId: Number(deviceId),
      configId: Number(configId),
      data: { note, type }
    });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteConfigNote({ deviceId: Number(deviceId), configId: Number(configId), noteId });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  const handleRestore = async () => {
    await restoreConfigHistory(changeEntry.id);
    handleCloseRestoreConfigHistoryModal();
  };

  if (!config.common.configAPI) {
    return null;
  }

  const transformHistory = (entries: any) => {
    const common = entries
      .filter((entry: any) => !entry.config_entry.mode_id)
      .map((entry: any) => ({
        after: JSON.parse(entry.new_value),
        before: JSON.parse(entry.old_value),
        key: entry.config_entry.key
      }));

    const modes: any = [];
    for (let index = 0; index < config.modes.length; index += 1) {
      const modeInfo = config.modes[index];

      const modeChanges = entries
        .filter((entry: any) => entry.config_entry.mode_id === modeInfo.id)
        .map((entry: any) => ({
          after: JSON.parse(entry.new_value),
          before: JSON.parse(entry.old_value),
          key: entry.config_entry.key
        }));

      modes.push({ changes: modeChanges, slot: modeInfo.slot, name: modeInfo.name });
    }

    return { common, modes };
  };

  const completeConfig = {
    ...parsedConfig.common,
    ...parsedConfig.modes.find((mode: any) => mode.slot === selectedModeSlot).config
  };

  const transformedHistory = transformHistory(changeEntry.entries);

  const keysChecking = (
    key: keyof DeviceConfigTemplate,
    keyInModeAtTimeOfChange: boolean = false
  ) => {
    const isGripsPositions = key.split('.')[0] === 'gripsPositions';
    const includedInKeys = commonKeys?.includes(key) || modeKeys?.includes(key);

    const isSupported: boolean = Boolean(isGripsPositions ? true : includedInKeys);
    const isKeyPresentInOther = Boolean(
      keyInModeAtTimeOfChange ? commonKeys?.includes(key) : modeKeys?.includes(key)
    );

    const isMoved: boolean = isSupported && !isGripsPositions && isKeyPresentInOther;

    return { isSupported, isMoved };
  };

  return (
    <>
      {isOpen && (
        <AddTemplateModal handleClose={handleCloseTemplateModal} configModes={parsedConfig.modes} />
      )}
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
          entry={parsedConfig}
          isLoading={isLoadingDeviceManager}
        />
      )}
      <CustomPageHeader
        header={t('configurator:component.device_config.applied_changes', 'Applied changes')}
        tooltipText={t('configurator:header.device_config_applied_changes.tooltip', 'Tooltip')}
      />
      <Card>
        <TableBody>
          <TableHeader>
            {t('configurator:component.device_config.configuration', 'Configuration')}
          </TableHeader>
          <TableHeader>
            {t('configurator:component.device_config.value_before', 'Value before')}
          </TableHeader>
          <TableHeader>
            {t('configurator:component.device_config.value_after', 'Value after')}
          </TableHeader>
          <TableHeader>
            {t('configurator:component.device_config.value_now', 'Value now')}
          </TableHeader>
        </TableBody>
        {transformedHistory.common.length > 0 && (
          <>
            <HeaderMode>{t('configurator:component.device_config.common', 'Common')}</HeaderMode>
            {transformedHistory.common.map((change: any) => (
              <ConfigHistoryItem
                key={changeEntry.id + Math.random()}
                name={change.key}
                configName={change.key}
                before={change.before}
                after={change.after}
                now={{
                  ...config.common.configAPI,
                  ...config.modes.find((_mode: any) => _mode.slot === slotSelected)?.configAPI
                }}
                supported={(() => {
                  if (!keysChecking(change.key).isSupported)
                    return CONFIG_PROPERTY_SUPPORT.inactive;
                  if (keysChecking(change.key).isMoved) return CONFIG_PROPERTY_SUPPORT.movedToMode;
                  return CONFIG_PROPERTY_SUPPORT.active;
                })()}
              />
            ))}
          </>
        )}
        {transformedHistory.modes.map(
          (mode: any) =>
            mode.changes.length > 0 && (
              <>
                <HeaderMode>
                  {t('configurator:component.device_config.mode', 'Mode')}: {mode.name}
                </HeaderMode>
                {mode.changes.map((change: any) => (
                  <ConfigHistoryItem
                    key={changeEntry.id + Math.random()}
                    name={change.key}
                    configName={change.key}
                    before={change.before}
                    after={change.after}
                    now={{
                      ...config.common.configAPI,
                      ...config.modes.find((_mode: any) => _mode.slot === mode.slot)?.configAPI
                    }}
                    supported={(() => {
                      if (!keysChecking(change.key, true).isSupported)
                        return CONFIG_PROPERTY_SUPPORT.inactive;
                      if (keysChecking(change.key, true).isMoved)
                        return CONFIG_PROPERTY_SUPPORT.movedToCommon;
                      return CONFIG_PROPERTY_SUPPORT.active;
                    })()}
                  />
                ))}
              </>
            )
        )}
      </Card>
      <Divider margin='40px' />
      <HeaderWrapper>
        <ConfigurationHeaderWrapper>
          <CustomPageHeader
            header={t('configurator:component.device_config.configuration', 'Configuration')}
            tooltipText={t('configurator:header.device_config.tooltip', 'Tooltip')}
          />
          {config.modes && (
            <TextField
              fullWidth
              id='selected-mode'
              label={t('configurator:component.device_config.mode_shown', 'Mode shown')}
              select
              sx={{ width: '150px' }}
              SelectProps={{
                value: selectedModeSlot,
                onChange: (e: any) => setSelectedModeSlot(e.target.value)
              }}>
              {config.modes.map((mode: any) => (
                <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                  {mode.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </ConfigurationHeaderWrapper>
        <ButtonWrapper>
          <Tooltip title={importTooltip()}>
            <CustomButton
              onClick={handleOpenRestoreConfigHistoryModal}
              disabled={disableImportButton()}>
              {t('configurator:component.device_config.load', 'Load')}
            </CustomButton>
          </Tooltip>
          <ToggleButtonGroup value={currentToggle}>
            <ToggleButton
              value={ToggleTypesChangesHistory.all}
              key='all'
              onClick={() => setCurrentToggle(ToggleTypesChangesHistory.all)}>
              {t('configurator:component.device_config.all', 'All')}
            </ToggleButton>
            <ToggleButton
              value={ToggleTypesChangesHistory.emg}
              key='emg'
              onClick={() => setCurrentToggle(ToggleTypesChangesHistory.emg)}>
              {t('configurator:component.device_config.emg', 'EMG')}
            </ToggleButton>
            <ToggleButton
              value={ToggleTypesChangesHistory.grips}
              key='grips'
              onClick={() => setCurrentToggle(ToggleTypesChangesHistory.grips)}>
              {t('configurator:component.device_config.grips', 'Grips')}
            </ToggleButton>
            <ToggleButton
              value={ToggleTypesChangesHistory.prosthesis}
              key='sequential'
              onClick={() => setCurrentToggle(ToggleTypesChangesHistory.prosthesis)}>
              {t('configurator:component.device_config.prosthesis', 'Prosthesis')}
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonWrapper>
      </HeaderWrapper>
      {changeEntry ? (
        <Wrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'notes' }}>
            {device?.amputee && (
              <Card>
                <PatientDetails deviceInfo={device} />
              </Card>
            )}
            <Card>
              <Accordion header={t('configurator:component.device_config.notes', 'Notes')}>
                <NotesList
                  notes={notes ?? []}
                  handleAdd={handleAddNote}
                  handleDelete={handleDeleteNote}
                  isLoadingAddNote={isLoadingCreateNote}
                />
              </Accordion>
            </Card>
          </ConfigDetailsWrapper>
          <div style={{ gridArea: 'config' }}>
            <ConfigComponent
              config={completeConfig || null}
              transformedConfig={transformAndApply(completeConfig)}
              currentToggle={currentToggle}
            />
          </div>
          <ConfigDetailsWrapper style={{ gridArea: 'version' }}>
            <Card>
              <Header3>{t('configurator:component.device_config.options', 'Options')}</Header3>
              <Button fullWidth onClick={handleOpenTemplateModal}>
                {t('configurator:component.device_config.save_as_template', 'Save as template')}
              </Button>
              <CustomButton
                fullWidth
                color='light'
                onClick={toggleRestorePoint}
                sx={{ marginTop: '16px' }}>
                {changeEntry.restore_point === 0
                  ? t(
                      'configurator:component.device_config.create_restore_point',
                      'Create restore point'
                    )
                  : t(
                      'configurator:component.device_config.unset_restore_point',
                      'Unset as restore point'
                    )}
              </CustomButton>
            </Card>
          </ConfigDetailsWrapper>
        </Wrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DeviceConfigComponent;
