import Tooltip from '@mui/material/Tooltip';
import { AudioMime, ImageMime, PDFMime, VideoMime } from 'adp-panel/constants/mimeTypes';
import { CustomTicketIcon } from 'components/CustomIcon/CustomIcon';
import React, { useRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface AttachmentHandlerProps {
  maxFileSize?: number; // Maximum file size in bytes
  allowedFileTypes?: string[]; // List of allowed MIME types
  filePreview: boolean;
  onFilesChange?: (files: UploadFileInfo[]) => void;
  attachments: UploadFileInfo[];
}

export type UploadFileStatus = 'Initial' | 'Selected' | 'Uploading' | 'Uploaded' | 'Failed';

export interface UploadFileInfo {
  uid: string;
  name: string;
  extension?: string;
  size?: number;
  validationErrors?: Array<string>;
  status: UploadFileStatus;
  progress: number;
  getRawFile?: () => File;
}

const AttachmentHandler: React.FC<AttachmentHandlerProps> = ({
  maxFileSize = 1,
  allowedFileTypes = [...PDFMime, ...ImageMime, ...VideoMime, ...AudioMime],
  onFilesChange,
  filePreview,
  attachments
}) => {
  const { t } = useTranslation();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const maxFileSizeInBytes = maxFileSize * 1000000; // Convert MB to bytes

  const validateFiles = (files: FileList): UploadFileInfo[] => {
    const validatedFiles: UploadFileInfo[] = [];
    for (const file of Array.from(files)) {
      const errors: string[] = [];
      if (file.size > maxFileSizeInBytes) {
        errors.push(
          t('common:validation.file_size_exceeds_limit', { size: maxFileSize, unit: 'MB' })
        );
      }
      if (!allowedFileTypes.some((type) => file.type.match(type))) {
        errors.push(t('common:validation.file_type_not_allowed', { type: file.type }));
      }

      validatedFiles.push({
        uid: uuidv4(),
        name: file.name,
        extension: file.name.split('.').pop(),
        size: file.size,
        validationErrors: errors.length ? errors : undefined,
        status: errors.length ? 'Failed' : 'Selected',
        progress: 0,
        getRawFile: () => file
      });
    }
    return validatedFiles;
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = validateFiles(files);
      onFilesChange?.([...attachments, ...validFiles]);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles = validateFiles(files);
      onFilesChange?.([...attachments, ...validFiles]);
    }
  };

  const triggerImageUpload = () => imageInputRef.current?.click();
  const triggerFileUpload = () => fileInputRef.current?.click();

  return (
    <>
      <Tooltip title={t('dashboard:component.ticket_details.upload_image', 'Reopen image')}>
        <InputAdornment position='end'>
          <IconButton onClick={triggerImageUpload}>
            <CustomTicketIcon name='add-image' />
          </IconButton>
          <input
            type='file'
            ref={imageInputRef}
            style={{ display: 'none' }}
            accept={ImageMime.join(',')}
            multiple
            onChange={handleImageUpload}
          />
        </InputAdornment>
      </Tooltip>

      <Tooltip title={t('dashboard:component.ticket_details.upload_file', 'Upload file')}>
        <InputAdornment position='end'>
          <IconButton onClick={triggerFileUpload}>
            <CustomTicketIcon name='add-file' />
          </IconButton>
          <input
            type='file'
            ref={fileInputRef}
            accept={[...PDFMime, ...AudioMime, ...VideoMime].join(',')}
            style={{ display: 'none' }}
            multiple
            onChange={handleFileUpload}
          />
        </InputAdornment>
      </Tooltip>

      {filePreview && <div>todo</div>}
    </>
  );
};

export default AttachmentHandler;
