import React, { useMemo } from 'react';
import { Alert } from '@mui/material';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import {
  genericSwitchingMap,
  genericSwitchingReversedMap,
  configSettingsNamesMap,
  gripSwitchingMap
} from 'configurator/utils/definesLocal';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';
import { useTranslation } from 'react-i18next';

const FollowingGrip = ({
  addHistoryProsthesis,
  followingGrip,
  replayIsEnabled,
  setConfigProperty,
  gripSwitchingMode,
  disabled
}: any) => {
  const { t } = useTranslation();
  const handleFollowingGrip = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('followingGrip', [genericSwitchingReversedMap.get(value)])
    );
  };
  const warningFollowingGrip = useMemo(() => {
    if (followingGrip[0] === 1 && gripSwitchingMode[0] === GripSwitchingModes.kHoldOpen) {
      return (
        <Alert severity='error' style={{ marginTop: '10px' }}>
          {t(
            'configurator:component.following_grip.warning_message',
            'Enabling this function might influence behavior of current {{gripSwitchingMode}}: {{gripSwitchingValue}}. Switching it off is preferable.',
            {
              gripSwitchingMode: configSettingsNamesMap.get('gripSwitchingMode'),
              gripSwitchingValue: gripSwitchingMap.get(GripSwitchingModes.kHoldOpen)
            }
          )}
        </Alert>
      );
    }
    return null;
  }, [followingGrip, gripSwitchingMode]);

  const FollowingGrip = useMemo(
    () => (
      <InputOptions
        header={t(configSettingsNamesMap.get('followingGrip') || '')}
        options={[
          'configurator:constants.generic_switching.on',
          'configurator:constants.generic_switching.off'
        ]}
        id='following-grip'
        onChange={handleFollowingGrip}
        disable={disabled}
        // @ts-ignore
        value={genericSwitchingMap.get(followingGrip[0])}
        description={t(
          'configurator:component.following_grip.description',
          'Enable or disable following grip'
        )}
        warning={warningFollowingGrip}
        tooltip={t('configurator:component.following_grip.tooltip', 'Hello')}
      />
    ),
    [followingGrip, gripSwitchingMode, disabled]
  );

  return FollowingGrip;
};

export default FollowingGrip;
