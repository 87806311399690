// Delay between signals is currently not used

export const singleElectrodeSettingsAlternatingPositions = {
  stateSwitchDelay: 0,
  maximumTimeBetweenPulses: 2,
  minimumTimeBetweenPulses: 3,
  minimumPulseTime: 4,
  maximumPulseTime: 5,
  delayBetweenSignals: 6
};

export const freezeModeEmgPositions = {
  closing: 0,
  opening: 1,
  detectorActivatingType: 2,
  detectorDeactivatingType: 3
};
