import { CircularProgress, Modal, Typography } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { Trans, useTranslation } from 'react-i18next';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { ModalStyle } from 'adp-panel/components/Modals/styles';
import BluetoothWebController from 'configurator/bluetooth-handler/bluetoothWeb';
import useNavbarConnect from 'configurator/hooks/bluetooth/useNavbarConnect';
import Card from 'adp-panel/components/Card/Card';
import SouthIcon from '@mui/icons-material/South';

const bluetooth = new BluetoothWebController();

const PairingModal = ({ handleModalClose }) => {
  const { t } = useTranslation('configurator');
  const { handleBluetooth } = useNavbarConnect();

  const handleConfirm = async () => {
    const pairedJSON = localStorage.getItem('paired');
    const paired = pairedJSON ? JSON.parse(pairedJSON) : [];
    const bluetoothId = await bluetooth.getBluetoothId();
    localStorage.setItem('paired', JSON.stringify([...paired, bluetoothId]));
    handleModalClose();
    handleBluetooth(bluetoothId);
  };

  const handleTryAgain = () => {
    handleBluetooth();
  };

  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper title={t('modals.pairing.title')}>
        <Card style={{ position: 'fixed', right: '20px', bottom: '20vh' }}>
          <Typography>
            <Trans i18nKey='modals.pairing.allow' t={t} />
          </Typography>
          <SouthIcon
            sx={{
              position: 'absolute',
              right: '10%',
              transform: 'translateX(-50%)',
              top: '30px',
              left: '50%',
              fontSize: '70px',
              color: '#FFF'
            }}
          />
        </Card>
        <div>
          <FormWrapper>
            <Typography>{t('modals.pairing.text')}</Typography>
            <Typography>{t('modals.pairing.text_2')}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CircularProgress size={60} />
            </div>
            <FormButtonsWrapper>
              <CustomButton
                data-testid='confirm-try-cancel'
                type='button'
                color='light'
                onClick={handleModalClose}>
                Cancel
              </CustomButton>
              <CustomButton
                data-testid='confirm-try-again'
                type='button'
                onClick={handleTryAgain}
                color='lightBlue'>
                Try again
              </CustomButton>
              <CustomButton data-testid='confirm-pairing' type='button' onClick={handleConfirm}>
                Confirm
              </CustomButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </div>
      </FormContentWrapper>
    </Modal>
  );
};

export default PairingModal;
