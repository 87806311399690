import CustomTextField from 'components/FormFields/CustomTextField';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import CustomLanguageSelect from 'components/FormFields/CustomLanguageSelect';
import { SectionHeader } from './styled';
import { useTranslation } from 'react-i18next';
import CustomPhoneField from 'components/FormFields/CustomPhoneFIeld';
import FormCheckbox from 'components/FormFields/FormCheckbox';
import PasswordStrength from 'configurator/components/atoms/PasswordStrength/PasswordStrength';

const BasicInfo = ({ control, watch, invitedUser = false }) => {
  const { t } = useTranslation('auth');

  const passwordWatch = watch('password');

  const openTermsPdf = () => {
    const pdfUrl =
      'https://aether-prod-bucket.s3.amazonaws.com/documents/POL-9_Terms_of_Service_of_Aether_Digital_Platform_v_2.pdf';
    window.open(pdfUrl, '_blank');
  };

  const openPolicyPdf = () => {
    const pdfUrl =
      'https://aether-prod-bucket.s3.us-east-1.amazonaws.com/documents/POL-8_Privacy_Policy_of_Aether_Digital_Platform_v_2.pdf';
    window.open(pdfUrl, '_blank');
  };
  return (
    <>
      <SectionHeader>{t('auth:component.register.subtitle', 'Basic Info')}</SectionHeader>
      <CustomTextField label={t('auth:register.form.name', 'Name')} id='name' control={control} />
      <CustomTextField
        label={t('auth:register.form.password', 'E-mail')}
        id='email'
        control={control}
        disabled={invitedUser}
      />
      {!invitedUser && (
        <CustomPhoneField
          label={t('account:component.account_details.form.phone_number', 'Phone number')}
          id='phone'
          noEndAdornment
          control={control}
        />
      )}
      <CustomPasswordField
        label={t('auth:component.register.form.password', 'Password')}
        id='password'
        control={control}
        noValidationMessage
      />
      <PasswordStrength password={passwordWatch} minLength={8} numbers specialChars upperCase />
      <CustomPasswordField
        label={t('auth:component.register.form.repassword', 'Re-type Password')}
        id='rePassword'
        control={control}
      />
      <CustomLanguageSelect
        id='language'
        label={t('account:component.register.form.language', 'Language')}
        control={control}
        optional={false}
        showLabel={true}
        loading={false}
        filterOptions={[{ code: 'en' }, { code: 'pl' }]}
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            {t('account:component.register.form.agree', 'I agree')}{' '}
            <span style={{ textDecoration: 'underline' }} onClick={openTermsPdf}>
              {t('account:component.register.form.terms_link', 'Terms of Service')}
            </span>
          </span>
        }
        id='termsOfService'
        control={control}
        shouldPreventChange
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            {t('account:component.register.form.agree', 'I agree')}{' '}
            <span style={{ textDecoration: 'underline' }} onClick={openPolicyPdf}>
              {t('account:component.register.form.terms_link', 'Private Policy')}
            </span>
          </span>
        }
        id='privacyPolicy'
        control={control}
        shouldPreventChange
      />
    </>
  );
};

export default BasicInfo;
