import * as Sentry from '@sentry/react';

import { useCreateInvitations } from 'adp-panel/hooks/api/useUsers';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useState } from 'react';
import { UserRoles } from 'types';
import { InvitationType, UserCreatePayload } from 'adp-panel/api/users/users.types';

export const useInviteUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: inviteUsers } = useCreateInvitations();

  const handleSubmit = async ({
    patient_id,
    invitations
  }: {
    patient_id: string;
    invitations: InvitationType[];
  }) => {
    try {
      setIsLoading(true);
      return await inviteUsers({ patient_id, invitations });
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to invite clinicians');
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading };
};
