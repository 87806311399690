import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import {
  configSettingsNamesMap,
  inputDeviceMap,
  inputDeviceReversedMap
} from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';

const InputOptionsEMG = ({
  addHistoryProsthesis,
  inputDevice,
  inputOptionsPermission,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const [inputDeviceValue] = inputDevice;
  const { t } = useTranslation();
  const handleInputOptions = (type: any, value: any) => {
    addHistoryProsthesis(setConfigProperty('inputDevice', [inputDeviceReversedMap.get(value)]));
  };
  const InputOptionsEMG = useMemo(
    () => (
      <InputOptions
        disable={replayIsEnabled || !inputOptionsPermission}
        header={t(configSettingsNamesMap.get('inputDevice') || '')!}
        options={Array.from(inputDeviceMap, ([, value]) => value)}
        onChange={handleInputOptions}
        id='options'
        value={inputDeviceMap.get(inputDeviceValue)}
        tooltip={t('configurator:component.input_option_emg.tooltip', 'Hello')}
      />
    ),
    [inputOptionsPermission, replayIsEnabled, inputDevice]
  );

  return InputOptionsEMG;
};

export default InputOptionsEMG;
