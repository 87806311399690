import Card from 'adp-panel/components/Card/Card';
import { Tooltip, Typography } from '@mui/material';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as ProfileSvg } from 'assets/profile.svg';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import { myTheme } from 'theme/theme';
import CustomAvatar from '../CustomAvatar/CustomAvatar';
import { shortenString } from 'utils/utils';
import { PatientCardWrapper, ResponsiveContainer } from './styled';

const PatientCard = ({
  name,
  serial,
  handleConfigure,
  handleProfile,
  handleSession,
  loadingSession,
  disableConnect
}: {
  name: string;
  serial?: any[];
  handleConfigure: () => void;
  handleProfile: () => void;
  handleSession: () => void;
  loadingSession: boolean;
  disableConnect: boolean;
}) => {
  const { t } = useTranslation('patients');

  const noDevice = serial?.length === 0;
  return (
    <PatientCardWrapper
      style={{
        backgroundColor: myTheme.palette.background,
        borderRadius: '15px',
        border: '2px solid #EAECF0',
        padding: '10px'
      }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomAvatar name={name} big />
        <div>
          <Typography variant='h6' sx={{ fontWeight: '600' }}>
            {shortenString(name, 30)}
          </Typography>
          {serial?.map((item) => (
            <Typography variant='body2' key={item.serial}>
              {t('patients:patient_card.device_id', 'Device SN')}: {item.serial}
            </Typography>
          ))}
        </div>
      </div>
      <ResponsiveContainer>
        <Tooltip
          title={
            disableConnect
              ? t(
                  'patients:details.start_local_session.other.tooltip',
                  'You are connected with other device. Disconnect first to switch.'
                )
              : ''
          }>
          <span>
            <CustomButton
              Icon={CogSvg}
              onClick={handleConfigure}
              disabled={noDevice || disableConnect}>
              {t('patients:patient_card.configure_zeus', 'Configure Zeus')}
            </CustomButton>
          </span>
        </Tooltip>
        <CustomButton Icon={ProfileSvg} color='light' onClick={handleProfile}>
          {t('patients:patient_card.profile', 'Profile')}
        </CustomButton>
        <Tooltip
          title={
            disableConnect
              ? t(
                  'patients:details.start_local_session.other.tooltip',
                  'You are connected with other device. Disconnect first to switch.'
                )
              : ''
          }>
          <span>
            <CustomButton
              Icon={ComputerSvg}
              color='light'
              onClick={handleSession}
              disabled={noDevice || disableConnect}
              loading={loadingSession}>
              {t('patients:patient_card.start_remote_session', 'Start Remote Session')}
            </CustomButton>
          </span>
        </Tooltip>
      </ResponsiveContainer>
    </PatientCardWrapper>
  );
};

export default PatientCard;
