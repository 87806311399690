import React from 'react';
import IconsSidebar from 'configurator/components/organisms/IconsSidebar.js/IconsSidebar';
import DefaultLayout from 'configurator/layouts/DefaultLayout';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';

interface CommonProps {
  component: React.ComponentType<any>;
  handleUndo?: any;
  isUnsaved?: boolean;
  children?: React.ReactNode;
  showSidebar?: boolean;
  isStandard?: any;
}

type LayoutSwitcherProps =
  | (CommonProps & { header: string; tooltipText: string })
  | (CommonProps & { header?: undefined | null; tooltipText?: undefined });

const LayoutSwitcher = ({
  component: Component,
  header = null,
  handleUndo = null,
  isUnsaved = false,
  children = null,
  showSidebar = true,
  tooltipText = 'placeholder'
}: LayoutSwitcherProps) => {
  return (
    <DefaultLayout>
      {showSidebar && <IconsSidebar undoFunc={handleUndo} unsaved={isUnsaved} />}
      {header && <CustomPageHeader header={header} tooltipText={tooltipText} />}
      {children ? children : <Component />}
    </DefaultLayout>
  );
};

export default LayoutSwitcher;
