import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { speedControlMap, speedControlReversedMap } from 'configurator/utils/definesLocal';
import { useTranslation } from 'react-i18next';

const SpeedControlStrategy = ({
  addHistoryProsthesis,
  speedControlStrategy,
  speedStrategyPermission,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const [speedControlStrategyValue] = speedControlStrategy;
  const { t } = useTranslation();
  const handleSpeedControl = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('speedControlStrategy', [speedControlReversedMap.get(value)])
    );
  };

  const SpeedControlStrategy = useMemo(
    () => (
      <InputOptions
        header={t('configurator:component.speed_control_strategy.header', 'Speed control strategy')}
        data-tour='strategy'
        options={[
          'configurator:constants.speed_control.one_speed',
          'configurator:constants.speed_control.proportional'
        ]}
        id='strategy'
        onChange={handleSpeedControl}
        disable={replayIsEnabled || !speedStrategyPermission}
        // @ts-ignore
        value={speedControlMap.get(speedControlStrategyValue)}
        description={t(
          'configurator:component.speed_control_strategy.description',
          'Mode in which you operate the prosthesis'
        )}
        tooltip={t('configurator:component.speed_control_strategy.tooltip', 'Hello')}
      />
    ),
    [speedControlStrategy, speedStrategyPermission]
  );

  return SpeedControlStrategy;
};

export default SpeedControlStrategy;
