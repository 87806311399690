import {
  GripSwitchingModes,
  SingleElectrodeMode,
  SpeedControlStrategies
} from 'bluetooth/Bluetooth/Control';
import {
  DeviceConfigTemplate,
  emgThresholdsEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import toast from 'react-hot-toast';
import i18next from 'i18next';

export const checkBatteryBeepEmergency = (
  currentConfig: DeviceConfigTemplate,
  differenceAllowedPercentage = 5,
  batteryFunctionVoltageToPercent,
  batteryFunctionPercentToVoltage
) => {
  let triggered = false;
  let batteryBeep = currentConfig?.batteryBeep;
  const emergencyBatterySettings = currentConfig?.emergencyBatterySettings;
  const batteryBeepVoltage = batteryBeep?.[0];
  const batteryBeepStatus = batteryBeep?.[1];
  const emergencyVoltage = emergencyBatterySettings?.[2];

  const batteryPercentageBeep = batteryFunctionVoltageToPercent(batteryBeepVoltage);
  const batteryPercentageEmergency = batteryFunctionVoltageToPercent(emergencyVoltage);
  const batteryBeepDifferencePercentage = batteryPercentageBeep - batteryPercentageEmergency;

  if (
    batteryBeepDifferencePercentage < differenceAllowedPercentage &&
    batteryBeep &&
    batteryBeepStatus === 1
  ) {
    const newBatteryBeepVoltage = batteryFunctionPercentToVoltage(
      batteryPercentageEmergency + differenceAllowedPercentage
    );

    batteryBeep = [newBatteryBeepVoltage, batteryBeep[1]];
    triggered = true;
  }

  return { adjustedSetting: batteryBeep, triggered };
};

export const checkEmgValidity = (config: DeviceConfigTemplate, emgSettings: emgThresholdsEntry) => {
  let changeSignalOpen = emgSettings[0];
  let changeSignalClose = emgSettings[1];
  let speed1Opening = emgSettings[2];
  let speed2Opening = emgSettings[3];
  let speed3Opening = emgSettings[4];
  let speed1Closing = emgSettings[5];
  let speed2Closing = emgSettings[6];
  let speed3Closing = emgSettings[7];

  if (typeof config.speedControlStrategy[0] === 'boolean') return;

  const speedControlStrategy = config.speedControlStrategy[0];
  const gripSwitchingMode = config.gripSwitchingMode[0];
  const singleElectrodeMode = config?.singleElectrodeMode?.[0];
  const singleElectrodeModeSettings = config?.singleElectrodeModeSettings;
  const startPointSignalThresholds = singleElectrodeModeSettings?.[0];
  const freezeModeRelaxationClosing = config?.freezeModeEmg?.[0];
  const freezeModeRelaxationOpening = config?.freezeModeEmg?.[1];
  const freezeModeEmgStatus = config?.freezeModeEmg?.[2];

  if (
    singleElectrodeMode !== undefined &&
    singleElectrodeMode === SingleElectrodeMode.kFastRising &&
    gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching
  ) {
    let triggered = false;
    if (speed1Opening <= startPointSignalThresholds) {
      speed1Opening = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (changeSignalOpen <= startPointSignalThresholds) {
      changeSignalOpen = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (changeSignalClose <= startPointSignalThresholds) {
      changeSignalClose = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (speed1Closing <= startPointSignalThresholds) {
      speed1Closing = startPointSignalThresholds + 1;
      triggered = true;
    }

    if (triggered)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.start_point_signal_threshold',
          'Start point signal threshold must be lower than CS/activation open/close'
        ),
        {
          id: 'startPointWarning'
        }
      );
  }

  if (freezeModeRelaxationClosing !== undefined && freezeModeEmgStatus !== 0) {
    let triggered = false;
    if (speed1Opening <= freezeModeRelaxationOpening) {
      speed1Opening = freezeModeRelaxationOpening === 100 ? 100 : freezeModeRelaxationOpening + 1;
      triggered = true;
    }
    if (speed1Closing <= freezeModeRelaxationClosing) {
      speed1Closing = freezeModeRelaxationClosing === 100 ? 100 : freezeModeRelaxationClosing + 1;
      triggered = true;
    }

    if (triggered)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.freeze_mode_emg',
          'Freeze mode relaxation thresholds must be lower than EMG activation thresholds'
        ),
        {
          id: 'freezeModeEmgRelaxationWarning'
        }
      );
  }

  // Correct wrong values
  if (speed1Opening > speed2Opening) {
    speed2Opening = speed1Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.speed_2_bigger_than_speed_1',
          "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
        ),
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1'
        }
      );
  }

  if (speed2Opening > speed3Opening) {
    speed3Opening = speed2Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.speed_3_bigger_than_speed_2',
          "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
        ),
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2'
        }
      );
  }
  if (speed1Closing > speed2Closing) {
    speed2Closing = speed1Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.speed_2_bigger_than_speed_1',
          "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
        ),
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1'
        }
      );
  }
  if (speed2Closing > speed3Closing) {
    speed3Closing = speed2Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        i18next.t(
          'configurator:emg_settings.warning.speed_3_bigger_than_speed_2',
          "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
        ),
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2'
        }
      );
  }

  const validatedEmg = [
    changeSignalOpen,
    changeSignalClose,
    speed1Opening,
    speed2Opening,
    speed3Opening,
    speed1Closing,
    speed2Closing,
    speed3Closing,
    emgSettings[8],
    emgSettings[9]
  ];
  return validatedEmg as emgThresholdsEntry;
};
