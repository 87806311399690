import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { FormHelperText, FormControlLabel, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { myTheme } from 'theme/theme';

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: myTheme.palette.primary,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: myTheme.palette.primary
        })
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: myTheme.palette.primary,
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600]
      })
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3
      })
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D'
    })
  }
}));

const FormSwitch = ({ id, label, control, style = {} }) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <div style={style}>
        <FormControl
          {...field}
          error={Boolean(fieldState.error)}
          component='fieldset'
          variant='standard'>
          <FormControlLabel
            label={label}
            sx={{
              marginLeft: 0,
              marginRight: 0,
              '& .MuiFormControlLabel-label': {
                marginLeft: '10px'
              }
            }}
            control={<CustomSwitch id={id} checked={field.value} />}
          />
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      </div>
    )}
  />
);

export default FormSwitch;
