import Card from 'adp-panel/components/Card/Card';

const LoaderWrapper = ({
  children,
  full,
  style
}: {
  children: React.ReactNode;
  full?: boolean;
  style?: any;
}) => (
  <Card>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(full && { height: '80vh' }),
        ...style
      }}>
      {children}
    </div>
  </Card>
);

export default LoaderWrapper;
