import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { CheckMfaPayload, MfaOptions } from 'adp-panel/api/authentication/authentication.types';
import { UserEntry } from 'adp-panel/api/users/users.types';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { phoneValidatorRequired } from 'adp-panel/components/FormInput/validators';
import VerifyPhoneModal from 'adp-panel/components/Modals/VerifyPhoneModal';
import { useModal } from 'adp-panel/hooks/api/useModal';
import { ME_QUERY_KEY, USER_QUERY_KEY } from 'adp-panel/hooks/api/useUsers';
import { Inputs } from 'adp-panel/pages/Account/AccountDetails';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import CustomLanguageSelect, {
  languages,
  LanguageType
} from 'components/FormFields/CustomLanguageSelect';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import CustomPhoneField from 'components/FormFields/CustomPhoneFIeld';
import CustomTextField from 'components/FormFields/CustomTextField';
import FormRadioGroup from 'components/FormFields/FormRadioGroup';
import FormSwitch from 'components/FormFields/FormSwitch';
import { ACCOUNT_CHANGE_PASSWORD } from 'constants/routes';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { NotificationFactory } from 'lib/NotificationFactory';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { myTheme } from 'theme/theme';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { GroupedFormAction } from './styled';

const accountDetailsSchema = yup.object({
  name: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  clinicName: yup
    .string()
    .optional()
    .max(100, ({ max, value }) => ({
      key: VALIDATOR_TEXT.MAX_LENGTH,
      values: { max, currentLength: value.length, exceededBy: value.length - max }
    }))
    .nullable(),
  clinicLocation: yup
    .string()
    .optional()
    .max(100, ({ max, value }) => ({
      key: VALIDATOR_TEXT.MAX_LENGTH,
      values: { max, currentLength: value.length, exceededBy: value.length - max }
    }))
    .nullable(),
  addressLine1: yup
    .string()
    .optional()
    .max(100, ({ max, value }) => ({
      key: VALIDATOR_TEXT.MAX_LENGTH,
      values: { max, currentLength: value.length, exceededBy: value.length - max }
    })),
  addressLine2: yup
    .string()
    .optional()
    .max(100, ({ max, value }) => ({
      key: VALIDATOR_TEXT.MAX_LENGTH,
      values: { max, currentLength: value.length, exceededBy: value.length - max }
    })),
  language: yup
    .object({
      code: yup
        .string()
        .typeError(VALIDATOR_TEXT.REQUIRED)
        .oneOf(['pl', 'en', 'ua', 'ru', 'es', 'de', 'it'])
    })
    .nonNullable(VALIDATOR_TEXT.REQUIRED),
  phone: yup.string().when('mfaMethod', {
    is: (val: string) => {
      return val === MfaOptions.sms;
    },
    then: (s) => s.required(VALIDATOR_TEXT.REQUIRED),
    otherwise: (s) => s
  })
});

const AccountDetailsForm = ({
  onSubmit,
  accountDetails,
  mfaStatus,
  updateMfa,
  updateUserPhone,
  isLoadingUpdate
}: {
  onSubmit: SubmitHandler<Inputs>;
  accountDetails: UserEntry;
  mfaStatus: CheckMfaPayload;
  updateUserPhone: Function;
  updateMfa: Function;
  isLoadingUpdate: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('account');
  const queryClient = useQueryClient();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const checkMethod = (mfaStatus: CheckMfaPayload) => {
    if (!mfaStatus.enabled) {
      if (mfaStatus.phone) {
        return MfaOptions.sms;
      } else {
        return MfaOptions.email;
      }
    } else {
      return mfaStatus.method;
    }
  };

  const handlePhoneVerify = async (phone: string) => {
    try {
      await updateUserPhone({ userId: accountDetails.id, phone });
      // Fallback to email 2fa when phone is changed
      if (mfaStatus.enabled === 1 && mfaStatus.method === MfaOptions.sms) {
        await updateMfa({ enabled: mfaStatus.enabled, method: MfaOptions.email });
        NotificationFactory.successNotification(
          t(
            'notifications:component.account_details.phone_changed_fallback_text',
            'Your 2fa method has been changed to email, because you have changed phone number'
          )
        );
      }
      handleModalOpen();
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    } finally {
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    }
  };

  const goToChangePassword = () => {
    navigate(ACCOUNT_CHANGE_PASSWORD);
  };

  const transformInitial = (
    accountDetailsResponse: UserEntry,
    mfaResponse: CheckMfaPayload
  ): Inputs => {
    return {
      name: accountDetailsResponse?.name,
      email: accountDetailsResponse?.email,
      password: 'password',
      mfaEnabled: mfaResponse?.enabled !== 0,
      mfaMethod: checkMethod(mfaStatus),
      phone: accountDetailsResponse.phone,
      clinicName: accountDetailsResponse.clinic_name,
      clinicLocation: accountDetailsResponse.clinic_location,
      addressLine1: accountDetailsResponse?.address1,
      addressLine2: accountDetailsResponse?.address2,
      language: languages.find(
        (language: LanguageType) => language.code === accountDetailsResponse.language
      )
    };
  };

  const {
    control,
    handleSubmit: handleSubmitAccountDetails,
    watch,
    formState,
    setValue
  } = useForm<Inputs>({
    defaultValues: transformInitial(accountDetails, mfaStatus),
    mode: 'onChange',
    shouldUnregister: false,
    // @ts-ignore
    resolver: yupResolver(accountDetailsSchema)
  });

  const isMFAEnabled = watch('mfaEnabled');
  const phoneNumber = watch('phone');

  const phoneVerified = accountDetails?.phone_verified_at && phoneNumber === accountDetails?.phone;
  const isPhoneValid =
    phoneNumber !== undefined &&
    phoneNumber.length > 0 &&
    isValidPhoneNumber(phoneNumber) &&
    phoneValidatorRequired(phoneNumber).length === 0;

  useEffect(() => {
    if (!phoneNumber) {
      setValue('mfaMethod', { value: MfaOptions.email });
    }
  }, [phoneNumber, setValue]);

  return (
    <>
      {isModalOpen && <VerifyPhoneModal handleClose={handleModalClose} />}
      <form onSubmit={handleSubmitAccountDetails(onSubmit)}>
        <FormWrapper>
          <CustomTextField
            label={t('account:component.account_details.form.name', 'Name')}
            id='name'
            control={control}
          />
          <CustomTextField
            label={t('account:component.account_details.form.email', 'E-mail')}
            id='email'
            disabled
            control={control}
          />
          <GroupedFormAction>
            <CustomPhoneField
              label={t('account:component.account_details.form.phone_number', 'Phone number')}
              id='phone'
              isValidPhoneNumber={Boolean(phoneVerified)}
              control={control}
            />
            {!phoneVerified && phoneNumber && (
              <CustomButton
                data-testid='verify-phone-numer'
                onClick={() => handlePhoneVerify(phoneNumber)}
                type='button'
                disabled={!isPhoneValid}
                sx={{ height: '40px' }}
                color='lightBlue'>
                {t('account:component.account_details.button.verify_phone', 'Verify')}
              </CustomButton>
            )}
          </GroupedFormAction>
          <GroupedFormAction>
            <CustomPasswordField
              disabled
              label={t('account:component.account_details.form.password', 'Password')}
              id='password'
              control={control}
            />
            <CustomButton
              onClick={goToChangePassword}
              data-testid='change-password'
              type='button'
              sx={{ whiteSpace: 'nowrap', height: '40px' }}
              color='lightBlue'>
              {t('account:component.account_details.button.change_password', 'Change password')}
            </CustomButton>
          </GroupedFormAction>
          <CustomLanguageSelect
            id='language'
            label={t('account:component.account_details.form.language', 'Language')}
            control={control}
            optional={false}
            showLabel={true}
            loading={false}
          />
          <Box sx={{ mt: '16px' }}>
            <Typography
              sx={{
                color: myTheme.palette.headerTitle2,
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px'
              }}>
              {t('account:component.account_details.form.title2', 'Optional fields')}
            </Typography>
          </Box>
          <CustomTextField
            label={t('account:component.account_details.form.clinic_name', 'Clinic name')}
            id='clinicName'
            control={control}
          />
          <CustomTextField
            label={t('account:component.account_details.form.clinic_location', 'Clinic location')}
            id='clinicLocation'
            control={control}
          />
          <CustomTextField
            label={t('account:component.account_details.form.address1', 'Address Line 1')}
            id='addressLine1'
            control={control}
          />
          <CustomTextField
            label={t('account:component.account_details.form.address2', 'Address Line 2')}
            id='addressLine2'
            control={control}
          />
          <Box sx={{ mt: '24px' }}>
            <Typography
              sx={{
                color: myTheme.palette.headerTitle2,
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px'
              }}>
              {t('account:component.account_details.form.2fa_title', 'Two factor authentication')}
            </Typography>
          </Box>
          <FormSwitch
            label={t('account:component.account_details.form.enable_2fa', 'Enable 2fa')}
            id='mfaEnabled'
            control={control}
          />
          {isMFAEnabled && (
            <FormRadioGroup
              label={t('account:component.account_details.form.choose_method', 'Choose method')}
              id='mfaMethod'
              data={
                phoneNumber && phoneVerified
                  ? [
                      {
                        label: t(
                          'account:component.account_details.form.2fa_method.email',
                          'Email'
                        ),
                        value: MfaOptions.email
                      },
                      {
                        label: t(
                          'account:component.account_details.form.2fa_method.sms',
                          'Text message (sms)'
                        ),
                        value: MfaOptions.sms
                      }
                    ]
                  : [
                      {
                        label: t(
                          'account:component.account_details.form.2fa_method.email',
                          'Email'
                        ),
                        value: MfaOptions.email
                      }
                    ]
              }
              control={control}
            />
          )}
          <FormButtonsWrapper>
            {/*<CustomButton*/}
            {/*  data-testid='cancelAccountDetails'*/}
            {/*  type='button'*/}
            {/*  color='light'*/}
            {/*  onClick={() => navigate(-1)}>*/}
            {/*  {t('account:component.account_details.button.cancel', 'Cancel')}*/}
            {/*</CustomButton>*/}
            <LoadingButton
              data-testid='submitAccountDetailsForm'
              type='submit'
              disabled={!formState.isValid || isLoadingUpdate}
              loading={isLoadingUpdate}>
              <span>
                {t('account:component.account_details.button.save_changes', 'Save changes')}
              </span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </form>
    </>
  );
};

export default AccountDetailsForm;
