import * as Sentry from '@sentry/react';

import { NotificationFactory } from 'lib/NotificationFactory';
import { useState } from 'react';
import { RegisterInvitedUserParams } from 'adp-panel/api/authentication/authentication.types';
import { useCreaterInvitedUser } from './useAuthentication';

export const useRegisterInvitedUser = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: registerInvitedUser } = useCreaterInvitedUser();

  const handleSubmit = async (data: RegisterInvitedUserParams) => {
    try {
      setIsLoading(true);
      return await registerInvitedUser(data);
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to register user');
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading };
};
