import { TableLink } from 'adp-panel/components/Links/Links';
import CustomButton from 'components/Button/CustomButton';
import { shortenString } from 'utils/utils';

const MultipleItemsLinks = ({ maxItems = 1, items, link, handleOpen }) => {
  const itemsArray = items?.slice(0, maxItems);
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {itemsArray?.map((item, index) => {
        const notLast = index + 1 !== itemsArray.length;
        const itemName = notLast ? `${item?.name}, ` : item?.name;
        return <TableLink to={`${link}/${item?.id}`}>{shortenString(itemName, 17)}</TableLink>;
      })}
      {items?.length > maxItems && (
        <span>
          <CustomButton
            color='light'
            sx={{ minWidth: 0, width: '36px', height: '36px', marginLeft: '8px' }}
            onClick={() => handleOpen(items)}>
            +{items.length - maxItems}
          </CustomButton>
        </span>
      )}
    </div>
  );
};

export default MultipleItemsLinks;
