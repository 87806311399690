import { VALIDATOR_TEXT } from 'constants/validatorText';
import i18next from 'i18next';
import * as yup from 'yup';

export const emailSchema = yup
  .string()
  .email('Must be a valid email')
  .matches(/@[^.]*\./, 'Must be a valid email');

export const stringSchema = yup.string().trim();

export const isStrongPassword = (value) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  // eslint-disable-next-line no-useless-escape
  const hasSymbole = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
  const isProperLength = value.length >= 8;
  let validConditions = 0;
  const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole, isProperLength];
  // eslint-disable-next-line no-return-assign
  conditions.forEach((condition) => (condition ? (validConditions += 1) : null));
  if (validConditions >= conditions.length) {
    return true;
  }
  return false;
};

export const strongPasswordSchema = yup
  .string()
  .test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
    if (value === undefined) return false;

    return isStrongPassword(value);
  });

export const retypePasswordSchema = yup
  .string()
  .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
    return this.parent.password === value;
  });

export const registerSchema = yup.object().shape({
  email: emailSchema,
  password: strongPasswordSchema,
  retypePassword: yup
    .string()
    .test('passwords-match', 'validation.passwords.must_match', function (value) {
      return this.parent.password === value;
    })
    .required('common:required_validator.required'),
  'privacy-policy': yup.boolean().oneOf([true], 'onboarding:validation.data_privacy_policy'),
  'data-processing-policy': yup
    .boolean()
    .oneOf([true], 'onboarding:validation.data_processing_policy'),
  name: yup.string().required('common:required_validator.required'),
  companyName: yup.string().required('common:required_validator.required')
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('common.required_validator.email')
    .required('common:required_validator.required'),
  password: yup.string().required('common:required_validator.required')
});

export const codeSchema = yup.object().shape({
  code: yup.string().required('common:required_validator.required')
});

export const ADD_DEVICE_ERRORS = {
  DEVICE_ASSIGNED_ANOTHER_CLINICIAN: 'onboarding:errors.device_assigned_another_clinician',
  DEVICE_ASSIGNED_ANOTHER_COMPANY: 'onboarding:errors.device_assigned_another_company',
  ACTIVATION_CODE_INCORRECT: 'onboarding:errors.activation_code_incorrect'
};

export const CUSTOM_ERRORS = {
  DEVICE_NOT_EXIST: 'onboarding:errors.device_not_exist',
  CONNECTION_ERROR: 'onboarding:errors.connection_error'
};

export const MESSAGES = {
  PLEASE_ENTER_CODE: 'onboarding:messages.please_enter_code'
};
