import { VelocityLevelChartRecord } from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';

interface TransformedVelocityLevel {
  group_by: string;
  velocity_open_1: number;
  velocity_open_2: number;
  velocity_open_3: number;
  velocity_close_1: number;
  velocity_close_2: number;
  velocity_close_3: number;
}

const parseNumber = (str: string | undefined): number => (str ? parseInt(str) : 0);

const accumulateItem = (
  accumulator: Record<string, TransformedVelocityLevel>,
  current: VelocityLevelChartRecord
): Record<string, TransformedVelocityLevel> => {
  const key = Number(current.group_by);
  const prevElement = accumulator[key];

  if (prevElement !== undefined) {
    accumulator[key] = {
      group_by: String(key),
      velocity_open_1: parseNumber(current.velocity_open_1) + prevElement.velocity_open_1,
      velocity_open_2: parseNumber(current.velocity_open_2) + prevElement.velocity_open_2,
      velocity_open_3: parseNumber(current.velocity_open_3) + prevElement.velocity_open_3,
      velocity_close_1: parseNumber(current.velocity_close_1) + prevElement.velocity_close_1,
      velocity_close_2: parseNumber(current.velocity_close_2) + prevElement.velocity_close_2,
      velocity_close_3: parseNumber(current.velocity_close_3) + prevElement.velocity_close_3
    };
  } else {
    accumulator[key] = {
      group_by: String(current.group_by),
      velocity_open_1: parseNumber(current.velocity_open_1),
      velocity_open_2: parseNumber(current.velocity_open_2),
      velocity_open_3: parseNumber(current.velocity_open_3),
      velocity_close_1: parseNumber(current.velocity_close_1),
      velocity_close_2: parseNumber(current.velocity_close_2),
      velocity_close_3: parseNumber(current.velocity_close_3)
    };
  }

  return accumulator;
};

const fillHourlyReport = (
  itemsByHour: Record<string, TransformedVelocityLevel>
): TransformedVelocityLevel[] => {
  const hourlyReport: any = [];
  const defaultItem = {
    group_by: '',
    velocity_open_1: 0,
    velocity_open_2: 0,
    velocity_open_3: 0,
    velocity_close_1: 0,
    velocity_close_2: 0,
    velocity_close_3: 0
  };

  for (let i = 0; i <= 23; i++) {
    const item = itemsByHour[i];
    const group_by = `${String(i).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`;

    if (item !== undefined) {
      hourlyReport.push({
        ...item,
        group_by
      });
    } else {
      hourlyReport.push({
        ...defaultItem,
        group_by
      });
    }
  }
  return hourlyReport;
};

const transformToOpenGraphKeysByHours = (items: TransformedVelocityLevel[]) =>
  items.map((item: TransformedVelocityLevel) => ({
    group_by: item.group_by,
    'Speed 1': item.velocity_open_1,
    'Speed 2': item.velocity_open_2,
    'Speed 3': item.velocity_open_3
  }));

const transformToCloseGraphKeysByHours = (items: TransformedVelocityLevel[]) =>
  items.map((item: TransformedVelocityLevel) => ({
    group_by: item.group_by,
    'Speed 1': item.velocity_close_1,
    'Speed 2': item.velocity_close_2,
    'Speed 3': item.velocity_close_3
  }));

const transformToOpenGraphKeys = (items: VelocityLevelChartRecord[]) => [
  items.reduce(
    (acc, item: VelocityLevelChartRecord) => {
      acc['Speed 1'] += Number(item.velocity_open_1);
      acc['Speed 2'] += Number(item.velocity_open_2);
      acc['Speed 3'] += Number(item.velocity_open_3);
      return acc;
    },
    {
      group_by: '',
      'Speed 1': 0,
      'Speed 2': 0,
      'Speed 3': 0
    }
  )
];

const transformToCloseGraphKeys = (items: VelocityLevelChartRecord[]) => [
  items.reduce(
    (acc, item: VelocityLevelChartRecord) => {
      acc['Speed 1'] += Number(item.velocity_close_1);
      acc['Speed 2'] += Number(item.velocity_close_2);
      acc['Speed 3'] += Number(item.velocity_close_3);
      return acc;
    },
    {
      group_by: '',
      'Speed 1': 0,
      'Speed 2': 0,
      'Speed 3': 0
    }
  )
];

const velocityLevelMapperByHours = (items: VelocityLevelChartRecord[]) => {
  const itemsByHour = items.reduce(accumulateItem, {});
  const reportByHours = fillHourlyReport(itemsByHour);

  return {
    open: transformToOpenGraphKeysByHours(reportByHours),
    close: transformToCloseGraphKeysByHours(reportByHours)
  };
};

export const velocityLevelOpenMapper = (items: VelocityLevelChartRecord[]) => {
  return transformToOpenGraphKeys(items);
};
export const velocityLevelCloseMapper = (items: VelocityLevelChartRecord[]) => {
  return transformToCloseGraphKeys(items);
};

export default velocityLevelMapperByHours;
