/* eslint-disable no-async-promise-executor */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { produce } from 'immer';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import { isEmpty, uniqueId } from 'lodash';
import { devtools, persist } from 'zustand/middleware';
import { Grips } from 'bluetooth/Bluetooth/Grips';
import {
  commonConfigProperties,
  defaultCommonConfig,
  defaultConfig,
  defaultModeConfig,
  modeConfigProperties
} from 'configurator/consts/deviceConfig/deviceConfig';
import {
  DeviceConfigTemplate,
  ModeConfigTemplate,
  CommonConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import {
  ConfigToSendFunctionMapping,
  getAllHashes,
  getDeviceConfigurations,
  getDevicesInfo,
  getSettingsHash,
  postAppReceivedProcedure,
  postCommunicateMode,
  postRtcTime,
  postSaveSettings,
  runProcedure,
  telemetryEnabled
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import BluetoothWebController from 'configurator/bluetooth-handler/bluetoothWeb';
import {
  getDeviceConfig,
  getDeviceHashes,
  getFirmwareKeys,
  updateDeviceHashes
} from 'configurator/api/device/device';
import { CalibrationEvents } from 'configurator/utils/LiveConfigurator/events';
import { timeoutCommandCustom } from 'configurator/utils/funcs';
import { listenAblyReply } from 'configurator/utils/LiveConfigurator/AblyHandlers';
import { ablyClient } from 'configurator/utils/LiveConfigurator/AblyClient';
import { Commands } from 'bluetooth/Bluetooth/Defines';
import { getTicketConfig } from 'configurator/api/tickets/tickets';
import { getModesConfigForDevice } from 'configurator/api/modes/modes';
import { MODALS } from 'configurator/views/Modals';
import {
  CALIBRATION_PROCEDURE_TIMEOUT,
  FETCHING_STATES,
  HISTORY_EVENTS
} from 'configurator/consts/consts';
import { EMG_SPIKE_WARNING, SINGLE_ALTERNATING_TIMINGS } from 'configurator/consts/notifications';
import { initialState as initialStateDeviceInfo, useDeviceInfoStore } from './deviceInfoStore';
import { compareConfigs, testClosingProcedure } from './helpers/bluetoothHelpers';
import { useUiStore } from './uiStore';
import { useReplayStore } from './replayStore';
import { useSettingsStore } from './settingsStore';
import { areAllKeysUninitialized } from './helpers';
import {
  controlConfigModifier,
  freezeModeEmgModifier,
  singleElectrodeModeModifier,
  singleElectrodeModeSettingsModifier,
  speedControlStrategyModifier,
  freezeModeValue,
  emergencyBatterySettingsAfterModifier,
  inputDeviceAfterModifier,
  singleElectrodeModeAfterModifier,
  batteryBeepAfterModifier,
  singleElectrodeSettingsAlternatingAfterModifier
} from './helpers/configModifiers';
import {
  getCurrentConfigApiSelector,
  getCurrentConfigSelector,
  getFwVersionSelector
} from './helpers/selectors';
import { EVENTS } from 'configurator/consts/events';
import i18next from 'i18next';
import { getFeatureToggles } from 'adp-panel/api/productFeature/productFeature';
import { HASH } from 'adp-panel/constants/featureToggles';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';

const bluetooth = new BluetoothWebController();
const undoChannel = new BroadcastChannel('undo');

export type SetConfigPropertyType = <T extends keyof DeviceConfigTemplate>(
  property: T,
  value: DeviceConfigTemplate[T]
) => void;

type ModeType = {
  config: ModeConfigTemplate;
  configAPI: ModeConfigTemplate | null;
  slot: number;
  name: string;
  id: number | null;
  active: 0 | 1 | 2 | undefined;
};

export type ConfigStoreState = {
  config: {
    common: {
      config: CommonConfigTemplate;
      configAPI: CommonConfigTemplate | null;
    };
    modes: ModeType[];
  };
  currentGrip: Grips;
  handMovementAllowed: boolean;
  procedureReply: number[] | null;
  procedureUsedType: ProcedureTypes | null;
  currentlyRunningProcedure: ProcedureTypes | null;
  configConflict: boolean;
  slotSelected: number;
  configHistory: HistoryEntryType[];
  configCopy: any;
  localConfigFetched: boolean;
  firstConnection: boolean | null;
  initialConfigState: FETCHING_STATES;
  commonPropertiesAPI: Array<keyof DeviceConfigTemplate> | null;
  modePropertiesAPI: Array<keyof DeviceConfigTemplate> | null;
  sendingQueue: Array<any> | [];
  setItemConfigStore: <T extends keyof ConfigStoreState>(
    property: T,
    value: ConfigStoreState[T]
  ) => void;
  addConfigHistory: (event: HISTORY_EVENTS, previousState) => void;
  setConfigCopy: () => void;
  setConfigProperty: SetConfigPropertyType;
  getInitialConfig: () => Promise<any>;
  getInitialConfigAPI: () => Promise<any>;
  setControlConfig: (newControlConfig) => void;
  disconnectDevice: () => Promise<any>;
  connectDevice: ({ bluetoothId }: { bluetoothId: string | null }) => Promise<any>;
  importConfig: ({
    common,
    modes
  }: {
    common: any;
    modes: { slot: number; config: any }[] | { id: number; config: any }[] | null;
  }) => void;
  importBackupConfig: ({ config, t }: { config: any; t: any }) => void;
  handleProcedure: ({
    procedureNumber,
    liveSession,
    preventInput,
    showPreventInputMessage
  }: {
    procedureNumber: ProcedureTypes;
    liveSession?: liveSessionProps;
    preventInput?: boolean;
    showPreventInputMessage?: boolean;
  }) => Promise<any>;
  geTicketConfigApi: () => Promise<any>;
  sendWholeConfigDevice: ({
    configToSend,
    sendPermanently
  }: {
    configToSend: any;
    sendPermanently?: boolean;
  }) => Promise<any>;
  resetGripPositions: (grip: Grips) => void;
  consumeHistory: (idOrEvent: HISTORY_EVENTS | number) => void;
  clearConfigHistory: () => void;
  addSendingQueue: (sendObject: any) => number;
  removeSendingQueue: () => void;
};

type liveSessionProps = {
  clinicianUUID: string | undefined;
  channelName: string;
};

type HistoryEntryType = {
  event: HISTORY_EVENTS;
  id: any;
  timestamp: any;
  fromSlot: number;
  diffConfig: {
    common: { after: any; before: any };
    modes: { id: number; slot: number; name: string; after: any; before: any }[];
  };
};

const initialStateConfigStore = {
  config: {
    common: { config: defaultCommonConfig, configAPI: null },
    modes: [
      {
        config: defaultModeConfig,
        configAPI: null,
        slot: 0,
        name: 'Default',
        id: null,
        active: 1 as 0 | 1 | 2 | undefined
      }
    ]
  },
  commonPropertiesAPI: null,
  modePropertiesAPI: null,
  sendingQueue: [],
  currentGrip: Grips.kGripTypeUnknown,
  handMovementAllowed: false,
  procedureReply: null,
  procedureUsedType: null,
  currentlyRunningProcedure: null,
  configConflict: false,
  slotSelected: 0,
  configHistory: [],
  configCopy: {},
  localConfigFetched: false,
  firstConnection: null,
  initialConfigState: FETCHING_STATES.idle
};

const getInitialConfigAPIFulfilled = (
  set,
  setConfigCopy,
  configAPI,
  commonKeys: null | Array<keyof DeviceConfigTemplate> = null,
  modeKeys: null | Array<keyof DeviceConfigTemplate> = null,
  modesData: null | Array<{ active: 0 | 1; slot: 0 | 1 | 2 }> = null
) => {
  if (
    !areAllKeysUninitialized(configAPI?.common) &&
    configAPI?.common &&
    !areAllKeysUninitialized(configAPI?.modes[0]?.config)
  ) {
    const modes = configAPI.modes.map((modeInfo) => ({
      config: modeInfo.config,
      configAPI: modeInfo.config,
      name: modeInfo.name,
      slot: modeInfo.slot,
      id: modeInfo.id,
      active: modesData?.find((modeData) => modeData.slot === modeInfo.slot)?.active
    }));

    const config = {
      common: {
        config: configAPI.common,
        configAPI: configAPI.common
      },
      modes
    };

    set(
      produce((state: any) => {
        state.config = config;
        state.firstConnection = false;
      }),
      false,
      { type: 'getInitialConfigAPI' }
    );
    setConfigCopy();
  } else {
    if (commonKeys && modeKeys) {
      const adjustedModeConfig = modeKeys.reduce(
        (prev, curr) => ({ ...prev, [`${curr}`]: defaultConfig[`${curr}`] }),
        {}
      );
      const adjustedCommonConfig = commonKeys.reduce(
        (prev, curr) => ({ ...prev, [`${curr}`]: defaultConfig[`${curr}`] }),
        {}
      );
      set(
        produce((state: any) => {
          state.config.common.config = adjustedCommonConfig;
          state.config.modes[0].config = adjustedModeConfig;
        }),
        false,
        { type: 'Adjust default config' }
      );
    }
    set({ firstConnection: true });
    toast(
      i18next.t(
        'configurator:config_store.notification.device_config_retrieved_fail',
        'Device config could not be retrieved, connect the device and send config'
      ),
      {
        icon: '⚠️',
        id: 'deviceConfigRetrievedFailToast'
      }
    );
  }
  useUiStore.setState({ initialConfigApiState: FETCHING_STATES.successful });
};

export const store = (set, get): ConfigStoreState => ({
  ...initialStateConfigStore,
  setItemConfigStore: <T extends keyof ConfigStoreState>(property: T, value: ConfigStoreState[T]) =>
    set({ [`${property}`]: value }),
  setConfigCopy: () =>
    set(
      (state) => ({
        configCopy: state.config
      }),
      false,
      { type: 'configCopy' }
    ),
  addConfigHistory: (event: HISTORY_EVENTS, previousState) => {
    const state = { ...get().config };
    const { slotSelected } = get();

    const commonDifference = {
      before: compareConfigs(state.common.config, previousState.common.config),
      after: compareConfigs(previousState.common.config, state.common.config)
    };

    const modesDifferences: any = [];
    state.modes.forEach((mode) => {
      const previousMode = previousState.modes.find((_mode) => _mode.slot === mode.slot);
      const modeDifference = compareConfigs(mode.config, previousMode.config);
      if (!isEmpty(modeDifference))
        modesDifferences.push({
          id: mode.id,
          slot: mode.slot,
          name: mode.name,
          before: modeDifference,
          after: compareConfigs(previousMode.config, mode.config)
        });
    });

    if (modesDifferences.length === 0 && isEmpty(commonDifference.before)) return;

    const newHistoryEntry: HistoryEntryType = {
      event,
      id: Number(uniqueId()),
      timestamp: Date.now(),
      fromSlot: slotSelected,
      diffConfig: {
        common: commonDifference,
        modes: modesDifferences
      }
    };

    set((state: ConfigStoreState) => ({
      configHistory: [...state.configHistory, newHistoryEntry]
    }));
  },
  setConfigProperty: <T extends keyof DeviceConfigTemplate>(
    property: T,
    value: DeviceConfigTemplate[T]
  ) => {
    const prevState: ConfigStoreState = { ...get() };
    const { firmware, versions } = useDeviceInfoStore.getState();
    const { commonPropertiesAPI, modePropertiesAPI } = get();
    const currentConfig = getCurrentConfigSelector(prevState);
    const deviceInfoState = useDeviceInfoStore.getState();
    let configsAffected = {};
    const { setConfigCopy } = get();

    // @ts-ignore
    switch (property) {
      case 'freezeModeEmg': {
        const { adjustedConfig, triggered } = freezeModeEmgModifier(
          value as freezeModeValue,
          currentConfig as DeviceConfigTemplate,
          firmware,
          versions,
          configsAffected
        );
        configsAffected = adjustedConfig;
        if (triggered)
          toast.error(
            i18next.t(
              'configurator:config_store.notification.freeze_mode_emg_warning',
              'Freeze mode relaxation thresholds must be lower than EMG activation thresholds'
            ),
            {
              id: 'freezeModeEmgRelaxationWarning'
            }
          );
        break;
      }
      case 'singleElectrodeMode': {
        const currentConfig = getCurrentConfigSelector(prevState);
        configsAffected = singleElectrodeModeModifier(value, currentConfig, configsAffected);
        break;
      }
      case 'singleElectrodeModeSettings': {
        const currentConfig = getCurrentConfigSelector(prevState);
        const { adjustedConfig, triggered } = singleElectrodeModeSettingsModifier(
          value,
          currentConfig.emgThresholds,
          configsAffected
        );
        configsAffected = adjustedConfig;
        if (triggered)
          toast.error(
            i18next.t(
              'configurator:config_store.notification.single_electrode_mode_settings_warning',
              'Start point signal threshold must be lower than activation open/close'
            ),
            {
              id: 'startPointWarning'
            }
          );
        break;
      }
      case 'speedControlStrategy': {
        const currentConfig = getCurrentConfigSelector(prevState);
        configsAffected = speedControlStrategyModifier(value, currentConfig, configsAffected);
        break;
      }
      default:
        configsAffected = {
          [`${property}`]: value
        };
        break;
    }

    let newCommon = prevState.config.common;
    let newModes = prevState.config.modes;

    const commonProperties = commonPropertiesAPI || commonConfigProperties;
    const modeProperties = modePropertiesAPI || modeConfigProperties;

    Object.keys(configsAffected).forEach((_property) => {
      // @ts-ignore
      if (commonProperties.includes(_property)) {
        newCommon = {
          ...newCommon,
          config: {
            ...newCommon.config,
            ...configsAffected
          }
        };
      }
      // @ts-ignore
      if (modeProperties.includes(_property)) {
        newModes = newModes.map((mode) => {
          if (mode.slot !== prevState.slotSelected) return mode;
          return {
            ...mode,
            config: {
              ...mode.config,
              ...configsAffected
            }
          };
        });
      }
    });

    // Create config with initial changes
    const newState: ConfigStoreState = {
      ...prevState,
      config: { common: newCommon, modes: newModes }
    };

    // Check if global changes are needed based on new config
    let newModesModified = null;
    switch (property) {
      case 'batteryBeep': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector(deviceInfoState) >= 220;
        const { newConfigModesSettings, triggered } = batteryBeepAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'batteryBeep')) {
          toast.error(
            i18next.t(
              'configurator:config_store.notification.battery_beep_warning',
              'Battery beep needs to be at least 6% higher than Emergency mode value, Battery beep was automatically adjusted'
            ),
            {
              id: 'batteryBeepWarning'
            }
          );
        }
        break;
      }
      case 'emergencyBatterySettings': {
        const currentConfig = getCurrentConfigSelector(newState);
        const { newConfigModesSettings, triggered } = emergencyBatterySettingsAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'batteryBeep')) {
          toast.error(
            i18next.t(
              'configurator:config_store.notification.battery_beep_warning',
              'Battery beep needs to be at least 6% higher than Emergency mode value, Battery beep was automatically adjusted'
            ),
            {
              id: 'batteryBeepWarning'
            }
          );
        }
        break;
      }
      case 'inputDevice': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector(deviceInfoState) >= 220;
        const { newConfigModesSettings } = inputDeviceAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;
        break;
      }
      case 'singleElectrodeMode': {
        const currentConfig = getCurrentConfigSelector(newState);
        const localConfigSupported = getFwVersionSelector(deviceInfoState) >= 220;
        const { newConfigModesSettings, triggered } = singleElectrodeModeAfterModifier(
          currentConfig as DeviceConfigTemplate,
          newState,
          localConfigSupported ? [newState.slotSelected] : null
        );
        newModesModified = newConfigModesSettings;

        if (triggered.find((element) => element.configName === 'emgSpike')) {
          toast.error(
            i18next.t(EMG_SPIKE_WARNING.translationKey, EMG_SPIKE_WARNING.translationOptions),
            EMG_SPIKE_WARNING.options
          );
        }
        break;
      }
      case 'singleElectrodeSettingsAlternating': {
        const { newConfigModesSettings, triggered } =
          singleElectrodeSettingsAlternatingAfterModifier(newState, [newState.slotSelected]);
        newModesModified = newConfigModesSettings;

        if (
          triggered.find((element) => element.configName === 'singleElectrodeSettingsAlternating')
        ) {
          toast.error(
            i18next.t(
              SINGLE_ALTERNATING_TIMINGS.translationKey,
              SINGLE_ALTERNATING_TIMINGS.translationOptions
            ),
            SINGLE_ALTERNATING_TIMINGS.options
          );
        }
        break;
      }
      default:
        break;
    }

    set(
      {
        config: {
          common: newState.config.common,
          modes: newModesModified || newState.config.modes
        }
      },
      false,
      {
        type: 'setConfigProperty',
        configsAffected,
        property,
        value
      }
    );

    // Return state previous to changes done in setConfigProperty, to keep track of changes history
    return prevState.config;
  },
  setControlConfig: (newControlConfig) => {
    const prevState = { ...get() };

    const { firmware, versions } = useDeviceInfoStore.getState();
    const currentConfig = getCurrentConfigSelector(prevState);

    const newInputSite = newControlConfig[0];
    const newGripSwitchingMode = newControlConfig[3];
    const oldInputSite = currentConfig.inputSite![0];
    controlConfigModifier({
      newInputSite,
      newGripSwitchingMode,
      oldInputSite,
      prevState,
      firmware,
      versions,
      set,
      get
    });

    return prevState.config;
  },
  getInitialConfigAPI: async () => {
    const { deviceId, getDeviceInfoAPI } = useDeviceInfoStore.getState();
    const { setConfigCopy } = get();
    if (!deviceId) {
      toast.error(
        i18next.t('configurator:config_store.notification.device_id_missing', 'Device id missing')
      );
      return;
    }
    try {
      useUiStore.setState({ initialConfigApiState: FETCHING_STATES.loading });
      set({ initialConfigState: FETCHING_STATES.loading });
      const deviceInfo = await getDeviceInfoAPI();
      const configKeys = await getFirmwareKeys({
        firmwareId: Number(deviceInfo?.firmware_version_id)
      });

      let commonKeys: null | Array<keyof DeviceConfigTemplate> = null;
      let modesKeys: null | Array<keyof DeviceConfigTemplate> = null;

      if (configKeys) {
        modesKeys = configKeys.filter((item) => !item.is_common).map((item) => item.key);
        const commonKeysUnadjusted = configKeys
          .filter((item) => item.is_common)
          .map((item) => item.key);

        // Groups gripsPositions into one property
        const uniqueCommonKeys = Array.from(
          new Set(commonKeysUnadjusted.map((key) => key.split('.')[0]))
        ) as any;

        commonKeys = uniqueCommonKeys?.length > 0 ? uniqueCommonKeys : null;

        set({
          commonPropertiesAPI: commonKeys,
          modePropertiesAPI: modesKeys
        });
      }
      const configAPI = await getDeviceConfig(Number(deviceId));
      const modesData = await getModesConfigForDevice({ deviceId });
      const modesActivity = modesData
        ? modesData.map((modeData) => ({ active: modeData.active, slot: modeData.slot }))
        : null;

      getInitialConfigAPIFulfilled(
        set,
        setConfigCopy,
        configAPI,
        commonKeys,
        modesKeys,
        modesActivity
      );
    } catch (e) {
      console.log(e);
      useUiStore.setState({ initialConfigApiState: FETCHING_STATES.failed });
      throw e;
    }
  },
  importBackupConfig: async ({ config, t }) => {
    const { common, modes } = config;

    set(
      produce((state: ConfigStoreState) => {
        state.config.common.config = common;
        state.config.modes = modes;
      }),
      false,
      { type: 'importBackupConfig', config }
    );
    toast.success(
      t(
        'configurator:config_store.notification.import_backup_config.success',
        'Successfully config imported'
      ),
      {
        id: 'importBackupConfig'
      }
    );
  },
  importConfig: ({
    common = null,
    modes = null
  }: {
    common: any;
    modes: { slot: number; config: any }[] | { id: number; config: any }[] | null;
  }) => {
    set(
      produce((state: ConfigStoreState) => {
        if (common) {
          state.config.common.config = common;
        }
        if (modes) {
          modes.forEach((modeInstalled) => {
            const modeReceiving = state.config.modes.find((_mode) => {
              if (modeInstalled?.slot || modeInstalled?.slot === 0)
                return _mode.slot === modeInstalled.slot;
              if (modeInstalled?.id || modeInstalled?.id === 0)
                return _mode.id === modeInstalled.id;
            });
            if (modeReceiving) modeReceiving.config = modeInstalled.config;
          });
        }
      }),
      false,
      { type: 'importConfig', common, modes }
    );
  },
  getInitialConfig: async () => {
    let aborted = false;

    const abort = (reject) => {
      reject(false);
      aborted = true;
    };

    const getInitialConfigFunc = new Promise(async (resolve, reject) => {
      const infoMessage = toast.loading(
        i18next.t(
          'configurator:config_store.notification.preparing_download_config',
          'Preparing to download config...'
        )
      );
      const { firstConnection, setConfigCopy, commonPropertiesAPI, modePropertiesAPI } = get();
      const toggles = await getFeatureToggles();
      const isHashToggleEnabled = toggles?.find((toggle) => toggle.slug === HASH)?.enabled;

      try {
        window.addEventListener(EVENTS.disconnect, () => abort(reject));
        useUiStore.setState({ initialConfigState: FETCHING_STATES.loading });
        const dateTime = dayjs();
        const getShortYear = (date) => Number(String(date).slice(2, 4));
        const { deviceId, connected } = useDeviceInfoStore.getState();
        const deviceInfoState = useDeviceInfoStore.getState();

        if (!deviceId) {
          toast.error(
            toast.error(
              i18next.t(
                'configurator:config_store.notification.device_id_missing',
                'Device id missing'
              )
            )
          );
          return;
        }

        if (connected) {
          if (bluetooth.telemetryEnabled) {
            await bluetooth.telemetryOff();
          }
          await delay(100);

          const commonProperties = commonPropertiesAPI || commonConfigProperties;
          const modeProperties = modePropertiesAPI || modeConfigProperties;

          const common = commonProperties.map((property) => ({
            name: property,
            arguments: []
          }));
          const mode = modeProperties.map((property) => ({
            name: property,
            arguments: []
          }));

          if (aborted) return;

          const hashes: any = {
            global: { hash: null, mismatch: null },
            modes: [
              { hash: null, mismatch: null },
              { hash: null, mismatch: null },
              { hash: null, mismatch: null }
            ]
          };

          const deviceHashes = await getDeviceHashes(deviceId);
          if (!deviceHashes) return;

          const firmwareSupportsHash = getFwVersionSelector(deviceInfoState) >= 231;
          const hashSupported = await getAllHashes(firmwareSupportsHash);
          let globalHash, modeHashes;

          if (hashSupported && isHashToggleEnabled) {
            globalHash = hashSupported.globalHash;
            modeHashes = hashSupported.modeHashes;
          }

          // Hash is not changed
          if (globalHash && deviceHashes?.hash_global) {
            if (globalHash !== deviceHashes.hash_global) {
              hashes.global.mismatch = true;
            }
            for (let index = 0; index < hashes.modes.length; index++) {
              const modeHashApi = deviceHashes[`hash_mode${index + 1}`];
              if (modeHashes[index] !== modeHashApi) hashes.modes[index].mismatch = true;
            }
          } else {
            hashes.global.mismatch = true;
            hashes.modes = hashes.modes.map((mode) => ({ ...mode, mismatch: true }));
          }
          const configAPI = await getDeviceConfig(Number(deviceId));

          // @ts-ignore
          let commonConfig: CommonConfigTemplate = configAPI.common;

          // Fallback to downloading configuration from device if there is a mismatch or at least 1 value in API is false
          if (hashes.global.mismatch || Object.values(commonConfig).includes(false)) {
            commonConfig = await getDeviceConfigurations(common, aborted);
          }

          if (aborted) return;

          const modesData = await getModesConfigForDevice({ deviceId });
          const modesActivity = modesData
            ? modesData.map((modeData) => ({ active: modeData.active, slot: modeData.slot }))
            : null;
          const modesConfigs: ModeType[] = [];

          for (let index = 0; index < configAPI.modes.length; index += 1) {
            const element = configAPI.modes[index];
            await delay(100);
            await postCommunicateMode(element.slot);
            await delay(100);
            const loadingModeToast = toast.loading(
              i18next.t('configurator:config_store.notification.fetching_mode_settings', {
                mode: element.name,
                defaultValue: 'Fetching {{mode}} settings'
              }),
              {
                id: infoMessage
              }
            );

            // @ts-ignore
            let modeConfig: ModeConfigTemplate = element.config;
            // Fallback to downloading configuration from device if there is a mismatch or at least 1 value in API is false
            if (
              Array.isArray(element.config) ||
              hashes.modes[index].mismatch ||
              Object.values(modeConfig).includes(false)
            ) {
              modeConfig = await getDeviceConfigurations(mode, aborted);
            }

            if (aborted) {
              toast.dismiss(loadingModeToast);
              return;
            }

            modesConfigs.push({
              config: modeConfig,
              slot: element.slot,
              name: element.name,
              id: element.id,
              configAPI: Array.isArray(element.config) ? null : element.config,
              active: modesActivity?.find((modeData) => modeData.slot === element.slot)?.active
            });
          }

          if (aborted) return;

          await delay(100);
          await postCommunicateMode(0);
          await delay(100);
          await postRtcTime([
            getShortYear(Number(dateTime.year())),
            Number(dateTime.month()) + 1,
            Number(dateTime.date()),
            Number(dateTime.hour()),
            Number(dateTime.minute()),
            Number(dateTime.second())
          ]);
          await delay(100);
          toast.dismiss(infoMessage);

          if (commonConfig) {
            set(
              produce((state: any) => {
                state.config.common.config = commonConfig;
                state.config.modes = modesConfigs;
                state.firstConnection = false;
                state.localConfigFetched = true;
              }),
              false,
              { type: 'getInitialConfig', commonConfig, modesConfigs }
            );
            setConfigCopy();
            if (!firstConnection) {
              const configAPI = await getDeviceConfig(Number(deviceId));
              const deviceDifferencesArray: any = [];

              const differenceCommon = compareConfigs(commonConfig, configAPI.common);
              if (!isEmpty(differenceCommon)) deviceDifferencesArray.push(differenceCommon);

              modesConfigs.forEach((element) => {
                const difference = compareConfigs(
                  element.config,
                  configAPI.modes.find((mode) => mode.slot === element.slot)?.config
                );

                if (!isEmpty(difference))
                  deviceDifferencesArray.push({ difference, slot: element.slot });
              });

              const isConfigConflict = deviceDifferencesArray.length > 0;

              if (isConfigConflict) {
                console.log(deviceDifferencesArray, 'DIFFERENCES');
                set({ configConflict: true });
              } else {
                if (hashes.global.mismatch && hashSupported && isHashToggleEnabled) {
                  await updateDeviceHashes({
                    deviceId,
                    data: {
                      hash_global: String(globalHash),
                      hash_mode1: String(modeHashes[0]),
                      hash_mode2: String(modeHashes[1]),
                      hash_mode3: String(modeHashes[2])
                    }
                  });
                }
              }
            }
            toast.success(
              i18next.t(
                'configurator:config_store.notification.configuration_downloaded',
                'Configuration downloaded'
              ),
              {
                id: 'configurationDownloadedToast'
              }
            );

            resolve({ common: commonConfig, modes: modesConfigs });
          }
          if (Boolean(commonConfig) === false) {
            toast.error(
              i18next.t(
                'configurator:config_store.notification.configuration_downloaded_fail',
                'Could not connect to the prosthesis'
              ),
              {
                id: 'configurationDownloadedFailToast'
              }
            );
          }
        }
        toast.dismiss(infoMessage);
        resolve(true);
      } catch (err: any) {
        console.error(err, 'Bad connection, disconnecting');
        toast.dismiss(infoMessage);
        useUiStore.setState({ initialConfigState: FETCHING_STATES.failed });
        await bluetooth.disconnectBluetooth();
        reject(err.message);
      }
    });

    const result = await getInitialConfigFunc;

    useUiStore.setState({
      initialConfigState: result ? FETCHING_STATES.successful : FETCHING_STATES.failed
    });

    window.removeEventListener(EVENTS.disconnect, abort);
    return result;
  },
  disconnectDevice: async () => {
    const infoMessage = toast.loading(
      i18next.t(
        'configurator:config_store.notification.disconnecting_device',
        'Disconnecting device...'
      )
    );
    const { serial } = useDeviceInfoStore.getState();
    try {
      useUiStore.setState({ disconnectingState: FETCHING_STATES.loading });
      await bluetooth.disconnectBluetooth();
      toast.dismiss(infoMessage);

      if (serial !== '') {
        toast(
          i18next.t(
            'configurator:config_store.notification.device_disconnected',
            'Device disconnected'
          ),
          { icon: '⚠️' }
        );
      }
      set(
        produce((state: any) => {
          state.handMovementAllowed = false;
          state.localConfigFetched = false;
        })
      );
      useDeviceInfoStore.setState({ connected: false, versions: initialStateDeviceInfo.versions });
      useUiStore.setState({ disconnectingState: FETCHING_STATES.successful });
      return true;
    } catch (err) {
      useUiStore.setState({ disconnectingState: FETCHING_STATES.failed });
      toast.dismiss(infoMessage);
      toast.error(
        i18next.t(
          'configurator:config_store.notification.disconnecting_device_error',
          "Couldn't disconnect the device"
        )
      );
      console.log(err);
      return false;
    }
  },
  connectDevice: async () => {
    if (bluetooth.connected) return { status: true };

    const infoMessage = toast.loading(
      i18next.t(
        'configurator:config_store.notification.connecting_device',
        'Connecting to the hand...'
      )
    );
    const { resetUpdate } = useSettingsStore.getState();
    try {
      useUiStore.setState({ bluetoothState: FETCHING_STATES.loading });
      const status = await bluetooth.initiateBluetooth();
      if (status) {
        await telemetryEnabled(false);
        bluetooth.device.addEventListener(
          'gattserverdisconnected',
          async () => {
            await get().disconnectDevice();
            useUiStore.getState().openModal(MODALS.disruptiveDisconnect);
            const eventStopProcedure = new Event(EVENTS.stopProcedure);
            const eventDisconnect = new Event(EVENTS.disconnect);
            window.dispatchEvent(eventStopProcedure);
            window.dispatchEvent(eventDisconnect);
          },
          {
            once: true,
            signal: bluetooth.controller.signal
          }
        );
        toast.dismiss(infoMessage);
        if (status) {
          useDeviceInfoStore.setState({ connected: status });
          toast.success(
            i18next.t('configurator:config_store.notification.device_connected', 'Device connected')
          );
        } else if (status === false) {
          toast.error(
            i18next.t(
              'configurator:config_store.notification.could_not_connect',
              'Could not connect to the prosthesis'
            )
          );
        } else {
          toast.error(status);
        }
        useUiStore.setState({ bluetoothState: FETCHING_STATES.successful });
        return { status };
      }
      toast.dismiss(infoMessage);
      useUiStore.setState({ bluetoothState: FETCHING_STATES.failed });
      return false;
    } catch (err: any) {
      console.log(err, 'Bad connection, disconnecting');
      toast.dismiss(infoMessage);
      useUiStore.setState({ bluetoothState: FETCHING_STATES.failed });
      await bluetooth.disconnectBluetooth();
      return err.message;
    }
  },
  handleProcedure: async ({
    procedureNumber,
    liveSession,
    preventInput = true,
    showPreventInputMessage = true
  }: {
    procedureNumber: ProcedureTypes;
    liveSession?: liveSessionProps;
    preventInput?: boolean;
    showPreventInputMessage?: boolean;
  }) => {
    const infoMessage = toast.loading(
      i18next.t('configurator:config_store.notification.running_procedure', 'Running procedure...')
    );
    let procedureReply;
    const { blockScreen, unblockScreen } = useUiStore.getState();
    try {
      useUiStore.setState({ procedureState: FETCHING_STATES.loading });
      if (preventInput) blockScreen(BLOCK_MODALS.PROCEDURE, showPreventInputMessage);
      set({ currentlyRunningProcedure: procedureNumber });
      const input = Array(120).fill(0);

      if (bluetooth.connected) {
        switch (procedureNumber) {
          case ProcedureTypes.i2cCommunicationCheck:
            procedureReply = await getDevicesInfo();
            break;
          case ProcedureTypes.testClosing: {
            procedureReply = await testClosingProcedure(EVENTS.stopProcedure);
            break;
          }
          case ProcedureTypes.checkMovementRangeTestClosingCombined: {
            const [generalTestClosingData, closingTimeFingers, currentsFingers] =
              await testClosingProcedure(EVENTS.stopProcedure);
            const checkMovementRangeData = await runProcedure(
              ProcedureTypes.checkMovementRange,
              input,
              EVENTS.stopProcedure
            );
            await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply);

            procedureReply = [
              generalTestClosingData,
              closingTimeFingers,
              currentsFingers,
              checkMovementRangeData
            ];
            break;
          }
          default:
            procedureReply = await runProcedure(procedureNumber, input, EVENTS.stopProcedure);
            await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply);
            break;
        }
      }
      if (liveSession?.clinicianUUID) {
        const channelAbly = ablyClient(liveSession?.clinicianUUID).channels.get(
          liveSession!.channelName
        );
        await channelAbly.publish(CalibrationEvents.start, [procedureNumber, ...input]);
        procedureReply = await timeoutCommandCustom(
          () => listenAblyReply(channelAbly, CalibrationEvents.finished),
          CALIBRATION_PROCEDURE_TIMEOUT
        );
      }
      toast.dismiss(infoMessage);
      if (procedureReply) {
        set({ procedureReply, procedureUsedType: procedureNumber });
        toast.success(
          i18next.t(
            'configurator:config_store.notification.procedure_successful',
            'Procedure successful'
          )
        );

        useUiStore.setState({ procedureState: FETCHING_STATES.successful });
        return {
          procedureReply,
          type: procedureNumber
        };
      }
      useUiStore.setState({ procedureState: FETCHING_STATES.failed });
      toast.error(
        i18next.t('configurator:config_store.notification.procedure_failed', 'Procedure failed')
      );
      return false;
    } catch (err) {
      toast.dismiss(infoMessage);
      useUiStore.setState({ procedureState: FETCHING_STATES.failed });
      return err;
    } finally {
      if (preventInput) unblockScreen(BLOCK_MODALS.PROCEDURE);
      set({ currentlyRunningProcedure: null });
    }
  },
  geTicketConfigApi: async () => {
    try {
      const { configUrl } = useReplayStore.getState();
      const { deviceId } = useDeviceInfoStore.getState();
      const { setConfigCopy } = get();

      if (configUrl === null || !deviceId) {
        return null;
      }

      const response = await getTicketConfig(configUrl);

      getInitialConfigAPIFulfilled(
        set,
        setConfigCopy,
        response?.data?.config ? response.data.config : null
      );
    } catch (err: any) {
      console.log(err);
      return false;
    }
  },
  sendWholeConfigDevice: async ({
    configToSend
  }: {
    configToSend: any;
    sendPermanently?: boolean;
  }) => {
    const infoMessage = toast.loading(
      i18next.t('configurator:config_store.notification.sending_changes', 'Sending changes...')
    );
    try {
      if (bluetooth.connected) {
        const { configConflict }: ConfigStoreState = get();
        const deviceInfoState = useDeviceInfoStore.getState();
        const prevState: ConfigStoreState = { ...get() };

        for (const key in configToSend) {
          if (Object.prototype.hasOwnProperty.call(configToSend, key)) {
            let args;
            const apiConfig = getCurrentConfigApiSelector(prevState);
            const deviceConfig = getCurrentConfigSelector(prevState);

            switch (key) {
              case 'freezeModeEmg':
                args = [
                  configToSend[key],
                  deviceInfoState.versions?.current,
                  configConflict ? apiConfig.inputSite : deviceConfig.inputSite,
                  configConflict ? apiConfig?.inputDevice : deviceConfig?.inputDevice
                ];
                break;
              default:
                args = [configToSend[key]];
            }

            await ConfigToSendFunctionMapping[key](...args);
            await delay(100);
          }
        }
        console.log(configToSend, 'TO SEND');
        toast.dismiss(infoMessage);
        toast.success(
          i18next.t('configurator:config_store.notification.config_sent', 'Config sent'),
          {
            id: 'configSentToast'
          }
        );
        return;
      }
      toast.dismiss(infoMessage);
      toast.error(
        i18next.t(
          'configurator:config_store.notification.device_not_connected',
          'Device not connected'
        )
      );
      return false;
    } catch (err) {
      toast.dismiss(infoMessage);
      return err;
    }
  },
  resetGripPositions: (grip: Grips) =>
    set(
      produce((state: any) => {
        state.config.common.config.gripsPositions[grip] = defaultConfig.gripsPositions[grip];
      }),
      false,
      { type: 'resetGripPositions', grip, value: defaultConfig.gripsPositions[grip] }
    ),
  consumeHistory: (idOrEvent: keyof typeof HISTORY_EVENTS | number) => {
    const previousState: ConfigStoreState = { ...get() };
    // @ts-ignore
    const change: HistoryEntryType = previousState.configHistory.findLast(
      (historyEntry: HistoryEntryType) =>
        typeof idOrEvent === 'number'
          ? historyEntry.id === idOrEvent
          : historyEntry.event === idOrEvent && historyEntry.fromSlot === previousState.slotSelected
    );

    if (!change) return;

    const { common } = previousState.config;

    let newCommon = common;
    if (!isEmpty(change.diffConfig.common.before)) {
      if (Object.keys(change.diffConfig.common.before).includes('gripsPositions')) {
        change.diffConfig.common.before.gripsPositions = {
          ...common.config.gripsPositions,
          ...change.diffConfig.common.before.gripsPositions
        };
      }
      newCommon = {
        ...common,
        config: { ...common.config, ...change.diffConfig.common.before }
      };
    }

    let newModes = previousState.config.modes;
    if (change.diffConfig.modes.length > 0) {
      newModes = previousState.config.modes.map((mode) => {
        const modeChange = change.diffConfig.modes.find((_mode) => _mode.slot === mode.slot);

        if (!modeChange) return mode;

        return { ...mode, config: { ...mode.config, ...modeChange.before } };
      });
    }
    set((state) => ({
      config: {
        common: newCommon,
        modes: newModes
      },
      configHistory: state.configHistory.filter((historyEntry) => historyEntry.id !== change.id)
    }));
    undoChannel.postMessage({ event: 'undo', diff: change.diffConfig });
  },
  clearConfigHistory: () => set({ configHistory: initialStateConfigStore.configHistory }),
  addSendingQueue: (sendObject) => {
    let newSendingQueue;
    set((state: ConfigStoreState) => {
      newSendingQueue = [...state.sendingQueue, { id: state.sendingQueue.length + 1, sendObject }];
      return { sendingQueue: newSendingQueue };
    });
    return newSendingQueue;
  },
  removeSendingQueue: () => {
    let newSendingQueue;
    set((state: ConfigStoreState) => {
      newSendingQueue = state.sendingQueue.slice(0, -1);
      return { sendingQueue: newSendingQueue };
    });
    return newSendingQueue;
  }
});

export const useConfigStore = create<ConfigStoreState>()(
  // @ts-ignore
  devtools(
    persist(store, {
      name: 'bluetooth',
      partialize: (state: any) => ({
        firstConnection: state.firstConnection
      })
    }),
    { name: 'Config' }
  )
);
