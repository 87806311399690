import { Grid, GridCellProps } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ActionButton, ActionButtonError } from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { checkOutlineIcon, xOutlineIcon } from '@progress/kendo-svg-icons';
import React, { useEffect, useState } from 'react';
import { useModal } from '../../hooks/api/useModal';
import { useTable } from '../../hooks/useTable';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteModal, {
  ModalMessageReleaseNote
} from '../../components/Modals/ConfirmDeleteModal';
import { ReleaseNoteMappingEntry } from '../../api/releaseNote/releaseNote.types';
import { FeatureItemWrapper, IconContainer, StyledIcon } from './styled';
import { IntlService } from '@progress/kendo-react-intl';
import { RELEASE_EDIT } from '../../../constants/routes';

export interface ReleaseNoteGridProps {
  queryMetaData: any;

  handleDelete(params: any, selectedRow: ReleaseNoteMappingEntry): void;
}

const supportFeature = (name: string, supported: boolean = false) => {
  if (supported) {
    return (
      <IconContainer>
        <StyledIcon icon={checkOutlineIcon} supported /> <span>{name}</span>
      </IconContainer>
    );
  }
  return (
    <IconContainer>
      <StyledIcon icon={xOutlineIcon} supported={false} /> <span>{name}</span>
    </IconContainer>
  );
};

const ReleaseGrid = ({ queryMetaData, handleDelete }: ReleaseNoteGridProps) => {
  const {
    result: releaseNoteList,
    total,
    page,
    pageSize,
    pageChange,
    refetch,
    isLoadingDelete
  } = queryMetaData;
  const { result, dataState, dataStateChange, updateTable } = useTable(releaseNoteList ?? []);
  const [currentRow, setCurrentRow] = useState<ReleaseNoteMappingEntry | null>();
  const navigate = useNavigate();
  useEffect(() => {
    updateTable(releaseNoteList ?? []);
  }, [releaseNoteList]);

  const handleSubmit = async (params: any, callback: Function, onSuccess: Function | null) => {
    if (callback) {
      await callback(params, currentRow);
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const handleModalOpen = async (rowId: number, callback: Function | undefined) => {
    await setCurrentRow(releaseNoteList.find((item: ReleaseNoteMappingEntry) => item.id === rowId));
    if (callback) {
      await callback();
    }
  };

  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useModal();

  return (
    <>
      {currentRow && Boolean(isDeleteModalOpen && currentRow) && (
        <ConfirmDeleteModal
          isLoading={isLoadingDelete}
          handleClose={handleCloseDelete}
          handleAccept={(e: any) =>
            handleSubmit(e, handleDelete, () => {
              handleCloseDelete();
              refetch();
            })
          }
          message={<ModalMessageReleaseNote id={currentRow.id} />}
        />
      )}
      <Grid
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={{ buttonCount: 4, pageSizes: [12, 20, 40] }}
        pageSize={pageSize}
        skip={page?.skip}
        take={page?.take}
        total={total}
        onPageChange={pageChange}>
        <Column field='software_version.name' title='Software' />
        <Column field='firmware_version.name' title='Firmware' />
        <Column field='pcb_version.name' title='PCB' />
        <Column field='device_model.name' title='Device model' />
        <Column
          field='is_fully_compatible'
          title='Full compatibility'
          cell={(e: GridCellProps) => (
            <td>
              {e.dataItem.is_fully_compatible
                ? supportFeature('Yes', true)
                : supportFeature('No', false)}
            </td>
          )}
        />
        <Column
          title='Features'
          cell={(e: GridCellProps) => (
            <td>
              <FeatureItemWrapper>
                {e.dataItem.features.map((item: any) =>
                  supportFeature(item.feature.name, item.is_compatible)
                )}
              </FeatureItemWrapper>
            </td>
          )}
        />
        <Column
          field='created_at'
          title='Creation date'
          cell={(e: GridCellProps) => {
            const DATE_FORMAT = 'yyyy-MM-dd HH:mm';
            const intl = new IntlService('en');
            return (
              <td>
                {intl.formatDate(
                  intl.parseDate(e.dataItem.created_at ? e.dataItem.created_at : ''),
                  DATE_FORMAT
                )}
              </td>
            );
          }}
        />
        <Column
          width={getTableSize(2)}
          title='Actions'
          cell={(e: any) => (
            <ActionButtonsWrapper>
              <Tooltip anchorElement='target' parentTitle={true} position={'top'}>
                {/*<ActionButton*/}
                {/*  title='Send to self'*/}
                {/*  onClick={() => handleModalOpen(e.dataItem.id, () => {})}>*/}
                {/*  <SvgIcon icon={svgIcons['envelopIcon']} size='medium' />*/}
                {/*</ActionButton>*/}
                {/*<ActionButton*/}
                {/*  title='Accept'*/}
                {/*  onClick={() => handleModalOpen(e.dataItem.id, () => {})}>*/}
                {/*  <SvgIcon icon={svgIcons['checkOutlineIcon']} size='medium' />*/}
                {/*</ActionButton>*/}
                <ActionButton
                  title='Edit'
                  onClick={() => navigate(RELEASE_EDIT, { state: { detail: e.dataItem } })}>
                  <SvgIcon icon={svgIcons['editToolsIcon']} size='medium' />
                </ActionButton>
                <ActionButtonError
                  title='Remove'
                  onClick={() => handleModalOpen(e.dataItem.id, handleOpenDelete)}>
                  <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                </ActionButtonError>
              </Tooltip>
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
    </>
  );
};

export default ReleaseGrid;
