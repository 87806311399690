import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useSessionStart } from 'hooks/useSessionStart';

const useNavbarConnect = () => {
  const { bluetoothDisconnect, bluetoothCheckPairing } = useBluetooth();
  const { connected, setItemDeviceInfo } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    setItemDeviceInfo: state.setItemDeviceInfo
  }));
  const { getInitialConfigAPI } = useConfigStore((state: any) => ({
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { handleOpenLocalSession } = useSessionStart();

  const connectDeviceConfigurator = async (
    serial: string,
    bluetooth_id: string,
    deviceId: number
  ) => {
    setItemDeviceInfo('deviceId', deviceId);
    await getInitialConfigAPI();
    handleOpenLocalSession(serial, bluetooth_id, deviceId, true, false);
  };

  const handleBluetooth = async (bluetoothId = null) => {
    if (connected) {
      bluetoothDisconnect();
      return;
    }

    const newAssignedDevice = await bluetoothCheckPairing(bluetoothId);

    // Connect to configurator

    if (newAssignedDevice) {
      connectDeviceConfigurator(
        newAssignedDevice.serial!,
        newAssignedDevice.bluetooth_id,
        newAssignedDevice.id
      );
      return;
    }
  };

  return { handleBluetooth };
};

export default useNavbarConnect;
