import {
  HeaderM,
  HeaderS,
  TextS,
  TextXS
} from 'configurator/components/atoms/Typography/Typography';
import styled, { css } from 'styled-components';
import { myTheme } from 'theme/theme';

export const TableStyle = css`
  display: grid;
  grid-template-columns: 40% repeat(auto-fit, minmax(100px, 1fr));
  justify-items: start;
  align-items: center;
`;

export const Header4 = styled.h4`
  ${HeaderS};
`;

export const Header3 = styled.h3`
  ${HeaderM};
  margin-bottom: 16px;
`;

export const TextMedium = styled.p`
  ${TextS}
`;

export const TextSub = styled.p`
  ${TextXS};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-areas:
    'config'
    'version'
    'notes';

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'version config'
      'notes config';
    grid-template-rows: auto 1fr;
  }
`;

export const ConfigDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: stretch;
  }
`;

export const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PatientImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
`;

export const InnerWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 16px;
`;

export const ConfigurationHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

export const TableBody = styled.div<{ sticky?: any }>`
  ${TableStyle};

  ${({ sticky }: any) =>
    sticky &&
    css`
      background-color: white;
      position: sticky;
      top: 0px;
    `};
`;

export const TableHeader = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${myTheme.palette.fontColor};
`;
