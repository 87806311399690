import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useTranslation } from 'react-i18next';
import FormButtonsWrapper from '../../FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import { ModalStyle } from '../styles';
import { useInviteUsers } from 'hooks/useInviteUsers';
import * as Sentry from '@sentry/react';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { getDefaultMedicalProfessionalsValues, InvitationsData } from './utils';
import {
  useInviteMedicalProfessional,
  useMedicalProfessionalsInviteList
} from './useInviteMedicalProfessional';
import { InviteMedicalProfessionalComponent } from './InviteMedicalProfessionalsComponent';
import { medicalProfessionalsSchema } from './schema';

const InviteMedicalProfessionalModal = ({ handleModalClose }) => {
  const { t } = useTranslation('medical_professionals');

  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));
  const { handleSubmit: handleSubmitInviteUsers, isLoading: isLoadingAddPatient } =
    useInviteUsers();

  // Get default values
  const additionalArgs = additionalModalArgs(MODALS.inviteMedicalProfessional)?.args;
  const defaultClinician = additionalArgs?.args?.clinician;
  const selectedPatient = additionalArgs?.args?.patient;

  const { cliniciansData } = useMedicalProfessionalsInviteList();
  const {
    control,
    updatedPatientsData,
    fields,
    watch,
    convertRoleToProperRenderValue,
    errors,
    updatedCliniciansData,
    isHelperMessagesAvailable,
    remove,
    append,
    isLastFilled,
    clinicians,
    handleSubmit,
    reset,
    register,
    setValue,
    trigger
  } = useInviteMedicalProfessional({
    defaultValues: getDefaultMedicalProfessionalsValues({ defaultClinician, selectedPatient }),
    schema: medicalProfessionalsSchema(cliniciansData)
  });

  const onSubmit = async (data) => {
    const isPatientDataFromDefaultValue = typeof data.patient === 'string';
    const patientId = isPatientDataFromDefaultValue
      ? selectedPatient.id
      : data.patient.id.toString();

    const invitationsData = InvitationsData(data.clinicians);

    try {
      const invitedUsers = await handleSubmitInviteUsers({
        patient_id: patientId,
        invitations: invitationsData
      });
      reset();
      handleModalClose();

      if (invitedUsers !== undefined) {
        openModal(MODALS.inviteMedicalProfessionalsSucessModal);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <Modal open={true} sx={{ width: '720px', ...ModalStyle }}>
      <FormContentWrapper
        title={t('medical_professionals:invite_modal.title', 'Invite new Medical Professional')}>
        <form key={selectedPatient} onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <InviteMedicalProfessionalComponent
              control={control}
              updatedPatientsData={updatedPatientsData}
              fields={fields}
              watch={watch}
              convertRoleToProperRenderValue={convertRoleToProperRenderValue}
              errors={errors}
              updatedCliniciansData={updatedCliniciansData}
              isHelperMessagesAvailable={isHelperMessagesAvailable}
              remove={remove}
              append={append}
              isLastFilled={isLastFilled}
              register={register}
              setValue={setValue}
              trigger={trigger}
            />
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-medical-form'
                type='button'
                color='light'
                onClick={handleModalClose}>
                {t('medical_professionals:invite_modal.buttons.cancel', 'Cancel')}
              </CustomButton>
              <LoadingButton
                data-testid='submit-patient-form'
                type='submit'
                disabled={!clinicians.length}
                loading={isLoadingAddPatient}>
                <span>
                  {t(
                    'medical_professionals:invite_modal.buttons.send_invitation',
                    'Send invitation'
                  )}
                </span>
              </LoadingButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      </FormContentWrapper>
    </Modal>
  );
};

export default InviteMedicalProfessionalModal;
