import * as Sentry from '@sentry/react';
import { NotificationFactory } from 'lib/NotificationFactory';
import { detachDevice } from 'adp-panel/api/devices/devices';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DEVICES_QUERY_KEY } from 'adp-panel/hooks/api/useDevices';

export const useDetachDevice = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(detachDevice, {
    onSuccess() {
      NotificationFactory.successNotification(
        t('notifications:detach_device.success', 'Device detached')
      );
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification(
        t('notifications:detach_device.error', 'Failed to detach device')
      );
    }
  });

  return {
    result: data,
    handleSubmit: mutateAsync,
    isLoading,
    isError
  };
};
