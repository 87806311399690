import React, { useMemo } from 'react';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { gripSwitchingMap, gripSwitchingReversedMap } from 'configurator/utils/definesLocal';
import {
  inputSitesEntry,
  controlModeEntry,
  speedControlStrategyEntry,
  gripSwitchingModeEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useTranslation } from 'react-i18next';

const GripSwitching = ({
  addHistoryProsthesis,
  inputSite,
  controlMode,
  speedControlStrategy,
  gripSwitchingMode,
  disable,
  setControlConfig
}: {
  addHistoryProsthesis: any;
  inputSite: inputSitesEntry;
  controlMode: controlModeEntry;
  speedControlStrategy: speedControlStrategyEntry;
  gripSwitchingMode: gripSwitchingModeEntry;
  disable: boolean;
  setControlConfig: any;
}) => {
  const [inputSiteValue] = inputSite;
  const [controlModeValue] = controlMode;
  const [speedControlStrategyValue] = speedControlStrategy;
  const [gripSwitchingModeValue] = gripSwitchingMode;
  const { t } = useTranslation();
  const handleGripSwitchingModes = (type: any, value: any) => {
    const newControlConfig = [
      inputSiteValue,
      controlModeValue,
      speedControlStrategyValue,
      gripSwitchingReversedMap.get(value)
    ];
    addHistoryProsthesis(setControlConfig(newControlConfig));
  };

  const GripSwitching = useMemo(
    () => (
      <InputOptions
        header={t('configurator:component.grip_switching_modes.header', 'Grip switching modes')}
        data-tour='grip-switching'
        options={[
          gripSwitchingMap.get(GripSwitchingModes.kCoContraction),
          gripSwitchingMap.get(GripSwitchingModes.kOpenOpen),
          gripSwitchingMap.get(GripSwitchingModes.kHoldOpen),
          ...(gripSwitchingModeValue === GripSwitchingModes.kCoapt
            ? [gripSwitchingMap.get(GripSwitchingModes.kCoapt)]
            : [])
        ]}
        id='mode'
        onChange={handleGripSwitchingModes}
        // @ts-ignore
        value={gripSwitchingMap.get(gripSwitchingModeValue)}
        description={t(
          'configurator:component.grip_switching_modes.description',
          'Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)'
        )}
        disable={gripSwitchingModeValue === GripSwitchingModes.kSingleGripSwitching || disable}
        tooltip={t('configurator:component.grip_switching_modes.tooltip', 'Hello')}
      />
    ),
    [inputSite, controlMode, speedControlStrategy, gripSwitchingMode, disable]
  );

  return GripSwitching;
};

export default GripSwitching;
