import { activePassiveTimeDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/activePassiveTime';
import { activityDensityDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/activityDensity';
import { exercisesPerformedDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/exercisesPerformed';
import { gripCountDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripCount';
import { gripCountHourDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripCountHour';
import { gripPerformedDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripPerformed';
import { gripSwitchingDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripSwitching';
import { gripSwitchingHourDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripSwitchingHour';
import { gripVelocityDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/gripVelocity';
import { switchesPerformedDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/switchesPerformed';
import { timeSpentDescription } from 'adp-panel/components/DeviceUsageMonitoring/Filters/descriptions/timeSpent';
import { ChartType, ChartInfo } from './types';

export const getChartDescription = (chartType: ChartType): ChartInfo => {
  const descriptions: Record<ChartType, ChartInfo> = {
    'active-passive-time': activePassiveTimeDescription,
    'grip-count': gripCountDescription,
    'grip-count-hour': gripCountHourDescription,
    velocity: gripVelocityDescription,
    'grip-switching': gripSwitchingDescription,
    'time-spent': timeSpentDescription,
    'grip-switching-hour': gripSwitchingHourDescription,
    'grip-performed': gripPerformedDescription,
    'switches-performed': switchesPerformedDescription,
    'exercises-performed': exercisesPerformedDescription,
    'activity-density': activityDensityDescription
  };

  return descriptions[chartType] || descriptions['active-passive-time'];
};
