export enum MfaOptions {
  email = 'email',
  sms = 'sms'
}

export interface UserLoginPayload {
  access_token: string | null;
  expires: string | null;
}

export interface ConfirmedMfaPayload {
  token: string | null;
}

export interface MfaRequire {
  require: boolean;
  channel: MfaOptions | null;
  code?: number | string | null;
}

export interface LoginParams {
  email: string;
  password: string;
  blockRedirect?: boolean;
}

export interface UserDetails {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  image: string;
  created_at: string;
  updated_at: string;
  notifications_timezone?: string;
}

export interface SendMfaCodePayload {
  channel: MfaOptions;
}

export interface VerifyMfaCodePayload {
  channel: MfaOptions;
  code: number;
  remember_mfa_session?: boolean;
  machine_key?: string;
  blockRedirect?: boolean;
}

export interface ChangePasswordPayload {
  old_password: string;
  new_password: string;
}

export interface ChangePasswordWithTokenPayload {
  token: string;
  email: string;
  password: string;
}

export interface VerifyPasswordChangeTokenPayload {
  token: string;
  email: string;
}

export interface SetMfaStatusPayload {
  enabled?: 0 | 1;
  method?: MfaOptions;
}

export interface VerifyPhonePayload {
  code: number;
}

export interface CheckMfaPayload {
  enabled: 0 | 1;
  method: MfaOptions;
  phone: 0 | 1;
  otp: 0 | 1;
}

export interface RegisterNewUserParams {
  name?: string;
  email: string;
  password: string;
  phone: string;
  language: string;
  clinic_name?: string;
  clinic_location?: string;
  address1?: string;
  address2?: string;
  mfa_enabled?: boolean;
  mfa_method?: string;
  activation_code: string;
}

export interface RegisterInvitedUserParams {
  name?: string;
  token: string | null;
  email: string;
  password: string;
  language: string;
  clinic_name?: string;
  clinic_location?: string;
  address1?: string;
  address2?: string;
  mfa_enabled?: boolean;
  mfa_method?: string;
}
