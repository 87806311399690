import {
  LiveSessionAction,
  SessionInitializationStatus
} from 'adp-panel/components/Modals/StartLiveSessionModal';
import { SessionStatus } from 'adp-panel/api/liveConfigurator/p2pSessions.types';
import { useEffect, useState } from 'react';
import {
  SESSION_QUERY_KEY,
  useP2PSession,
  useP2PSessionActions
} from 'adp-panel/hooks/api/useP2PSession';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { PATIENTS_PERMISSIONS } from 'adp-panel/constants/rolesPermissions';
import useUserData from 'hooks/useUserData';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { CONFIGURATOR_DEVICE } from 'constants/routes';
import { Types } from 'ably';
import * as Sentry from '@sentry/react';
import { REMOTE_SESSION_DEBUG } from 'adp-panel/constants/config';
import { DevicesQueryParams, DeviceExtendOptions } from 'adp-panel/api/devices/device.types';
import { NotificationFactory } from 'lib/NotificationFactory';
import { ablyClient, assertConfiguration, ablyClientSession } from 'adp-panel/api/utils/ablyClient';
import { UserDetails } from 'adp-panel/api/authentication/authentication.types';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useDevicesList } from 'adp-panel/hooks/api/useDevices';

const generateDefaultAblyChannelName = (patientId: string) =>
  `pushenabled:liveConfigurationSession:${patientId}`;
const configureAbly = (patientId: string, user: UserDetails) => {
  ablyClient(user);
  const ably = assertConfiguration();
  const notificationChannel = ably.channels.get(generateDefaultAblyChannelName(patientId));
  return {
    ably,
    notificationChannel
  };
};
let timer: ReturnType<typeof setTimeout> | null = null;

export const useSessionInitialization = ({ patientId }) => {
  const [step, setStep] = useState(SessionInitializationStatus.waiting);
  const [connectionToken, setConnectionToken] = useState<string>('');
  const [sessionConnection, setSessionConnection] =
    useState<Types.RealtimeChannelCallbacks | null>();
  const [currentAblyStatus, setCurrentAblyStatus] = useState<Types.ConnectionState>('connected');
  const [selectedDevice, setSelectedDevice] = useState<DeviceEntry | null>();

  const { data: userData, me, rolesByName } = useUserData();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useUiStore((state) => ({
    openModal: state.openModal,
    closeModal: state.closeModal
  }));

  const { initSessions, updateSession, createSessionIsLoading, closeSessionIsLoading } =
    useP2PSessionActions();

  const startSessionIsAvailable = currentAblyStatus === 'connected';

  const queryParamsDevices: DevicesQueryParams = {
    amputee: patientId,
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };
  const {
    result: devices,
    total: totalDevices,
    isLoading: isLoadingDevices
  } = useDevicesList(queryParamsDevices);
  const {
    result: liveSession,
    refetch: refetchSession,
    isLoading: isLoadingSession
  } = useP2PSession(
    Number(me?.id),
    parseInt(patientId, 10),
    userHasPermissions(PATIENTS_PERMISSIONS.REMOTE_SESSION, rolesByName)
  );
  const { ably, notificationChannel } = configureAbly(patientId, me);

  useEffect(() => {
    ably.connection.on((stateChange: Types.ConnectionStateChange) => {
      const previous = stateChange.previous as Types.ConnectionState;
      const current = stateChange.current as Types.ConnectionState;
      setCurrentAblyStatus(current);
      if (previous === 'connecting' && current === 'disconnected') {
        NotificationFactory.errorNotification('Remote session ', stateChange.reason?.message);
      }
    });

    return () => {
      ably.connection.off();
    };
  }, [ably]);

  useEffect(() => {
    if (liveSession?.token) {
      const selectedDevice =
        liveSession.device_id &&
        devices &&
        devices.find(({ id }: DeviceEntry) => id === liveSession.device_id);
      setConnectionToken(liveSession?.token);
      setStep(
        liveSession?.status === SessionStatus.waitingForDecision
          ? SessionInitializationStatus.waiting
          : SessionInitializationStatus.success
      );
      if (REMOTE_SESSION_DEBUG) {
        setStep(SessionInitializationStatus.success);
        return;
      }
      selectedDevice && setSelectedDevice(selectedDevice);
    }
  }, [liveSession, devices]);

  const handleSessionModalOpen = async () => {
    await refetchSession();
    openModal(MODALS.session);
  };

  const handleSessionModalClose = () => {
    closeModal(MODALS.session);
  };

  const closeSessionAPI = async () => {
    const liveSessionData = await refetchSession();
    if (liveSessionData.isError) return;
    await updateSession({ id: liveSessionData.data.id, status: SessionStatus.closed });
  };

  const handleConnectionActions = async (action: LiveSessionAction) => {
    if (action === 'reconnect') {
      if (REMOTE_SESSION_DEBUG) {
        setStep(SessionInitializationStatus.success);
        return;
      }
      if (liveSession) {
        await closeSessionAPI();
      }
      // @ts-ignore
      handleInitSession(selectedDevice);
      return;
    }

    if (action === 'session_already_exists') {
      navigate(CONFIGURATOR_DEVICE, {
        state: {
          deviceId: liveSession.device_id,
          amputeeId: patientId,
          token: connectionToken,
          sessionId: liveSession.id
        }
      });
      handleSessionModalClose();
      return;
    }

    if (action === 'close' && liveSession) {
      try {
        handleSessionModalClose();
        if (sessionConnection) {
          await sessionConnection.publish('close_session', 'close');
        }
        await closeSessionAPI();
      } catch (err) {
        Sentry.captureException(err);
        console.log('error', err);
      }
      return;
    }

    if (action === 'start' && liveSession) {
      navigate(CONFIGURATOR_DEVICE, {
        state: {
          deviceId: liveSession.device_id,
          amputeeId: patientId,
          token: connectionToken,
          sessionId: liveSession.id
        }
      });
      handleSessionModalClose();
      return;
    }

    handleSessionModalClose();
  };

  const sendNotification = (token: string | null = null, device: DeviceEntry, id: number) => {
    const extras = {
      push: {
        notification: {
          title: 'Start session'
        },
        data: {
          token
        }
      }
    };

    const data = {
      token,
      clinician: {
        name: me?.name,
        avatar: me?.image,
        companyName: userData?.clinic_name
      },
      device,
      expireAt: dayjs().add(15, 'minute').format('HH:mm'),
      id
    };

    notificationChannel.publish({
      name: 'start_session_request',
      data,
      extras: extras
    });
  };

  const handleInitSession = async (device: DeviceEntry) => {
    try {
      setStep(SessionInitializationStatus.waiting);
      const { token, clinician_uuid, id } = await initSessions({
        amputee_id: parseInt(patientId, 10),
        amputee_uuid: uuidv4(),
        clinician_uuid: uuidv4(),
        device_id: Number(device.id)
      });

      setSelectedDevice(device);
      setSessionConnection(ablyClientSession(clinician_uuid).channels.get(token));
      setConnectionToken(token);
      await refetchSession();

      if (REMOTE_SESSION_DEBUG) {
        console.log('REMOTE_SESSION_DEBUG', REMOTE_SESSION_DEBUG);
        setStep(SessionInitializationStatus.success);
        return true;
      }

      timer = setTimeout(() => {
        setStep(SessionInitializationStatus.error);
      }, 1000 * 90);

      sendNotification(token, device, id);
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
      return false;
    }
  };

  useEffect(() => {
    if (connectionToken) {
      const handleDecision = (message: any) => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        if (message.data === 'accepted') {
          setStep(SessionInitializationStatus.success);
          return;
        }

        closeSessionAPI();
        setSessionConnection(null);
        setStep(SessionInitializationStatus.error);
      };
      notificationChannel.subscribe('session_decision', handleDecision);
    }

    return () => {
      notificationChannel.unsubscribe('session_decision');
    };
  }, [connectionToken]);

  useEffect(() => {
    ably.connection.on((stateChange: Types.ConnectionStateChange) => {
      const previous = stateChange.previous as Types.ConnectionState;
      const current = stateChange.current as Types.ConnectionState;
      setCurrentAblyStatus(current);
      if (previous === 'connecting' && current === 'disconnected') {
        NotificationFactory.errorNotification('Remote session ', stateChange.reason?.message);
      }
    });

    return () => {
      ably.connection.off();
    };
  }, [ably]);

  return {
    createSessionIsLoading,
    startSessionIsAvailable,
    handleConnectionActions,
    liveSession,
    devices,
    step,
    handleSessionModalOpen,
    handleInitSession,
    handleSessionModalClose,
    isLoadingSession: isLoadingSession || closeSessionIsLoading
  };
};
