/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { singleElectrodeModeSettingsEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';
import { useTranslation } from 'react-i18next';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const SingleElectrodeModeSettings = ({
  addHistoryProsthesis,
  singleElectrodeModeSettings,
  disable,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  singleElectrodeModeSettings: singleElectrodeModeSettingsEntry;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const { t } = useTranslation();
  const [singleElectrodeSettingsLocal, setSingleElectrodeModeSettingsLocal] = useState<any>(
    initialState.config.singleElectrodeModeSettings
  );

  const handleFastRisingSliders = (value: any, sliderNumber: any) => {
    let type: 'startPointSignalThreshold' | 'windowTime' | 'holdOpenTimeShort' | 'holdOpenTimeLong';
    let payload;
    switch (sliderNumber) {
      case 0:
        type = 'startPointSignalThreshold';
        payload = [
          value,
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
        break;
      case 1:
        type = 'windowTime';
        payload = [
          singleElectrodeModeSettings[0],
          value,
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
        break;
      case 2:
        type = 'holdOpenTimeShort';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          value,
          singleElectrodeModeSettings[3]
        ];
        break;
      case 3:
        type = 'holdOpenTimeLong';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          value
        ];
        break;
      default:
        type = 'holdOpenTimeLong';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
    }
    // @ts-ignore
    addHistoryProsthesis(setConfigProperty('singleElectrodeModeSettings', payload));
  };

  useEffect(() => {
    if (singleElectrodeModeSettings) {
      setSingleElectrodeModeSettingsLocal(singleElectrodeModeSettings);
    }
  }, [singleElectrodeModeSettings]);

  const SingleElectrodeModeSettings = useMemo(
    () => (
      <MultiSlider
        header={t('configurator:component.single_electrode_mode_settings.header', {
          defaultValue: 'Slope options'
        })}
        sliderValues={singleElectrodeSettingsLocal}
        disabled={disable}
        limits={[
          { min: 1, max: 100 },
          { min: 50, max: 1000 },
          { min: 250, max: 5000 },
          { min: 300, max: 5000 }
        ]}
        handleChange={(...args: any) =>
          setSingleElectrodeModeSettingsLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>
            {t(
              'configurator:component.single_electrode_mode_settings.start_point_signal_threshold',
              {
                defaultValue: 'Start point signal threshold: {{value}}',
                value: singleElectrodeSettingsLocal?.[0]
              }
            )}
          </p>,
          <p>
            {t('configurator:component.single_electrode_mode_settings.window_time', {
              defaultValue: 'Window time: {{time}} s',
              time: parseNumber(singleElectrodeSettingsLocal?.[1])
            })}
          </p>,
          <p>
            {t('configurator:component.single_electrode_mode_settings.primary_hold_open_time', {
              defaultValue: 'Primary hold open time: {{time}} s',
              time: parseNumber(singleElectrodeSettingsLocal?.[2])
            })}
          </p>,
          <p>
            {t('configurator:component.single_electrode_mode_settings.secondary_hold_open_time', {
              defaultValue: 'Secondary hold open time: {{time}} s',
              time: parseNumber(singleElectrodeSettingsLocal?.[3])
            })}
          </p>
        ]}
        handleOnAfterChange={(...args: any) => handleFastRisingSliders(args[1], args[2])}
        description={t('configurator:component.single_electrode_mode_settings.description', {
          defaultValue: 'Sets slope settings'
        })}
        tooltip={t('configurator:component.single_electrode_mode_settings.tooltip', {
          defaultValue: 'Hello'
        })}
      />
    ),
    [singleElectrodeModeSettings, singleElectrodeSettingsLocal, disable]
  );

  return SingleElectrodeModeSettings;
};

export default SingleElectrodeModeSettings;
