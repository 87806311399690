import React from 'react';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { Divider, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalStyle } from './styles';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as UserSvg } from 'assets/user.svg';
import { TableLink } from '../Links/Links';
import { useNavigate } from 'react-router-dom';

type PatientsModalProps = {
  handleClose: any;
  items: any;
  showRedirect?: boolean;
  title: string;
  link: any;
};

const MultipleItemsModal = ({
  handleClose,
  title,
  items,
  showRedirect = true,
  link
}: PatientsModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('patients');

  const redirectToPatient = (patientId) => {
    navigate(`${link}/${patientId}`);
  };

  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper title={title}>
        <ul style={{ maxHeight: '40vh', overflowY: 'auto' }}>
          {items.map((item) => (
            <li
              style={{
                listStyle: 'none'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '16px 0'
                }}>
                <TableLink to={`${link}/${item?.id}`}>{item.name}</TableLink>
                {showRedirect && (
                  <CustomButton
                    Icon={UserSvg}
                    color='lightBlue'
                    onClick={() => redirectToPatient(item?.id)}
                  />
                )}
              </div>
              <Divider />
            </li>
          ))}
        </ul>
        <FormButtonsWrapper>
          <CustomButton color='light' onClick={handleClose}>
            {t('patients:modal.buttons.cancel', 'Cancel')}
          </CustomButton>
        </FormButtonsWrapper>
      </FormContentWrapper>
    </Modal>
  );
};

export default MultipleItemsModal;
