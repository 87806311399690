import { useTypedParams } from 'hooks/useTypedParams';
import GoalUsageTab from '../Goals/GoalsUsageTab';

const PatientGoals = () => {
  const { patientId } = useTypedParams(['patientId']);

  return (
    <div style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important' }}>
      <GoalUsageTab userId={parseInt(patientId, 10)} />
    </div>
  );
};

export default PatientGoals;
