const FormButtonsWrapper = ({
  children,
  marginTop = '26px',
  style
}: {
  children: any;
  marginTop?: string;
  style?: any;
}) => (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop, gap: '16px', ...style }}>
    {children}
  </div>
);

export default FormButtonsWrapper;
