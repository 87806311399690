import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useInvitedUsers, useUserMe, useUsers } from 'adp-panel/hooks/api/useUsers';
import { RoleEnum, UsersInvitedQueryParams } from 'adp-panel/api/users/users.types';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useUserData from 'hooks/useUserData';
import { ROLES, updateDataWithFullName } from './utils';
import { emailSchema } from 'configurator/views/Register/utils';

export const useMedicalProfessionalsInviteList = () => {
  const queryParamsMedicalProfessionals: UsersInvitedQueryParams = {
    perpage: 9999
  };

  const { result: cliniciansData } = useInvitedUsers(queryParamsMedicalProfessionals);

  return { cliniciansData };
};

export const useInviteMedicalProfessional = <T extends FieldValues & { clinicians: any }>({
  defaultValues,
  schema
}: {
  defaultValues: any;
  schema: any;
}) => {
  const { t } = useTranslation('medical_professionals');

  const queryParamsPatients = { roles: RoleEnum.amputee };
  const { result: patientsData } = useUsers(queryParamsPatients);

  const { cliniciansData } = useMedicalProfessionalsInviteList();
  const { result: accountDetails } = useUserMe();
  const { data: me } = useUserData();

  const {
    control,
    handleSubmit: handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    trigger,
    register
  } = useForm<T>({
    defaultValues: defaultValues,
    mode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(schema)
  });
  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'clinicians'
  });
  // @ts-ignore
  const [clinicians] = watch(['clinicians']);

  const [helperMessages, setHelperMessages] = useState<number[]>([]);

  const isHelperMessagesAvailable = (index: number) => {
    if (helperMessages.includes(index))
      return t(
        'medical_professionals:helper_message.text',
        'The Clinician is not in the system and will be invited'
      );
  };

  const updatedCliniciansData =
    cliniciansData &&
    updateDataWithFullName(cliniciansData)
      .filter(
        (clinician) =>
          clinician.id !== accountDetails?.id &&
          !clinicians?.some((existingClinician) => existingClinician.clinician?.id === clinician.id)
      )
      .filter((clinician) => clinician.id !== me.id)
      .sort((a, b) => b.active - a.active);

  useEffect(() => {
    clinicians?.forEach((clinician, index) => {
      const selectedClinician = clinician?.clinician;
      if (selectedClinician && typeof selectedClinician === 'string') {
        const isNotInOptions = !updatedCliniciansData.some(
          (option) => option.email === selectedClinician
        );
        if (isNotInOptions && !helperMessages.includes(index))
          setHelperMessages((prevMessages) => [...prevMessages, index]);
        if (!isNotInOptions && helperMessages.includes(index))
          setHelperMessages((prevMessages) => prevMessages.filter((num) => num !== index));
      } else {
        if (helperMessages.includes(index))
          setHelperMessages((prevMessages) => prevMessages.filter((num) => num !== index));
      }
    });
  }, [clinicians, updatedCliniciansData]);

  const isLastFilled = () => {
    if (clinicians?.length === 0) return true;
    const maxNumberOfInvitation = 10;
    if (clinicians?.length === maxNumberOfInvitation) return false;

    return clinicians?.every(
      (item) => (item.clinician?.email || emailSchema.isValidSync(item.clinician)) && item.role?.id
    );
  };

  const convertRoleToProperRenderValue = (role) => {
    const ClinicRoles = ['ClinicAdmin', 'Clinician'];
    if (ClinicRoles.includes(role)) return ROLES[0].name;
    else return ROLES[1].name;
  };

  const updatedPatientsData = updateDataWithFullName(patientsData);

  return {
    clinicians,
    control,
    updatedPatientsData,
    fields,
    watch,
    convertRoleToProperRenderValue,
    errors,
    updatedCliniciansData,
    isHelperMessagesAvailable,
    remove,
    append,
    isLastFilled,
    handleSubmit,
    reset,
    trigger,
    setValue,
    register
  };
};

export default useInviteMedicalProfessional;
