import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Check } from 'assets/check-solid.svg';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { HeaderM, TextS } from 'configurator/components/atoms/Typography/Typography';

export const Header1 = styled.h3`
  ${HeaderM};
`;

export const Paragraph = styled.p`
  ${TextS}
`;

export const Regular = styled.span`
  font-weight: 400;
`;

const StyledIcon = css`
  width: 20px;
  margin-right: 5px;

  @media (max-height: 924px) {
    width: 15px;
  }
`;

export const StyledCheck = styled(Check)`
  color: #16c60c;
  ${StyledIcon};
`;

export const StyledTimes = styled(Times)`
  color: #ee0000;
  ${StyledIcon};
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-right: 5px;

  &::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colorPrimary};
    border-color: ${({ theme }) => theme.colorPrimary} transparent
      ${({ theme }) => theme.colorPrimary} transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IndicatorInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IndicatorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const UpdateButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;
