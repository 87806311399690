import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { SectionParagraph } from './styled';
import { useTranslation } from 'react-i18next';

const EmailSection = () => {
  const { t } = useTranslation('auth');
  return (
    <div style={{ marginTop: '30px' }}>
      <SectionParagraph style={{ marginTop: '50px' }}>
        {t(
          'auth:component.register.subtitle_code_help',
          'Don`t have a code? Contant us by writing to the email address below.'
        )}
      </SectionParagraph>
      <div
        style={{
          display: 'flex',
          marginTop: '30px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <EmailOutlinedIcon sx={{ color: '#33499C' }} />
        <a
          href='mailto:support@aetherbiomedical.com'
          style={{
            color: '#33499C',
            textDecoration: 'none',
            fontWeight: '500',
            marginLeft: '10px'
          }}>
          {t('auth:register.link.email', 'support@aetherbiomedical.com')}
        </a>
      </div>
    </div>
  );
};

export default EmailSection;
