import React from 'react';
import { ReactComponent as LockSvg } from 'assets/lock2.svg';
import { ReactComponent as InfoCircle } from 'assets/info-circle-light.svg';
import { ReactComponent as CursorClick } from 'assets/cursor-click.svg';
import { ReactComponent as HelpCircle } from 'assets/help-circle.svg';
import { ReactComponent as AlertTriangle } from 'assets/alert-triangle.svg';
import { ReactComponent as AddAudio } from 'assets/ticket/add_audio.svg';
import { ReactComponent as AddFile } from 'assets/ticket/add_file.svg';
import { ReactComponent as AddImage } from 'assets/ticket/add_image.svg';
import { ReactComponent as TicketSend } from 'assets/ticket/ticket_send.svg';
import { ReactComponent as HelpIcon } from 'assets/help.svg';
import { ReactComponent as SearchIcon } from 'assets/search-sm.svg';
import { ReactComponent as ReopenIcon } from 'assets/reopen-icon.svg';

export const icons = {
  lock: LockSvg,
  'info-circle': InfoCircle,
  'cursor-click': CursorClick,
  'help-circle': HelpCircle,
  'alert-triangle': AlertTriangle,
  'help-icon': HelpIcon,
  'search-icon': SearchIcon,
  'reopen-icon': ReopenIcon
};

const ticketIcons = {
  'add-audio': AddAudio,
  'add-file': AddFile,
  'add-image': AddImage,
  send: TicketSend
};

type IconProps = {
  name: keyof typeof icons;
  style?: React.CSSProperties;
  className?: string;
  [key: string]: any;
};

type TicketIconProps = {
  name: keyof typeof ticketIcons;
  style?: React.CSSProperties;
  className?: string;
};

const CustomIcon: React.FC<IconProps> = ({ name, style, className, ...rest }) => {
  const IconComponent = icons[name];
  return <IconComponent style={style} className={className} {...rest} />;
};

export const CustomTicketIcon: React.FC<TicketIconProps> = ({ name, style, className }) => {
  const IconComponent = ticketIcons[name];
  return <IconComponent style={style} className={className} />;
};

export default CustomIcon;
