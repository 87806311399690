import * as Sentry from '@sentry/react';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../../../constants/routes';
import { useResetPasswordRequest } from '../../../hooks/api/useUsers';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailSchema } from 'configurator/views/Register/utils';
import CustomTextField from 'components/FormFields/CustomTextField';
import { LoadingButton } from '@mui/lab';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import BackgroundImage from '../BackgroundImage';

const ResetPassword = ({
  redirection = LOGIN,
  customCallback = null,
  backCallback
}: {
  redirection?: any;
  customCallback?: Function | null;
  backCallback?: any;
}) => {
  const { mutateAsync: resetPassword, isLoading } = useResetPasswordRequest();
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      await resetPassword(data.email);
      if (customCallback) {
        customCallback();
        return;
      }
      navigate(redirection);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error');
    }
  };

  const registerUserSchema = yup.object().shape({
    email: emailSchema.required(
      t('auth:component.register.email.validator', 'Email must be valid string')
    )
  });

  const { handleSubmit: handleSubmitLogin, control } = useForm<any>({
    resolver: yupResolver(registerUserSchema),
    mode: 'onChange'
  });

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
      <div>
        <AuthLayout lightVariant showUdi>
          <AuthWrapperBox>
            <AuthTitle>
              {t('auth:component:reset_password.title', 'Sign In With Your Existing Account')}
            </AuthTitle>
            <AuthParagraph variant='body2'>
              {t(
                'auth:component:reset_password.text',
                'Already registered with us? Sign in with your email to connect to the configurator or explore the Aether Digital Platform (ADP) for additional functionalities.'
              )}
            </AuthParagraph>
            <form onSubmit={handleSubmitLogin(onSubmit)}>
              <CustomTextField
                label={t('auth:register.form.password', 'E-mail')}
                id='email'
                control={control}
              />
              <FormButtonsWrapper>
                {backCallback && (
                  <CustomButton color='light' type='button' onClick={() => backCallback()}>
                    {t('auth:register.button.next_step', 'Back')}
                  </CustomButton>
                )}
                <LoadingButton type='submit' loading={isLoading}>
                  {t('auth:register.button.next_step', 'Submit')}
                </LoadingButton>
              </FormButtonsWrapper>
            </form>
          </AuthWrapperBox>
        </AuthLayout>
      </div>
      <BackgroundImage />
    </div>
  );
};

export default ResetPassword;
