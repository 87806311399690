/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { useConfigStore } from 'configurator/reducers/configStore';
import { oneSpeedEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { SliderLabelWrapper } from 'configurator/components/molecules/Sliders/styled';
import { useTranslation } from 'react-i18next';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const OneSpeed = ({
  addHistoryProsthesis,
  speed,
  disable
}: {
  addHistoryProsthesis: any;
  speed: oneSpeedEntry;
  disable: boolean;
}) => {
  const { t } = useTranslation();
  const [oneSpeedLocal, setOneSpeedLocal] = useState<any>(initialState.config.oneSpeed);
  const { setConfigProperty } = useConfigStore((state: any) => ({
    setConfigProperty: state.setConfigProperty
  }));

  const handleOneSpeedSlider = (oneSpeedValue: any) => {
    addHistoryProsthesis(setConfigProperty('oneSpeed', [oneSpeedValue]));
  };

  useEffect(() => {
    if (speed) {
      setOneSpeedLocal(speed);
    }
  }, [speed]);

  const OneSpeed = useMemo(
    () => (
      <MultiSlider
        header={t('configurator:component.one_speed.header', 'One speed settings')}
        disabled={disable}
        sliderValues={oneSpeedLocal}
        limits={[{ min: 50, max: 100 }]}
        handleChange={(...args: any) =>
          setOneSpeedLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[0] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <SliderLabelWrapper>
            <p>{t('configurator:component.one_speed.label', 'Speed:')}</p>
          </SliderLabelWrapper>
        ]}
        handleOnAfterChange={(...args: any) => handleOneSpeedSlider(args[1])}
        description={t(
          'configurator:component.one_speed.description',
          'Sets speed of fingers in one speed mode'
        )}
        key='One speed slider'
        tooltip={t('configurator:component.one_speed.tooltip', 'Hello')}
        valuesToDisplay={[`${oneSpeedLocal?.[0]}%`]}
      />
    ),
    [speed, oneSpeedLocal, disable]
  );

  return OneSpeed;
};

export default OneSpeed;
