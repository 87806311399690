import AttachmentFileIcon from 'adp-panel/pages/Dashboard/Inbox/AttachmentFileIcon';
import CustomButton from 'components/Button/CustomButton';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossSvg } from 'assets/cross.svg';

interface FilePreviewProps {
  fileName: string;
  extension: string;
  uid: string;
  onRemove: (uid: string) => void;
}

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 5px 10px;
  max-width: 200px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #e0e7ff;
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  padding: 5px;
`;

const FileText = styled.div`
  font-size: 14px;
  color: #374151;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilePreview: React.FC<FilePreviewProps> = ({ fileName, extension, uid, onRemove }) => {
  return (
    <FileContainer>
      <IconWrapper>
        <AttachmentFileIcon label={extension.toUpperCase()} size={30} />
      </IconWrapper>
      {/*<Icon>*/}
      {/*  <span>{extension.toUpperCase()}</span>*/}
      {/*</Icon>*/}
      <FileText>{fileName}</FileText>
      <CustomButton
        type='button'
        sx={{ minWidth: '40px', height: '40px' }}
        variant='text'
        color='error'
        Icon={CrossSvg}
        onClick={() => onRemove(uid)}
      />
    </FileContainer>
  );
};

export default FilePreview;
