import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { CenterLegendText, GraphComponentInsideContainer, SubLegendText } from '../styled';
import { ReactComponent as HelpIcon } from 'assets/help.svg';

import {
  GrapLegend,
  LegendCategory,
  LegendLabel,
  LegendShape,
  LegendSubLabel,
  LegendTooltipIcon,
  LegendWrapper,
  TooltipWrapper
} from './styled';
import { Tooltip } from '@progress/kendo-react-tooltip';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import activeAndPassiveGraphFormatter from '../Mappers/ActiveAndPassiveGraphFormatter';

dayjs.extend(duration);

export const TimeSpentGraphWithCustomLegend = ({ data, keys, yLabel }: any) => {
  const Metric = ({ dataWithArc, centerX, centerY }: any) => {
    let total = 0;
    dataWithArc.forEach((datum: any) => {
      total += Number(datum.data['Time']);
    });

    return (
      <>
        <CenterLegendText
          x={centerX}
          y={centerY}
          className='timeSpentGraph'
          textAnchor='middle'
          dominantBaseline='central'>
          {activeAndPassiveGraphFormatter(total, 'Time')}
        </CenterLegendText>
        <SubLegendText x={centerX} y={centerY + 30} textAnchor='middle' dominantBaseline='central'>
          Total
        </SubLegendText>
      </>
    );
  };

  return (
    <GraphComponentInsideContainer>
      <div style={{ height: '90%' }}>
        <ResponsivePie
          data={data}
          value={keys}
          margin={{ top: 60, right: 0, bottom: 40, left: 0 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={2}
          activeOuterRadiusOffset={8}
          borderWidth={0}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          valueFormat={(value: number) => {
            return activeAndPassiveGraphFormatter(value, keys[0]);
          }}
          colors={['hsl(219, 96%, 57%)', 'hsl(210, 10%, 91%)']}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', Metric]}
        />
      </div>
      <LegendWrapper>
        {data.map((legend: any) => {
          return (
            <GrapLegend key={legend.id}>
              <LegendShape color={legend.color} />
              <LegendCategory>
                <LegendLabel>{legend.label}</LegendLabel>
                <LegendSubLabel>
                  {activeAndPassiveGraphFormatter(legend[keys], keys[0])}
                </LegendSubLabel>
              </LegendCategory>
              <LegendTooltipIcon>
                <Tooltip
                  anchorElement='target'
                  parentTitle={true}
                  position={'top'}
                  content={(props: any) => <TooltipWrapper>{props.title}</TooltipWrapper>}>
                  <HelpIcon title={legend.description} />
                </Tooltip>
              </LegendTooltipIcon>
            </GrapLegend>
          );
        })}
      </LegendWrapper>
    </GraphComponentInsideContainer>
  );
};
