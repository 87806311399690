import styled from 'styled-components';
import { HeaderM, HeaderXL } from 'configurator/components/atoms/Typography/Typography';
import { Tooltip } from '@mui/material';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const SettingsContainer = styled.div`
  display: grid;
  max-width: 800px;
  gap: 32px;
`;

export const ConfigurationSection = ({ children, tooltipText, disabled, style = {} }) => (
  <div style={{ ...style, height: '100%' }}>
    <Tooltip title={disabled ? tooltipText : ''} sx={{ pointerEvents: 'auto' }}>
      <div
        style={
          disabled
            ? { opacity: 0.5, pointerEvents: 'auto', height: '100%' }
            : { opacity: 1, pointerEvents: 'auto', height: '100%' }
        }>
        {children}
      </div>
    </Tooltip>
  </div>
);
