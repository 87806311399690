import styled from 'styled-components';

export const ResponsiveContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;

  @media (min-width: 1280px) {
  }

  @media (min-width: 1440px) {
    margin-top: 16px;
  }

  @media (min-width: 1920px) {
    margin-top: 20px;
    gap: 8px;
  }

  @media (min-width: 2560px) {
    margin-top: 24px;
    gap: 16px;
  }
`;

export const PatientCardWrapper = styled.div`
  width: 100%;

  @media (min-width: 1440px) {
    width: unset;
  }
`;
