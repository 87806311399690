import React from 'react';
import { FormHelperText, Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const FormCheckbox = ({ id, label, control, shouldPreventChange = false }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <FormControl
          {...field}
          error={Boolean(fieldState.error)}
          component='fieldset'
          variant='standard'>
          <FormControlLabel
            label={label}
            sx={{
              alignItems: 'flex-start'
            }}
            control={
              <Checkbox
                sx={{
                  paddingTop: 0
                }}
                id={id}
                checked={field.value}
                onChange={(e) => {
                  if (shouldPreventChange) {
                    e.preventDefault();
                  } else {
                    field.onChange(e);
                  }
                }}
              />
            }
          />
          <FormHelperText>{t(fieldState.error?.message || '')}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormCheckbox;
