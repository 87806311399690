import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { parseBluetoothFirmware } from 'configurator/utils/funcs';
import ModalBase from './ModalBase';

const HandDetailsWrapper = styled.div`
  width: 350px;
`;

const Header1 = styled.h3`
  ${HeaderM};
`;

const Regular = styled.span`
  font-weight: 400;
`;

const HandDetails = ({ handleClose }: any) => {
  const { t } = useTranslation();
  const { serial, versions } = useDeviceInfoStore((state: any) => ({
    versions: state.versions,
    serial: state.serial
  }));

  return (
    <ModalBase
      handleClick={handleClose}
      header={t('configurator:component.hand_details.hand_details', 'Hand details')}>
      <DialogContent>
        <HandDetailsWrapper>
          <Header1>
            {t('configurator:component.hand_details.serial_number', 'Serial number: ')}
            <Regular>{serial}</Regular>
          </Header1>
          <Header1>
            {t('configurator:component.hand_details.firmware_version', 'Firmware version: ')}
            <Regular>{parseBluetoothFirmware(versions?.current)}</Regular>
          </Header1>
        </HandDetailsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

HandDetails.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default HandDetails;
