import { useDocumentsAccept, useDocumentsStatus } from '../../hooks/api/useDocuments';
import { useModal } from '../../hooks/api/useModal';
import useLogout from 'hooks/useLogout';
import * as Sentry from '@sentry/react';

const useDocumentsAcceptanceModal = (isLogged: boolean = false) => {
  const {
    isOpen: documentStatusModalOpen,
    handleOpen: handleDocumentStatusModalOpen,
    handleClose: handleDocumentStatusModalClose
  } = useModal();
  const { logout } = useLogout();
  const { result, isLoading: isLoadingDocumentStatus } = useDocumentsStatus(isLogged);
  const { mutateAsync: acceptDocuments, isLoading } = useDocumentsAccept();

  const logoutAndReset = () => {
    handleDocumentStatusModalClose();
    logout();
  };

  const handleSubmit = async (e: any, files: number[]) => {
    try {
      await acceptDocuments({ documents: files });
      handleDocumentStatusModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return {
    logoutDocuments: logoutAndReset,
    submitDocuments: handleSubmit,
    isLoadingDocuments: isLoading || isLoadingDocumentStatus,
    documentStatusModalOpen: documentStatusModalOpen,
    documentsResults: result
  };
};

export default useDocumentsAcceptanceModal;
