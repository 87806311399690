import { useQueryClient } from '@tanstack/react-query';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useNavigate } from 'react-router-dom';
import { CONFIGURATOR_DEVICE } from 'constants/routes';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { SESSION_QUERY_KEY } from 'adp-panel/hooks/api/useP2PSession';

export const useSessionStart = () => {
  const { setItemLiveConfigurator } = useLiveConfiguratorStore((state) => ({
    setItemLiveConfigurator: state.setItemLiveConfigurator
  }));
  const { openModal, setItemUiStore, sessionStartingState } = useUiStore((state) => ({
    openModal: state.openModal,
    setItemUiStore: state.setItemUiStore,
    sessionStartingState: state.sessionStartingState
  }));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOpenLocalSession = (
    serial: string,
    bluetooth_id: string,
    deviceId: string | number,
    connect = false,
    checkPairing = true
  ) => {
    navigate(CONFIGURATOR_DEVICE, {
      state: {
        serial,
        bluetoothId: bluetooth_id,
        deviceId: String(deviceId),
        connect,
        checkPairing
      }
    });
  };

  const handleOpenRemoteSession = async (patientId: number) => {
    try {
      setItemUiStore('sessionStartingState', FETCHING_STATES.loading);
      setItemLiveConfigurator('sessionPatientId', patientId);
      await queryClient.resetQueries([SESSION_QUERY_KEY]);
      openModal(MODALS.session);
    } finally {
      setItemUiStore('sessionStartingState', FETCHING_STATES.idle);
    }
  };

  return { handleOpenRemoteSession, handleOpenLocalSession, sessionStartingState };
};
