import { round } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as PageIcon } from 'assets/page.svg';

interface IconWithDynamicTextProps {
  label: string;
  size?: number;
}

const IconWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const AttachmentFileIcon: React.FC<IconWithDynamicTextProps> = ({ label, size = 100 }) => {
  const textRef = useRef<SVGTextElement>(null);
  const [rectWidth, setRectWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.getBBox().width;
      setRectWidth(round(textWidth) + 6);
    }
  }, [label]);

  return (
    <IconWrapper size={size}>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z'
          fill='white'
          stroke='#D0D5DD'
          strokeWidth='1.5'
        />
        <path d='M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5' stroke='#D0D5DD' strokeWidth='1.5' />
        <rect x='1' y='18' width={rectWidth} height='16' rx='2' fill='#33499C' />

        <text
          style={{ fontWeight: 700, fill: 'white' }}
          ref={textRef}
          x={rectWidth / 2 + 1}
          y='29.5'
          fill='white'
          fontSize='10'
          textAnchor='middle'>
          {label}
        </text>
      </svg>
    </IconWrapper>
  );
};

export default AttachmentFileIcon;
