import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomTooltip = ({
  title,
  placement = 'right'
}: {
  title: React.ReactNode;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}) => (
  <Tooltip title={title} placement={placement}>
    <InfoOutlinedIcon sx={{ fill: '#101A5A', width: '20px', cursor: 'pointer' }} />
  </Tooltip>
);

export default CustomTooltip;
