import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import CustomTable, { ActionsWrapper } from 'adp-panel/components/Table/CustomTable';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import { ReactComponent as HandSvg } from 'assets/hand-device-x.svg';
import { ReactComponent as HandAddSvg } from 'assets/hand-device-add.svg';
import { RoleEnum } from '../../../api/users/users.types';
import { userHasPermissions } from '../../../utils/permissionUtils';
import { DeviceTableRow, RolesByName } from '../types/patient.types';
import { NoDevicesContainer, NoDevicesContent } from '../styled/PatientDevices.styled';

interface PatientDevicesProps {
  devices: DeviceEntry[];
  totalDevices: number;
  isLoadingDevices: boolean;
  rolesByName: RolesByName;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (model: { page: number; pageSize: number }) => void;
  handleUnassignDevice: (deviceId: number) => void;
  handleAssignDeviceModalOpen: () => void;
}

const mapDeviceRows = (apiData: DeviceEntry[]): DeviceTableRow[] =>
  apiData.map((device) => ({
    id: device.id,
    serial: device.serial || '',
    model: device.model?.name || '',
    mobileStatus: true,
    lastDumUpdate: device.last_activity_at || ''
  }));

export const PatientDevices: React.FC<PatientDevicesProps> = ({
  devices,
  totalDevices,
  isLoadingDevices,
  rolesByName,
  paginationModel,
  setPaginationModel,
  handleUnassignDevice,
  handleAssignDeviceModalOpen
}) => {
  const { t } = useTranslation('patients');
  const deviceExists = devices?.[0]?.serial;
  const isClinician = userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName);

  if (!deviceExists) {
    return (
      <Card>
        <NoDevicesContainer>
          <NoDevicesContent>
            <Card>
              <HandSvg />
            </Card>
            <Typography sx={{ fontWeight: 600 }}>
              {t('patients:details.no_devices', 'No devices')}
            </Typography>
            {isClinician && (
              <>
                <Typography variant='caption' sx={{ width: '250px' }}>
                  {t(
                    'patients:details.no_devices_description',
                    'Click the button below to add first device to the patient.'
                  )}
                </Typography>
                <CustomButton Icon={HandAddSvg} onClick={handleAssignDeviceModalOpen}>
                  {t('patients:details.assign_device', 'Assign device')}
                </CustomButton>
              </>
            )}
          </NoDevicesContent>
        </NoDevicesContainer>
      </Card>
    );
  }

  return (
    <div style={{ marginTop: '32px' }}>
      <CustomTable
        tableData={devices}
        totalItems={totalDevices}
        isLoading={isLoadingDevices}
        columns={[
          {
            field: 'serial',
            headerName: t('patients:details.table.serial_number', 'Serial Number'),
            flex: 1
          },
          {
            field: 'model',
            headerName: t('patients:details.table.model', 'Model'),
            flex: 1,
            sortable: false
          },
          {
            field: 'mobileStatus',
            headerName: t('patients:details.table.mobile_app_status', 'Mobile App Status'),
            flex: 1,
            sortable: false
          },
          {
            field: 'lastDumUpdate',
            headerName: t(
              'patients:details.table.last_update',
              'Date of last update of device usage data'
            ),
            flex: 1,
            sortable: false
          },
          {
            field: 'actions',
            flex: 1,
            headerName: '',
            renderCell: (params) => {
              const { id } = params.row;
              const canAssignDevice = userHasPermissions(
                [RoleEnum.clinician, RoleEnum.clinicAdmin],
                rolesByName
              );

              if (!canAssignDevice) return null;

              return (
                <ActionsWrapper>
                  <CustomButton
                    Icon={TrashSvg}
                    color='light'
                    data-testid='button-delete'
                    onClick={() => handleUnassignDevice(id)}
                  />
                </ActionsWrapper>
              );
            }
          }
        ]}
        columnVisibilityModel={{
          active_code: userHasPermissions([RoleEnum.superAdmin], rolesByName)
        }}
        mapTableData={mapDeviceRows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};
