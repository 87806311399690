import React from 'react';
import styled from 'styled-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export enum PROCEDURE_INDICATOR_VARIANTS {
  done = 'done',
  in_progress = 'in_progress',
  waiting = 'waiting',
  failed = 'failed'
}
const COLOR_MAPPING = {
  [PROCEDURE_INDICATOR_VARIANTS.done]: '#60c84f1a',
  [PROCEDURE_INDICATOR_VARIANTS.in_progress]: '#F48F6F1A',
  [PROCEDURE_INDICATOR_VARIANTS.waiting]: '#33499C1A',
  [PROCEDURE_INDICATOR_VARIANTS.failed]: '#F48F6F1A'
};

const ICON_MAPPING = {
  [PROCEDURE_INDICATOR_VARIANTS.done]: <CheckCircleOutlineIcon sx={{ color: '#28A745' }} />,
  [PROCEDURE_INDICATOR_VARIANTS.in_progress]: <SyncIcon sx={{ color: '#F95F1D' }} />,
  [PROCEDURE_INDICATOR_VARIANTS.waiting]: <HourglassEmptyIcon sx={{ color: '#33499C' }} />,
  [PROCEDURE_INDICATOR_VARIANTS.failed]: <ErrorOutlineIcon sx={{ color: '#F95F1D' }} />
};

const IndicatorWrapper = styled.div<{ variant: PROCEDURE_INDICATOR_VARIANTS }>`
  width: 42px;
  height: 42px;
  background-color: ${({ variant }) => COLOR_MAPPING[variant]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProcedureIndicator = ({
  variant = PROCEDURE_INDICATOR_VARIANTS.waiting
}: {
  variant?: PROCEDURE_INDICATOR_VARIANTS;
}) => <IndicatorWrapper variant={variant}>{ICON_MAPPING[variant]}</IndicatorWrapper>;

export default ProcedureIndicator;
