import CustomButton from 'components/Button/CustomButton';
import CustomChip from 'components/CustomChip/CustomChip';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { CHOOSE_GRIPS } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HandDeviceSvg } from 'assets/hand-device.svg';
import { ReactComponent as DisconnectBluetoothSvg } from 'assets/bt.svg';
import { ReactComponent as BackArrowSvg } from 'assets/back-arrow.svg';
import useNavbarConnect from 'configurator/hooks/bluetooth/useNavbarConnect';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';

const ConnectButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { connected, serial } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    serial: state.serial
  }));
  const { remoteSessionEnabled } = useLiveConfiguratorStore((state) => ({
    remoteSessionEnabled: state.enabled
  }));
  const { handleBluetooth } = useNavbarConnect();

  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const { isUnsaved } = useUnsaved();

  const handleBluetoothAction = () => {
    if (isUnsaved) {
      const args = { action: handleBluetooth, unsavedChanges: true };
      openModal(MODALS.confirmSave, args);
    } else {
      handleBluetooth();
    }
  };

  return (
    <div style={{ display: 'flex', gap: '12px' }}>
      {connected ||
        (remoteSessionEnabled && (
          <CustomButton color='light' onClick={() => navigate(CHOOSE_GRIPS)}>
            {connected && (
              <CustomChip
                color='success'
                label={t('common:component.navbar.connected_sn', {
                  defaultValue: 'Connected (SN: {{serial}})',
                  serial
                })}
                sx={{ borderRadius: '6px', padding: '0', marginRight: '12px', height: '0' }}
              />
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {t('common:component.navbar.back_to_configurator', 'Back to configurator')}
              <BackArrowSvg />
            </div>
          </CustomButton>
        ))}
      {!remoteSessionEnabled && (
        <CustomButton
          color={connected ? 'light' : 'primary'}
          Icon={connected ? DisconnectBluetoothSvg : HandDeviceSvg}
          onClick={() => handleBluetoothAction()}>
          {connected
            ? t('common:component.navbar.disconnect_sn', {
                defaultValue: 'Disconnect (SN: {{serial}})',
                serial
              })
            : t('common:component.navbar.connect_device', 'Connect to Zeus')}
        </CustomButton>
      )}
    </div>
  );
};

export default ConnectButton;
