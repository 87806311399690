import { MenuItem, Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useTranslation } from 'react-i18next';
import { useConfigStore } from 'configurator/reducers/configStore';
import useModes from 'configurator/hooks/useModes';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { ModalStyle } from 'adp-panel/components/Modals/styles';
import CustomDropdown from 'components/FormFields/CustomDropdown';
import { useState } from 'react';

const ChangeModeModal = ({ handleModalClose }) => {
  const { t } = useTranslation();
  const {
    slotSelected,
    config: { modes }
  } = useConfigStore((state) => state);

  const [selectedMode, setSelectedMode] = useState(slotSelected);
  const { handleChangeMode } = useModes();

  const handleConfirm = () => {
    handleChangeMode(selectedMode);
    handleModalClose();
  };

  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper
        title={t('configurator:component.change_mode_modal.title', 'Change mode')}>
        <div>
          <FormWrapper>
            <CustomDropdown
              id='selected-mode'
              label={t('configurator:component.change_mode_modal.selected_mode', 'Selected mode')}
              value={selectedMode}
              onChange={setSelectedMode}
              disabled={Boolean(slotSelected === null || modes?.length === 0)}
              options={modes.map((mode) => (
                <MenuItem
                  key={`selected-mode-select_${mode.name}`}
                  value={mode.slot}
                  disabled={mode.active === 0}>
                  {mode.name}
                </MenuItem>
              ))}
            />
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-change-mode-modal'
                type='button'
                color='light'
                onClick={handleModalClose}>
                {t('configurator:component.change_mode_modal.cancel', 'Cancel')}
              </CustomButton>
              <CustomButton
                data-testid='cancel-change-mode-modal'
                type='button'
                onClick={handleConfirm}>
                {t('configurator:component.change_mode_modal.confirm', 'Confirm')}
              </CustomButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </div>
      </FormContentWrapper>
    </Modal>
  );
};

export default ChangeModeModal;
