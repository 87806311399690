import styled from 'styled-components';

export const PermissionError = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontSize.labelBig};
  font-weight: bold;
`;

export const ValidationErrorsList = styled.ul`
  margin: 8px 0;
  padding: 0;
  max-width: 200px;

  li {
    color: ${({ theme }) => theme.palette.error};
    font-size: 0.75rem;
    padding: 4px 0;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
  }
`;
