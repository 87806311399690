/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { JaaSMeeting } from '@jitsi/react-sdk';
import StandaloneChart from 'configurator/views/Graph/StandaloneChart';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import useMeeting from 'configurator/hooks/useMeeting';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import { Rnd } from 'react-rnd';
import ClearIcon from '@mui/icons-material/Clear';
import CustomHeaderSmall from 'configurator/components/atoms/Typography/CustomHeaderSmall';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { GRAPH } from 'constants/routes';

const OuterChartWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const EmgStyle = {
  padding: '4px 12px 0 12px'
};

const CustomCardStyle = {
  height: '100%',
  border: '1px solid #eaecf0',
  borderRadius: '12px',
  backgroundColor: '#FFF'
};

const DragHandleStyle = {
  height: '50px',
  background: 'lightgrey',
  cursor: 'move',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px'
};

const DragHandleWrapperStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
  height: '100%'
};

const CardPadding = (isMeetingOn, isEmgOn) => {
  if (isEmgOn) return '100px';

  if (isMeetingOn) return '50px';
};

const ResizeParent = ({
  children,
  onStartDrag,
  onStopDrag,
  closeWindow,
  isMeetingOn,
  isEmgOn,
  header,
  id,
  initialPositions = { x: 0, y: 0 }
}) => (
  <Rnd
    default={{
      x: initialPositions.x,
      y: initialPositions.y,
      width: '30vw',
      height: '70vh'
    }}
    id={id}
    bounds={'parent'}
    style={{ zIndex: '999' }}
    onDragStart={onStartDrag}
    dragHandleClassName='drag-handle'
    onDragStop={onStopDrag}>
    <div
      style={{
        ...CustomCardStyle,
        paddingBottom: CardPadding(isMeetingOn, isEmgOn)
      }}>
      <div
        className='drag-handle'
        style={{
          ...DragHandleStyle
        }}>
        <div
          style={{
            ...DragHandleWrapperStyle
          }}>
          <CustomHeaderSmall header={header} sx={{ margin: 0 }} />
          <div onClick={closeWindow} style={{ cursor: 'pointer' }}>
            <ClearIcon />
          </div>
        </div>
      </div>
      {children}
    </div>
  </Rnd>
);

export const FORBIDDEN_CHART_LOCATION = GRAPH;

const Meeting = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState<null | { x: number; y: number }>();
  const [isDragging, setIsDragging] = useState(false);
  const { meetingStatus, viewMode, meetingJwt, roomId, setItemMeeting } = useMeetingStore();
  const { handleCloseMeeting } = useMeeting();

  const saveLastLocation = () => {
    const element = document.querySelector('#resize_floating');
    if (!element) return;
    const rect = element.getBoundingClientRect();

    setLastLocation({ x: rect.left, y: rect.top });
  };

  const onStartDrag = () => setIsDragging(true);
  const onStopDrag = () => {
    saveLastLocation();
    setIsDragging(false);
  };

  const isInForbiddenChartLocation = location.pathname.includes(FORBIDDEN_CHART_LOCATION);
  const isEmgOn = viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG;

  const closeWindow = async () => {
    if (roomId) {
      await handleCloseMeeting();
    }
    setItemMeeting('viewMode', ViewModes.standard);
  };

  const floatingWindowHeader = () => {
    if (roomId && isEmgOn)
      return t('configurator:floating_window.header.meeting_emg', 'Meeting | EMG');
    if (roomId) return t('configurator:floating_window.header.meeting', 'Meeting');
    if (isEmgOn) return t('configurator:floating_window.header.emg', 'EMG');
  };

  return meetingStatus ? (
    <ResizeParent
      id='resize_floating'
      onStartDrag={onStartDrag}
      onStopDrag={onStopDrag}
      closeWindow={closeWindow}
      isMeetingOn={roomId}
      isEmgOn={isEmgOn}
      header={floatingWindowHeader()}>
      <OuterChartWrapper>
        {roomId && (
          <div
            style={{
              flex: '1',
              zIndex: '0',
              pointerEvents: isDragging ? 'none' : 'auto'
            }}>
            <JaaSMeeting
              appId={process.env.REACT_APP_JITSI_APP_ID!}
              roomName={roomId}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.width = '100%';
                iframeRef.style.height = '100%';
              }}
              jwt={meetingJwt!}
              onReadyToClose={handleCloseMeeting}
            />
          </div>
        )}
        {isEmgOn && (
          <div style={{ flex: 1, height: '50%' }}>
            <StandaloneChart style={{ ...EmgStyle }} />
          </div>
        )}
      </OuterChartWrapper>
      {!meetingStatus && isEmgOn && !isInForbiddenChartLocation && (
        <OuterChartWrapper>
          <StandaloneChart style={{ ...EmgStyle }} />
        </OuterChartWrapper>
      )}
    </ResizeParent>
  ) : (
    !isInForbiddenChartLocation && (
      <ResizeParent
        initialPositions={lastLocation ? lastLocation : { x: 0, y: 0 }}
        onStartDrag={onStartDrag}
        onStopDrag={onStopDrag}
        closeWindow={closeWindow}
        isMeetingOn={false}
        isEmgOn={true}
        id='resize_floating'
        header={floatingWindowHeader()}>
        {isEmgOn && <StandaloneChart style={{ ...EmgStyle }} />}
      </ResizeParent>
    )
  );
};

export default Meeting;
