import TimeSpentGood from 'assets/graphs/time_spent_good.svg';
import TimeSpentBad from 'assets/graphs/time_spent_bad.svg';
import { ChartInfo } from './types';

export const timeSpentDescription: ChartInfo = {
  description: 'common:component.graphs.time_spent_description',
  sections: [
    {
      title: 'common:component.graphs.time_spent.section_1.title',
      content: [
        {
          title: 'common:component.graphs.time_spent.section_1.issue.title',
          content: 'common:component.graphs.time_spent.section_1.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.time_spent.section_1.significance.title',
          content: 'common:component.graphs.time_spent.section_1.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.time_spent.section_1.action.title',
          content: 'common:component.graphs.time_spent.section_1.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ],
      examples: [
        {
          label: 'common:component.graphs.time_spent.section_1.examples.good',
          status: 'good',
          image: TimeSpentGood
        },
        {
          label: 'common:component.graphs.time_spent.section_1.examples.bad',
          status: 'bad',
          image: TimeSpentBad
        }
      ]
    }
  ]
};
