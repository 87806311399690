import { useQuery } from '@tanstack/react-query';
import { getSerachRecords } from 'adp-panel/api/search/search';
import { SearchQueryParams } from 'adp-panel/api/search/search.types';

export const SEARCH_QUERY_KEY = 'global_search';

export const useSearch = (queryParams: SearchQueryParams, enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError, isInitialLoading } =
    useQuery([SEARCH_QUERY_KEY, queryParams], () => getSerachRecords(queryParams), {
      enabled,
      keepPreviousData: false
    });

  return {
    result: data ? data.items : [],
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading: isInitialLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
