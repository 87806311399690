import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const activeAndPassiveGraphFormatter = (
  secondsToFormat: number,
  type: 'Time' | 'Percentage'
): string => {
  if (secondsToFormat < 0) {
    return '';
  }

  if (type === 'Percentage') {
    return `${secondsToFormat}%`;
  }

  const dur = dayjs.duration(secondsToFormat, 'seconds');
  const days = dur.days();
  const hours = dur.hours();
  const remainingMinutes = dur.minutes();
  const seconds = dur.seconds();

  const timeParts: any = [];
  if (days > 0) {
    timeParts.push(`${days}d`);
  }
  if (hours > 0 || days > 0) {
    timeParts.push(`${hours}h`);
  }
  if (remainingMinutes > 0 || hours > 0 || days > 0) {
    timeParts.push(`${remainingMinutes}m`);
  }
  if (seconds > 0 || remainingMinutes > 0 || hours > 0 || days > 0) {
    timeParts.push(`${seconds}s`);
  }

  return timeParts.join(' ');
};

export default activeAndPassiveGraphFormatter;
