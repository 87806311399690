import { commonFormStyles, LabelStyle, LabelWrapper } from 'components/FormFields/commonStyles';
import React from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { myTheme } from 'theme/theme';

type FormRadioData = {
  label: string;
  value: string | number;
  component?: React.ReactNode;
};

type FormRadioGroupProps = {
  id: any;
  label: string;
  control: any;
  data: FormRadioData[];
};

const FormRadioGroup = ({ id, label, control, data = [] }: FormRadioGroupProps) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <FormControl component='fieldset' variant='standard' error={Boolean(fieldState.error)}>
        <FormLabel htmlFor={id} sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle }}>{label}</Typography>
          </LabelWrapper>
        </FormLabel>
        <RadioGroup
          aria-labelledby={id}
          value={field.value ?? ''}
          onChange={(e) => field.onChange(e.target.value)}>
          {data.map((radioItem, index) => (
            <React.Fragment key={index}>
              <FormControlLabel
                value={radioItem.value}
                sx={{ alignItems: 'flex-start', marginTop: '12px' }}
                control={<Radio />}
                label={
                  <>
                    {radioItem.label}
                    <Typography
                      sx={{
                        color: myTheme.palette.subtitle,
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}>
                      Hendrerit mauris eget et maecenas integer volutpat commodo. Risus ipsum sed id
                      consectetur tellus.
                    </Typography>
                  </>
                }
              />
              {radioItem.component && <div style={{ marginTop: '8px' }}>{radioItem.component}</div>}
            </React.Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    )}
  />
);

export default FormRadioGroup;
