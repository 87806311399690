import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';
import {
  CalibrationWrapper,
  Header2,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper
} from 'configurator/components/molecules/CalibrationProcedure/styled';
import useProcedureReply from 'configurator/hooks/useProcedure';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useTranslation } from 'react-i18next';

const I2cCommunicationCheck = ({
  sessionEnabled,
  sessionApi,
  channel,
  handleProcedure,
  disableCalibration
}) => {
  const procedureI2cCommunicationCheck = useProcedureReply(ProcedureTypes.i2cCommunicationCheck);
  const procedureState = useUiStore((state) => state.procedureState);
  const { t } = useTranslation();

  return (
    <>
      <OptionsWithSlider
        header='I2C Communication check'
        toggleOptions={['On', 'Off']}
        disabled
        toggleValue={0}
        sliderValue={0}
        handleSliderChange={() => false}
        sliderLimits={{ min: 0, max: 0 }}
        sliderLabel={<></>}
        handleToggleChange={() => false}
        handleSliderOnAfterChange={() => false}
        description={t(
          'configurator:component.i2c_communication_check.description',
          'Check if internal communication of the hand is working correctly. Pattern recognition system connection will be checked as well if it is present.'
        )}
        hideInput
      />
      <CalibrationWrapper>
        {procedureI2cCommunicationCheck && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>
              {t(
                'configurator:component.i2c_communication_check.procedure_results',
                'Procedure results'
              )}
            </Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <Header2 as='h4'>
                    {t(
                      'configurator:component.i2c_communication_check.procedure_results',
                      'Procedure results'
                    )}
                  </Header2>
                  <ProcedureStatusBox status={procedureI2cCommunicationCheck.rtcInitialization}>
                    {procedureI2cCommunicationCheck.rtcInitialization === 1
                      ? t('configurator.constants.utils.success', 'Success')
                      : t('configurator.constants.utils.failure', 'Failure')}
                  </ProcedureStatusBox>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.i2c_communication_check.external_memory_1',
                      'External memory 1'
                    )}
                  </td>
                  <ProcedureStatusBox status={procedureI2cCommunicationCheck.externalMemory1}>
                    {procedureI2cCommunicationCheck.externalMemory1 === 1
                      ? t('configurator.constants.utils.fund', 'Found')
                      : t('configurator.constants.utils.not_found', 'Not found')}
                  </ProcedureStatusBox>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.i2c_communication_check.external_memory_2',
                      'External memory 2'
                    )}
                  </td>
                  <ProcedureStatusBox status={procedureI2cCommunicationCheck.externalMemory2}>
                    {procedureI2cCommunicationCheck.externalMemory2 === 1
                      ? t('configurator.constants.utils.fund', 'Found')
                      : t('configurator.constants.utils.not_found', 'Not found')}
                  </ProcedureStatusBox>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t('configurator:component.i2c_communication_check.coapt', 'Coapt')}
                  </td>
                  <ProcedureStatusBox status={procedureI2cCommunicationCheck.coapt}>
                    {procedureI2cCommunicationCheck.coapt === 1
                      ? t('configurator.constants.utils.fund', 'Found')
                      : t('configurator.constants.utils.not_found', 'Not found')}
                  </ProcedureStatusBox>
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip
          title={
            disableCalibration
              ? t(
                  'configurator:component.i2c_communication_check.device_not_connected',
                  'Device not connected'
                )
              : null
          }>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={() =>
                handleProcedure({
                  procedureNumber: ProcedureTypes.i2cCommunicationCheck,
                  ...(sessionEnabled && {
                    liveSession: {
                      clinicianUUID: sessionApi?.clinician_uuid,
                      channelName: channel?.name
                    }
                  })
                })
              }>
              <span>{t('configurator:component.i2c_communication_check.check', 'Check')}</span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};
export default I2cCommunicationCheck;
