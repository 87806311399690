import { FormControlLabel, MenuItem, Switch, Tooltip, Typography } from '@mui/material';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import CustomDropdown from 'components/FormFields/CustomDropdown';
import CustomTextField from 'components/FormFields/CustomTextField';
import { gripTypes } from 'constants/hand';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Trash } from 'assets/trash-red.svg';
import { postGripSpeed } from 'configurator/bluetooth-handler/bluetoothFunctions';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import InfoCard from 'components/InfoCard/InfoCard';
import { useUiStore } from 'configurator/reducers/uiStore';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';

export const CustomGripFormAdd = ({
  control,
  showCustomGripTemplates,
  setShowCustomGripTemplates,
  customGripsTemplates,
  handleChangeGripTemplate,
  isLoadingCreate,
  handleSubmit,
  isLoadingCreateAndSaveTemplate,
  handleSubmitNewCustomGrip,
  lastSelectedGrip,
  selectedGripType,
  setCustomGripAddMode,
  setSelectedGripType,
  setItemConfigStore,
  connected
}) => {
  const { t } = useTranslation();
  const { blockScreen, unblockScreen } = useUiStore((state) => ({
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen
  }));

  const tryGrip = async () => {
    try {
      blockScreen(BLOCK_MODALS.TRY_CUSTOM_GRIP);
      await postGripSpeed(100);
      await delay(3000);
      await postGripSpeed(-100);
      await delay(3000);
      await postGripSpeed(0);
    } finally {
      unblockScreen(BLOCK_MODALS.TRY_CUSTOM_GRIP);
    }
  };

  return (
    <>
      <Typography sx={{ textAlign: 'center', marginBottom: '24px' }}>Details</Typography>
      <FormWrapper>
        <CustomTextField
          id='name'
          label={t('configurator:grips_configuration.custom_grip.form.name', 'Name for the grip')}
          control={control}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showCustomGripTemplates}
              onChange={() => setShowCustomGripTemplates((prev) => !prev)}
            />
          }
          label={t(
            'configurator:grips_configuration.custom_grip.form.use_template',
            'Use template'
          )}
        />
        {showCustomGripTemplates && (
          <CustomDropdown
            id='custom-grip-template'
            onChange={handleChangeGripTemplate}
            displayEmpty
            renderValue={(selected) => {
              if (selected === undefined)
                return t(
                  'configurator:grips_configuration.custom_grip.form.select_template',
                  'Select template'
                );

              return customGripsTemplates?.find(
                (customGripTemplate) => customGripTemplate.id === selected
              )?.name;
            }}
            options={customGripsTemplates?.map((customTemplate) => (
              <MenuItem
                key={`selected-grip-template_${customTemplate.name}`}
                value={customTemplate.id}>
                {customTemplate.name}
              </MenuItem>
            ))}
          />
        )}
        <CustomDropdown
          id='thumb-side'
          label={t('configurator:grips_configuration.custom_grip.form.side', 'Grip type')}
          value={selectedGripType}
          onChange={setSelectedGripType}
          options={gripTypes.map((gripType) => (
            <MenuItem key={`selected-grip-type_${gripType.name}`} value={gripType.id}>
              {gripType.name}
            </MenuItem>
          ))}
        />
        {connected && (
          <InfoCard
            headerText={t(
              'configurator:grips_configuration.custom_grip.form.info.preview',
              'Preview'
            )}
            paragraphText={t(
              'configurator:grips_configuration.custom_grip.form.info.paragraph',
              'You can watch the settings being made on the device if it is connected.'
            )}
            buttonAction={tryGrip}
            buttonText={t('configurator:grips_configuration.custom_grip.form.try', 'Check grip')}
          />
        )}
      </FormWrapper>
      <FormButtonsWrapper style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <CustomButton
          color='light'
          onClick={() => {
            setItemConfigStore('currentGrip', lastSelectedGrip);
            setShowCustomGripTemplates(false);
            setCustomGripAddMode(false);
          }}>
          {t('configurator:grips_configuration.custom_grip.form.cancel', 'Cancel')}
        </CustomButton>
        <FormButtonsWrapper marginTop='0'>
          <CustomButton
            color='lightBlue'
            type='submit'
            loading={isLoadingCreateAndSaveTemplate}
            onClick={handleSubmit((e) => handleSubmitNewCustomGrip(e, true))}>
            {t(
              'configurator:grips_configuration.custom_grip.form.create_and_save_as_template',
              'Create and save as template'
            )}
          </CustomButton>
          <CustomButton
            onClick={handleSubmit((e) => handleSubmitNewCustomGrip(e))}
            loading={isLoadingCreate}>
            {t(
              'configurator:grips_configuration.custom_grip.form.create_custom_grip',
              'Create custom grip'
            )}
          </CustomButton>
        </FormButtonsWrapper>
      </FormButtonsWrapper>
    </>
  );
};

export const CustomGripForm = ({
  control,
  selectedGripType,
  setSelectedGripType,
  handleDeleteCustomGrip,
  isLoadingDeleteCustomGrip,
  handleSubmit,
  handleAddCustomGripTemplate,
  handleUpdateCustomGrip,
  isLoadingUpdateCustomGrip
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography sx={{ textAlign: 'center', marginBottom: '24px' }}>Details</Typography>
      <FormWrapper>
        <CustomTextField
          id='name'
          label={t('configurator:grips_configuration.custom_grip.form.name', 'Name for the grip')}
          control={control}
        />
        <CustomDropdown
          id='thumb-side'
          label={t('configurator:grips_configuration.custom_grip.form.side', 'Grip type')}
          value={selectedGripType}
          onChange={setSelectedGripType}
          options={gripTypes.map((gripType) => (
            <MenuItem key={`selected-grip-type_${gripType.name}`} value={gripType.id}>
              {gripType.name}
            </MenuItem>
          ))}
        />
      </FormWrapper>
      <FormButtonsWrapper style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <CustomButton
          color='light'
          Icon={Trash}
          onClick={handleDeleteCustomGrip}
          loading={isLoadingDeleteCustomGrip}
          sx={{ color: '#D92D20' }}>
          {t('configurator:grips_configuration.custom_grip.form.remove', 'Remove')}
        </CustomButton>
        <FormButtonsWrapper marginTop='0'>
          <CustomButton
            color='lightBlue'
            type='submit'
            onClick={handleSubmit(handleAddCustomGripTemplate)}>
            {t(
              'configurator:grips_configuration.custom_grip.form.save_as_template',
              'Save as template'
            )}
          </CustomButton>

          <Tooltip
            title={t(
              'configurator:grips_configuration.custom_grip.form.save_details_tooltip',
              'Saves name and thumb opposition status. To save position settings click on the glowing save icon on the toolbar on the right.'
            )}>
            <span>
              <CustomButton
                onClick={handleSubmit(handleUpdateCustomGrip)}
                loading={isLoadingUpdateCustomGrip}>
                Save details
              </CustomButton>
            </span>
          </Tooltip>
        </FormButtonsWrapper>
      </FormButtonsWrapper>
    </>
  );
};
