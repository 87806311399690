import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { MODALS } from 'configurator/views/Modals';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import ModalBase from './ModalBase';

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;
type AboutProps = {
  handleClose: Function;
};

const BootloaderModeModal = ({ handleClose }: AboutProps) => {
  const openModal = useUiStore((state) => state.openModal);
  const { t } = useTranslation();
  const { disconnectDevice } = useConfigStore();

  const accepted = async () => {
    openModal(MODALS.firmware);
    handleClose();
  };

  const denied = async () => {
    disconnectDevice();
    handleClose();
  };

  return (
    <ModalBase
      header={t(
        'configurator:component.bootloader_mode_modal.firmware_update_mode',
        'Firmware update mode'
      )}
      hideCloseButton
      allowBackDropClose={false}>
      <DialogContent>
        <ParagraphBold>
          {t(
            'configurator:component.bootloader_mode_modal.device_in_firmware_update_mode',
            'Device is in firmware update mode, please update to use the software.'
          )}{' '}
        </ParagraphBold>
        <ButtonsWrapper>
          <Button variant='outlined' onClick={denied}>
            {t('configurator:component.bootloader_mode_modal.no_disconnect', 'No, disconnect')}
          </Button>
          <Button onClick={accepted}>
            {t('configurator:component.bootloader_mode_modal.yes_update', 'Yes, update')}
          </Button>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default BootloaderModeModal;
