const commonPositionsDropdowns = {
  yPositionCommon1: '60%',
  yPositionCommon2: '12%',
  xPositionCommon1: '11%',
  xPositionCommon2: '30%'
};

export const positionsDropdowns = [
  {
    top: commonPositionsDropdowns.yPositionCommon2,
    right: commonPositionsDropdowns.xPositionCommon2,
    transform: 'translateX(50%) translateY(-50%)'
  },
  {
    top: commonPositionsDropdowns.yPositionCommon1,
    right: commonPositionsDropdowns.xPositionCommon1,
    transform: 'translateX(50%) translateY(-50%)'
  },
  { top: '88%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' },
  {
    top: commonPositionsDropdowns.yPositionCommon1,
    left: commonPositionsDropdowns.xPositionCommon1,
    transform: 'translateX(-50%) translateY(-50%)'
  },
  {
    top: commonPositionsDropdowns.yPositionCommon2,
    left: commonPositionsDropdowns.xPositionCommon2,
    transform: 'translateX(-50%) translateY(-50%)'
  }
];

export const arrowWidth = '18%';
const baseDegBottom = 52;
const baseDeg = 144;

const arrowsYDifference1 = 3;
const arrowsYDifference2 = 2;
const arrowsXDifference2 = 3;
const arrowsXDifference1 = 3;
const yPositionCommon1 = 87;
const yPositionCommon2 = 34;
const xPositionCommon1 = 25;
const xPositionCommon2 = 13;

const commonPositionsPrimary = {
  yPositionCommon1: `${yPositionCommon1}%`,
  yPositionCommon2: `${yPositionCommon2}%`,
  xPositionCommon1: `${xPositionCommon1}%`,
  xPositionCommon2: `${xPositionCommon2}%`
};

export const arrowsPositionsPrimary = [
  {
    right: commonPositionsPrimary.xPositionCommon1,
    top: commonPositionsPrimary.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDeg}deg)`
  },
  {
    right: commonPositionsPrimary.xPositionCommon2,
    top: commonPositionsPrimary.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%) rotate(${baseDegBottom}deg)`
  },
  {
    left: '50%',
    top: '12%',
    transform: `translateX(-50%) translateY(-50%) rotate(${0}deg)`
  },
  {
    left: commonPositionsPrimary.xPositionCommon2,
    top: commonPositionsPrimary.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDegBottom}deg)`
  },
  {
    left: commonPositionsPrimary.xPositionCommon1,
    top: commonPositionsPrimary.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%) rotate(-${baseDeg}deg)`
  }
];

const commonPositionsSecondary = {
  yPositionCommon1: `${yPositionCommon1 - arrowsYDifference1}%`,
  yPositionCommon2: `${yPositionCommon2 + arrowsYDifference2}%`,
  xPositionCommon1: `${xPositionCommon2 + arrowsXDifference2}%`,
  xPositionCommon2: `${xPositionCommon1 + arrowsXDifference1}%`
};

export const arrowsPositionsSecondary = [
  {
    right: commonPositionsSecondary.xPositionCommon2,
    top: commonPositionsSecondary.yPositionCommon1,
    transform: `translateX(50%) translateY(-50%) rotateZ(${baseDeg}deg)`
  },
  {
    right: commonPositionsSecondary.xPositionCommon1,
    top: commonPositionsSecondary.yPositionCommon2,
    transform: `translateX(50%) translateY(-50%) rotateZ(${baseDegBottom}deg)`
  },
  {
    left: '50%',
    top: '15%',
    transform: `translateX(-50%) translateY(-50%) rotateZ(${0}deg)`
  },
  {
    left: commonPositionsSecondary.xPositionCommon1,
    top: commonPositionsSecondary.yPositionCommon2,
    transform: `translateX(-50%) translateY(-50%) rotateZ(-${baseDegBottom}deg)`
  },
  {
    left: commonPositionsSecondary.xPositionCommon2,
    top: commonPositionsSecondary.yPositionCommon1,
    transform: `translateX(-50%) translateY(-50%) rotateZ(-${baseDeg}deg)`
  }
];

export const gripSwitchIndicatorPrimary = [
  {
    left: '50%',
    top: '8%',
    transform: `translateX(-50%) translateY(-50%)`
  }
];

export const gripSwitchIndicatorSecondary = [
  {
    left: '50%',
    top: '18%',
    transform: `translateX(-50%) translateY(-50%)`
  }
];
