import React, { useMemo } from 'react';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import { HISTORY_EVENTS, SUPPORTED_FEATURES } from 'configurator/consts/consts';
import { useConfigStore } from 'configurator/reducers/configStore';
import {
  GripSwitchingModes,
  InputDevices,
  InputSites as InputSitesEnum,
  SingleElectrodeMode,
  SpeedControlStrategies
} from 'bluetooth/Bluetooth/Control';
import {
  getCurrentConfigSelector,
  getFwVersionSelector
} from 'configurator/reducers/helpers/selectors';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { ConfigurationSection, SettingsContainer } from './styled';
import EmgSpike from './Features/EmgSpike';
import BatteryBeep from './Features/BatteryBeep';
import HoldOpen from './Features/HoldOpen';
import SingleElectrodeModeSettings from './Features/SingleElectrodeModeSettings';
import PulseTimings from './Features/PulseTimings';
import CoContractionTimings from './Features/CoContractionTimings';
import FreezeModeEMG from './Features/FreezeModeEmg';
import SoftGrip from './Features/Softgrip';
import BuzzingVolumeSettings from './Features/BuzzingVolumeSettings';
import InputOptionsEMG from './Features/InputOptionsEMG';
import InputSites from './Features/InputSites';
import SpeedControlStrategy from './Features/SpeedControlStrategy';
import GripSwitching from './Features/GripSwitching';
import SingleElectrodeStrategy from './Features/SingleElectrodeStrategy';
import FreezeModeEMGSettings from './Features/FreezeModeEMGSettings';
import FollowingGrip from './Features/FollowingGrip';
import FingersSpeed from './Features/FingerSpeedCalibration';
import FreezeModeEMGThresholds from './Features/FreezeModeEmgThresholds';
import EmergencyBatteryBeep from './Features/EmergencyBatterySettings';
import OneSpeed from './Features/OneSpeed';
import SingleElectrodeSettingsAlternating from './Features/SingleElectrodeModeSettingsAlternating';
import Card from 'adp-panel/components/Card/Card';
import CustomHeaderSmall from 'configurator/components/atoms/Typography/CustomHeaderSmall';
import { useTranslation } from 'react-i18next';
import LoaderWrapper from 'components/Loader/Loader';
import { CircularProgress } from '@mui/material';
import useCanAccess from 'adp-panel/hoc/useCanAccess';

export const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

const SettingsWrapper = ({ children, header }) => {
  return (
    <div>
      <CustomHeaderSmall header={header} />
      <Card>
        <SettingsContainer>{children}</SettingsContainer>
      </Card>
    </div>
  );
};

const ProsthesisSettingsComponent = () => {
  const { t } = useTranslation();
  const {
    inputSite,
    inputDevice,
    controlMode,
    speedControlStrategy,
    gripSwitchingMode,
    batteryBeep,
    emgSpike,
    holdOpen,
    softGrip,
    pulseTimings,
    coContractionTimings,
    freezeModeEmg,
    singleElectrodeMode,
    singleElectrodeModeSettings,
    buzzingVolumeSettings,
    userFeedbackType,
    fingerStrength,
    freezeModeEmgSettings,
    followingGrip,
    emergencyBatterySettings,
    oneSpeed,
    singleElectrodeSettingsAlternating
  } = useConfigStore(getCurrentConfigSelector);
  const { setConfigProperty, setControlConfig, handleProcedure } = useConfigStore((state: any) => ({
    setConfigProperty: state.setConfigProperty,
    setControlConfig: state.setControlConfig,
    handleProcedure: state.handleProcedure
  }));

  const { isLoading: isLoadingCanAccessSettings, canAccess: canAccessSettings } = useCanAccess({
    resource: 'settings'
  });
  const { isLoading: isLoadingCanAccessOther, canAccess: canAccessOther } = useCanAccess({
    resource: 'other'
  });
  const { isLoading: isLoadingCanAccessSoftGrip, canAccess: canAccessSoftGrip } = useCanAccess({
    resource: 'soft-grip'
  });
  const { isLoading: isLoadingCanAccessSpeedControl, canAccess: canAccessSpeedControlStrategy } =
    useCanAccess({
      resource: 'speed-control'
    });
  const addConfigHistory = useConfigStore((state: any) => state.addConfigHistory);
  const replayIsEnabled = useReplayStore((state: any) => state.enabled);
  const {
    channel,
    enabled: sessionEnabled,
    sessionApi
  } = useLiveConfiguratorStore((state: any) => ({
    channel: state.channel,
    enabled: state.enabled,
    sessionApi: state.sessionApi
  }));
  const { supportedFeatures, isFullyCompatible } = useCompatibilities();
  const { connected: deviceConnected } = useDeviceInfoStore((state: any) => ({
    connected: state.connected
  }));
  const deviceInfoState = useDeviceInfoStore((state: any) => state);

  const featuresOptions = useMemo(() => {
    let disableFreezeMode = false;
    const featuresSlugs = supportedFeatures
      .filter((feature: any) => feature.is_compatible)
      .map((feature: any) => feature.slug);

    if (!isFullyCompatible) {
      if (!featuresSlugs.includes(SUPPORTED_FEATURES.freezeModeEMG)) disableFreezeMode = true;
    }

    return {
      disableFreezeMode,
      supportNewUserFeedbackType: featuresSlugs.includes(SUPPORTED_FEATURES.feedbackType),
      supportFingerSpeedCalibration: featuresSlugs.includes(SUPPORTED_FEATURES.speedCalibration)
    };
  }, [supportedFeatures, isFullyCompatible]);

  const addHistoryProsthesis = (prevConfig: any) => {
    addConfigHistory(HISTORY_EVENTS.prosthesisSettings, prevConfig);
  };

  const isFreezeModeOn = Boolean(freezeModeEmg?.[2]);

  const normalizeConfig = (value: any, property: keyof DeviceConfigTemplate) => {
    const isNotInitialized = (value: any) => value === false;

    return isNotInitialized(value) ? defaultConfig[`${property}`] : value;
  };

  const configIsSupportedByFirmware = (property: any) => property !== undefined;
  const disableCalibration = replayIsEnabled || (!deviceConnected && !sessionEnabled);

  const coaptSelected = inputDevice?.[0] === InputDevices.kInputOptionPatRec;
  const slopeSelected =
    inputSite?.[0] === InputSitesEnum.kSingleElectrode &&
    singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising;

  if (
    isLoadingCanAccessSettings ||
    isLoadingCanAccessOther ||
    isLoadingCanAccessSoftGrip ||
    isLoadingCanAccessSpeedControl
  )
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );

  return (
    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
      <SettingsWrapper
        header={t('configurator:prosthesis_settings.input_configuration', 'Input configuration')}>
        {configIsSupportedByFirmware(inputDevice) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <InputOptionsEMG
              addHistoryProsthesis={addHistoryProsthesis}
              inputDevice={normalizeConfig(inputDevice, 'inputDevice')}
              inputOptionsPermission={canAccessSettings}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(inputSite) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <InputSites
              addHistoryProsthesis={addHistoryProsthesis}
              inputSite={normalizeConfig(inputSite, 'inputSite')}
              controlMode={normalizeConfig(controlMode, 'controlMode')}
              speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
              gripSwitchingMode={normalizeConfig(gripSwitchingMode, 'gripSwitchingMode')}
              disable={replayIsEnabled || !canAccessSettings || coaptSelected}
              setControlConfig={setControlConfig}
            />
          </ConfigurationSection>
        )}
      </SettingsWrapper>
      <SettingsWrapper header={t('configurator:prosthesis_settings.grip_control', 'Grip control')}>
        {configIsSupportedByFirmware(gripSwitchingMode) &&
          gripSwitchingMode?.[0] !== GripSwitchingModes.kSingleGripSwitching && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <GripSwitching
                addHistoryProsthesis={addHistoryProsthesis}
                inputSite={normalizeConfig(inputSite, 'inputSite')}
                controlMode={normalizeConfig(controlMode, 'controlMode')}
                speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
                gripSwitchingMode={normalizeConfig(gripSwitchingMode, 'gripSwitchingMode')}
                disable={replayIsEnabled || !canAccessSettings || coaptSelected}
                setControlConfig={setControlConfig}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(coContractionTimings) &&
          // @ts-ignore
          gripSwitchingMode?.[0] === GripSwitchingModes.kCoContraction && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <CoContractionTimings
                addHistoryProsthesis={addHistoryProsthesis}
                coContractionTimings={normalizeConfig(coContractionTimings, 'coContractionTimings')}
                disable={replayIsEnabled || !canAccessSettings || coaptSelected}
                setConfigProperty={setConfigProperty}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(singleElectrodeMode) &&
          // @ts-ignore
          gripSwitchingMode?.[0] === GripSwitchingModes.kSingleGripSwitching && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <SingleElectrodeStrategy
                addHistoryProsthesis={addHistoryProsthesis}
                singleElectrodeMode={normalizeConfig(singleElectrodeMode, 'singleElectrodeMode')}
                disable={replayIsEnabled || !canAccessSettings || coaptSelected}
                setConfigProperty={setConfigProperty}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(singleElectrodeModeSettings) &&
          singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
          inputSite?.[0] === InputSitesEnum.kSingleElectrode && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <SingleElectrodeModeSettings
                addHistoryProsthesis={addHistoryProsthesis}
                singleElectrodeModeSettings={normalizeConfig(
                  singleElectrodeModeSettings,
                  'singleElectrodeModeSettings'
                )}
                disable={replayIsEnabled || !canAccessSettings || coaptSelected}
                setConfigProperty={setConfigProperty}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(singleElectrodeSettingsAlternating) &&
          getFwVersionSelector(deviceInfoState) >= 231 &&
          singleElectrodeMode?.[0] === SingleElectrodeMode.kAlternating &&
          inputSite?.[0] === InputSitesEnum.kSingleElectrode && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <SingleElectrodeSettingsAlternating
                addHistoryProsthesis={addHistoryProsthesis}
                singleElectrodeSettingsAlternating={normalizeConfig(
                  singleElectrodeSettingsAlternating,
                  'singleElectrodeSettingsAlternating'
                )}
                disabled={replayIsEnabled || coaptSelected || !canAccessSettings}
                setConfigProperty={setConfigProperty}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(pulseTimings) &&
          (gripSwitchingMode?.[0] === GripSwitchingModes.kOpenOpen ||
            (getFwVersionSelector(deviceInfoState) < 231 &&
              singleElectrodeMode?.[0] === SingleElectrodeMode.kAlternating &&
              gripSwitchingMode?.[0] === GripSwitchingModes.kSingleGripSwitching)) && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <PulseTimings
                addHistoryProsthesis={addHistoryProsthesis}
                pulseTimings={normalizeConfig(pulseTimings, 'pulseTimings')}
                disable={replayIsEnabled || coaptSelected || !canAccessSettings}
              />
            </ConfigurationSection>
          )}
        {Boolean(gripSwitchingMode?.[0] === GripSwitchingModes.kHoldOpen) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <HoldOpen
              addHistoryProsthesis={addHistoryProsthesis}
              holdOpen={normalizeConfig(holdOpen, 'holdOpen')}
              disable={!canAccessSettings || replayIsEnabled || coaptSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(freezeModeEmg) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <FreezeModeEMG
              addHistoryProsthesis={addHistoryProsthesis}
              freezeModeSwitchPermission={canAccessSettings}
              freezeModeEmg={normalizeConfig(freezeModeEmg, 'freezeModeEmg')}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(freezeModeEmgSettings) && isFreezeModeOn && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <FreezeModeEMGSettings
              addHistoryProsthesis={addHistoryProsthesis}
              freezeModeEmgSettings={normalizeConfig(
                freezeModeEmgSettings,
                'freezeModeEmgSettings'
              )}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
              gripSwitchingMode={gripSwitchingMode}
              disabled={!canAccessSettings || replayIsEnabled}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(freezeModeEmg) &&
          isFreezeModeOn &&
          !featuresOptions.disableFreezeMode && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <FreezeModeEMGThresholds
                addHistoryProsthesis={addHistoryProsthesis}
                freezeModeEmg={
                  freezeModeEmg === false ? defaultConfig.freezeModeEmg : freezeModeEmg
                }
                replayIsEnabled={replayIsEnabled}
                setConfigProperty={setConfigProperty}
                disabled={replayIsEnabled || !canAccessSettings}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(followingGrip) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <FollowingGrip
              addHistoryProsthesis={addHistoryProsthesis}
              followingGrip={normalizeConfig(followingGrip, 'followingGrip')}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
              gripSwitchingMode={gripSwitchingMode}
              disabled={!canAccessSettings || replayIsEnabled}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(emgSpike) && (
          <ConfigurationSection
            disabled={!canAccessSettings}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <EmgSpike
              addHistoryProsthesis={addHistoryProsthesis}
              emgSpike={normalizeConfig(emgSpike, 'emgSpike')}
              emgSpikesPermission={canAccessSettings}
              disabled={replayIsEnabled || !canAccessSettings || coaptSelected || slopeSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      </SettingsWrapper>
      <SettingsWrapper
        header={t('configurator:prosthesis_settings.speed_and_strength', 'Speed and strength')}>
        {configIsSupportedByFirmware(speedControlStrategy) && (
          <ConfigurationSection
            disabled={!canAccessSpeedControlStrategy}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <SpeedControlStrategy
              addHistoryProsthesis={addHistoryProsthesis}
              speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
              speedStrategyPermission={canAccessSpeedControlStrategy}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(oneSpeed) &&
          // @ts-ignore
          speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold && (
            <ConfigurationSection
              disabled={!canAccessSettings}
              tooltipText={t(
                'configurator:component.toggle_tip.no_access',
                "You don't have access"
              )}>
              <OneSpeed
                addHistoryProsthesis={addHistoryProsthesis}
                speed={normalizeConfig(oneSpeed, 'oneSpeed')}
                disable={replayIsEnabled || !canAccessSettings}
              />
            </ConfigurationSection>
          )}
        {configIsSupportedByFirmware(softGrip) && (
          <SoftGrip
            addHistoryProsthesis={addHistoryProsthesis}
            softGrip={normalizeConfig(softGrip, 'softGrip')}
            fingerStrength={normalizeConfig(fingerStrength, 'fingerStrength')}
            softGripPermission={canAccessSoftGrip}
            replayIsEnabled={replayIsEnabled}
            sessionApi={sessionApi}
            sessionEnabled={sessionEnabled}
            channel={channel}
            setConfigProperty={setConfigProperty}
            handleProcedure={handleProcedure}
            disableCalibration={disableCalibration}
          />
        )}
        {featuresOptions?.supportFingerSpeedCalibration && (
          <ConfigurationSection
            disabled={false}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <FingersSpeed
              sessionApi={sessionApi}
              sessionEnabled={sessionEnabled}
              channel={channel}
              handleProcedure={handleProcedure}
              disableCalibration={disableCalibration}
            />
          </ConfigurationSection>
        )}
      </SettingsWrapper>
      <SettingsWrapper
        header={t('configurator:prosthesis_settings.battery_and_feedback', 'Battery and feedback')}>
        {configIsSupportedByFirmware(buzzingVolumeSettings) && (
          <ConfigurationSection
            disabled={!canAccessOther}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <BuzzingVolumeSettings
              addHistoryProsthesis={addHistoryProsthesis}
              userFeedbackType={normalizeConfig(userFeedbackType, 'userFeedbackType')}
              buzzingVolumeSettings={normalizeConfig(
                buzzingVolumeSettings,
                'buzzingVolumeSettings'
              )}
              replayIsEnabled={replayIsEnabled}
              supportNewFeedbackType={featuresOptions.supportNewUserFeedbackType}
              setConfigProperty={setConfigProperty}
              disabled={replayIsEnabled || !canAccessOther}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(batteryBeep) && (
          <ConfigurationSection
            disabled={!canAccessOther}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <BatteryBeep
              addHistoryProsthesis={addHistoryProsthesis}
              batteryBeep={normalizeConfig(batteryBeep, 'batteryBeep')}
              setConfigProperty={setConfigProperty}
              disabled={replayIsEnabled || !canAccessOther}
            />
          </ConfigurationSection>
        )}
        {configIsSupportedByFirmware(emergencyBatterySettings) && (
          <ConfigurationSection
            disabled={!canAccessOther}
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}>
            <EmergencyBatteryBeep
              addHistoryProsthesis={addHistoryProsthesis}
              emergencyBatterySettings={normalizeConfig(
                emergencyBatterySettings,
                'emergencyBatterySettings'
              )}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
              disabled={replayIsEnabled || !canAccessOther}
            />
          </ConfigurationSection>
        )}
      </SettingsWrapper>
    </div>
  );
};

export default ProsthesisSettingsComponent;
