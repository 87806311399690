import { useState } from 'react';
import {
  EmgPeakChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { GraphFilters } from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import dayjs from 'dayjs';
import { grips_options } from 'adp-panel/constants/grips';

export const formatData = (date: Date | undefined) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const transformGrips = (grip: string | number) =>
  grip === 'all' ? grips_options.map((item) => item.id) : [Number(grip)];

export const filterParser = {
  default: (
    filters: GraphFilters,
    previousFilters: UsageMonitoryQueryParams
  ): UsageMonitoryQueryParams => {
    if (!filters.device) {
      return previousFilters;
    }
    return {
      ...previousFilters,
      deviceId: filters.device.id,
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  emgPeak: (
    filters: GraphFilters,
    previousFilters: EmgPeakChartQueryParams
  ): EmgPeakChartQueryParams => {
    if (!filters.device) {
      return previousFilters;
    }
    return {
      ...previousFilters,
      deviceId: filters.device.id,
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  hourly: (
    filters: GraphFilters,
    previousFilters: UsageMonitoryQueryParams
  ): UsageMonitoryQueryParams => {
    const { grip } = filters;
    if (!filters?.device?.id || grip === undefined) {
      return previousFilters;
    }

    const deviceId = filters.device.id;
    const transformedGrip = transformGrips(grip);

    return {
      ...previousFilters,
      deviceId,
      ...(transformedGrip && transformedGrip.length > 0 && { grips: transformedGrip }),
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  },
  velocity: (
    filters: GraphFilters,
    previousFilters: VelocityLevelChartQueryParams
  ): VelocityLevelChartQueryParams => {
    const { grip } = filters;

    if (!filters.device || grip === undefined) {
      return previousFilters;
    }

    const transformedGrip = transformGrips(grip);

    return {
      ...previousFilters,
      deviceId: filters.device.id,
      ...(transformedGrip && transformedGrip.length > 0 && { grips: transformedGrip }),
      date_from: formatData(filters?.dateRange?.start),
      date_to: filters?.dateRange?.end ? formatData(filters?.dateRange?.end) : undefined
    };
  }
};

interface BaseQueryParams {
  deviceId?: number;
}

const useDeviceUsageTab = <QueryType extends BaseQueryParams>(
  fetchFunction: Function,
  params = {} as QueryType,
  parser: (_filters: GraphFilters, _params: QueryType) => any = filterParser.default
) => {
  const finalParser = parser ?? filterParser.default;
  const [localParams, setParams] = useState<QueryType>(params);
  const { result: chartData = [], isLoading } = fetchFunction(localParams, !!localParams.deviceId);

  const handleFilterChange = (filters: GraphFilters) => {
    setParams(finalParser(filters, localParams));
  };

  return {
    handleFilterChange,
    chartData,
    isLoading
  };
};

export default useDeviceUsageTab;
