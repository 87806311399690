import authProvider from '../providers/authProvider';
import { useEffect, useState } from 'react';
import { PermissionEntry, Role, RoleEnum, UserEntry } from '../api/users/users.types';
import { difference, flatten } from 'lodash';
import { rolesByName, userHasPermissions } from 'adp-panel/utils/permissionUtils';

interface Props {
  action?: string | null;
  resource: string;
  record?: any;
}

const provider = authProvider;

interface PartElement {
  action: string | null;
  resource: string | null;
}

const transformPermission = (items: PermissionEntry[]): PartElement[] => {
  const parts: PartElement[] = [];
  items.map((permission: PermissionEntry) => {
    const permissionParts = permission.name.split('.');
    parts.push({
      action: permissionParts[2] ?? null,
      resource: permissionParts[1] ?? null
    });
  });

  return parts;
};

export const userHasRole = (user: UserEntry, role: RoleEnum) => {
  return !!user.roles?.find((roleEntry: Role) => roleEntry.name === role);
};

const getAccessStatus = async ({ action, resource }: Props) => {
  const { permissions, roles } = await provider.getPermissions();
  const availablePermissions = transformPermission(permissions);

  let canAccess = false;

  if (
    userHasPermissions(
      [RoleEnum.superAdmin, RoleEnum.clinician, RoleEnum.clinicAdmin],
      rolesByName(roles)
    )
  ) {
    canAccess = true;

    return { canAccess };
  }

  const resourcePermissionsPart = availablePermissions.filter(
    (part: PartElement) => part.resource === resource
  );
  const resourceActionPermission = resourcePermissionsPart.find(
    (part: PartElement) => part.action && part.action === action
  );

  if (!resourcePermissionsPart.length) {
    canAccess = false;
  }

  if (action && resource) {
    resourceActionPermission !== undefined ? (canAccess = true) : (canAccess = false);
  }

  if (action === null && resource && resourcePermissionsPart.length) {
    canAccess = true;
  }

  return { canAccess };
};

const useCanAccess = ({
  action = null,
  resource
}: Props): { readonly isLoading: boolean; readonly canAccess: boolean } => {
  const [isLoadingPermission, setIsLoadingPermission] = useState<boolean>(true);
  const [isCanAccess, setIsCanAccess] = useState<boolean>(false);

  useEffect(() => {
    const getPermissionsAndRoles = async () => {
      const { canAccess } = await getAccessStatus({ action, resource });
      setIsLoadingPermission(false);
      setIsCanAccess(canAccess);
    };
    getPermissionsAndRoles().catch(() => {
      setIsLoadingPermission(false);
    });
  }, []);

  return {
    isLoading: isLoadingPermission,
    canAccess: isCanAccess
  };
};

export const comparePermissions = (permissions: any, permissionToCompare: any) =>
  difference(permissions, permissionToCompare);
export const convertToPermissionString = (permissions: any) =>
  permissions.map((item: any) => `user.${item.id}`);

export const extractUserFromPermission = (
  permissions: PermissionEntry[],
  users: UserEntry[] | undefined
) => {
  const userIds = permissions.map((permission: PermissionEntry) => permission.name.split('.')[1]);
  return users && users.filter((item: UserEntry) => userIds.includes(String(item.id)));
};

export const haveAccessToPatient = (permissions: PermissionEntry[], patientId: number) => {
  const userIds = permissions.map((permission: PermissionEntry) => permission.name.split('.')[1]);
  return userIds.includes(String(patientId));
};

export const extractPermissionsFromApi = (apiPermissions: PermissionEntry[], permissions: any) => {
  const finalResult: { text: string; id: string }[] = [];
  const resources = apiPermissions.map(
    (permission: PermissionEntry) => permission.name.split('.')[1]
  );
  const actions = apiPermissions.map((permission: PermissionEntry) => {
    const parts = permission.name.split('.');
    if (parts[1] === undefined || parts[2] === undefined) {
      return '';
    }
    return `${parts[1]}.${parts[2]}`;
  });

  permissions.forEach((filter: any) => {
    if (filter.items !== undefined) {
      filter.items.forEach((item: any) => {
        if (actions.includes(item.id)) {
          finalResult.push({ text: item.text, id: item.id });
        }
      });
    }

    if (resources.includes(filter.id)) {
      finalResult.push({ text: filter.text, id: filter.id });
    }
  });

  return finalResult;
};

export default useCanAccess;
