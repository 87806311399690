import React from 'react';
import { Button, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useConfigStore } from 'configurator/reducers/configStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 40px;
`;

type RestoreRecentConfigModalProps = {
  handleClose: any;
};

const RestoreRecentConfigModal = ({ handleClose }: RestoreRecentConfigModalProps) => {
  const closeModal = useUiStore((state) => state.closeModal);
  const { t } = useTranslation();
  const { getInitialConfigAPI, clearConfigHistory } = useConfigStore((state) => ({
    getInitialConfigAPI: state.getInitialConfigAPI,
    clearConfigHistory: state.clearConfigHistory
  }));
  const initialConfigApiState = useUiStore((state) => state.initialConfigApiState);
  const restoreRecentChannel = new BroadcastChannel('handleRestoreRecent');

  const restoreRecentConfig = async () => {
    clearConfigHistory();
    await getInitialConfigAPI();
    closeModal(MODALS.restoreRecent);
    restoreRecentChannel.postMessage({ event: 'restoreRecent' });
  };

  return (
    <ModalBase
      handleClick={handleClose}
      header={t(
        'configurator:component.restore_recent_config_modal.restore_configuration',
        'Restore configuration'
      )}>
      <DialogContent sx={{ width: '450px' }}>
        <ParagraphBold>
          {t(
            'configurator:component.restore_recent_config_modal.confirm_restore_recent',
            'Do you want to restore recent config? Unsaved changes will be lost.'
          )}
        </ParagraphBold>
        <ButtonsWrapper>
          <Button onClick={handleClose} variant='outlined' type='button'>
            {t('configurator:component.restore_recent_config_modal.cancel', 'Cancel')}
          </Button>
          <LoadingButton
            type='button'
            onClick={restoreRecentConfig}
            loading={initialConfigApiState === FETCHING_STATES.loading}>
            <span>
              {t(
                'configurator:component.restore_recent_config_modal.restore_recent',
                'Restore recent'
              )}
            </span>
          </LoadingButton>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default RestoreRecentConfigModal;
