import { getter } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useEffect, useState } from 'react';
import React from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useUserData from '../../../hooks/useUserData';
import { RoleEnum, UserExtendOptions } from '../../api/users/users.types';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { FormDropDownList } from '../../components/FormInput/FormDropdownList';
import FormInput from '../../components/FormInput/FormInput';
import FormMultiSelect from '../../components/FormInput/FormMultiSelect';
import FormMultiSelectTree from '../../components/FormInput/FormMultiSelectTree';
import { FormPasswordTextBox } from '../../components/FormInput/FormPasswordTextBox';
import FormUpload from '../../components/FormInput/FormUpload';
import {
  emailValidator,
  requiredValidator,
  requiredValidatorTree,
  strongPasswordValidator
} from '../../components/FormInput/validators';
import GeneralLabel from '../../components/Labels/GeneralLabel';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { permissions } from '../../constants/permissions';
import {
  ALLIED_PERMISSIONS,
  CLINIC_ADMIN_PERMISSIONS,
  CLINICIANS_PERMISSIONS
} from '../../constants/rolesPermissions';
import {
  extractPermissionsFromApi,
  extractUserFromPermission,
  userHasRole
} from '../../hoc/useCanAccess';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import { useLocationsInfinite } from '../../hooks/api/useLocations';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import { rolesNamesMap } from '../../utils/definesLocal';
import { userHasPermissions } from '../../utils/permissionUtils';
import { FormButtonsWrapper, FormLoaderWrapper } from './styled';

interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  user?: any;
  isUpdate?: boolean;
  isAdmin?: boolean;
  roleInitial?: RoleEnum;
}

const rolesData = [
  {
    label: rolesNamesMap.get(RoleEnum.clinician),
    id: RoleEnum.clinician,
    requiredRoles: CLINICIANS_PERMISSIONS.CREATE
  },
  {
    label: rolesNamesMap.get(RoleEnum.clinicianSupport),
    id: RoleEnum.clinicianSupport,
    requiredRoles: ALLIED_PERMISSIONS.CREATE
  },
  {
    label: rolesNamesMap.get(RoleEnum.clinicAdmin),
    id: RoleEnum.clinicAdmin,
    requiredRoles: CLINIC_ADMIN_PERMISSIONS.CREATE
  }
];

const ClinicianForm = ({
  onSubmit,
  user = undefined,
  isLoading = false,
  isUpdate = false,
  isAdmin = false,
  roleInitial = RoleEnum.clinician
}: Props) => {
  const { t } = useTranslation('clinicians');
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const { data: userData, rolesByName } = useUserData();
  const [selectedRole, setSelectedRole] = useState<RoleEnum>(roleInitial);
  const [isPasswordOptional, setIsPasswordOptional] = useState<boolean>(true);
  const [formUpdateKey, setFormUpdateKey] = useState(1);
  const { result: locations, refetch: refetchLocations } = useLocationsInfinite(
    {
      company: userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
        ? userData?.location?.company_id
        : selectedCompany?.toString(),
      perpage: 100
    },
    !!selectedCompany
  );

  const {
    result: patients,
    isLoading: patientIsLoading,
    refetch: refetchPatients
  } = useUsersInfinite(
    {
      extend: [UserExtendOptions.roles, UserExtendOptions.devices, UserExtendOptions.permissions],
      company: selectedCompany,
      roles: 'Amputee'
    },
    !!selectedCompany
  );
  const { result: companies } = useCompaniesInfinite({ perpage: 100 }, isAdmin);
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    onSubmit(e);
  };

  useEffect(() => {
    if (user?.location?.company) {
      setSelectedCompany(user.location.company.id);
    }
    if (user?.role) {
      setSelectedRole(user.role.name);
    }
  }, []);

  useEffect(() => {
    if (
      !selectedCompany &&
      userHasPermissions(ALLIED_PERMISSIONS.CREATE, rolesByName) &&
      userData?.location?.company_id &&
      !isUpdate
    ) {
      setSelectedCompany(userData.location.company_id);
    }
  }, [userData]);

  useEffect(() => {
    refetchLocations().then(() => {
      setFormUpdateKey((prev) => prev + 1);
    });
  }, [selectedCompany, JSON.stringify(companies)]);

  useEffect(() => {
    refetchPatients().then(() => {
      setFormUpdateKey((prev) => prev + 1);
    });
  }, [selectedCompany, JSON.stringify(patients)]);

  const onCompanyChange = (e: any) => {
    const company = e.value;
    setSelectedCompany(company.id);
  };

  const firstNameGetter: any = getter('password');
  const lastNameGetter: any = getter('retypePassword');
  const emailGetter: any = getter('email');
  const confirmEmailGetter: any = getter('retypeEmail');
  const firstOrLastNameValidator = (values: any) => {
    const VALIDATION_SUMMARY = {
      ['retypePassword']: '',
      ['retypeEmail']: ''
    };

    if (emailGetter(values) !== confirmEmailGetter(values)) {
      VALIDATION_SUMMARY['retypeEmail'] = t(
        'clinicians:component.clinician_form.validation.emails_must_match',
        'Emails must match'
      );
    }

    if (firstNameGetter(values) !== lastNameGetter(values)) {
      VALIDATION_SUMMARY['retypePassword'] = t(
        'clinicians:component.clinician_form.validation.passwords_must_match',
        'Passwords must match'
      );
    }

    if (isPasswordOptional) {
      VALIDATION_SUMMARY['retypePassword'] = '';
    }

    return VALIDATION_SUMMARY;
  };

  if (isUpdate && patientIsLoading) {
    return null;
  }

  const initialFormValues = user
    ? {
        ...user,
        company: { name: user?.location?.company?.name, id: user?.location?.company?.id },
        location: { name: user?.location?.name, id: user?.location?.id },
        retypeEmail: user?.email,
        role: rolesData.find((_role) => _role.id === user.role?.name),
        permissions: extractPermissionsFromApi(user.permissions ?? [], permissions),
        ...(user.permissions &&
          user.permissions.length > 0 && {
            patients: extractUserFromPermission(user.permissions, patients)
          })
      }
    : {
        ...user,
        role: {
          label: rolesData.find((role) => role.id === roleInitial)!.label,
          id: roleInitial
        }
      };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      key={JSON.stringify(initialFormValues)}
      validator={firstOrLastNameValidator}
      render={(formRenderProps: FormRenderProps) => {
        const formPatients = patients?.filter(
          (patient: any) =>
            !formRenderProps
              .valueGetter('patients')
              ?.find((extractedPatient: any) => extractedPatient.id === patient.id)
        );

        return (
          <FormElement>
            <Field
              id={'name'}
              name={'name'}
              label={t('clinicians:component.clinician_form.fields.name', 'Name')}
              validator={requiredValidator}
              component={FormInput}
              autoComplete='new-password'
            />
            <Field
              id={'email'}
              name={'email'}
              label={t('clinicians:component.clinician_form.fields.email', 'Email')}
              component={FormInput}
              validator={emailValidator}
              autoComplete='new-password'
            />
            <Field
              id={'retypeEmail'}
              name={'retypeEmail'}
              label={t('clinicians:component.clinician_form.fields.retype_email', 'Re-type email')}
              component={FormInput}
              validator={emailValidator}
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
              autoComplete='new-password'
            />
            {!isUpdate && (
              <>
                <Field
                  id={'password'}
                  name={'password'}
                  label={
                    <Tooltip anchorElement='target' position='top'>
                      <span
                        title={t(
                          'clinicians:component.clinician_form.fields.password_tooltip',
                          'If the password is not created, the patient must set it when logging in for the first time after clicking on the link with the invitation to the platform.'
                        )}>
                        {t('clinicians:component.clinician_form.fields.password', 'Password')}
                      </span>
                    </Tooltip>
                  }
                  component={FormPasswordTextBox}
                  onChange={(e) => {
                    setIsPasswordOptional(e.value.length === 0);
                  }}
                  validator={isPasswordOptional ? undefined : strongPasswordValidator}
                  optional={isPasswordOptional}
                  autoComplete='new-password'
                />
                <Field
                  id={'retypePassword'}
                  name={'retypePassword'}
                  label={t(
                    'clinicians:component.clinician_form.fields.retype_password',
                    'Re-type password'
                  )}
                  component={FormPasswordTextBox}
                  validator={isPasswordOptional ? undefined : strongPasswordValidator}
                  optional={isPasswordOptional}
                  autoComplete='new-password'
                />
              </>
            )}
            {isAdmin && companies && (
              <Field
                id={'company'}
                name={'company'}
                label={t('clinicians:component.clinician_form.fields.company', 'Company')}
                component={FormComboBox}
                data={companies}
                filterable={true}
                textField='name'
                onChange={(e) => {
                  onCompanyChange(e);
                  formRenderProps.onChange('location', { value: null });
                }}
                onFilterChange={() => {
                  formRenderProps.onChange('location', { value: null });
                }}
                validator={requiredValidator}
                disabled={Boolean(user?.location?.company)}
              />
            )}
            {userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName) &&
              (locations ? (
                <Field
                  id={'location'}
                  name={'location'}
                  label={t('clinicians:component.clinician_form.fields.location', 'Location')}
                  component={FormComboBox}
                  data={locations}
                  filterable={true}
                  textField='name'
                  key={`${formUpdateKey}-${formRenderProps.valueGetter('company')}`}
                  disabled={
                    userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
                      ? false
                      : !formRenderProps.valueGetter('company')
                  }
                  onChange={(e) => {
                    const location = e.value;
                    if (!location && !isUpdate) {
                      formRenderProps.onChange('company', { value: null });
                      formRenderProps.onChange('location', { value: null });
                      formRenderProps.onChange('patients', { value: null });
                    }
                  }}
                  validator={requiredValidator}
                />
              ) : (
                <FormLoaderWrapper>
                  <Loader />
                </FormLoaderWrapper>
              ))}
            <Field
              id={'role'}
              name={'role'}
              label={t('clinicians:component.clinician_form.fields.role', 'Role')}
              component={FormDropDownList}
              validator={requiredValidator}
              data={rolesData.filter((role) =>
                userHasPermissions(
                  role?.requiredRoles ? role.requiredRoles : undefined,
                  rolesByName
                )
              )}
              textField='label'
              dataItemKey='id'
              onChange={(e) => {
                setSelectedRole(e.value.id);
              }}
            />
            {selectedRole === RoleEnum.clinicAdmin && !isUpdate && (
              <GeneralLabel
                style={{ marginTop: '16px' }}
                type='info'
                text={t(
                  'clinicians:component.clinician_form.fields.clinic_admin_info',
                  'User with the Clinic Admin role will set up password when logging in for the first time. Additionally, for this type of user, two-step authorization is required.'
                )}
              />
            )}
            {selectedRole === RoleEnum.clinicianSupport && (
              <>
                <Field
                  data-testid='patients'
                  id={'patients'}
                  name={'patients'}
                  label={t(
                    'clinicians:component.clinician_form.fields.choose_patients',
                    'Choose patients'
                  )}
                  component={FormMultiSelect}
                  data={formPatients ?? []}
                  key={`${formUpdateKey}-${formRenderProps.valueGetter('patients')}`}
                  filterable={true}
                  textField='name'
                  validator={requiredValidatorTree}
                  disabled={
                    userHasRole(userData, RoleEnum.superAdmin) &&
                    !formRenderProps.valueGetter('company')
                  }
                />
                <Field
                  data-testid='permissions'
                  id={'permissions'}
                  name={'permissions'}
                  label={t(
                    'clinicians:component.clinician_form.fields.permissions',
                    'Permissions to ADP and Zeus Configurator'
                  )}
                  component={FormMultiSelectTree}
                  data={permissions}
                  filterable={true}
                  textField='text'
                  subItemsField='items'
                  expandField='expanded'
                  checkField='checkField'
                  dataItemKey='id'
                  checkIndeterminateField='checkIndeterminateField'
                  validator={requiredValidatorTree}
                />
              </>
            )}
            <Field
              data-testid='image'
              id={'image'}
              name={'image'}
              label={'Avatar'}
              component={FormUpload}
              restrictions={{
                allowedExtensions: ['.jpg', '.png', '.gif'],
                maxFileSize: 5000000 // 5mb in bytes
              }}
              hint={t(
                'clinicians:component.clinician_form.fields.avatar_hint',
                'Allowed formats: png / jpg / gif. Max 5mb file.'
              )}
              optional={true}
            />
            <FormButtonsWrapper className='k-form-buttons'>
              <Button
                onClick={() => navigate(-1)}
                type='button'
                data-testid='cancel-clinician-form'>
                {t('clinicians:component.clinician_form.buttons.cancel', 'Cancel')}
              </Button>
              <Button
                data-testid='submit-clinician-form'
                themeColor={'primary'}
                type='submit'
                disabled={!formRenderProps.allowSubmit || isLoading}>
                {isUpdate
                  ? t('clinicians:component.clinician_form.buttons.save', 'Save')
                  : t('clinicians:component.clinician_form.buttons.create', 'Create')}
                {isLoading && <LoaderButton />}
              </Button>
            </FormButtonsWrapper>
          </FormElement>
        );
      }}
    />
  );
};

export default ClinicianForm;
