import { InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from 'adp-panel/utils/parsePhone';
import { commonFormStyles, LabelStyle, LabelWrapper } from 'components/FormFields/commonStyles';
import CustomPhoneSelect from 'components/FormFields/CustomPhoneSelect';
import { useEffect, useState } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ValidCheck } from 'assets/check-circle.svg';
import { ReactComponent as InValidCheck } from 'assets/info-circle.svg';
import CustomTextField from './CustomTextField';

const CustomEmailField = ({
  control,
  label,
  id,
  isValidPhoneNumber = false,
  optional = false,
  styleForLabel = {}
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <div
          style={{
            width: '100%'
          }}>
          <InputLabel
            shrink={false}
            htmlFor={id}
            sx={{ marginBottom: commonFormStyles.labelMarginBottom, ...styleForLabel }}>
            <LabelWrapper>
              <Typography sx={{ ...LabelStyle }}>{label}</Typography>
              {optional && <Typography>(optional)</Typography>}
            </LabelWrapper>
          </InputLabel>
          <CustomTextField
            label=''
            id='email'
            control={control}
            slotProps={{
              input: {
                sx: {
                  padding: 0
                },
                endAdornment: (
                  <InputAdornment position='end' style={{ marginRight: '14px' }}>
                    {isValidPhoneNumber ? <ValidCheck /> : <InValidCheck />}
                  </InputAdornment>
                )
              }
            }}
          />
        </div>
      )}
    />
  );
};

export default CustomEmailField;
