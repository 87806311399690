/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { Alert, DialogContent } from '@mui/material';
import platform from 'platform';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import Divider from 'configurator/components/atoms/Divider/Divider';
import BootloaderController from 'configurator/bluetooth-handler/bootloaderController';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceUpdate } from 'configurator/hooks/api/useDevice';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import {
  ALLOW_MANUAL_UPDATE,
  CURRENT_BOOTLOADER_VERSION,
  FETCHING_STATES
} from 'configurator/consts/consts';
import { Blinker } from 'configurator/utils/Blinker';
import { BootloaderStates } from 'bluetooth/Bluetooth/Defines';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useUiStore } from 'configurator/reducers/uiStore';
import ModalBase from '../ModalBase';
import { Header1, Regular } from './styled';
import { UPDATE_STATES } from './utils';
import {
  NewestVersionIndicator,
  StartUpdateComponent,
  UpdateInProgress,
  VersionNotSupported
} from './components';
import { MODALS } from 'configurator/views/Modals';
import { useNavigate } from 'react-router-dom';
import { CHOOSE_GRIPS } from 'constants/routes';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';

const Bootloader = new BootloaderController();
const BlinkerTab = new Blinker('#elevatorMusicId');

const FirmwareModal = ({ handleClose }) => {
  const [currentPart, setCurrentPart] = useState(0);
  const [maxParts, setMaxParts] = useState(0);
  const remoteSession = useLiveConfiguratorStore((state) => state.enabled);
  const [updateStatus, setUpdateStatus] = useState<UPDATE_STATES>(
    remoteSession ? UPDATE_STATES.inProgress : UPDATE_STATES.notInitialized
  );
  const { bluetoothConnect, checkEmergencyMode } = useBluetooth();
  const { connectionState, firmwareUpdateState, setItemUiStore, closeModal } = useUiStore(
    (state) => ({
      connectionState: state.connectionState,
      firmwareUpdateState: state.firmwareUpdateState,
      setItemUiStore: state.setItemUiStore,
      closeModal: state.closeModal
    })
  );
  const {
    deviceId,
    deviceConnected,
    supported: updateIsSupportedFomCurrentFw,
    versions,
    pcbVersion,
    bluetoothId
  } = useDeviceInfoStore((state) => ({
    deviceId: state.deviceId,
    deviceConnected: state.connected,
    supported: state.supported,
    versions: state.versions,
    pcbVersion: state.pcbVersion,
    bluetoothId: state.bluetoothId
  }));
  const {
    availableFirmwares,
    isFirmwareUpdateAvailable,
    isFirmwareUpdateNeeded,
    deviceFirmwareUnknown
  } = useCompatibilities();
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const navigate = useNavigate();
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const recentFirmware = `${availableFirmwares?.[0]?.[`file_firmware_v${pcbVersion}`]}`;
  const recentBootloader = `${availableFirmwares?.[0]?.[`file_bootloader_v${pcbVersion}`]}`;
  const isMacOs = platform?.os?.family === 'OS X';

  const getFirmwareFiles = async (firmwareURL) => {
    try {
      const request = await fetch(firmwareURL);
      if (request.ok) {
        const bytes = await request.arrayBuffer();
        Bootloader.updateBootloaderParts(bytes);
        const max = Bootloader.getMaxParts();
        setCurrentPart(0);
        setMaxParts(max);
        return true;
      }
      throw new Error('Bad response status');
    } catch (err) {
      console.log(err);
      setUpdateStatus(UPDATE_STATES.failedToFetch);
      return false;
    }
  };

  const updateFailed =
    updateStatus === UPDATE_STATES.fixFailed || updateStatus === UPDATE_STATES.failed;

  const manualReconnect = async (btId: any = null) => {
    const connected = await bluetoothConnect(btId);
    if (connected && !updateFailed) closeModal(MODALS.firmware);
  };

  const updateManual = async () => {
    const isInEmergency = await checkEmergencyMode();
    if (isInEmergency) return;
    setUpdateStatus(UPDATE_STATES.inProgress);
    BlinkerTab.start();
    const status = await Bootloader.updateFirmwareNew();
    setUpdateStatus(
      status === BootloaderStates.complete ? UPDATE_STATES.successful : UPDATE_STATES.failed
    );
    BlinkerTab.stop();

    await disconnectDevice();

    // If update is successful, set isAfterUpdate and copy current settings
    if (status === 2) {
      await updateDevice({
        deviceId: Number(deviceId),
        data: { firmware_version_id: availableFirmwares[0].id }
      });
      await getInitialConfigAPI();
    }

    if (isMacOs) return;

    // Auto-reconnect
    await delay(5000);
    manualReconnect(bluetoothId);
  };

  const loadFile = async () => {
    const firmware: HTMLInputElement | null = document.querySelector('#firmwareInput');

    if (!firmware) return;

    const files = firmware.files![0];
    await getFirmwareFiles(window.URL.createObjectURL(files));
    updateManual();
  };

  const automaticConnection = async (tries = 2) => {
    let attempt = 0;
    const connectHand = async () => {
      await delay(5000);
      const connected = await bluetoothConnect(bluetoothId);

      attempt += 1;
      if (!connected) {
        if (attempt < tries) return connectHand();
        return false;
      }
      return true;
    };
    return connectHand();
  };

  const updateAutomatic = async (firmwareFileUrl, bootloaderFileUrl) => {
    const isInEmergency = await checkEmergencyMode();
    if (isInEmergency) return;
    setItemUiStore('firmwareUpdateState', FETCHING_STATES.loading);
    BlinkerTab.start();
    try {
      await getInitialConfigAPI();
      const fetchStatus = await getFirmwareFiles(firmwareFileUrl);
      if (fetchStatus) {
        let firmwareInstallStatus;
        setUpdateStatus(UPDATE_STATES.inProgress);
        const bootloaderVersion = await Bootloader.checkBootloaderVersion();
        if (bootloaderVersion < CURRENT_BOOTLOADER_VERSION) {
          setUpdateStatus(UPDATE_STATES.inProgressFix);
          await getFirmwareFiles(bootloaderFileUrl);
          const statusBootloaderUpdate = await Bootloader.updateFirmware();
          setUpdateStatus(
            statusBootloaderUpdate === BootloaderStates.complete
              ? UPDATE_STATES.fixSuccessful
              : UPDATE_STATES.fixFailed
          );
        }
        if (updateStatus !== UPDATE_STATES.fixFailed) {
          await delay(2000);
          await getFirmwareFiles(firmwareFileUrl);
          firmwareInstallStatus = await Bootloader.updateFirmwareNew();
          setUpdateStatus(
            firmwareInstallStatus === BootloaderStates.complete || firmwareInstallStatus === false
              ? UPDATE_STATES.successful
              : UPDATE_STATES.failed
          );
          BlinkerTab.stop();
        }

        await disconnectDevice();

        // If update is successful, set isAfterUpdate and copy current settings
        if (
          firmwareInstallStatus === BootloaderStates.complete ||
          firmwareInstallStatus === false
        ) {
          await updateDevice({
            deviceId: Number(deviceId),
            data: { firmware_version_id: availableFirmwares[0].id }
          });
          await getInitialConfigAPI();
          localStorage.setItem(`calibrationNeeded-${bluetoothId}`, 'true');
        }
        setItemUiStore('firmwareUpdateState', FETCHING_STATES.successful);

        if (isMacOs) return;

        // Auto-reconnect
        const connected = await automaticConnection();

        if (connected === false) setUpdateStatus(UPDATE_STATES.reconnectionUnsuccessful);

        return;
      }
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
      BlinkerTab.stop();
    } catch (e) {
      console.log(e);
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
      BlinkerTab.stop();
    }
  };

  const updateCurrentPart = (data) => {
    setCurrentPart(data.detail);
  };

  function handleLeave(event) {
    event.returnValue = 'Sure you want to leave?';
  }

  const updateRunning =
    updateStatus === UPDATE_STATES.inProgress ||
    updateStatus === UPDATE_STATES.successful ||
    updateStatus === UPDATE_STATES.failed ||
    updateStatus === UPDATE_STATES.inProgressFix ||
    updateStatus === UPDATE_STATES.fixSuccessful ||
    updateStatus === UPDATE_STATES.reconnectionUnsuccessful;

  // Update running or was successful
  const updateRunningNoErrors =
    updateStatus === UPDATE_STATES.inProgress ||
    updateStatus === UPDATE_STATES.inProgressFix ||
    updateStatus === UPDATE_STATES.fixSuccessful;

  const firmwareVersionCurrentParsed =
    versions?.current?.[1] !== undefined &&
    versions?.current?.[4] !== undefined &&
    versions?.current?.[7] !== undefined
      ? `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`
      : '';
  const inBootloaderMode = deviceConnected && !versions?.current?.[1];

  const allowUpdate =
    isFirmwareUpdateAvailable ||
    isFirmwareUpdateNeeded ||
    inBootloaderMode ||
    deviceFirmwareUnknown;

  // Stops close modal button to have any effect
  const preventClosing = () => false;

  const handleHideCloseButton = () => {
    if (updateRunning) return true;

    // Currently connected device has too low FW (1.7 or earlier)
    if (!updateIsSupportedFomCurrentFw) return true;

    // Previous FW update failed, device is in emergency mode
    if (inBootloaderMode) return true;
  };

  const handleClickClose = () => {
    if (updateRunningNoErrors) return preventClosing;
    // Currently connected device has too low FW (1.7 or earlier)
    if (!updateIsSupportedFomCurrentFw) return preventClosing;

    return handleClose();
  };

  const handleCloseUpdate = () => {
    if (deviceConnected) {
      disconnectDevice();
    }
    handleClose();
  };

  const handleRestartUpdate = () => {
    updateAutomatic(recentFirmware, recentBootloader);
  };

  const handleUpdateFirmwareProgressRemote = (data) => {
    const progress = data?.detail?.data?.progress;
    const isBootloader = data?.detail?.data?.isBootloader;

    if (isBootloader && updateStatus !== UPDATE_STATES.inProgressFix) {
      setUpdateStatus(UPDATE_STATES.inProgressFix);
    }
    if (progress) {
      setCurrentPart(progress);
    }
  };

  const handleUpdateFirmwareFinishedRemote = (data) => {
    const result = data?.detail?.data?.result;

    if (result === 1) {
      setUpdateStatus(UPDATE_STATES.successful);
    }

    if (result === 0) {
      setUpdateStatus(UPDATE_STATES.failed);
    }
  };

  const handleCloseRemote = async () => {
    closeModal(MODALS.firmware);
    await getInitialConfigAPI();
  };

  useEffect(() => {
    navigate(CHOOSE_GRIPS);

    window.addEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
    window.addEventListener('beforeunload', handleLeave);
    window.addEventListener(`firmware_update_progress`, handleUpdateFirmwareProgressRemote);
    window.addEventListener(`firmware_update_result`, handleUpdateFirmwareFinishedRemote);

    return function clean() {
      window.removeEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
      window.removeEventListener(`beforeunload`, handleLeave);
      window.removeEventListener(`firmware_update_progress`, handleUpdateFirmwareProgressRemote);
      window.addEventListener(`firmware_update_result`, handleUpdateFirmwareFinishedRemote);
    };
  }, []);

  const partPercentage = remoteSession
    ? currentPart
    : maxParts
      ? Math.ceil((currentPart / maxParts) * 100)
      : 0;

  return (
    <ModalBase
      header='Update firmware'
      hideCloseButton={handleHideCloseButton()}
      handleClick={handleClickClose}
      allowBackDropClose={false}
      fullWidth
      maxWidth='sm'>
      <audio
        id='elevatorMusicId'
        src='https://aetherbiomedical-images.s3.amazonaws.com/elevator.mp3'
        preload='auto'
        loop
      />
      <DialogContent>
        {allowUpdate && (
          <>
            {updateStatus !== UPDATE_STATES.successful && !remoteSession && (
              <Alert severity='info'>
                Some browsers may limit the Bluetooth connection in the event of inactivity.
                Relaxing music will be played for you to maintain the connection while waiting for
                the update.
              </Alert>
            )}
            <Divider margin='10px' />
            {updateRunningNoErrors && (
              <>
                <Alert severity='warning'>
                  Do not turn off the device, close the application or change active browser tab
                  until firmware update will be done.
                </Alert>
                <Divider margin='10px' />
              </>
            )}
          </>
        )}
        {!inBootloaderMode && !updateRunning && (
          <>
            <Header1>
              Device version: <Regular>{firmwareVersionCurrentParsed}</Regular>
            </Header1>
            <NewestVersionIndicator
              allowUpdate={allowUpdate}
              availableFirmwares={availableFirmwares}
            />
          </>
        )}
        {(updateStatus === UPDATE_STATES.notInitialized ||
          updateStatus === UPDATE_STATES.failedToFetch) && (
          <StartUpdateComponent
            updateAutomatic={() => updateAutomatic(recentFirmware, recentBootloader)}
            firmwareUpdateState={firmwareUpdateState}
            allowUpdate={allowUpdate}
            pcbVersion={pcbVersion}
            inBootloaderMode={inBootloaderMode}
            loadFile={loadFile}
            allowManualUpdate={ALLOW_MANUAL_UPDATE}
          />
        )}
        {!updateIsSupportedFomCurrentFw && <VersionNotSupported />}
        {updateRunning && (
          <UpdateInProgress
            part={partPercentage}
            updateRunningNoErrors={updateRunningNoErrors}
            updateStatus={updateStatus}
            handleRestartUpdate={handleRestartUpdate}
            isMacOs={isMacOs}
            deviceConnected={deviceConnected}
            handleClose={handleCloseUpdate}
            isRemote={remoteSession}
            handleCloseRemote={handleCloseRemote}
          />
        )}
        {!deviceConnected && isMacOs && !remoteSession && (
          <>
            <Divider margin='10px' />
            <LoadingButton
              loading={connectionState === FETCHING_STATES.loading}
              fullWidth
              onClick={() => manualReconnect()}>
              <span>Reconnect</span>
            </LoadingButton>
          </>
        )}
      </DialogContent>
    </ModalBase>
  );
};

export default FirmwareModal;
