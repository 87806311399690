import styled from 'styled-components';

export const GraphWrapper = styled.div`
  width: 100%;
  //padding: 24px 32px;
`;

export const Header = styled.h3`
  color: #101828;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  display: flex;
  justify-content: space-between;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  column-gap: 36px;
  row-gap: 10px;
`;

export const GraphComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 16px;
`;

export const GraphComponentInsideContainer = styled.div`
  height: 100%;
  max-height: 500px;
`;

export const TotalComponent = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
`;

export const CenterLegendText = styled.text`
  fill: #1d2b4f;
  font-size: 24px;
  font-weight: bold;
`;

export const SubLegendText = styled.text`
  margin-top: 15px;
  font-size: 14px;
  fill: #626c84;
`;
