/* eslint-disable no-restricted-syntax */
import { compareConfigs } from 'configurator/reducers/helpers/bluetoothHelpers';
import { gripsGroupsOptionsMap } from 'configurator/utils/definesLocal';
import { ConfigPayloadObject } from 'configurator/api/device/device.types';
import i18next from 'i18next';

export const transformConfig = (config) => {
  const gripsTransformed: any[] = [];
  let gripSequentialConfig: any[] = [];
  let gripPairsConfig: any[] = [];
  if (config.gripsPositions) {
    for (const grip of Object.keys(config.gripsPositions)) {
      gripsTransformed.push(gripsGroupsOptionsMap.get(parseInt(grip, 10)));
    }
  }
  if (config.gripPairsConfig) {
    gripPairsConfig = config.gripPairsConfig.map((grip) =>
      i18next.t(gripsGroupsOptionsMap.get(grip))
    );
  }
  if (config.gripSequentialConfig) {
    gripSequentialConfig = config.gripSequentialConfig.map((grip) =>
      i18next.t(gripsGroupsOptionsMap.get(grip))
    );
  }

  return { gripsTransformed, gripPairsConfig, gripSequentialConfig };
};

export const transformAndApply = (completeConfig) => {
  const { gripsTransformed, gripPairsConfig, gripSequentialConfig } =
    transformConfig(completeConfig);
  return { grips: gripsTransformed, gripPairsConfig, gripSequentialConfig };
};

export const transformConfigAPI = (changeEntry: any, modesList) => {
  const parsedConfig = JSON.parse(changeEntry.config);

  if ('modes' in parsedConfig) {
    return { parsedConfig };
  }

  const modes: any = [];

  for (const key in parsedConfig) {
    if (key.startsWith('mode_')) {
      const id = parseInt(key.split('_')[1], 10);
      const { slot } = modesList.find((mode) => mode.id === id);
      const config = parsedConfig[key];

      modes.push({ id, slot, config });
    }
  }

  return {
    parsedConfig: {
      common: parsedConfig.common,
      modes
    }
  };
};

export const transformCompared = (now, after) => {
  const compared = compareConfigs(now, after);
  return Object.keys(compared).map((key) => ({ key, value: compared[key] }));
};

export const findDifferencesImport = (common, modes, completeConfigToCompare) =>
  modes
    .map((_mode) => {
      const configNow = {
        ...common.configAPI,
        ...modes.find((mode) => mode.slot === _mode.slot)?.configAPI
      };
      const configToCompare = completeConfigToCompare?.modes
        ? {
            ...completeConfigToCompare.common,
            ...completeConfigToCompare.modes.find((mode) => mode.slot === _mode.slot).config
          }
        : completeConfigToCompare;
      const compared = transformCompared(configNow, configToCompare);
      return compared;
    })
    .some((difference) => difference.length > 0);

export const getConfigProperties = (config, properties) =>
  Object.keys(config)
    .filter((key: any) => properties.includes(key))
    .reduce((obj, key) => {
      obj[key] = config[key];
      return obj;
    }, {});

export const transformConfigToArrayFormat = (config: ConfigPayloadObject) =>
  Object.entries(config.modes)
    .filter(([key]) => key !== '_')
    .map(([, value]) => value);
