import * as Sentry from '@sentry/react';

import { NotificationFactory } from 'lib/NotificationFactory';
import { useState } from 'react';
import { RegisterNewUserParams } from 'adp-panel/api/authentication/authentication.types';
import { useCreaterNewUser } from './useAuthentication';

export const useRegisterNewUser = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: registerUser } = useCreaterNewUser();

  const handleSubmit = async (data: RegisterNewUserParams) => {
    try {
      setIsLoading(true);
      return await registerUser(data);
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to register user');
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading };
};
