import { InputLabel, Typography } from '@mui/material';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from 'adp-panel/utils/parsePhone';
import { commonFormStyles, LabelStyle, LabelWrapper } from 'components/FormFields/commonStyles';
import CustomPhoneSelect from 'components/FormFields/CustomPhoneSelect';
import { useEffect, useState } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CustomPhoneField = ({
  control,
  label,
  id,
  isValidPhoneNumber = false,
  optional = false,
  noEndAdornment = false,
  styleForLabel = {}
}) => {
  const { defaultValues } = useFormState({ control });
  const [selectedCountry, setSelectedCountry] = useState('+48');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValues === undefined) return;
    const value = defaultValues['phone'] ?? '';
    const initialPhone = parsePhoneFromAPI(defaultValues['phone']);
    const country = parseCountryCodeFromAPI(value);
    if (country) {
      setSelectedCountry(country);
    }
    if (initialPhone) {
      setPhoneNumber(initialPhone);
    }
  }, [defaultValues]);

  const handleCountryChange = (newCountryCode, onChange) => {
    setSelectedCountry(newCountryCode);
    const fullPhoneNumber = updateFullPhoneNumber(newCountryCode, phoneNumber);
    onChange(fullPhoneNumber);
  };

  const handlePhoneNumberChange = (newPhoneNumber, onChange) => {
    setPhoneNumber(newPhoneNumber);
    const fullPhoneNumber = updateFullPhoneNumber(selectedCountry, newPhoneNumber);
    onChange(fullPhoneNumber);
  };

  const updateFullPhoneNumber = (countryCode, phone) => {
    const value = phone.replace(/\D/g, '');
    return `${countryCode}-${value}`;
  };

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <div
          style={{
            width: '100%'
          }}>
          <InputLabel
            shrink={false}
            htmlFor={id}
            sx={{ marginBottom: commonFormStyles.labelMarginBottom, ...styleForLabel }}>
            <LabelWrapper>
              <Typography sx={{ ...LabelStyle }}>{label}</Typography>
              {optional && <Typography>(optional)</Typography>}
            </LabelWrapper>
          </InputLabel>
          <CustomPhoneSelect
            phoneNumber={phoneNumber}
            selectedCountry={selectedCountry}
            onCountryChange={(newCountryCode) => handleCountryChange(newCountryCode, onChange)}
            onPhoneNumberChange={(newPhoneNumber) =>
              handlePhoneNumberChange(newPhoneNumber, onChange)
            }
            onBlur={onBlur}
            isValidPhoneNumber={isValidPhoneNumber}
            value={value}
            noEndAdornment={noEndAdornment}
            error={Boolean(fieldState.error)}
            helperText={t(fieldState.error?.message || '')}
          />
        </div>
      )}
    />
  );
};

export default CustomPhoneField;
