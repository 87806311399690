import { useState } from 'react';
import { useDevicesList, useDeviceUpdate } from '../../../hooks/api/useDevices';
import { DeviceExtendOptions, DevicesQueryParams } from '../../../api/devices/device.types';

export const usePatientDevices = (patientId: string) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const queryParamsDevices: DevicesQueryParams = {
    amputee: patientId,
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };

  const {
    result: devices,
    total: totalDevices,
    isLoading: isLoadingDevices
  } = useDevicesList(queryParamsDevices);

  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();

  const handleUnassignDevice = async (deviceId: number) => {
    await updateDevice({ data: { amputee_id: null }, deviceId: deviceId.toString() });
  };

  return {
    devices,
    totalDevices,
    isLoadingDevices,
    isLoadingUpdate,
    selectedDevice,
    setSelectedDevice,
    paginationModel,
    setPaginationModel,
    handleUnassignDevice
  };
};
