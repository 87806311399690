/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Fingers } from 'configurator/utils/definesLocal';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import {
  postJointTargetPosition,
  queryTelemetryOnce
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import Error from 'configurator/components/atoms/Error/Error';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { useTranslation } from 'react-i18next';

const HeaderSecondary = styled.h4`
  ${TextS};
  height: 30px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 30px;
  }
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-columns: 1fr 50px 1fr 50px 1fr 50px 1fr 50px 1fr;
  }
`;

const DividerInner = styled.hr`
  border-left: 1px solid ${({ theme }) => theme.colorPrimary};
  margin-top: 30px;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 50px;
  }
`;

const ActionsList = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
`;

const StyledError = styled(Error)`
  margin-top: 20px;
`;

const fingersArray = [
  { finger: Fingers.Thumb, name: 'Thumb' },
  { finger: Fingers.Index, name: 'Index' },
  { finger: Fingers.Middle, name: 'Middle' },
  { finger: Fingers.Ring, name: 'Ring' },
  { finger: Fingers.Pinky, name: 'Pinky' }
];

const ServicePositions = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const deviceConnected = useDeviceInfoStore((state) => state.connected);

  const getTelemetryData = async () => {
    const telemetryData = await queryTelemetryOnce();
    return telemetryData;
  };

  const isClosed = (telemetryData, finger) => telemetryData.fingers[finger].encoderTicks > 850;

  const closeFinger = async (finger) => {
    const telemetryData = await getTelemetryData();

    if (finger === Fingers.Index || finger === Fingers.Middle) {
      if (telemetryData.isThumbOpposed) {
        setErrorMessage(
          t(
            'configurator:component.service_positions.thumb_opposed_error',
            'When thumb is opposed, it can collide with other fingers. To close them, make thumb not opposed.'
          )
        );
        return;
      }
      if (finger === Fingers.Index && isClosed(telemetryData, Fingers.Thumb)) {
        setErrorMessage(
          t(
            'configurator:component.service_positions.thumb_closed_error',
            'When thumb is closed, it can block index finger. To move it, open the thumb first.'
          )
        );
        return;
      }
      await postJointTargetPosition(finger, 999);
      return;
    }

    await postJointTargetPosition(finger, 999);
  };

  const openFinger = async (finger) => {
    const telemetryData = await getTelemetryData();
    if (
      finger === Fingers.Index &&
      isClosed(telemetryData, Fingers.Thumb) &&
      isClosed(telemetryData, Fingers.Index)
    ) {
      setErrorMessage(
        t(
          'configurator:component.service_positions.thumb_closed_open_index_error',
          'When thumb is closed, it can block index finger. To open index finger, open the thumb first.'
        )
      );
      return;
    }
    await postJointTargetPosition(finger, 0);
  };

  const setFingerServicePosition = async (finger) => {
    const telemetryData = await getTelemetryData();

    if (telemetryData.isThumbOpposed) {
      setErrorMessage(
        t(
          'configurator:component.service_positions.switch_thumb_position_error',
          'To set the service position of the fingers, switch the thumb to non-opposed position.'
        )
      );
      return;
    }

    for (let i = 0; i < 5; i += 1) {
      const currentFinger = i;
      if (currentFinger === finger) {
        await postJointTargetPosition(currentFinger, 0);
        await delay(100);
        continue;
      }
      if (currentFinger === Fingers.Thumb) {
        await postJointTargetPosition(currentFinger, 600);
        await delay(100);
        continue;
      }
      await postJointTargetPosition(currentFinger, 999);
      await delay(100);
    }
  };

  const handleFingerServicePosition = (finger) => {
    if (deviceConnected) {
      setErrorMessage(null);
      setFingerServicePosition(finger);
    }
  };

  const handleOpenFinger = (finger) => {
    if (deviceConnected) {
      setErrorMessage(null);
      openFinger(finger);
    }
  };

  const handleCloseFinger = (finger) => {
    if (deviceConnected) {
      setErrorMessage(null);
      closeFinger(finger);
    }
  };

  return (
    <div>
      <ActionsContainer>
        {fingersArray.map((finger, index) => (
          <>
            <div>
              <HeaderSecondary>
                {t(`configurator:component.fingers_speed.${finger.name.toLowerCase()}`)}
              </HeaderSecondary>
              <ActionsList>
                <Button onClick={() => handleFingerServicePosition(finger.finger, index, 0)}>
                  {t(
                    'configurator:component.service_positions.service_position',
                    'Service position'
                  )}
                </Button>
                <Button onClick={() => handleOpenFinger(finger.finger, index, 1)}>
                  {t('configurator:component.service_positions.open', 'Open')}
                </Button>
                <Button onClick={() => handleCloseFinger(finger.finger, index, 2)}>
                  {t('configurator:component.service_positions.close', 'Close')}
                </Button>
              </ActionsList>
            </div>
            {index !== fingersArray.length - 1 ? <DividerInner /> : null}
          </>
        ))}
      </ActionsContainer>
      {errorMessage && <StyledError>{errorMessage}</StyledError>}
    </div>
  );
};

export default ServicePositions;
