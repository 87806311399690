import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addDevice } from 'adp-panel/api/devices/devices';
import { mapErrorMessage } from 'utils/notifications';

export const DEVICE_ADD = 'device_add';

export const useDeviceAdd = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(addDevice, {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [DEVICE_ADD] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
