import { getter } from '@progress/kendo-react-common';
import { GoalFrequencyType } from 'adp-panel/api/goals/goals.types';
import i18n from 'i18n';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const onlyNumbers = new RegExp(/^[0-9]*$/);
// eslint-disable-next-line no-useless-escape
const specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const isLowerCase = (str: string) => str == str.toLowerCase() && str != str.toUpperCase();
const isUpperCase = (str: string) => str != str.toLowerCase() && str == str.toUpperCase();
const testCaseString = (str: string, validationFunction: any) =>
  str.split('').find(validationFunction);
const containsLowerCaseChar = (str: string) => testCaseString(str, isLowerCase);
const containsUpperCaseChar = (str: string) => testCaseString(str, isUpperCase);
const containsNumber = (str: string) => /\d/.test(str);
const containsSpecialCharacter = (str: string) => specialCharacters.test(str);

export const emailValidator = (value: string) =>
  !value
    ? 'validation:email_validator.required' // Email field is required.
    : emailRegex.test(value)
      ? ''
      : 'validation:email_validator.invalid_format'; // Email is not in a valid format.
export const emailValidatorOptional = (value: string) =>
  value ? (emailRegex.test(value) ? '' : 'validation:email_validator.invalid_format') : ''; // Email is not in a valid format.

export const nameValidator = (value: string) =>
  !value ? 'validation:name_validator.required' : ''; // Full Name is required.

export const userNameValidator = (value: string) =>
  !value
    ? 'validation:user_name_validator.required' //User Name is required
    : value.length < 5
      ? 'validation:user_name_validator.character_length' // User name should be at least 5 characters long.
      : '';
export const strongPasswordValidator = (value: string) =>
  !value
    ? 'validation:password_validator.required' // Password is required.
    : value.length < 8
      ? 'validation:password_validator.character_length' // Password must be at least 8 characters long.
      : !containsLowerCaseChar(value)
        ? 'validation:password_validator.lowercase' // Password must have at least 1 lowercase character.
        : !containsUpperCaseChar(value)
          ? 'validation:password_validator.uppercase' // Password must have at least 1 uppercase character.
          : !containsNumber(value)
            ? 'validation:password_validator.number' // Password must have at least 1 number.
            : !containsSpecialCharacter(value)
              ? 'validation:password_validator.special_character' // Password must have at least 1 special character.
              : '';
export const phoneValidator = (value: string) =>
  !value
    ? 'validation:phone_validator.required' // Phone number is required.
    : phoneRegex.test(value)
      ? ''
      : 'validation:phone_validator.invalid_format'; // Not a valid phone number.

export const phoneValidatorOptional = (value: string) =>
  value ? (onlyNumbers.test(value) ? '' : 'validation:phone_validator.only_numbers') : ''; // Phone number can contain only numbers.

export const phoneValidatorRequired = (value: string) =>
  !value
    ? 'validation:phone_validator.required' // Phone number is required.
    : phoneRegex.test(value)
      ? ''
      : 'validation:phone_validator.only_numbers'; // Phone number can contain only numbers.

export const cardValidator = (value: string) =>
  !value
    ? 'validation:card_validator.required' // Credit card number is required.
    : ccardRegex.test(value)
      ? ''
      : 'validation:card_validator.invalid_format'; // Not a valid credit card number format.

export const cvcValidator = (value: string) =>
  !value
    ? 'validation:cvc_validator.required' // CVC code is required.
    : cvcRegex.test(value) || value.length !== 3
      ? ''
      : 'validation:cvc_validator.invalid_format'; // Not a valid CVC code format.
export const guestsValidator = (value: any) =>
  !value
    ? 'validation:guests_validator.required' // Number of guests is required.
    : value < 5
      ? ''
      : 'validation:guests_validator.max_guests'; // Maximum 5 guests.

export const nightsValidator = (value: string) =>
  value ? '' : 'validation:nights_validator.required'; // Number of Nights is required.

export const arrivalDateValidator = (value: string) =>
  value ? '' : 'validation:arrival_date_validator.required'; // Arrival Date is required.

export const colorValidator = (value: string) =>
  value ? '' : 'validation:color_validator.required'; // Color is required.

export const requiredValidator = (value: string) =>
  value ? '' : i18n.t('common.required_validator.required', 'This file is required');

export const customTotalGripValidator = (value: string, minValue: number) => {
  if (Number(value) === 0 || value === '' || value === undefined) return '';
  return Number(value) >= minValue ? '' : 'validation:custom_total_grip_validator.min_value'; // Error: Total grip count goal needs to be equal or more than the sum of all selected grips.
};
export const customFrequencyValidator = (value: string, period: number, required = false) => {
  if (period < 7 && value === GoalFrequencyType.w)
    return i18n.t(
      'validation:custom_frequency_validator.week_frequency',
      'Goal must have at least 7 days to choose week frequency'
    ); // Error: Goal must have at least 7 days to choose week frequency.
  if (period < 30 && value === GoalFrequencyType.m)
    return i18n.t('common.required_validator.required', 'This file is required'); // Error: Goal must have at least 30 days to choose month frequency.
  if (required) return requiredValidator(value);

  return '';
};
export const requiredValidatorTree = (value: Array<any>) =>
  value?.length > 0 ? '' : 'validation:required_validator_tree.required'; // Error: This field is required.
export const passwordValidator = (value: string) =>
  value && value.length > 8 ? '' : 'validation:password_validator.character_length'; // Password must be at least 8 symbols.
export const addressValidator = (value: string) =>
  value ? '' : 'validation:address_validator.required'; // Address is required.

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = (values: any) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: 'validation:form_validator.validation_summary', // Please fill in the following fields.
    ['username']: !userName ? 'validation:user_name_validator.required' : '', // User Name is required.
    ['email']:
      emailValue && emailRegex.test(emailValue) ? '' : 'validation:email_validator.required' // Email is required and should be in a valid format.
  };
};
