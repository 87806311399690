import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { SERVICE_MENU } from 'constants/routes';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { MODALS } from 'configurator/views/Modals';
import { useDeviceManager } from './api/useDeviceManager';
import useModes from './useModes';

const useSidebar = () => {
  const { t } = useTranslation();
  const { handleOpenSave } = useModes();
  const { retrieveConfig } = useDeviceManager();
  const { openModal } = useUiStore((state) => ({
    openModal: state.openModal
  }));
  const { amputeeId, connected } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId,
    connected: state.connected
  }));
  const firstConnection = useConfigStore((state) => state.firstConnection);
  const liveConfiguratorEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const navigate = useNavigate();
  const ticketCanBeSent = (!connected && amputeeId) || connected;
  const canShowSend = firstConnection === false && replayIsEnabled === false;

  const handleFirmwareNavbar = (deviceConnected: boolean, liveSessionEnabled: boolean) => {
    if (deviceConnected) {
      openModal(MODALS.firmware);
      return;
    }

    if (liveSessionEnabled) {
      toast(
        t(
          'configurator:component.sidebar.firmware_session_warning',
          'Patient needs to go to "more" tab in mobile application to update firmware during session'
        ),
        {
          id: 'liveSessionEnabledFirmwareWarning',
          icon: '⚠️',
          duration: 20000
        }
      );
      return;
    }

    toast.error(t('configurator:component.sidebar.device_not_connected', 'Device not connected'), {
      id: 'deviceNotConnectedToast'
    });
  };

  const handleServiceMenuNavbar = (deviceConnected: boolean, e) => {
    e.preventDefault();

    if (!deviceConnected) {
      toast.error(
        t('configurator:component.sidebar.device_not_connected', 'Device not connected'),
        {
          id: 'deviceNotConnectedToast'
        }
      );
      return;
    }
    navigate(SERVICE_MENU);
  };

  const allowConnectButton = !liveConfiguratorEnabled && !replayIsEnabled;

  return {
    handleSave: handleOpenSave,
    handleRestoreRecent: retrieveConfig,
    handleFirmwareNavbar,
    handleServiceMenuNavbar,
    allowConnectButton,
    ticketCanBeSent,
    canShowSend
  };
};

export default useSidebar;
