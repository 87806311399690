import { RoleEnum } from 'adp-panel/api/users/users.types';

export const HOME = '/';
export const REDIRECT = `${HOME}redirect`;

// UNAUTHENTICATED
export const LOGIN = `${HOME}login`;
export const CONFIRM_MFA = `${HOME}mfa/verify`;
export const FORGOTTEN_PASSWORD = `${HOME}forgotten-password`;
export const SETUP_PASS = `${HOME}setup-pass`;
export const REGISTER_USER = `${HOME}register`;
export const ACCEPT_INVITATION = `${HOME}invitation/accept`;

// AUTHENTICATED
export const DASHBOARD = `${HOME}dashboard`;
export const REGISTER_USER_2FA = `${HOME}register-2fa`;

export const LOGS = `${HOME}logs`;

export const COMPANY = `${HOME}company`;
export const COMPANY_DETAILS = `${COMPANY}/:companyId`;
export const COMPANY_ADD = `${COMPANY}/add`;
export const COMPANY_EDIT = `${COMPANY}/edit`;

export const MEDICAL_PROFESSIONALS = `${HOME}medical-professionals`;
export const CLINICIANS = `${HOME}clinicians`;
export const CLINICIAN = `${CLINICIANS}/:clinicianId`;
export const CLINICIAN_ADD = `${CLINICIANS}/add`;
export const CLINICIAN_EDIT = `${CLINICIANS}/edit`;

export const LOCATION = `${HOME}location`;
export const LOCATION_DETAILS = `${LOCATION}/:locationId`;
export const LOCATION_ADD = `${LOCATION}/add`;
export const LOCATION_EDIT = `${LOCATION}/edit`;

export const DEVICE = `${HOME}device`;
export const DEVICE_DETAILS = `${DEVICE}/:deviceId`;
export const DEVICE_ADD = `${DEVICE}/add`;
export const DEVICE_EDIT = `${DEVICE}/edit`;

export const PATIENTS = `${HOME}patients`;
export const PATIENT = `${PATIENTS}/:patientId`;
export const PATIENT_ADD = `${PATIENTS}/add`;
export const PATIENT_EDIT = `${PATIENTS}/edit`;

export const ACTIVATION_CODES = `${HOME}codes`;

export const MODES = `${HOME}modes`;

export const RELEASES = `${HOME}releases`;
export const RELEASE_ADD = `${RELEASES}/add`;
export const RELEASE_EDIT = `${RELEASES}/edit`;
export const RELEASE_COMPATIBILITY_ADD = `${RELEASES}/compatibility/add`;
export const RELEASE_COMPATIBILITY_EDIT = `${RELEASES}/compatibility/edit`;

export const VERSIONS = `${HOME}versions`;
export const VERSION_ADD = `${VERSIONS}/add`;

export const ACCOUNT = `${HOME}account`;
export const ACCOUNT_CHANGE_PASSWORD = `${ACCOUNT}/change-password`;

export const USER_MANAGE = `${HOME}manage-user`;
export const USER_MANAGE_EDIT = `${USER_MANAGE}/edit`;

export const MESSAGE = `${HOME}message`;
export const TICKET_REDIRECT = `${HOME}ticket`;
export const TICKET = `${HOME}tickets/:ticketId`;

export const SERVICING = `${HOME}servicing`;

export const GOALS = `${HOME}goals`;
export const GOALS_ADD = `${GOALS}/add`;
export const GOALS_EDIT = `${GOALS}/edit`;

export const FEATURE_TOGGLES = `${HOME}toggles`;
export const WELCOME = `/configurator/configuration`;

export const CONFIGURATOR_HOME = '/configurator';
export const CONFIGURATOR_DEVICE = '/configurator/device';
export const SUPPORT_TICKET = '/configurator/support-ticket';
export const REGISTER = '/configurator/register';
export const CONFIGURATION = '/configurator/configuration';
export const CHOOSE_GRIPS = '/configurator/choose-grips';
export const PROSTHESIS_SETTINGS = '/configurator/prosthesis-settings';
export const APPLICATION_SETTINGS = '/configurator/application-settings';
export const GRIPS_CONFIGURATION = '/configurator/grips-configuration';
export const GRAPH = '/configurator/graph';
export const SERVICE_MENU = '/configurator/service-menu';
export const SESSION_HISTORY = '/configurator/session-history';
export const DEVICE_HISTORY = '/configurator/device-history';
export const DEVICE_HISTORY_DETAIL = `${DEVICE_HISTORY}/:configId`;
export const CONFIGURATOR_SERVICING = '/configurator/servicing';
export const CONFIG_TEMPLATES = '/configurator/config-templates';
export const CONFIG_TEMPLATES_DETAIL = `${CONFIG_TEMPLATES}/:templateId`;
