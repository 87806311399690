import * as yup from 'yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { emailSchema, isStrongPassword, stringSchema } from 'configurator/views/Register/utils';

export const patientSchema = yup.object().shape({
  firstName: stringSchema.required(VALIDATOR_TEXT.REQUIRED).max(30, ({ max, value }) => ({
    key: VALIDATOR_TEXT.MAX_LENGTH,
    values: { max, currentLength: value.length, exceededBy: value.length - max }
  })),
  lastName: stringSchema.required(VALIDATOR_TEXT.REQUIRED).max(30, ({ max, value }) => ({
    key: VALIDATOR_TEXT.MAX_LENGTH,
    values: { max, currentLength: value.length, exceededBy: value.length - max }
  })),
  email: emailSchema.required(VALIDATOR_TEXT.REQUIRED),
  password: yup.string().test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
    if (value === undefined || value === null || value === '') return true;

    return isStrongPassword(value);
  }),
  retypePassword: yup
    .string()
    .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
      const parentPassword = this.parent.password;
      if (parentPassword === undefined || parentPassword === null || parentPassword === '')
        return true;

      return parentPassword === value;
    }),
  device: yup
    .mixed()
    .test('is-object', 'patients:patient_form.error_wrong_format', (value) => {
      if (value === null || value === undefined) return true;

      return typeof value === 'object';
    })
    .nullable()
});
