import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useTranslation } from 'react-i18next';

const useImportButton = (importDifferences: any) => {
  const { t } = useTranslation();
  const { amputeeId, connected } = useDeviceInfoStore((state: any) => ({
    amputeeId: state.amputeeId,
    connected: state.connected
  }));

  const importTooltip = () => {
    if (!amputeeId && !connected)
      return t('configurator:import_button.tooltip_no_device', 'No device connected');
    if (!importDifferences)
      return t('configurator:import_button.tooltip_no_differences', 'No differences to import');
    return null;
  };

  const disableImportButton = () => {
    if (!amputeeId && !connected) return true;
    if (!importDifferences) return true;

    return false;
  };

  return { importTooltip, disableImportButton };
};

export default useImportButton;
