/* eslint-disable react/jsx-key */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useProcedureReply from 'configurator/hooks/useProcedure';
import { useUiStore } from 'configurator/reducers/uiStore';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';
import {
  CalibrationWrapper,
  Header2,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureTable,
  ProcedureTableWrapper
} from 'configurator/components/molecules/CalibrationProcedure/styled';
import { useTranslation } from 'react-i18next';

const CheckMovementRangeFingerClosingCombinedProcedure = ({
  sessionEnabled,
  sessionApi,
  channel,
  handleProcedure,
  disableCalibration
}: any) => {
  const { t } = useTranslation();
  const procedureData = useProcedureReply(ProcedureTypes.checkMovementRangeTestClosingCombined);
  const procedureState = useUiStore((state: any) => state.procedureState);

  return (
    <>
      <OptionsWithSlider
        header={t('configurator:component.check_movement_range.header', 'Test fingers')}
        toggleOptions={['On', 'Off']}
        disabled
        toggleValue={0}
        sliderValue={0}
        handleSliderChange={() => false}
        sliderLimits={{ min: 0, max: 0 }}
        sliderLabel={<></>}
        handleToggleChange={() => false}
        handleSliderOnAfterChange={() => false}
        description={t(
          'configurator:component.check_movement_range.description',
          'The parameters of the fingers will be tested to assess if they are working correctly. Keep the hand in secure position - the fingers will move during the test procedure.'
        )}
        hideInput
      />
      <CalibrationWrapper>
        {procedureData && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>
              {t(
                'configurator:component.check_movement_range.procedure_results',
                'Procedure results'
              )}
            </Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td
                    aria-label={t(
                      'configurator:component.fingers_speed.procedure_information',
                      'Procedure information'
                    )}>
                    &nbsp;
                  </td>
                  <td>{t('configurator:component.fingers_speed.thumb', 'Thumb')}</td>
                  <td>{t('configurator:component.fingers_speed.index', 'Index')}</td>
                  <td>{t('configurator:component.fingers_speed.middle', 'Middle')}</td>
                  <td>{t('configurator:component.fingers_speed.ring', 'Ring')}</td>
                  <td>{t('configurator:component.fingers_speed.pinky', 'Pinky')}</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.avg_closing_time',
                      'Average closing time (ms)'
                    )}
                  </td>
                  {procedureData.closingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.min_closing_time',
                      'Minimum closing time (ms)'
                    )}
                  </td>
                  {procedureData.minimumClosingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.max_closing_time',
                      'Maximum closing time (ms)'
                    )}
                  </td>
                  {procedureData.maximumClosingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.avg_current',
                      'Average current (mA)'
                    )}
                  </td>
                  {procedureData.averageCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.min_current',
                      'Minimum current (mA)'
                    )}
                  </td>
                  {procedureData.minimumCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.max_current',
                      'Maximum current (mA)'
                    )}
                  </td>
                  {procedureData.maximumCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td
                    aria-label={t(
                      'configurator:component.fingers_speed.procedure_information',
                      'Procedure information'
                    )}>
                    &nbsp;
                  </td>
                  <td>{t('configurator:component.fingers_speed.thumb', 'Thumb')}</td>
                  <td>{t('configurator:component.fingers_speed.index', 'Index')}</td>
                  <td>{t('configurator:component.fingers_speed.middle', 'Middle')}</td>
                  <td>{t('configurator:component.fingers_speed.ring', 'Ring')}</td>
                  <td>{t('configurator:component.fingers_speed.pinky', 'Pinky')}</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.upper_position',
                      'Upper position'
                    )}
                  </td>
                  {procedureData.positionsUpper!.map((position) => (
                    <td colSpan={1}>{position}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.lower_position',
                      'Lower position'
                    )}
                  </td>
                  {procedureData.positionsLower!.map((position) => (
                    <td colSpan={1}>{position}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.upper_revolutions',
                      'Upper revolutions'
                    )}
                  </td>
                  {procedureData.revolutionsUpper!.map((revolution) => (
                    <td colSpan={1}>{revolution / 1000}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>
                    {t(
                      'configurator:component.check_movement_range.lower_revolutions',
                      'Lower revolutions'
                    )}
                  </td>
                  {procedureData.revolutionsLower!.map((revolution) => (
                    <td colSpan={1}>{revolution / 1000}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip
          title={
            disableCalibration
              ? t(
                  'configurator:component.check_movement_range.device_not_connected',
                  'Device not connected'
                )
              : null
          }>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={() =>
                handleProcedure({
                  procedureNumber: ProcedureTypes.checkMovementRangeTestClosingCombined,
                  ...(sessionEnabled && {
                    liveSession: {
                      clinicianUUID: sessionApi?.clinician_uuid,
                      channelName: channel?.name
                    }
                  })
                })
              }>
              <span>
                {t(
                  'configurator:component.check_movement_range.calibration_procedure',
                  'Calibration procedure'
                )}
              </span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};
export default CheckMovementRangeFingerClosingCombinedProcedure;
