import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceUpdate } from 'configurator/hooks/api/useDevice';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { PCBVersionEntry } from 'configurator/api/versions/versions.types';
import ErrorLabel from 'configurator/components/atoms/Error/ErrorLabel';
import Loader from 'configurator/components/atoms/Loader/Loader';
import { useUiStore } from 'configurator/reducers/uiStore';
import {
  FETCHING_STATES,
  MAIN_PCB_ORDER,
  MAIN_PCB_ORDER_V3,
  MAIN_PCB_ORDER_V4
} from 'configurator/consts/consts';
import { getServicingParts } from 'configurator/api/servicing/servicing';
import { useServicingRepair } from 'configurator/hooks/api/useServicing';
import toast from 'react-hot-toast';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import BluetoothWebController from 'configurator/bluetooth-handler/bluetoothWeb';
import { postSerialNumber } from 'configurator/bluetooth-handler/bluetoothFunctions';
import { getDevice } from 'configurator/api/device/device';
import { DeviceExtendOptions, DeviceQueryParams } from 'configurator/api/device/device.types';
import { StyledCheck, StyledTimes } from 'configurator/modals/FirmwareModal/styled';
import useUserData from 'hooks/useUserData';

const bluetoothWebController = new BluetoothWebController();

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

type PcbReplacementConnectionProps = {
  handlePrevious: Function;
  handleNext: any;
  bluetoothId: string;
  pcb: PCBVersionEntry;
};

const deviceQueryParams: DeviceQueryParams = {
  extend: [DeviceExtendOptions.firmwareVersion, DeviceExtendOptions.pcbVersion]
};

const PcbReplacementConnection = ({
  handlePrevious,
  handleNext,
  bluetoothId,
  pcb
}: PcbReplacementConnectionProps) => {
  const { deviceConnected, pcbVersion, deviceId, getPcbVersionDevice } = useDeviceInfoStore(
    (state: any) => ({
      deviceConnected: state.connected,
      pcbVersion: state.pcbVersion,
      deviceId: state.deviceId,
      getPcbVersionDevice: state.getPcbVersionDevice
    })
  );
  const {
    mutateAsync: updateDevice,
    isSuccess: isSuccessDeviceUpdate,
    isLoading: isLoadingDeviceUpdate,
    isError: isErrorDeviceUpdate
  } = useDeviceUpdate();
  const { disconnectDevice } = useConfigStore((state: any) => ({
    disconnectDevice: state.disconnectDevice
  }));
  const connectionState = useUiStore((state: any) => state.connectionState);
  const { mutateAsync: createServicingRepair } = useServicingRepair();
  const { me } = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const { bluetoothConnect } = useBluetooth();

  const versionsMatch = Number(pcb.name[0]) === Number(pcbVersion);

  const reportRepair = async () => {
    try {
      const parts = await getServicingParts({ perpage: 1000 });
      const pcbPart = parts?.items?.find((part: any) => {
        if (Number(pcb.name[0]) === 3) return part.name === MAIN_PCB_ORDER_V3;
        if (Number(pcb.name[0]) === 4) return part.name === MAIN_PCB_ORDER_V4;

        return part.name === MAIN_PCB_ORDER;
      });

      if (!pcbPart) {
        toast.error('PCB part does not exist, contact Aether support.');
        return;
      }

      const formData = new FormData();
      formData.append('device_id', String(deviceId!));
      formData.append('user_id', String(me!.id));
      formData.append(`parts[0][part_id]`, String(pcbPart.id));
      formData.append(`parts[0][reason]`, 'PCB replacement');
      await createServicingRepair(formData);
    } catch (e) {
      console.log('error', e);
      toast.error(String(e));
    }
  };

  const connect = async () => {
    try {
      // Ensure new device is selected each time
      bluetoothWebController.forgetDevice();
      setIsLoading(true);
      const connected = await bluetoothConnect(bluetoothId);

      if (!connected) throw new Error();

      const { pcbVersion } = await getPcbVersionDevice();
      const deviceInfo = await getDevice(Number(deviceId), deviceQueryParams);

      if (!deviceInfo?.serial || !pcbVersion) throw new Error();

      if (connected && Number(pcb.name[0]) === pcbVersion) {
        // If PCB version provided and connected are the same and Bluetooth ID provided are the same, update hand in API
        await updateDevice({
          deviceId: Number(deviceId),
          data: {
            pcb_version_id: pcb.id,
            bluetooth_id: bluetoothId
          }
        });

        // Synchronize configs
        await postSerialNumber(String(deviceInfo.serial));

        await reportRepair();
        setIsLoading(false);
      } else {
        throw new Error();
      }
    } catch {
      setIsLoading(false);
    }
  };

  const handleBack = async () => {
    if (deviceConnected) await disconnectDevice();
    handlePrevious();
  };

  const showError =
    (isErrorDeviceUpdate || !versionsMatch) &&
    deviceConnected &&
    !isLoadingDeviceUpdate &&
    !isLoading;

  return (
    <DialogContent>
      <Wrapper>
        <p>
          After replacing the PCB, connect to the device. If the hand does not show up on the list,
          go back to previous step, and change bluetooth ID.
        </p>
        {pcbVersion && (
          <InfoWrapper>
            <div>PCB version: v{pcbVersion}.X</div>
            <div>Selected PCB version: v{pcb.name}</div>
            {versionsMatch ? (
              <>
                (Match) <StyledCheck />
              </>
            ) : (
              <>
                (No match) <StyledTimes />
              </>
            )}
          </InfoWrapper>
        )}
        {showError && (
          <ErrorLabel>
            There was an error when updating device information. Check if the PCB versions match, if
            not go back to previous step to change it and reconnect.
          </ErrorLabel>
        )}
        {isLoadingDeviceUpdate || isLoading ? (
          <Loader style={{ margin: '0 auto' }} />
        ) : (
          <>
            {!isSuccessDeviceUpdate && (
              <LoadingButton
                type='button'
                onClick={deviceConnected ? disconnectDevice : connect}
                loading={connectionState === FETCHING_STATES.loading}
                sx={{ width: '150px' }}>
                <span> {deviceConnected ? 'Disconnect' : 'Connect'}</span>
              </LoadingButton>
            )}
            <ButtonsWrapper>
              {!isSuccessDeviceUpdate && (
                <Button type='button' onClick={handleBack} variant='outlined'>
                  Previous step
                </Button>
              )}
              {isSuccessDeviceUpdate && versionsMatch && deviceConnected && (
                <Button type='button' onClick={handleNext}>
                  Next step
                </Button>
              )}
            </ButtonsWrapper>
          </>
        )}
      </Wrapper>
    </DialogContent>
  );
};

export default PcbReplacementConnection;
