import { Button as MuiButton, ButtonGroup as MuiButtonGroup, styled } from '@mui/material';

export const StyledButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  borderRadius: '4px !important',
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: '#0000003b',
    borderRightColor: '#0000003b'
  },
  '& .MuiButtonGroup-firstButton': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  },
  '& .MuiButtonGroup-lastButton': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  '& .MuiButtonGroup-grouped:not(:first-of-type)': {
    borderLeftColor: '#0000003b'
  }
}));

export const StyledButton = styled(MuiButton)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 16px',
  height: '40px',
  minWidth: '80px',
  color: '#344054',
  backgroundColor: theme.palette.common.white,
  fontWeight: 500,
  fontSize: '14px',
  borderColor: '#0000003b',
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[200]
  },

  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#EEF4FF',
    color: '#33499C',
    borderColor: '#0000003b'
  }
}));

export const DescriptionPanel = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '12px',
  padding: '24px',
  marginTop: '16px',
  border: '1px solid #EAECF0'
}));

export const DescriptionTitle = styled('h2')({
  fontSize: '18px',
  fontWeight: 500,
  color: '#101828',
  marginBottom: '16px',
  marginTop: 0
});

export const DescriptionText = styled('p')({
  fontSize: '14px',
  color: '#475467',
  lineHeight: '20px',
  marginBottom: '24px'
});

export const InterpretationSection = styled('div')({
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  padding: '16px',
  marginBottom: '16px',
  border: '1px solid #F9FAFB'
});

export const SectionTitle = styled('h3')({
  fontSize: '16px',
  fontWeight: 600,
  color: '#101828',
  marginBottom: '16px',
  marginTop: 0
});

export const IssueContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
});

interface InfoBoxesContainerProps {
  columns?: number;
}

export const InfoBoxesContainer = styled('div')<InfoBoxesContainerProps>(({ columns = 3 }) => ({
  width: '100%',
  display: 'flex',
  'column-gap': '24px'
}));

interface InfoBoxProps {
  isText?: boolean;
}

export const InfoBox = styled('div')<InfoBoxProps>(({ isText }) => ({
  flex: isText ? 1 : 'inherit'
}));

export const InfoContent = styled('div')({});

export const InfoTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: '#101828'
});

export const InfoTitleWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: 600,
  color: '#101828',
  marginBottom: '10px'
});

export const InfoText = styled('div')({
  fontSize: '14px',
  color: '#344054',
  lineHeight: '20px'
});

export const CloseButton = styled(MuiButton)({
  marginTop: '16px'
});

export const TitleWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '16px'
});

interface IconWrapperProps {
  variant?: 'warning' | 'info' | 'success' | 'error';
}

export const IconWrapper = styled('div')<IconWrapperProps>(({ theme, variant }) => {
  const getVariantStyles = () => {
    switch (variant) {
      case 'warning':
        return {
          backgroundColor: '#FFFAEB',
          color: '#B54708',
          outlineColor: '#FEF0C7'
        };
      case 'info':
        return {
          backgroundColor: '#EFF8FF',
          color: '#1570EF',
          outlineColor: '#D1E9FF'
        };
      case 'success':
        return {
          backgroundColor: '#ECFDF3',
          color: '#027A48',
          outlineColor: '#D1FADF'
        };
      case 'error':
        return {
          backgroundColor: '#FEF3F2',
          color: '#B42318',
          outlineColor: '#FEE4E2'
        };
      default:
        return {
          backgroundColor: '#F9FAFB',
          color: '#344054',
          outlineColor: '#D3DCE6'
        };
    }
  };

  const variantStyles = getVariantStyles();

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: variantStyles.backgroundColor,
    outline: `1px solid ${variantStyles.outlineColor}`,
    borderRadius: '8px',
    padding: '8px',
    '& svg': {
      width: '20px',
      height: '20px',
      color: variantStyles.color
    }
  };
});

export const ImagesSection = styled('div')({
  marginTop: '24px',
  padding: '16px',
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  border: '1px solid #F9FAFB'
});

export const ImageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  marginTop: '16px',

  '& img': {
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
    marginTop: '8px'
  }
});

export const CloseButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px'
});
