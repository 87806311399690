import { Avatar } from '@mui/material';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string) {
  let firstLetterName = name.split(' ')[0][0];
  let secondLetterName = name.split(' ')?.[0]?.[1];
  let firstLetterSurname = name.split(' ')?.[1]?.[0];

  if (firstLetterName) {
    firstLetterName = firstLetterName.toUpperCase();
  }
  if (secondLetterName) {
    secondLetterName = secondLetterName.toUpperCase();
  }
  if (firstLetterSurname) {
    firstLetterSurname = firstLetterSurname.toUpperCase();
  }

  if (firstLetterSurname) {
    return { children: `${firstLetterName}${firstLetterSurname}` };
  }

  if (!firstLetterSurname) {
    if (secondLetterName) return { children: `${firstLetterName}${secondLetterName}` };
    return { children: `${firstLetterName}${firstLetterName}` };
  }
  return { children: `${firstLetterName}${firstLetterName}` };
}

const normalStyle = {
  width: 28,
  height: 28,
  fontSize: 12
};

const bigStyle = {
  width: 84,
  height: 84,
  fontSize: 30
};

const baseStyle = {
  textTransform: 'uppercase',
  marginRight: '16px'
};

const parseStyle = (name, big = false, style = null) => {
  if (style) return style;

  if (big) return { ...baseStyle, bgcolor: stringToColor(name), ...bigStyle };

  return { ...baseStyle, bgcolor: stringToColor(name), ...normalStyle };
};

const CustomAvatar = ({
  name,
  big = false,
  style = null
}: {
  name: string;
  big?: boolean;
  style?: any;
}) => <Avatar {...stringAvatar(name)} sx={parseStyle(name, big, style)} />;

export default CustomAvatar;
