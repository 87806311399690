import { EVENTS } from 'configurator/consts/events';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useEffect, useRef, useState } from 'react';

type LocalSessionBackupConfigType = {
  data: { common: any; modes: any };
  deviceId: number;
};

const useLocalSessionBackupConfig = () => {
  const { isUnsaved } = useUnsaved();

  const [localSessionBackupConfig, setLocalSessionBackupConfig] =
    useState<LocalSessionBackupConfigType | null>(null);
  const { config } = useConfigStore((state) => ({
    config: state.config
  }));
  const { deviceId } = useDeviceInfoStore((state) => ({
    deviceId: Number(state.deviceId)
  }));
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const configRef = useRef(config);
  const unsavedRef = useRef(isUnsaved);

  useEffect(() => {
    configRef.current = config;
  }, [config]);

  useEffect(() => {
    unsavedRef.current = isUnsaved;
  }, [isUnsaved]);

  const handleBackupConfig = (connectedDeviceId: number) => {
    const configBackupForCurrentConnectedDevice = localStorage.getItem(
      `localSessionBackupConfig-${connectedDeviceId}`
    );

    if (configBackupForCurrentConnectedDevice) {
      openModal(MODALS.localSessionBackupConfig);
    }
  };

  const handleDeviceDisconnected = (event: any) => {
    if (!unsavedRef.current) {
      return;
    }
    const currentConfig = configRef.current;
    const localSessionBackupCurrentConfig: LocalSessionBackupConfigType = {
      deviceId,
      data: {
        common: currentConfig.common.config,
        modes: currentConfig.modes
      }
    };
    setLocalSessionBackupConfig(localSessionBackupCurrentConfig);
    localStorage.setItem(
      `localSessionBackupConfig-${deviceId}`,
      JSON.stringify(localSessionBackupCurrentConfig)
    );
  };

  const updateLocalSessionBackupConfig = (newConfig: any) => {
    setLocalSessionBackupConfig(newConfig);
    localStorage.setItem('localSessionBackupConfig', JSON.stringify(newConfig));
  };

  useEffect(() => {
    window.addEventListener(EVENTS.disconnect, handleDeviceDisconnected);

    return () => {
      window.removeEventListener(EVENTS.disconnect, handleDeviceDisconnected);
    };
  }, []);

  return {
    localSessionBackupConfig,
    updateLocalSessionBackupConfig,
    handleBackupConfig
  };
};

export default useLocalSessionBackupConfig;
