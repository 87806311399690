import React from 'react';
import { Button, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalBase from './ModalBase';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { EVENTS } from 'configurator/consts/events';
import { abortTestProcedure } from 'configurator/bluetooth-handler/bluetoothFunctions';
import { useConfigStore } from 'configurator/reducers/configStore';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';

const PreventInputModal = () => {
  const { t } = useTranslation();
  const { procedureState, blockModals } = useUiStore((state) => ({
    procedureState: state.procedureState,
    blockModals: state.blockModals
  }));
  const { currentlyRunningProcedure } = useConfigStore((state) => ({
    currentlyRunningProcedure: state.currentlyRunningProcedure
  }));

  const abortProcedure = () => {
    const event = new Event(EVENTS.stopProcedure);
    window.dispatchEvent(event);
    abortTestProcedure();
  };

  const isRunningCheckMovementRangeCombined =
    procedureState === FETCHING_STATES.loading &&
    currentlyRunningProcedure === ProcedureTypes.checkMovementRangeTestClosingCombined;

  const showMessage = blockModals?.[0]?.showMessage;

  const canShowMessage = () => {
    if (showMessage === undefined || showMessage === true) return true;
    if (showMessage === false) return false;

    return true;
  };

  return (
    <ModalBase handleClick={() => null} header='' basicModal allowBackDropClose={false}>
      {canShowMessage() && (
        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            {t('configurator:component.prevent_input_modal.wait', 'Please wait...')}
            {isRunningCheckMovementRangeCombined && (
              <div style={{ marginTop: '16px' }}>
                <Button onClick={abortProcedure}>
                  {t('configurator:component.prevent_input_modal.abort', 'Abort')}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      )}
    </ModalBase>
  );
};

export default PreventInputModal;
