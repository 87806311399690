import CustomTextField from 'components/FormFields/CustomTextField';
import { SectionHeader } from './styled';
import { useTranslation } from 'react-i18next';

const ClinicsInfo = ({ control }) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <SectionHeader>
        {t('auth:component.register.subtitle_clinics_info', 'Clinic`s info')}
      </SectionHeader>
      <CustomTextField
        label={t('auth:register.form.clinic_name', 'Clinic name')}
        optional
        id='clinicName'
        control={control}
      />
      <CustomTextField
        label={t('auth:register.form.clinic_location', 'Clinic location')}
        optional
        id='clinicLocation'
        control={control}
      />
      <CustomTextField
        label={t('auth:register.form.clinic_line1', 'Address line 1')}
        optional
        id='clinicAddress'
        control={control}
      />
      <CustomTextField
        label={t('auth:register.form.clinic_line2', 'Address line 2')}
        optional
        id='clinicAddress2'
        control={control}
      />
    </>
  );
};

export default ClinicsInfo;
