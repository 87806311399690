import { ChartInfo } from './types';
import GoodVelocity from 'assets/graphs/velocity_good.svg';
import Bad1Velocity from 'assets/graphs/velocity_bad_1.svg';
import Bad2Velocity from 'assets/graphs/velocity_bad_2.svg';
import AdjustVelocity from 'assets/graphs/velocity_adjust.svg';

export const gripVelocityDescription: ChartInfo = {
  description: 'common:component.graphs.velocity_description',
  sections: [
    {
      title: 'common:component.graphs.velocity.section_1.title',
      content: [
        {
          title: 'common:component.graphs.velocity.section_1.issue.title',
          content: 'common:component.graphs.velocity.section_1.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.velocity.section_1.significance.title',
          content: 'common:component.graphs.velocity.section_1.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.velocity.section_1.action.title',
          content: 'common:component.graphs.velocity.section_1.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ],
      examples: [
        {
          label: 'common:component.graphs.velocity.section_1.examples.good',
          status: 'good',
          image: GoodVelocity
        },
        {
          label: 'common:component.graphs.velocity.section_1.examples.bad_1',
          status: 'bad',
          image: Bad1Velocity
        },
        {
          label: 'common:component.graphs.velocity.section_1.examples.bad_2',
          status: 'bad',
          image: Bad2Velocity
        },
        {
          label: 'common:component.graphs.velocity.section_1.examples.adjust',
          status: 'bad',
          image: AdjustVelocity
        }
      ]
    },
    {
      title: 'common:component.graphs.velocity.section_2.title',
      content: [
        {
          title: 'common:component.graphs.velocity.section_2.issue.title',
          content: 'common:component.graphs.velocity.section_2.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.velocity.section_2.significance.title',
          content: 'common:component.graphs.velocity.section_2.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.velocity.section_2.action.title',
          content: 'common:component.graphs.velocity.section_2.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ],
      examples: [
        {
          label: 'common:component.graphs.velocity.section_2.examples.good',
          status: 'good',
          image: GoodVelocity
        },
        {
          label: 'common:component.graphs.velocity.section_2.examples.bad_1',
          status: 'bad',
          image: Bad1Velocity
        },
        {
          label: 'common:component.graphs.velocity.section_2.examples.bad_2',
          status: 'bad',
          image: Bad2Velocity
        },
        {
          label: 'common:component.graphs.velocity.section_2.examples.adjust',
          status: 'neutral',
          image: AdjustVelocity
        }
      ]
    }
  ]
};
