import { useNavigate } from 'react-router-dom';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../../api/users/users.types';
import { useEffect, useState } from 'react';
import { useUsersInfinite } from '../../../hooks/api/useUsers';
import { mapUsersRoles, userHasPermissions } from '../../../utils/permissionUtils';
import useUserData from '../../../../hooks/useUserData';
import { useVersions } from '../../../hooks/api/useVersions';
import CustomTextField from 'components/FormFields/CustomTextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import FormComboBox from 'components/FormFields/FormComboBox';
import { CircularProgress } from '@mui/material';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

export const deviceSchema = yup.object().shape({
  model: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object'),
  serial: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  bluetooth_id: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  firmware: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object'),
  pcb: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object'),
  clinicians: yup.mixed().nullable(),
  amputee: yup.mixed().nullable()
});

interface DeviceFormProps {
  existingData?: any;
  handleSubmit: any;
  loading: boolean;
}

const DeviceForm = ({ existingData, handleSubmit, loading }: DeviceFormProps) => {
  const defaultValues = {
    model: null,
    serial: '',
    bluetooth_id: '',
    clinicians: [],
    amputee: null,
    pcb: null,
    firmware: null
  };

  const isEdit = Boolean(existingData);

  const {
    control,
    watch,
    handleSubmit: handleSubmitDevice,
    resetField,
    setValue
  } = useForm<{
    model: any;
    serial: string;
    bluetooth_id: string;
    clinicians: any;
    amputee: any;
    firmware: any;
    pcb: any;
  }>({
    defaultValues: isEdit ? existingData : defaultValues,
    // @ts-ignore
    resolver: yupResolver(deviceSchema)
  });

  const cliniciansWatch = watch('clinicians');

  const navigate = useNavigate();
  const { rolesByName } = useUserData();

  const [queryParamsClinician, setQueryParamsClinician] = useState<UsersQueryParams>({
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    extend: [UserExtendOptions.roles],
    perpage: 1000
  });
  const [queryParamsPatient, setQueryParamsPatient] = useState<UsersQueryParams>({
    roles: RoleEnum.amputee,
    perpage: 1000
  });

  const { PCBVersion, FirmwareVersion, DeviceModel } = useVersions(
    userHasPermissions([RoleEnum.superAdmin], rolesByName)
  );
  const { result: clinicians, isLoading: isLoadingClinicians } =
    useUsersInfinite(queryParamsClinician);
  const { result: patients, isLoading: isLoadingPatients } = useUsersInfinite(queryParamsPatient);

  const cliniciansWithRoles = clinicians && mapUsersRoles(clinicians);

  const initialFetchData = PCBVersion && FirmwareVersion && DeviceModel;
  const dynamicFetchData = cliniciansWithRoles && patients;

  const noClinicianSelected = !cliniciansWatch || cliniciansWatch?.length === 0;
  useEffect(() => {
    if (noClinicianSelected) {
      setValue('amputee', null);
    }
  }, [cliniciansWatch]);

  if (!initialFetchData) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmitDevice(handleSubmit)}>
      <FormWrapper>
        <FormComboBox
          label='Model'
          id='model'
          control={control}
          options={DeviceModel}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <FormComboBox
          label='Firmware'
          id='firmware'
          control={control}
          options={FirmwareVersion}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => {
            console.log(option, value);
            return option.id === value.id;
          }}
        />
        <FormComboBox
          label='PCB'
          id='pcb'
          control={control}
          options={PCBVersion}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <CustomTextField label='Serial Number' id='serial' control={control} />
        )}
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <CustomTextField label='Bluetooth Id' id='bluetooth_id' control={control} />
        )}
        {dynamicFetchData ? (
          <>
            <FormComboBox
              label='Clinicians'
              id='clinicians'
              control={control}
              options={cliniciansWithRoles}
              optionLabel={'name'}
              disabled={isLoadingClinicians}
              optional
              multiple
            />
            <FormComboBox
              label='Patient ID'
              id='amputee'
              control={control}
              options={patients}
              optionLabel={'name'}
              disabled={isLoadingPatients || noClinicianSelected}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              optional
            />
          </>
        ) : (
          <CircularProgress />
        )}
        <FormButtonsWrapper marginTop='12px'>
          <CustomButton data-testid='cancel-device-form' onClick={() => navigate(-1)} color='light'>
            Cancel
          </CustomButton>
          <CustomButton data-testid='submit-device-form' type='submit' loading={loading}>
            {isEdit ? 'Edit' : 'Add device'}
          </CustomButton>
        </FormButtonsWrapper>
      </FormWrapper>
    </form>
  );
};

export default DeviceForm;
