/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Types } from 'ably';
import TelemetryController from 'configurator/bluetooth-handler/telemetryController';
import { EMERGENCY_MODE_NOTIFICATION } from 'configurator/consts/notifications';
import { useConfigStore } from 'configurator/reducers/configStore';
import useModes from 'configurator/hooks/useModes';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import useMeeting from 'configurator/hooks/useMeeting';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { ablyClient } from 'configurator/utils/LiveConfigurator/AblyClient';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import useUserData from 'hooks/useUserData';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import { MODALS } from 'configurator/views/Modals';

const initialState = {
  mainChannel: null,
  mainChannelSubscriptionsReady: false,
  additionalSubscriptionsReady: false,
  sessionStartCalled: false
};

const LiveConfigurator = ({ children, remoteSessionState }: any) => {
  const [mainChannel, setMainChannel] = useState<Types.RealtimeChannelPromise | null>(
    initialState.mainChannel
  );
  const [mainChannelSubscriptionsReady, setMainChannelSubscriptionsReady] = useState(
    initialState.mainChannelSubscriptionsReady
  );
  const [additionalSubscriptionsReady, setAdditionalSubscriptionsReady] = useState(
    initialState.additionalSubscriptionsReady
  );
  const [meetingChannel, setMeetingChannel] = useState<Types.RealtimeChannelPromise | null>();
  const [sessionStartCalled, setSessionStartCalled] = useState(initialState.sessionStartCalled);
  const { enabled, channel: connection, sessionApi, isUnsaved } = remoteSessionState;
  const { handleChangeMode } = useModes();
  const { config, slotSelected, getInitialConfigAPI } = useConfigStore((state: any) => ({
    config: state.config,
    slotSelected: state.slotSelected,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { mainViewLoaded, blockScreen, unblockScreen, openModal, isModalOpen } = useUiStore(
    (state) => ({
      mainViewLoaded: state.mainViewLoaded,
      blockScreen: state.blockScreen,
      unblockScreen: state.unblockScreen,
      openModal: state.openModal,
      isModalOpen: state.isModalOpen
    })
  );
  const { handleCloseMeeting, handleOpenMeeting } = useMeeting();
  const { setItemLiveConfigurator } = useLiveConfiguratorStore((state: any) => ({
    setItemLiveConfigurator: state.setItemLiveConfigurator
  }));
  const { amputeeId } = useDeviceInfoStore((state: any) => ({
    amputeeId: state.amputeeId
  }));
  const { sendReady, sessionReady, disconnectRemoteSession } = useRemoteSession();
  const { data: userData } = useUserData();
  const { videoSessionId } = useMeetingStore((state) => ({
    videoSessionId: state.videoSessionId
  }));

  const isFirmwareMobileOpen = isModalOpen(MODALS.firmware);

  const readyToNotifyMobile =
    sessionReady &&
    amputeeId &&
    mainChannel &&
    mainViewLoaded &&
    mainChannelSubscriptionsReady &&
    additionalSubscriptionsReady;

  const restartSession = () => {
    setMainChannel(initialState.mainChannel);
    setSessionStartCalled(initialState.sessionStartCalled);
    setAdditionalSubscriptionsReady(initialState.additionalSubscriptionsReady);
    setMainChannelSubscriptionsReady(initialState.mainChannelSubscriptionsReady);
  };

  useEffect(() => {
    if (!enabled) {
      restartSession();
    }
  }, [enabled]);

  useEffect(() => {
    const prepareMainSubscribers = async () => {
      const main = ablyClient(sessionApi.clinician_uuid).channels.get(connection.name);

      setMainChannel(main);

      await main.attach();

      await main?.presence.subscribe('enter', (member) => {
        setItemLiveConfigurator('patientConnected', true);
      });

      await main?.presence.subscribe('leave', (member) => {
        setItemLiveConfigurator('patientConnected', false);
      });

      await main?.subscribe('emg', (message) => {
        if (message.data) {
          TelemetryController.parseTelemetry({ detail: [{ payload: message.data }] });
        }
      });

      await main?.subscribe('restart_web', (message) => {
        getInitialConfigAPI();
      });

      await main?.subscribe('firmware_update_progress', (message) => {
        openModal(MODALS.firmware);

        const event = new CustomEvent('firmware_update_progress', {
          detail: message
        });
        window.dispatchEvent(event);
      });

      await main?.subscribe('firmware_update_result', (message) => {
        const event = new CustomEvent('firmware_update_result', {
          detail: message
        });
        window.dispatchEvent(event);
      });

      await main.presence.enter();
      setMainChannelSubscriptionsReady(true);
    };
    if (enabled && sessionApi?.clinician_uuid && !mainChannel) {
      prepareMainSubscribers();
    }
    return () => {
      if (mainChannel) {
        mainChannel.unsubscribe();
      }
    };
  }, [enabled, connection, sessionApi, mainChannel]);

  useEffect(() => {
    const prepareAdditionalSubscribers = async () => {
      await mainChannel?.subscribe('close_session_patient', () => {
        disconnectRemoteSession(false);
        toast('Patient has closed the session', { icon: '⚠️' });
      });

      await mainChannel?.subscribe('query_mode_web', () => {
        handleChangeMode(slotSelected ?? config.modes[0].slot);
      });

      await mainChannel?.subscribe('emergency_mode', () => {
        toast(EMERGENCY_MODE_NOTIFICATION.message, EMERGENCY_MODE_NOTIFICATION.options);
      });

      await mainChannel?.subscribe('block_adp_screen', () => {
        blockScreen(BLOCK_MODALS.MOBILE_BLOCK);
      });

      await mainChannel?.subscribe('unblock_adp_screen', () => {
        unblockScreen(BLOCK_MODALS.MOBILE_BLOCK);
      });

      setAdditionalSubscriptionsReady(true);
    };
    if (mainChannel) {
      prepareAdditionalSubscribers();
    }
    return () => {
      if (mainChannel) {
        mainChannel.unsubscribe('close_session_patient');
        mainChannel.unsubscribe('query_mode_web');
        mainChannel.unsubscribe('emergency_mode');
        mainChannel.unsubscribe('block_adp_screen');
        mainChannel.unsubscribe('unblock_adp_screen');
      }
    };
  }, [isUnsaved, mainChannel, slotSelected, config.modes]);

  useEffect(() => {
    const prepareMeetingSubscribers = async () => {
      const channel = `meeting:${amputeeId}`;
      const meetingChannel = ablyClient(`meeting:${userData?.id}`).channels.get(channel);
      setMeetingChannel(meetingChannel);

      await meetingChannel?.subscribe('close_meeting_web', (message) => {
        handleCloseMeeting(false);
      });

      await meetingChannel?.presence.subscribe('leave', (member) => {
        handleCloseMeeting(false);
      });

      meetingChannel.presence.enter();
    };

    if (amputeeId && userData && videoSessionId) {
      prepareMeetingSubscribers();
    }

    return () => {
      if (meetingChannel) {
        meetingChannel.unsubscribe();
      }
    };
  }, [amputeeId, meetingChannel, videoSessionId]);

  useEffect(() => {
    const handleStartSession = async () => {
      if (sessionStartCalled) {
        toast.error('Session already started');
        return;
      }
      await sendReady();
      handleOpenMeeting(Number(amputeeId));
      setSessionStartCalled(true);
    };

    if (readyToNotifyMobile) handleStartSession();
  }, [readyToNotifyMobile]);

  return <>{children}</>;
};

export default LiveConfigurator;
