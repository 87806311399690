/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import AccountDetailsForm from 'adp-panel/pages/Account/AccountDetailsForm';
import { NotificationFactory } from 'lib/NotificationFactory';
import { SubmitHandler } from 'react-hook-form';
import { useMfaMethod, useMfaUpdate } from 'hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import { SetMfaStatusPayload } from '../../api/authentication/authentication.types';
import { UserUpdatePayload } from '../../api/users/users.types';
import { useChangeUserPhone, useUserMe, useUserUpdate } from '../../hooks/api/useUsers';
import { useLocation } from 'react-router-dom';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { default as DefaultLayoutConfigurator } from 'configurator/layouts/DefaultLayout';

export type Inputs = {
  name: string;
  email: string;
  phone: string | undefined;
  password: string;
  language: any;
  clinicName: string | undefined;
  clinicLocation: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  mfaEnabled: boolean;
  mfaMethod: any;
};

const AccountDetails = () => {
  const { t, i18n } = useTranslation('account');
  const { result: accountDetails, isLoading: isLoadingUserDetails } = useUserMe();
  const { result: mfaStatus, isLoading: isLoadingMfaStatus } = useMfaMethod();
  const { mutateAsync: updateUser, isLoading: isLoadingUserUpdate } = useUserUpdate();
  const { mutateAsync: updateMfa, isLoading: isLoadingMfaUpdate } = useMfaUpdate();
  const { mutateAsync: updateUserPhone, isLoading: isLoadingPhoneUpdate } = useChangeUserPhone();
  const location = useLocation();
  const { configuratorLayout = false } = location.state || {};

  const handleSubmit: SubmitHandler<Inputs> = async (data) => {
    const {
      name = undefined,
      addressLine1 = undefined,
      addressLine2 = undefined,
      clinicName = undefined,
      clinicLocation = undefined,
      language = undefined,
      mfaEnabled = undefined,
      mfaMethod,
      phone = undefined
    } = data;

    const userData = {
      id: accountDetails.id,
      address1: addressLine1,
      address2: addressLine2,
      clinic_name: clinicName,
      clinic_location: clinicLocation,
      ...(name && { name }),
      ...(language && { language: language.code })
    } as UserUpdatePayload;

    const mfaData = {
      enabled: mfaEnabled ? 1 : 0,
      ...(mfaMethod && mfaEnabled && { method: mfaMethod })
    } as SetMfaStatusPayload;

    try {
      await updateUser(userData);
      await updateMfa(mfaData);

      if (phone !== undefined && phone.length === 0) {
        await updateUserPhone({ userId: accountDetails.id, phone: '0' });
      }
      if (userData.language) {
        i18n.changeLanguage(language.code);
      }
      NotificationFactory.successNotification(
        t('notifications:component.account_details.user_settings_updated', 'User settings updated')
      );
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const isLoadingUpdate = isLoadingUserUpdate || isLoadingMfaUpdate || isLoadingPhoneUpdate;
  const isLoadingData = isLoadingUserDetails || isLoadingMfaStatus;

  if (isLoadingData) return <ConfirmationLoader fullScreen />;

  return configuratorLayout ? (
    <DefaultLayoutConfigurator>
      <FormContentWrapper
        title={t('account:component.account_details.form.title', 'Account settings')}
        small>
        <AccountDetailsForm
          onSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
          accountDetails={accountDetails}
          updateUserPhone={updateUserPhone}
          updateMfa={updateMfa}
          mfaStatus={mfaStatus}
        />
      </FormContentWrapper>
    </DefaultLayoutConfigurator>
  ) : (
    <DefaultLayout>
      <FormContentWrapper
        title={t('account:component.account_details.form.title', 'Account settings')}
        small>
        <AccountDetailsForm
          onSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
          accountDetails={accountDetails}
          updateUserPhone={updateUserPhone}
          updateMfa={updateMfa}
          mfaStatus={mfaStatus}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};
export default AccountDetails;
