import GoodActiveTime from 'assets/graphs/good-active-time.svg';
import BadActiveTime from 'assets/graphs/bad-active-time.svg';
import { ChartInfo } from './types';

export const activePassiveTimeDescription: ChartInfo = {
  description: 'common:component.graphs.grips_count_description',
  sections: [
    {
      title: 'common:component.graphs.grips_count.section_1.title',
      content: [
        {
          title: 'common:component.graphs.grips_count.section_1.issue.title',
          content: 'common:component.graphs.grips_count.section_1.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.grips_count.section_1.significance.title', //'Significance',
          content: 'common:component.graphs.grips_count.section_1.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.grips_count.section_1.action.title', //'Action',
          content: 'common:component.graphs.grips_count.section_1.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ],
      examples: [
        {
          label: 'common:component.graphs.grips_count.section_1.examples.good',
          status: 'good',
          image: GoodActiveTime
        },
        {
          label: 'common:component.graphs.grips_count.section_1.examples.bad',
          status: 'bad',
          image: BadActiveTime
        }
      ]
    },
    {
      title: 'common:component.graphs.grips_count.section_2.title',
      content: [
        {
          title: 'common:component.graphs.grips_count.section_2.issue.title',
          content: 'common:component.graphs.grips_count.section_2.issue.text',
          icon: 'alert-triangle',
          iconVariant: 'warning'
        },
        {
          title: 'common:component.graphs.grips_count.section_2.significance.title', //'Significance',
          content: 'common:component.graphs.grips_count.section_2.significance.text',
          icon: 'info-circle',
          iconVariant: 'info'
        },
        {
          title: 'common:component.graphs.grips_count.section_2.action.title', //'Action',
          content: 'common:component.graphs.grips_count.section_2.action.text',
          icon: 'cursor-click',
          iconVariant: 'success'
        }
      ],
      examples: [
        {
          label: 'common:component.graphs.grips_count.section_2.examples.good',
          status: 'good',
          image: GoodActiveTime
        },
        {
          label: 'common:component.graphs.grips_count.section_2.examples.bad',
          status: 'bad',
          image: BadActiveTime
        }
      ]
    }
  ]
};
