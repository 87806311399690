import React from 'react';
import { InputLabel, TextField, Typography } from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

export type ValidateResult = string | boolean | undefined;

export interface CustomErrorMessage {
  key: string;
  values?: Record<string, any>;
}

export interface CustomFieldError extends Omit<FieldError, 'message' | 'types'> {
  message?: string | CustomErrorMessage;
  types?: Record<string, ValidateResult>;
}

export interface CustomTextField {
  id: string;
  label: any;
  control: any;
  disabled?: boolean;
  optional?: boolean;
  slotProps?: any;
}

const CustomTextField = ({
  id,
  label,
  control,
  disabled = false,
  optional = false,
  slotProps = undefined
}: CustomTextField) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => {
        const error = fieldState.error as CustomFieldError;
        let helperText = '';
        const message = error?.message;
        if (message) {
          if (typeof message === 'string') {
            helperText = t(message);
          } else if ('key' in message) {
            helperText = t(message.key, message.values);
          }
        }

        return (
          <div>
            <InputLabel
              shrink={false}
              htmlFor={id}
              sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
              <LabelWrapper>
                <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                {optional && (
                  <Typography>({t('common:text_field.optional_label', 'Optional')})</Typography>
                )}
              </LabelWrapper>
            </InputLabel>
            <TextField
              {...field}
              id={id}
              size='small'
              variant='outlined'
              disabled={disabled}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(fieldState.error)}
              helperText={helperText}
              slotProps={slotProps}
            />
          </div>
        );
      }}
    />
  );
};

export default CustomTextField;
