import { GoalConditionType } from 'adp-panel/api/goals/goals.types';
import dayjs from 'dayjs';
import { useUserGoalCreate, useUserGoalConditionsCreate } from 'adp-panel/hooks/api/useGoals';
import * as Sentry from '@sentry/react';
import GoalsForm from './GoalsForm';
import { filterExercises, filterGrips, frequencyPeriods } from './utils';
import { NotificationFactory } from 'lib/NotificationFactory';
import { Modal } from '@mui/material';
import { ModalStyle } from 'adp-panel/components/Modals/styles';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { useTranslation } from 'react-i18next';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';

const AddGoalModal = ({ handleClose }) => {
  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));

  const additionalArgs = additionalModalArgs(MODALS.addGoalModal)?.args;
  const userId = additionalArgs.patientId;
  const { t } = useTranslation('goals');
  const { mutateAsync: goalCreate, isLoading: isLoadingGoalCreate } = useUserGoalCreate();
  const { mutateAsync: goalConditionCreate, isLoading: isLoadingGoalConditionCreate } =
    useUserGoalConditionsCreate();

  const isLoading = isLoadingGoalCreate || isLoadingGoalConditionCreate;

  const handleAddSubmit = async (values: any) => {
    try {
      const startDate = String(dayjs(values.start_date).format('YYYY-MM-DD'));
      const endDate = String(
        dayjs(values.start_date)
          .add(values.period - 1, 'day')
          .format('YYYY-MM-DD')
      );
      const goal = await goalCreate({
        userId,
        start_date: startDate,
        end_date: endDate,
        active: 1
      });
      if (!goal?.id) return;

      const gripsGoals = filterGrips(values);
      const exercisesGoals = filterExercises(values);

      await goalConditionCreate({
        userId,
        goalId: goal.id,
        type: GoalConditionType.switch,
        switches_count: values.switches,
        switches_frequency: values['switches-frequency']
      });
      await Promise.all(
        gripsGoals.map((gripGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.grip,
            grip_id: typeof gripGoal.id === 'number' ? gripGoal.id + 1 : null,
            grips_count: gripGoal.performedPerDay,
            grips_frequency: values['grips-frequency']
          })
        )
      );
      await Promise.all(
        exercisesGoals.map((exerciseGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.exercise,
            exercise_id: Number(exerciseGoal.id),
            exercise_frequency: exerciseGoal.frequency,
            exercise_count: exerciseGoal.performedPerDay
          })
        )
      );
      NotificationFactory.successNotification(t('goals:add_goal.modal.created', 'Goal created'));
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
      NotificationFactory.errorNotification(
        t('goals:add_goal.modal.fail', 'Failed to create goal')
      );
    }
  };

  return (
    <Modal open sx={{ width: '700px', ...ModalStyle }}>
      <FormContentWrapper title={t('goals:add_goal.modal.title', 'Add goal')}>
        <GoalsForm
          isLoading={isLoading}
          handleSubmit={handleAddSubmit}
          initialValues={{
            ['grips-frequency']: frequencyPeriods[0].value,
            ['switches-frequency']: frequencyPeriods[0].value
          }}
          handleModalClose={handleClose}
        />
      </FormContentWrapper>
    </Modal>
  );
};

export default AddGoalModal;
