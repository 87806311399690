import { useModal } from '../../../hooks/api/useModal';

export const usePatientModals = () => {
  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();

  const {
    isOpen: isUnassignDeviceModalOpen,
    handleOpen: handleUnassignDeviceModalOpen,
    handleClose: handleUnassignDeviceModalClose
  } = useModal();

  const {
    isOpen: isAssignDeviceModalOpen,
    handleOpen: handleAssignDeviceModalOpen,
    handleClose: handleAssignDeviceModalClose
  } = useModal();

  return {
    deleteModal: {
      isOpen: isDeleteModalOpen,
      handleOpen: handleDeleteModalOpen,
      handleClose: handleDeleteModalClose
    },
    unassignDeviceModal: {
      isOpen: isUnassignDeviceModalOpen,
      handleOpen: handleUnassignDeviceModalOpen,
      handleClose: handleUnassignDeviceModalClose
    },
    assignDeviceModal: {
      isOpen: isAssignDeviceModalOpen,
      handleOpen: handleAssignDeviceModalOpen,
      handleClose: handleAssignDeviceModalClose
    }
  };
};
