/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import HorizontalGraphSlider from 'configurator/components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';
import { useTranslation } from 'react-i18next';

import {
  Header2,
  OptionsSliderDescription,
  OptionsWrapper,
  OptionsWrapperMultiSlider,
  TooltipWrapper
} from './styled';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

interface OptionsWithSliderType {
  toggleValue: any;
  toggleOptions: any[];
  sliderValue: number;
  header: any;
  sliderLabel: any;
  sliderLimits: { min: number; max: number };
  handleSliderChange: Function;
  handleToggleChange: Function;
  handleSliderOnAfterChange: Function;
  description: string | React.ReactElement;
  disableSlider?: boolean;
  disabled?: boolean;
  scale?: (x: number) => number;
  step?: number;
  hideInput?: boolean;
  valueToDisplay?: any;
  tooltip?: React.ReactNode;
}

export const OptionsWithSlider = ({
  toggleValue,
  toggleOptions,
  sliderValue,
  header,
  sliderLabel,
  sliderLimits,
  handleSliderChange,
  handleToggleChange,
  handleSliderOnAfterChange,
  description,
  disableSlider = false,
  disabled = false,
  scale,
  step = 1,
  hideInput = false,
  valueToDisplay,
  tooltip,
  ...props
}: OptionsWithSliderType) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <TooltipWrapper>
        <Header2>{header}</Header2>
        {tooltip && <CustomTooltip title={tooltip} />}
      </TooltipWrapper>
      <OptionsSliderDescription>{description}</OptionsSliderDescription>
      {!hideInput && (
        <OptionsWrapper>
          <FormControl>
            <RadioGroup row name='position'>
              {toggleOptions.map((option) => (
                <FormControlLabel
                  disabled={disabled}
                  value={option}
                  control={<Radio onChange={(...args) => handleToggleChange(option, ...args)} />}
                  label={t(option)}
                  checked={toggleValue == option}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <HorizontalGraphSlider
            value={sliderValue}
            limits={sliderLimits}
            label={sliderLabel}
            disableSliders={disabled || disableSlider}
            handleChange={handleSliderChange}
            handleOnAfterChange={handleSliderOnAfterChange}
            valueToDisplay={valueToDisplay}
            scale={scale}
            step={step}
          />
        </OptionsWrapper>
      )}
    </div>
  );
};

interface MultiSliderType {
  header: string;
  sliderValues: any;
  labelSliders: any;
  limits: Array<{ min: number; max: number }>;
  description: string | React.ReactElement;
  handleChange: Function;
  handleOnAfterChange: any;
  disabled?: boolean;
  valuesToDisplay?: Array<any>;
  tooltip?: React.ReactNode;
}

export const MultiSlider = ({
  header,
  sliderValues,
  labelSliders,
  limits,
  handleChange,
  handleOnAfterChange,
  description,
  disabled = false,
  valuesToDisplay,
  tooltip,
  ...props
}: MultiSliderType) => (
  <div {...props}>
    <TooltipWrapper>
      <Header2>{header}</Header2>
      {tooltip && <CustomTooltip title={tooltip} />}
    </TooltipWrapper>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperMultiSlider>
      {limits.map((slider, index) => (
        <HorizontalGraphSlider
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          label={labelSliders[index]}
          disableSliders={disabled}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={(...args) => handleOnAfterChange(...args, index)}
          key={header + index}
          valueToDisplay={valuesToDisplay?.[index]}
        />
      ))}
    </OptionsWrapperMultiSlider>
  </div>
);
