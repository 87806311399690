import { Typography } from '@mui/material';
import { ArcherElement } from 'react-archer';
import Ellipse from 'assets/ellipse.svg';
import { useTranslation } from 'react-i18next';
import { GripSwitchingModeIndicator } from '../GripSwitchingModeIndicator/GripSwitchingModeIndicator';

const TreeFontStyle = {
  fontWeight: 600,
  fontSize: '20px'
};

const LineStyle: any = {
  strokeColor: '#D0D5DD',
  strokeWidth: 2,
  lineStyle: 'straight',
  endMarker: false
};

const GripChooserTreePairing = ({
  opposed = true,
  relations
}: {
  opposed?: boolean;
  relations: any[];
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <ArcherElement
        id='start1'
        relations={[
          {
            targetId: 'primary',
            targetAnchor: 'top',
            sourceAnchor: 'bottom',
            style: LineStyle
          },
          {
            targetId: 'secondary',
            targetAnchor: 'top',
            sourceAnchor: 'bottom',
            style: LineStyle
          }
        ]}>
        <Typography sx={{ ...TreeFontStyle, padding: '0 10px 0 10px' }}>
          {opposed
            ? t('configurator:common.opposed', 'Opposed')
            : t('configurator:common.non_opposed', 'Non-opposed')}
        </Typography>
      </ArcherElement>
      <div style={{ height: '150px' }}>{null}</div>
      <div style={{ position: 'relative', width: '100%', height: '20px' }}>
        <ArcherElement
          id='primary'
          relations={[
            {
              targetId: relations[0][0],
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
              style: LineStyle
            },
            {
              targetId: relations[0][1],
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
              style: LineStyle
            }
          ]}>
          <Typography
            sx={{
              position: 'absolute',
              left: '23%',
              transform: 'translateX(-50%)',
              ...TreeFontStyle,
              padding: '0 10px 0 10px'
            }}>
            Primary
          </Typography>
        </ArcherElement>
        <GripSwitchingModeIndicator
          primary={false}
          style={{
            position: 'absolute',
            right: '50%',
            transform: 'translateX(50%)',
            top: '-240%'
          }}
        />
        <img
          src={Ellipse}
          style={{
            width: '46%',
            position: 'absolute',
            left: '49.5%',
            transform: 'translateX(-50%)',
            top: '-80%'
          }}
        />
        <ArcherElement
          id='secondary'
          relations={[
            {
              targetId: relations[1][0],
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
              style: LineStyle
            },
            {
              targetId: relations[1][1],
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
              style: LineStyle
            }
          ]}>
          <Typography
            sx={{
              position: 'absolute',
              left: '77%',
              transform: 'translateX(-50%)',
              ...TreeFontStyle,
              padding: '0 10px 0 10px'
            }}>
            Secondary
          </Typography>
        </ArcherElement>
      </div>
      <div style={{ height: '150px' }}>{null}</div>
    </div>
  );
};

export default GripChooserTreePairing;
