import styled from 'styled-components';
import { TextS, HeaderM } from 'configurator/components/atoms/Typography/Typography';

export const ProcedureReplyWrapper = styled.div`
  ${TextS};
  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const ProcedureTableWrapper = styled.table`
  margin: 10px 0 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 0 10px 0;
  }
`;

export const ProcedureTable = styled.tbody`
  border: 2px solid #f2f2f2;
`;

export const ProcedureRow = styled.tr`
  text-align: center;

  td {
    padding: 0.5em;
    border: 2px solid #f2f2f2;
  }
`;

export const CalibrationWrapper = styled.div`
  margin-top: 20px;
`;

export const ProcedureStatusBox = styled.td<{ status?: any }>`
  color: ${({ status }) => (status === 1 ? 'green' : 'red')};
`;

export const DoubleLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;
