/* eslint-disable no-unused-vars */
import i18next from 'i18next';
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { debounce, mapValues, pick } from 'lodash';
import toast from 'react-hot-toast';
import { Alert, Checkbox, CircularProgress, Tooltip } from '@mui/material';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { gripsImagesMap } from 'configurator/utils/gripsImages';
import {
  createFingerBitmask,
  postCurrentGrip,
  postFingerLimits,
  postGripConfig,
  postInitialGripPositions,
  postJointTargetPosition
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { GRIPS, HISTORY_EVENTS } from 'configurator/consts/consts';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { timeoutCommandCustom } from 'configurator/utils/funcs';
import { useReplayStore } from 'configurator/reducers/replayStore';
import TelemetryController from 'configurator/bluetooth-handler/telemetryController';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Add } from 'assets/add.svg';
import { CustomGripsConfig, Grips } from '../../../bluetooth/Bluetooth/Grips';
import {
  AllowText,
  CardsContainer,
  GripImage,
  GripWrapper,
  HandMovementCheckboxWrapper,
  StyledError,
  StyledPositionAdjuster,
  Viewer,
  ViewersWrapper
} from './styled';
import useWatch from 'configurator/hooks/useWatch';
import Card from 'adp-panel/components/Card/Card';
import { ConfigurationSection } from '../ProsthesisSettings/styled';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import CustomButton from 'components/Button/CustomButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderWrapper from 'components/Loader/Loader';
import { customGripSchema } from './schema';
import useCustomGrips from './useCustomGrips';
import { CustomGripFormAdd, CustomGripForm } from './CustomGripsForms';

const FALLBACK_POSITION = [0, 0, 0, 0, 0];

const setHandGripPositions = async (gripValues: number[]) => {
  if (gripValues) {
    await postJointTargetPosition(0, gripValues[0]);
    await postJointTargetPosition(1, gripValues[1]);
    await postJointTargetPosition(2, gripValues[2]);
    await postJointTargetPosition(3, gripValues[3]);
    await postJointTargetPosition(4, gripValues[4]);
  }
};

export const getGripName = (customGripsAllowed, selectedGrip) => {
  if (
    selectedGrip === Grips.kGripCustom1 ||
    selectedGrip === Grips.kGripCustom2 ||
    selectedGrip === Grips.kGripCustom3
  ) {
    const customName = customGripsAllowed?.find(
      (customGrip) => customGrip?.grip_number === selectedGrip
    )?.name;
    if (customName) return customName;
  }

  return gripsGroupsOptionsMap.get(selectedGrip);
};

const sendFingersThrottled = debounce(setHandGripPositions, 100);

const sendFingers = async (value: any, index: any, { index: fingerIndex }: any) => {
  await postJointTargetPosition(fingerIndex, value);
};

const sendIndividualFingerThrottled = debounce(sendFingers, 100);

const normalizeConfig = (value: any) => {
  const isNotInitialized = (initial: any, limit: any) => initial === false || limit === false;
  const mappedObject = mapValues(value, (_value, key) => {
    const initial = value[`${key}`]?.initial;
    const limit = value[`${key}`]?.limit;
    if (isNotInitialized(initial, limit)) {
      // @ts-ignore
      return defaultConfig.gripsPositions[`${key}`];
    }
    return value[`${key}`];
  });

  return mappedObject;
};

enum SITES {
  initial,
  limit
}

const extractFingerChange = (event: any) => {
  const after = event.data.diff.common.after.gripsPositions;
  const afterGrip = Object.keys(after);
  let before = event.data.diff.common.before.gripsPositions;
  before = pick(before, afterGrip);

  const afterGripValues = after[`${afterGrip[0]}`];
  const beforeGripValues = before[`${afterGrip[0]}`];

  const initialDifferenceIndex = afterGripValues.initial.findIndex(
    (item: any, index: any) => item !== beforeGripValues.initial[index]
  );
  const limitDifferenceIndex = afterGripValues.limit.findIndex(
    (item: any, index: any) => item !== beforeGripValues.limit[index]
  );

  let index;
  let value;

  if (initialDifferenceIndex > -1) {
    index = initialDifferenceIndex;
    value = beforeGripValues.initial[index];
  } else {
    index = limitDifferenceIndex;
    value = beforeGripValues.limit[index];
  }

  return { value, index, site: initialDifferenceIndex ? SITES.initial : SITES.limit };
};

const GripsConfigurationComponent = () => {
  const { t } = useTranslation();
  const { gripsPositions } = useConfigStore(getCurrentConfigSelector);
  const {
    handMovementAllowed,
    currentGrip,
    setItemConfigStore,
    resetGripPositions,
    setConfigProperty,
    addConfigHistory
  } = useConfigStore((state: any) => ({
    handMovementAllowed: state.handMovementAllowed,
    currentGrip: state.currentGrip,
    setItemConfigStore: state.setItemConfigStore,
    resetGripPositions: state.resetGripPositions,
    setConfigProperty: state.setConfigProperty,
    addConfigHistory: state.addConfigHistory
  }));
  const customGripSelected =
    currentGrip === Grips.kGripCustom1 ||
    currentGrip === Grips.kGripCustom2 ||
    currentGrip === Grips.kGripCustom3;
  const { connected: deviceConnected } = useDeviceInfoStore((state) => ({
    connected: state.connected
  }));
  const [grips, setGrips] = useState<any>(GRIPS);
  const [disableInput, setDisableInput] = useState(true);
  const [selectedGrip, setSelectedGrip] = useState(Grips.kGripTypeUnknown);
  const [lastSelectedGrip, setLastSelectedGrip] = useState<null | Grips>(null);
  const restoreRecentChannel = new BroadcastChannel('handleRestoreRecent');
  const undoChannel = new BroadcastChannel('undo');
  const [restoreReceived, setRestoreReceived] = useState(false);
  useWatch(TelemetryController, ['prosthesisGrip', 'gripInTransition']);
  const [values, setValues] = useState(() => {
    const initial = {};
    for (const key in Grips) {
      if (Object.hasOwnProperty.call(Grips, key)) {
        // @ts-ignore
        initial[Grips[key]] = {
          initial: [0, 0, 0, 0, 0],
          limit: [0, 0, 0, 0, 0]
        };
      }
    }
    return initial;
  });
  const { canAccess } = useCanAccess({ action: 'gripsConfiguration', resource: 'grips' });
  const { enabled: replayIsEnabled } = useReplayStore();
  const gripInTransition = handMovementAllowed && TelemetryController.gripInTransition;

  const {
    handleDeleteCustomGrip,
    handleAddCustomGripTemplate,
    handleSubmitNewCustomGrip,
    customGripsAllowed,
    handleAddCustomGrip,
    customGripsLimitReached,
    customGripsTemplates,
    isLoadingCreateAndSaveTemplate,
    isLoadingCreate,
    showCustomGripTemplates,
    setShowCustomGripTemplates,
    isLoadingCustomGrips,
    isLoadingDeleteCustomGrip,
    setCustomGripAddMode,
    customGripAddMode,
    selectedGripType,
    setSelectedGripType,
    handleChangeGripTemplate,
    handleUpdateCustomGrip,
    isLoadingUpdateCustomGrip
  } = useCustomGrips({ selectedGrip, values, currentGrip, setValues });

  useEffect(() => {
    if (customGripAddMode && TelemetryController.prosthesisGrip !== currentGrip) {
      postCurrentGrip(currentGrip);
      return;
    }

    if (
      (TelemetryController.prosthesisGrip || TelemetryController.prosthesisGrip === 0) &&
      handMovementAllowed &&
      TelemetryController.prosthesisGrip !== 255
    ) {
      setItemConfigStore('currentGrip', TelemetryController.prosthesisGrip);
    }
  }, [TelemetryController.prosthesisGrip]);

  // Update grips positions from store
  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setValues(normalizeConfig(gripsPositions));
      setGrips(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter((position) => {
            return (
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              !Number.isNaN(position)
            );
          })
          .filter((position) => {
            // Filter custom grips
            const positionIsCustomGrip =
              position === Grips.kGripCustom1 ||
              position === Grips.kGripCustom2 ||
              position === Grips.kGripCustom3;

            if (positionIsCustomGrip)
              return Boolean(
                customGripsAllowed?.find(
                  (customGripAllowed) => customGripAllowed?.grip_number === position
                )
              );

            return position;
          })
      );
    }
  }, [gripsPositions, JSON.stringify(customGripsAllowed)]);

  // Disable input if grip is undefined
  useEffect(() => {
    const setHandPosition = async () => {
      let breakLoop = false;
      const waitForTransitionEnd = async () => {
        while (TelemetryController.gripInTransition && !breakLoop) {
          await delay(100);
        }
        return true;
      };
      await postCurrentGrip(currentGrip);
      await delay(300);
      await timeoutCommandCustom(() => waitForTransitionEnd(), 5000);
      await setHandGripPositions(gripsPositions[currentGrip]?.initial ?? FALLBACK_POSITION);
      breakLoop = true;
    };

    if (currentGrip || currentGrip === 0) {
      if (currentGrip === Grips.kGripTypeUnknown) {
        setDisableInput(true);
      }
      if (currentGrip !== Grips.kGripTypeUnknown) {
        setDisableInput(false);
      }
      setSelectedGrip(currentGrip);
      if (handMovementAllowed) {
        setHandPosition();
      }
    }
  }, [currentGrip, handMovementAllowed]);

  useEffect(() => {
    if (customGripAddMode) {
      setLastSelectedGrip(currentGrip);
      setItemConfigStore('handMovementAllowed', true);

      const gripOrder = [Grips.kGripCustom1, Grips.kGripCustom2, Grips.kGripCustom3];
      const firstMissingGrip = gripOrder.find(
        (grip) =>
          !customGripsAllowed?.some((customGripAllowed) => customGripAllowed?.grip_number === grip)
      );

      // If there's a missing grip, update the current grip
      if (firstMissingGrip) {
        setItemConfigStore('currentGrip', firstMissingGrip);
      }
    }
  }, [customGripAddMode, JSON.stringify(customGripsAllowed)]);

  useEffect(() => {
    restoreRecentChannel.onmessage = () => {
      setRestoreReceived(true);
    };
    if (handMovementAllowed) {
      sendFingersThrottled(gripsPositions[currentGrip]?.initial ?? FALLBACK_POSITION);
      setRestoreReceived(false);
      undoChannel.onmessage = (message) => {
        const { value, index } = extractFingerChange(message);
        sendIndividualFingerThrottled(value, null, { index });
      };
    }

    return () => {
      restoreRecentChannel.close();
      undoChannel.close();
    };
  }, [handMovementAllowed, restoreReceived]);

  const handleOptions = async (option: any) => {
    let newGrip: any = gripsGroupsOptionsReversedMap.get(option);

    if (!newGrip) {
      newGrip = customGripsAllowed?.find((customGrip) => customGrip?.name === option)?.grip_number;
    }
    setItemConfigStore('currentGrip', newGrip);
  };

  const handleSliderChange = async (
    value: any,
    index: any,
    { index: fingerIndex, sliderType, min, max }: any
  ) => {
    let valueSanitized = value;
    if (typeof valueSanitized === 'string') {
      valueSanitized = parseInt(value, 10);
      if (Number.isNaN(valueSanitized)) valueSanitized = min;
    }
    if (value > max) {
      valueSanitized = max;
    }
    if (value < min) {
      valueSanitized = min;
    }
    // @ts-ignore
    let newInitial = values[selectedGrip].initial;
    // @ts-ignore
    let newLimit = values[selectedGrip].limit;
    if (sliderType === 'primary') {
      newInitial = newInitial.map((v: any, i: any) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newLimit = newLimit.map((v: any, i: any) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      initial: newInitial,
      limit: newLimit
    };
    setValues((prev) => ({
      ...prev,
      [selectedGrip]: { ...prev[selectedGrip], ...newGripValues }
    }));
  };

  const handleFingerToggle = async (index) => {
    const newActiveFingers = values[selectedGrip]?.activeFingers.map((finger, _index) => {
      if (_index === index) {
        if (finger === 0) return 1;
        if (finger === 1) return 0;
      }
      return finger;
    });

    if (customGripAddMode && deviceConnected) {
      setValues((prev) => ({
        ...prev,
        [selectedGrip]: { ...prev[selectedGrip], activeFingers: newActiveFingers }
      }));
      await postGripConfig([
        selectedGrip,
        CustomGripsConfig.kActiveFingers,
        createFingerBitmask(newActiveFingers)
      ]);
      return;
    }

    addConfigHistory(
      HISTORY_EVENTS.gripsConfiguration,
      setConfigProperty('gripsPositions', {
        ...values,
        [selectedGrip]: {
          initial: values[selectedGrip].initial,
          limit: values[selectedGrip].limit,
          activeFingers: newActiveFingers
        }
      })
    );
  };

  const handleSliderChangeThrottled = debounce(handleSliderChange, 10);

  const handleOnAfterChange = async (...args: any) => {
    const [newValue, _, { index, sliderType }] = args;
    // @ts-ignore
    const initialValue = values[selectedGrip].initial[index];
    // @ts-ignore
    const limitValue = values[selectedGrip].limit[index];
    const errorMessage = t(
      'configurator:component.grips_config.error_message',
      "Beginning position is greater than the end position, finger won't be able to move back to beginning position"
    );
    if (sliderType === 'primary') {
      if (newValue > limitValue) {
        toast.error(errorMessage, { id: 'gripsConfigurationPrimary' });
      }
    } else if (newValue < initialValue) {
      toast.error(errorMessage, { id: 'gripsConfigurationSecondary' });
    }

    if (customGripAddMode && deviceConnected) {
      await postInitialGripPositions(selectedGrip, values[selectedGrip].initial);
      await postFingerLimits(selectedGrip, values[selectedGrip].limit);
      return;
    }

    addConfigHistory(
      HISTORY_EVENTS.gripsConfiguration,
      setConfigProperty('gripsPositions', values)
    );
  };

  const {
    control,
    handleSubmit: handleSubmit,
    watch,
    setValue
  } = useForm({
    mode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(customGripSchema)
  });

  useEffect(() => {
    setValue(
      'name',
      customGripsAllowed?.find((customGrip) => customGrip?.grip_number === selectedGrip)?.name
    );
  }, [selectedGrip, JSON.stringify(customGripsAllowed)]);

  return (
    <div style={{ maxWidth: '1100px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '24px'
        }}>
        <CustomPageHeader
          header={t('configurator:navbar.grips_configuration')}
          sx={{ margin: 0 }}
          tooltipText={t('configurator:header.grips_configuration.tooltip', 'Tooltip')}
          margin={false}
        />
        {!customGripAddMode && gripsPositions?.[16] && (
          <Tooltip
            title={
              customGripsLimitReached
                ? t(
                    'configurator:grips_configuration.custom_grip.form.custom_grips_add_limit_tooltip',
                    'You can have a maximum of 3 custom grips.'
                  )
                : ''
            }>
            <span>
              <CustomButton
                onClick={handleAddCustomGrip}
                color='light'
                disabled={customGripsLimitReached}
                Icon={Add}>
                {t(
                  'configurator:grips_configuration.custom_grip.form.add_custom_grip',
                  'Add custom grip'
                )}
              </CustomButton>
            </span>
          </Tooltip>
        )}
      </div>
      <CardsContainer>
        <GripWrapper as={Card} padding='30px'>
          <ConfigurationSection
            tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}
            disabled={!canAccess}>
            <StyledPositionAdjuster
              values={{
                // @ts-ignore
                columnPrimary:
                  disableInput || selectedGrip === 255
                    ? [0, 0, 0, 0, 0]
                    : values[selectedGrip].initial,
                // @ts-ignore
                columnSecondary:
                  disableInput || selectedGrip === 255
                    ? [0, 0, 0, 0, 0]
                    : values[selectedGrip].limit
              }}
              handleSliderChange={(...args: Parameters<typeof handleSliderChangeThrottled>) => {
                handleSliderChangeThrottled(...args);
                if (handMovementAllowed) {
                  sendIndividualFingerThrottled(...args);
                }
              }}
              handleInputChange={handleSliderChange}
              handleOnAfterChange={handleOnAfterChange}
              handleOnAfterChangeInput={(
                ...args: Parameters<typeof handleSliderChangeThrottled>
              ) => {
                handleOnAfterChange(...args);
                if (handMovementAllowed) {
                  sendIndividualFingerThrottled(...args);
                }
              }}
              rows={[
                t('configurator:component.value_mapping.grips_positions.thumb', 'Thumb'),
                t('configurator:component.value_mapping.grips_positions.index', 'Index'),
                t('configurator:component.value_mapping.grips_positions.middle', 'Middle'),
                t('configurator:component.value_mapping.grips_positions.ring', 'Ring'),
                t('configurator:component.value_mapping.grips_positions.pinky', 'Pinky')
              ]}
              columns={[
                t('configurator:component.grips_config.beginning_position', 'Beginning position'),
                t('configurator:component.grips_config.end_position', 'End position')
              ]}
              // @ts-ignore
              hideButton
              limits={[
                { min: 0, max: 1000 },
                { min: 0, max: 1000 },
                { min: 0, max: 1000 },
                { min: 0, max: 1000 },
                { min: 0, max: 1000 }
              ]}
              disableInput={disableInput || replayIsEnabled || !canAccess || gripInTransition}
              buttonText={t(
                'configurator:component.grips_config.restore_button',
                'Restore default grip configuration'
              )}
              handleActionButton={() => {
                resetGripPositions(currentGrip);
                setRestoreReceived(true);
              }}
              toggles={customGripAddMode || customGripSelected}
              toggleValues={values[selectedGrip]?.activeFingers}
              onToggleChange={handleFingerToggle}
            />
          </ConfigurationSection>
        </GripWrapper>
        {isLoadingCustomGrips ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <div>
            {!customGripAddMode && (
              <ViewersWrapper as={Card}>
                <DropdownImg
                  options={grips.map((grip: any) => ({
                    value: getGripName(customGripsAllowed, grip),
                    img: gripsImagesMap.get(grip)
                  }))}
                  disabled={gripInTransition}
                  selected={{
                    value: getGripName(customGripsAllowed, selectedGrip),
                    img: gripsImagesMap.get(selectedGrip)
                  }}
                  onChange={(option: any) => handleOptions(option)}
                  showImg={false}
                  label={t('configurator:component.grips_config.current_grip', 'Current grip')}
                />
                <Viewer>
                  <GripImage src={gripsImagesMap.get(selectedGrip)} alt='Graph viewer' />
                </Viewer>
                {gripInTransition && (
                  <Alert severity='info'>
                    {t(
                      'configurator:component.grips_config.grip_in_transition',
                      'Grip in transition, please wait.'
                    )}
                  </Alert>
                )}
                {deviceConnected && !disableInput && !gripInTransition && (
                  <HandMovementCheckboxWrapper>
                    <div>
                      <AllowText>
                        {t(
                          'configurator:component.grips_config.allow_hand_movement',
                          'Allow hand movement'
                        )}
                      </AllowText>
                      <Checkbox
                        onClick={() =>
                          setItemConfigStore('handMovementAllowed', !handMovementAllowed)
                        }
                        id='movement'
                        name='movement'
                        checked={handMovementAllowed}
                      />
                    </div>
                    <StyledError show={handMovementAllowed}>
                      {t(
                        'configurator:component.grips_config.safe_position_warning',
                        'Make sure hand is in safe position!'
                      )}
                    </StyledError>
                  </HandMovementCheckboxWrapper>
                )}
                {customGripSelected && (
                  <div style={{ width: '100%' }}>
                    <CustomGripForm
                      control={control}
                      selectedGripType={selectedGripType}
                      setSelectedGripType={setSelectedGripType}
                      handleDeleteCustomGrip={handleDeleteCustomGrip}
                      isLoadingDeleteCustomGrip={isLoadingDeleteCustomGrip}
                      handleSubmit={handleSubmit}
                      handleAddCustomGripTemplate={handleAddCustomGripTemplate}
                      handleUpdateCustomGrip={handleUpdateCustomGrip}
                      isLoadingUpdateCustomGrip={isLoadingUpdateCustomGrip}
                    />
                  </div>
                )}
              </ViewersWrapper>
            )}
            {customGripAddMode && (
              <Card>
                <CustomGripFormAdd
                  control={control}
                  showCustomGripTemplates={showCustomGripTemplates}
                  setShowCustomGripTemplates={setShowCustomGripTemplates}
                  customGripsTemplates={customGripsTemplates}
                  handleChangeGripTemplate={handleChangeGripTemplate}
                  isLoadingCreate={isLoadingCreate}
                  handleSubmit={handleSubmit}
                  isLoadingCreateAndSaveTemplate={isLoadingCreateAndSaveTemplate}
                  handleSubmitNewCustomGrip={handleSubmitNewCustomGrip}
                  lastSelectedGrip={lastSelectedGrip}
                  selectedGripType={selectedGripType}
                  setCustomGripAddMode={setCustomGripAddMode}
                  setSelectedGripType={setSelectedGripType}
                  setItemConfigStore={setItemConfigStore}
                  connected={deviceConnected}
                />
              </Card>
            )}
          </div>
        )}
      </CardsContainer>
    </div>
  );
};

export default GripsConfigurationComponent;
