import { useRef, useState } from 'react';
import {
  CircularProgress,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { userCanVisit } from 'adp-panel/utils/permissionUtils';
import useUserData from 'hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import {
  MENU_ACTIONS,
  MENU_BOTTOM_CONFIG,
  MENU_CONFIG,
  MenuItem
} from 'adp-panel/constants/mainMenu';
import { ListWrapper, Logo, LogoWrapper, SidebarWrapper } from './styled';
import { DASHBOARD } from 'constants/routes';
import logo from 'assets/aether_biomedical_logo.svg';
import { styled } from '@mui/material/styles';
import { myTheme } from 'theme/theme';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useLogout from 'hooks/useLogout';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';

const SidebarListItemButton = styled(ListItemButton)<{ component?: React.ElementType }>({
  '&.Mui-selected': {
    backgroundColor: '#F2F4F7',
    '&:hover': {
      backgroundColor: `#F2F4F7`
    }
  },
  '& .MuiTouchRipple-child': {
    backgroundColor: 'green'
  }
});

export const MenuCustomItem = ({
  name,
  url,
  items = [],
  Icon,
  action,
  configuratorStyle
}: {
  name: string;
  url?: string;
  items: MenuItem[];
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  action?: any;
  configuratorStyle?: boolean;
}) => {
  const { t } = useTranslation();
  const { rolesByName } = useUserData();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const { handleLogout } = useLeaveConfigurator();
  const location = useLocation();
  const { logout } = useLogout();
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const handleAction = (action) => {
    if (action === MENU_ACTIONS.logout) logout(true);

    if (action === MENU_ACTIONS.inviteNewMedicalProfessional) {
      openModal(MODALS.inviteMedicalProfessional);
    }

    if (action === MENU_ACTIONS.logoutConfigurator) {
      handleLogout();
    }
  };

  const SidebarButton = ({
    action,
    url,
    pathname,
    name,
    Icon = null,
    lightFont = false,
    configuratorStyle = false
  }: {
    url: string | undefined;
    action: any;
    pathname: string;
    name: string;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
    lightFont?: boolean;
    configuratorStyle?: boolean;
  }) => (
    <SidebarListItemButton
      key={name}
      selected={url === pathname}
      sx={{ borderRadius: '8px', ...(configuratorStyle && { color: 'white' }) }}
      onClick={() => handleAction(action)}>
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: '28px'
          }}>
          <Icon style={{ stroke: configuratorStyle ? 'white' : '#101828' }} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={name}
        sx={{
          '.MuiTypography-root': {
            fontWeight: lightFont || configuratorStyle ? '400' : '600',
            fontSize: configuratorStyle ? '16px' : '14px'
          }
        }}
      />
    </SidebarListItemButton>
  );

  const StandardNavLink = ({
    url,
    action,
    pathname,
    name,
    Icon = null,
    lightFont = false,
    configuratorStyle = false
  }: {
    url: string | undefined;
    action: any;
    pathname: string;
    name: string;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
    lightFont?: boolean;
    configuratorStyle?: boolean;
  }) => (
    <NavLink
      to={url!}
      style={{
        textDecoration: 'none',
        color: configuratorStyle ? 'white' : myTheme.typography.colors.text1
      }}>
      <SidebarButton
        key={url + action}
        url={url}
        action={action}
        pathname={pathname}
        name={name}
        lightFont={lightFont}
        Icon={Icon}
      />
    </NavLink>
  );

  if (items.length === 0) {
    if (url) {
      return (
        <StandardNavLink
          url={url}
          action={action}
          pathname={location.pathname}
          name={t(name)}
          Icon={Icon}
          configuratorStyle={configuratorStyle}
        />
      );
    }

    return (
      <SidebarButton
        url={url}
        action={action}
        pathname={location.pathname}
        name={t(name)}
        Icon={Icon}
        configuratorStyle={configuratorStyle}
      />
    );
  }
  return (
    <span>
      {items.length > 0 && (
        <div>
          <SidebarListItemButton
            key={name}
            selected={url === location.pathname}
            sx={{ borderRadius: '8px' }}
            onClick={toggleMenu}>
            {Icon && (
              <ListItemIcon
                sx={{
                  minWidth: '28px'
                }}>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={name}
              sx={{
                '.MuiTypography-root': {
                  fontWeight: '600',
                  fontSize: '14px'
                }
              }}
            />
            {showMenu ? <ExpandLess /> : <ExpandMore />}
          </SidebarListItemButton>
          <Collapse in={showMenu} timeout='auto' unmountOnExit>
            <List>
              {items.map((item: MenuItem) => {
                if (!userCanVisit(item?.roles, rolesByName)) {
                  return null;
                }

                if (item.url) {
                  return (
                    <StandardNavLink
                      url={item.url}
                      key={item.url}
                      pathname={location.pathname}
                      name={t(item.name)}
                      action={item.action}
                      lightFont
                    />
                  );
                }

                return (
                  <SidebarButton
                    key={item.name}
                    url={item.url}
                    action={item.action}
                    pathname={location.pathname}
                    name={t(item.name)}
                    lightFont
                  />
                );
              })}
            </List>
          </Collapse>
        </div>
      )}
    </span>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { rolesByName, isLoading } = useUserData();

  return (
    <SidebarWrapper>
      <LogoWrapper>
        <NavLink to={DASHBOARD}>
          <Logo src={logo} />
        </NavLink>
      </LogoWrapper>
      <ListWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            padding: '24px 0'
          }}>
          <List
            sx={{
              padding: '0 20px',
              display: 'flex',
              gap: '4px',
              flexDirection: 'column'
            }}>
            {!isLoading &&
              MENU_CONFIG.map((element) => {
                if (!userCanVisit(element?.roles, rolesByName)) {
                  return null;
                }
                return (
                  <MenuCustomItem
                    data-testid={element.name}
                    key={element.name}
                    url={element.url}
                    name={t(element.name)}
                    items={element.items ?? []}
                    Icon={element.icon}
                  />
                );
              })}
            {isLoading && <CircularProgress />}
          </List>
          <List
            sx={{
              padding: '0 20px',
              display: 'flex',
              gap: '4px',
              flexDirection: 'column'
            }}>
            {!isLoading &&
              MENU_BOTTOM_CONFIG.map((element) => {
                if (!userCanVisit(element?.roles, rolesByName)) {
                  return null;
                }
                return (
                  <MenuCustomItem
                    data-testid={element.name}
                    key={element.name}
                    url={element.url}
                    name={t(element.name)}
                    items={element.items ?? []}
                    Icon={element.icon}
                    action={element.action}
                  />
                );
              })}
            {isLoading && <CircularProgress />}
          </List>
        </div>
      </ListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
